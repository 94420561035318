/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
export interface EventHandlerMap {
  [type: string]: Array<Handler>;
}
export type Handler = (event?: any, changed?: any) => void;

export default class Emitter {
  public all: EventHandlerMap;

  public constructor(all?: EventHandlerMap) {
    this.all = all || Object.create(null);
  }

  /**
   * Register an event handler for the given type.
   * @param type Type of event to listen for.
   * @param handler Function to call in response to given event.
   */
  public on(type: string, handler: Handler): void {
    (this.all[type] || (this.all[type] = [])).push(handler);
  }

  /**
   * Remove an event handler for the given type.
   * @param type Type of event to unregister `handler` from.
   * @param handler Handler function to remove.
   */
  public off(type: string, handler: Handler): void {
    if (this.all[type]) {
      this.all[type].splice(this.all[type].indexOf(handler) >>> 0, 1);
    }
  }

  /**
   * Removes all event handlers to prevent memory leaks.
   */
  public reset(): void {
    this.all = {};
  }

  /**
   * Invoke all handlers for the given type.
   * @param type The event type to invoke.
   * @param evt Any value (object is recommended and powerful),
   * passed to each handler.
   * @param changed Any changed values if the event encompasses more.
   */
  public emit(type: string, evt?: any, changed?: any): void {
    (this.all[type] || []).slice().forEach(handler => changed ? handler(evt, changed) : handler(evt));
  }
}

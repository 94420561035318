/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2020 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, RUNTIME, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/aem-migration.ts

export const aemCloudMigration: SolutionRoute = {
  appId: 'aem-migration',
  analytics: {
    code: 'cam',
    scripts: {
      dev: '//assets.adobedtm.com/a7d65461e54e/1df46f55b80a/launch-7f21d788b890-staging.min.js',
      qa: '//assets.adobedtm.com/a7d65461e54e/1df46f55b80a/launch-7f21d788b890-staging.min.js',
      stage: '//assets.adobedtm.com/a7d65461e54e/1df46f55b80a/launch-7f21d788b890-staging.min.js',
      prod: '//assets.adobedtm.com/a7d65461e54e/1df46f55b80a/launch-b371e5428f52.min.js'
    }
  },
  spaAppId: 'cq-aem-cloud-adoption-ui',
  gainsight: {productKey: 'AP-UBWAIBMYGXAJ-2'},
  path: '/aem/migration',
  runtime: RUNTIME.CDN,
  serviceCode: 'dma_aem_cloud',
  sandbox: {
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:12399'
    }
  },
  access: {
    code: ['dma_aem_ams', 'dma_aem_onprem', 'dma_aem_cloud'],
    flag: 'aem-on-prem-enabled'
  }
};

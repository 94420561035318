/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import {PDH_GLOBAL} from '../models/Solution';
/* eslint-disable sort-keys */
export interface PDHScripts {
  scripts : {
    [key: string]: string;
  }
}

export const pdhGlobalMap: Record<PDH_GLOBAL, PDHScripts> = {
  [PDH_GLOBAL.AA]: {
    scripts: {
      dev: `https://exc-unifiedcontent.experience-stage.adobe.net/static/launch/a7d65461e54e/9facf15f232c/launch-72ef4c8b9d13-development.min.js`,
      qa: `https://exc-unifiedcontent.experience-stage.adobe.net/static/launch/a7d65461e54e/9facf15f232c/launch-72ef4c8b9d13-development.min.js`,
      stage: `https://exc-unifiedcontent.experience-stage.adobe.net/static/launch/a7d65461e54e/9facf15f232c/launch-5ec5233fa57f-staging.min.js`,
      prod: `https://exc-unifiedcontent.experience.adobe.net/static/launch/a7d65461e54e/9facf15f232c/launch-d7625967ab29.min.js`
    }
  },
  [PDH_GLOBAL.WEBSDK]: {
    scripts: {
      dev: 'https://exc-unifiedcontent.experience-stage.adobe.net/static/launch/a7d65461e54e/42cd47aaea34/launch-7a9519e58acb-development.min.js',
      qa: 'https://exc-unifiedcontent.experience-stage.adobe.net/static/launch/a7d65461e54e/42cd47aaea34/launch-7a9519e58acb-development.min.js',
      stage: 'https://exc-unifiedcontent.experience-stage.adobe.net/static/launch/a7d65461e54e/42cd47aaea34/launch-f9d24f81368c-staging.min.js',
      prod: 'https://exc-unifiedcontent.experience.adobe.net/static/launch/a7d65461e54e/42cd47aaea34/launch-27e001f5444b.min.js'
    }
  },
  [PDH_GLOBAL.BOTH]: {
    scripts: {
      dev: 'https://exc-unifiedcontent.experience-stage.adobe.net/static/launch/a7d65461e54e/799ee354b05f/launch-0c3516d9a43b-development.min.js',
      qa: 'https://exc-unifiedcontent.experience-stage.adobe.net/static/launch/a7d65461e54e/799ee354b05f/launch-0c3516d9a43b-development.min.js',
      stage: 'https://exc-unifiedcontent.experience-stage.adobe.net/static/launch/a7d65461e54e/799ee354b05f/launch-7ee01764bcdd-staging.min.js',
      prod: 'https://exc-unifiedcontent.experience.adobe.net/static/launch/a7d65461e54e/799ee354b05f/launch-38f35b9cfbad.min.js'
    }
  }
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, PDH_GLOBAL, RUNTIME, SessionScope, SolutionRoute, THUNDERBIRD} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/starter.ts

const starter: SolutionRoute = {
  appId: 'starter',
  autoInstantiateRuntime: true,
  path: '/sample',
  redirectFrom: [{path: '/starter'}],
  sharedCaches: ['cacheSample'],
  thunderbird: THUNDERBIRD.SERVICE_WORKER,
  spaAppId: 'exc-unified-shell-starter',
  analytics: {omegaGlobal: PDH_GLOBAL.WEBSDK},
  runtime: RUNTIME.CDN,
  sandbox: {
    history: {
      type: HISTORY.HASH,
      config: {addParamsToHash: true}
    },
    sources: {
      dev: 'https://localhost.corp.adobe.com:12399'
    }
  },
  session: {
    defaultTTL: 7200, // 2 hours
    scope: SessionScope.CONTEXT
  },
  urlContext: {key: 'sandbox', optional: true}
};

export default starter;

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {addIframeSrcParams, cleanQuery, getPaths, hasChanges} from '@exc/url';
import {APIService} from '../services/APIService';
import {AppEvents, ContextWaitingState, ErrorTypes, HTMLStatus} from '../enums';
import appLoadState from './AppLoadState';
import type {
  AppLoadState,
  AppMessage,
  AppSandbox,
  AppSandboxProps,
  AppSandboxState,
  ReadyProperties,
  SPAApp
} from '../models/AppSandbox';
import type {AppMonitorService} from '../services/AppMonitorService';
import classNames from 'classnames';
import type {CoreContext} from '../models/Context';
import CoreContextType from '../context';
import CoreStrings from '../CoreStrings';
import type {DiscoveryService} from '../services/DiscoveryService';
import {DonePayload, extractTokenMetadata, wait} from '@exc/shared';
import {
  getApplicationSourceUrl,
  getBasePath,
  getRuntimeParam,
  getSpaAppConfig,
  hasConfigChanges,
  initSandbox,
  isAppHealthy,
  isOrgInAccount,
  isShellInfoForIncorrectOrg,
  prefetchOnLoad,
  setMetricsApplication,
  shouldWaitForContextUpdates,
  updateDigitalData,
  updateSourceWithParams,
  updateTemplatedURL
} from '../utils/appSandboxUtils';
import {getHistoryType} from '../utils';
import type {Hero} from '../models/Hero';
import {history} from '@exc/router';
import {HISTORY, HistoryConfig, Section} from '../models/Solution';
import Iframe from './Iframe';
import type {IMS} from '@adobe/exc-app/user';
import {Internal} from '@adobe/exc-app/internal';
import {isWorkerActivated} from '../utils/workerUtils';
import {MessageService} from '../services/MessageService';
import metrics, {Level, Events as MetricsEvents} from '@adobe/exc-app/metrics';
import orchestrationService from '../services/OrchestrationService';
import type {PerformanceRecord} from '@adobe/exc-app/page';
import React from 'react';
import SandboxSpinner from './SandboxSpinner';
import {THUNDERBIRD_SPA_ROOT} from '../constants';
import type {Timer} from '@adobe/exc-app/metrics/Metrics';
import TitleService from '../services/TitleService';
import type {UserInputService} from '../services/UserInputService';
import type {WorkHubItem} from '@exc/graphql/src/models/workhub';

const ErrorView = React.lazy(() => import('./Error'));

export default class Sandbox extends React.Component<AppSandboxProps, AppSandboxState> implements AppSandbox {
  private _discoveryTime = 0;
  private appDone = false;
  private currentSection = '';
  private invalidSource = '';
  private blockedUri = '';
  private messageService = new MessageService();
  private monitorService?: AppMonitorService;
  private monitorServicePromise?: Promise<void>;
  private prevContext?: CoreContext;
  private prevSection = '';
  private readonly apiService: APIService;
  private readonly historyConfig: HistoryConfig;
  private readonly isComposedApp: boolean;
  private readonly sandboxId: string;
  private readonly sandboxStart?: number;
  private readonly sandboxTimer: Timer;
  private readonly sections?: Section[];
  private readonly userInputServicePromise: Promise<UserInputService>;
  private initialState: Partial<AppLoadState> = {};
  private readyProperties?: ReadyProperties;
  private waitForContext: ContextWaitingState | false = false;
  private waitForDiscovery = false;
  private waitForReady = false;

  public basePath = '';
  public configURL?: URL;
  public connected = false;
  // eslint-disable-next-line react/static-property-placement
  public readonly context: CoreContext;
  public customIms?: IMS;
  public frame?: HTMLIFrameElement;
  public initialized = false;
  public lastAppId?: string;
  public metricsAppId: string;
  public mounted = true;
  public origin = '';
  public readonly appId: string;
  public readonly historyType: HISTORY;
  public readonly metrics = metrics.create('exc.core.modules.Sandbox');
  public readonly titleService: TitleService;
  public waitForRuntime = false;

  constructor(props: AppSandboxProps, context: CoreContext) {
    super(props);

    this.context = context;
    this.apiService = new APIService(this.messageService);
    this.userInputServicePromise = import('../postAuth')
      .then(({UserInputService}) => new UserInputService(this.messageService, this));

    const {appId, metricsAppId = appId, sandbox: {hideInitialSpinner, history: historyConfig, ims}, sections, sideNav} = props.config;
    const {workHubResponse} = context;
    const {config = undefined} = typeof historyConfig === 'object' ? historyConfig : {};

    // Create the title service for managing titles for this current application.
    this.titleService = new TitleService(props.config, undefined, workHubResponse);
    this.customIms = ims;

    this.appId = appId;
    this.isComposedApp = !!sideNav?.workHubId;
    this.metricsAppId = metricsAppId;
    initSandbox(this);
    this.sections = sections;

    // If the user switched from app A to app B, and app A did not call "page.done",
    // we want to turn firstRender off so we don't log performance metrics for app B.
    const {lastAppId, sandboxId} = appLoadState.onSandboxLoad(appId);
    this.lastAppId = lastAppId;
    this.sandboxId = sandboxId;
    // On app switch, we time the load from the Sandbox constructor since Unified Shell
    // is already loaded.
    this.lastAppId && (this.sandboxStart = performance.now());
    this.historyConfig = config || {};
    this.historyType = getHistoryType(props.config);
    this.onMessage = this.onMessage.bind(this);
    this.onMount = this.onMount.bind(this);
    this.sandboxTimer = this.metrics.start('Sandbox', {applicationId: this.metricsAppId});

    this.generateIframeSource();
    this.state = {
      frameKey: 0,
      main: false,
      noSource: false,
      notFound: false,
      showWaitMessage: false,
      spinner: !hideInitialSpinner,
      ...this.initialState
    };
  }

  get solutionConfig() {
    return this.props.config;
  }

  get source() {
    return this.state.src;
  }

  set customFeedback(feedback: boolean) {
    this.userInputServicePromise.then(userInputService => userInputService.customFeedback = feedback);
  }

  inErrorState = () => !!this.state.error;

  inNotFoundState = () => this.state.notFound;

  isSpinnerOn = () => {
    const {src, spinner} = this.state;
    return !src || this.context.htmlReady === HTMLStatus.PENDING || spinner;
  };

  setSpinner(show: boolean) {
    this.metrics.event(show ? MetricsEvents.SPINNER_START : MetricsEvents.SPINNER_DONE);
    show ? this.setState({spinner: true}) : this.setState({showWaitMessage: false, spinner: false});
  }

  getLoadState = (): AppLoadState => {
    const {
      blockedUri, connected, frame, initialized, invalidSource, waitForContext, waitForDiscovery, waitForReady, waitForRuntime
    } = this;
    return {
      blockedUri,
      connected,
      hasFrame: !!frame,
      initialized,
      invalidSource,
      spinnerOn: this.isSpinnerOn(),
      waitForContext,
      waitForDiscovery,
      waitForReady,
      waitForRuntime
    };
  };

  async setPageTimeout() {
    if (this.props.config.sandbox.pageTimeout === 0) {
      // PageTimeout set to 0 means there is no page timeout so we don't need
      // to bother setting up the monitor service
      this.metrics.event('Sandbox.noTimeout', {level: Level.INFO});
      return;
    }
    const start = Date.now();
    const needTimeout = () => (!this.initialized || this.isSpinnerOn()) && isAppHealthy(this);
    if (!this.monitorServicePromise) {
      // Wait 5 seconds before loading the monitor JS - We might not need it at all.
      // We'll deduct the 5 seconds + JS load time later by using 'start' above.
      await wait(5000);
      if (!needTimeout()) {
        // No need anymore for a timeout, return.
        return;
      }

      // 5 seconds passed, load the service if not loaded already.
      this.monitorServicePromise || (this.monitorServicePromise = import('../services/AppMonitorService')
        .then(({AppMonitorService}) => {
          this.monitorService = new AppMonitorService(this);
          this.monitorService.on(AppEvents.SLOW, () => this.setState({showWaitMessage: true}));
          this.monitorService.on(AppEvents.TIMEOUT, ({error, errorCode}) => this.setState({error, errorCode}));
        }));
    }

    await this.monitorServicePromise;
    needTimeout() && this.monitorService?.setPageTimeout(this, start);
  }

  /**
   * Set state if component is mounted, otherwise set initial state.
   * Initial state will be applied to state by the constructor.
   * @param state
   * @private
   */
  private safeSetState(state: Partial<AppSandboxState>) {
    if (this.state) {
      return this.setState(state as AppSandboxState);
    }
    this.initialState = {...this.initialState, ...state};
  }

  private updateSource(src: URL, useRootPath = false) {
    // If we don't have a legit source, send diagnostic info to EIM.
    (!src || !src.toString().startsWith('http')) && this.metrics.event(
      'Sandbox.invalidSource',
      {level: Level.ERROR},
      {
        appId: this.metricsAppId,
        nonSupportedBrowser: this.context.nonSupportedBrowser,
        src
      });
    orchestrationService.onStartLoad();
    // Future improvement: Set Not Found or Error state if can't get a source.
    // This should be done in ticket EXC-14593 after we verify there is no
    // legit reason for source to be empty here.
    if (useRootPath) {
      src.pathname = '/';
    }
    return this.safeSetState({src});
  }

  public async generateIframeSource(forceReload = false, useRootPath = false): Promise<void> {
    const {addParamsToHash} = this.historyConfig;
    const {mfeAppIds, sandbox: {overrideParams = false}, spaAppId} = this.props.config;
    const isHashType = this.historyType === HISTORY.HASH;
    const src = getApplicationSourceUrl(this);

    if (!src) {
      this.safeSetState({noSource: true, notFound: true});
      return;
    }

    // Waiting for IMS to return in the case of a discovery URL.
    // In the case of a regular source, let's proceed!
    this.waitForContext = shouldWaitForContextUpdates(this, src);
    if (this.waitForContext) {
      return;
    }

    this.basePath = getBasePath(this);
    let discoveryHasUrl = false;
    const iframeTimer = this.metrics.start('IframeTimer');

    if (typeof src === 'object') {
      const {discoveryService} = this.context;
      // Restart the timeout countdown for discovery
      this.setPageTimeout();
      this.waitForDiscovery = true;
      const result = await (discoveryService as DiscoveryService).generateIframeSourceDiscovery(src, this);
      if ('src' in result) {
        const {customIms, hasUrl, src: srcUrl} = result;
        customIms && (this.customIms = customIms);
        discoveryHasUrl = hasUrl;
        this.configURL = srcUrl;
      } else {
        const {error, showErrorPage} = result;
        showErrorPage && this.safeSetState({error, noSource: true, notFound: true});
        return;
      }
      this.waitForDiscovery = false;
      this.inErrorState() && this.safeSetState({error: undefined, noSource: false, notFound: false});
      this.setPageTimeout();
    } else {
      this.configURL = new URL(updateTemplatedURL(src, this, this.props.params));
    }

    this.origin = this.configURL.origin;

    let spaApp: SPAApp | undefined;
    // Gets the spaApp and gets the liveVersion from the window config or an
    // enabled feature flag. If no liveVersion is available, don't use it.
    if (spaAppId) {
      const spaAppData = getSpaAppConfig(spaAppId, this);
      spaAppData.liveVersion !== undefined && (spaApp = spaAppData as SPAApp);
    }

    let spaApps = spaApp ? [spaApp] : [];

    if (mfeAppIds?.length) {
      spaApps = [...spaApps, ...mfeAppIds.map(id => getSpaAppConfig(id, this) as SPAApp)];
    }

    // SPA Pipeline apps should always get Runtime from the same domain as Shell.
    const runtimeParam = getRuntimeParam(this);
    const iframeSrc = new URL(this.configURL);
    const isThunderbird = iframeSrc.pathname.startsWith(THUNDERBIRD_SPA_ROOT);

    isThunderbird && Internal.preloadEngine();

    addIframeSrcParams({
      addRuntimeParams: !isThunderbird,
      addToQuery: !discoveryHasUrl && !(isHashType && addParamsToHash),
      appId: iframeSrc.origin === window.origin ? this.appId : undefined,
      overrideParams,
      runtimeParam,
      spaApps,
      src: iframeSrc
    });

    // Set the application in metrics
    setMetricsApplication(this.metricsAppId, this.props.config, spaApp);

    // If discovery was used and is not from the cache, we should have the
    // entire URL, so we can just set the src and stop. If the discovery
    // response didn't have the url (came from config), or if the discovery came
    // from cache, we need to pull all the path and query params from the
    // current URL so that it's on the correct page.
    if (discoveryHasUrl || useRootPath) {
      forceReload && iframeSrc.searchParams.set('shell_forceReload', Date.now().toString());
      if (discoveryHasUrl) {
        this._discoveryTime = iframeTimer.time('Loaded.Discovery.NotCache', {url: src});
        // Update the digital data for the current app.
        updateDigitalData(this);
      }
      return this.updateSource(iframeSrc, useRootPath);
    }

    // Update the digital data for the current app.
    updateDigitalData(this, spaApp?.liveVersion);

    const url = updateSourceWithParams(iframeSrc, forceReload, this);
    this.origin = url.origin;
    this._discoveryTime = iframeTimer.time('Loaded', {url});
    this.updateSource(url);

    // If app not yet initialized, start countdown again
    this.setPageTimeout();
  }

  componentWillUnmount() {
    this.userInputServicePromise.then(userInputService => userInputService.unmount());
    this.apiService.unmount(this);
    this.frame = undefined;
    this.mounted = false;
    this.monitorServicePromise?.then(() => this.monitorService?.reset());
    appLoadState.unregisterSandbox(this.sandboxId);
  }

  componentDidMount() {
    this.monitorServicePromise || this.setPageTimeout();
  }

  componentDidUpdate(prevProps: Readonly<AppSandboxProps>) {
    const getParam = (p: Record<string, string>) => (Object.keys(p).length ? p[0] : '') || '';
    const {prevContext, props: {hash, params}} = this;
    const query = cleanQuery(this.props.query);
    const nextParams = getParam(params);
    const prevParams = getParam(prevProps.params);
    const paramsTheSame = prevParams === nextParams &&
      prevProps.params.contextParam === params.contextParam &&
      JSON.stringify(prevProps.query) === JSON.stringify(query) &&
      prevProps.hash === hash;
    this.prevContext = this.context;

    if (!paramsTheSame) {
      // This could possibly be moved into a different lifecycle method
      const {spinnerOnChange} = this.historyConfig;
      if (this.historyType === HISTORY.SERVER && spinnerOnChange) {
        this.setState({spinner: true});
      }
      this.basePath = getBasePath(this);
      this.apiService.updateAppHistory(nextParams, this);

      // The path within the application is different, but the previous appId
      // and the current appId are the same. For Platform and AJO Platform,
      // remove the block navigation configuration.
      //
      // This is necessary because Platform is itself a monolith that contains
      // many applications within it. When an application changes, a new Sandbox
      // does not load because the main application does not change.
      const {appId} = this.props.config;
      const {appId: prevAppId} = prevProps.config;
      if (
        prevAppId === appId &&
        ['experiencePlatformUI', 'cjm-platform'].includes(appId) &&
        prevParams.split('/')[1] !== nextParams.split('/')[1]
      ) {
        this.titleService.update(undefined, true);
        this.apiService.unblockNavigation();
      }
    }

    this.apiService.resetOnNewConfig(prevProps, this) && this.generateIframeSource();

    // On ready failed because the token wasn't available at the time. Trigger
    // it again now that the custom token is available. The readyProperties is
    // used to ensure that ready has been called already and is waiting for
    // the custom token to be available.
    if (
      this.customIms &&
      !this.initialized &&
      this.context.appToken &&
      this.readyProperties &&
      hasConfigChanges(prevContext, this.context, ['appToken'])
    ) {
      this.onReady(this.readyProperties);
    }

    // The path changed (& fired a history event in shouldComponentUpdate)
    // And Not Found was previously active.
    // We should hide Not Found since the page navigated and may not be needed now.
    !paramsTheSame && this.state.notFound && !this.state.noSource && this.setState({notFound: false});

    const shouldWaitForContext = shouldWaitForContextUpdates(this);

    shouldWaitForContext || this.apiService.processEventQueue(this);

    // Should always update the hero portion of the title if this is the first
    // load (no prevContext) or there are changes to the hero.
    if (!prevContext || hasConfigChanges(prevContext, this.context, ['hero'])) {
      this.titleService.hero = this.context.hero as Hero;
    }

    // If the Sandbox is not yet connected and imsOrg is available, prefetch data.
    !this.connected && hasConfigChanges(prevContext, this.context, ['imsOrg']) && prefetchOnLoad(this);

    // If this is a Work Hub-enabled composed app,
    // update title once Work Hub response is defined.
    if (this.isComposedApp && hasConfigChanges(prevContext, this.context, ['workHubResponse'])) {
      this.titleService.workHubResponse = this.context.workHubResponse as WorkHubItem[];
    }

    // Regenerate iframe source when IMS profile is known.
    // This is the case when discovery URL needs to be invoked.
    // Otherwise, dont need to do this since IMS profile isn't needed to start loading.
    if (this.waitForContext && !shouldWaitForContext) {
      this.waitForContext = false;
      this.generateIframeSource();
      return;
    } else if (shouldWaitForContext) {
      // Wait for context reason might have changed, update.
      this.waitForContext = shouldWaitForContext;
    }

    const {generateSource, sendConfiguration, useRootPath} = this.apiService.onContextUpdate(prevContext, this);
    generateSource && this.generateIframeSource(true, useRootPath);
    sendConfiguration && this.sendConfiguration();
  }

  onMount(frame: HTMLIFrameElement) {
    this.frame = frame;
    this.sandboxTimer.time('init', {
      appId: this.metricsAppId,
      discoveryTime: this._discoveryTime
    });
    // If somehow we got a ping before mount, process queue.
    this.connected && this.messageService.processMessageAfterMount(this);
  }

  /**
   * To be run on page load. Runs on every server history change.
   * @param obj The below params.
   * @param obj.hash Hash from iframe.
   * @param obj.href Full iframe URL.
   * @param obj.pathname Pathname of iframe.
   * @param obj.search Search from iframe.
   */
  async onReady({hash, href, pathname, search}: ReadyProperties) {
    this.waitForReady = true;
    this.basePath = getBasePath(this);

    // In the "server" case (meaning, traditional server rendered app)
    // we want to redirect to the correct url if not already served on ready
    if (this.historyType === HISTORY.SERVER) {
      // Get and combine paths and search strings
      const {absolutePaths} = this.historyConfig;
      const {browser, iframe} = getPaths(pathname, search, hash, !!absolutePaths, this.basePath, this.configURL as URL);
      const browserString = browser.path + browser.search + browser.hash;
      const iframeString = iframe.path + iframe.search + iframe.hash;
      // Update metrics app ID if section has changed.
      // i.e. Analytics move between its SPA and PHP sections.
      if (this.sections) {
        const section = this.sections.find(sec => iframeString.startsWith(sec.route));
        if (section) {
          this.metricsAppId = section.metricsAppId;
          this.prevSection = this.currentSection || '';
          this.currentSection = section.name;
          setMetricsApplication(this.metricsAppId, this.props.config);
        }
      }
      // Compare the iframe's path with the current browser path
      if (hasChanges(browserString, iframeString).any) {
        // If not matching, update the browser's path to match iframe path.
        // Additionally, protect against multiple // in a row.
        history.replace((this.basePath + iframeString).replace(/^\/{2,}/g, '/'));
      }
    }

    // If custom IMS is required but the custom token is not available yet, set
    // the ready properties so that it can be called from the componentDidUpdate
    // method when appToken is available.
    if (this.customIms) {
      if (!this.context.appToken || !this.context.imsInfo?.imsProfile?.userId) {
        this.readyProperties = {hash, href, pathname, search};
        return;
      }

      // If the custom token exists, validate that the user is correct. This was
      // previously caused by a timing issue due to regular profiles and type2e
      // profiles.
      if (this.context.appToken) {
        const {user_id: userId} = extractTokenMetadata(this.context.appToken) || {};
        // If the userId from the token does not match the userId of the profile
        // of the loaded user, request a new token for this client.
        if (userId !== this.context.imsInfo.imsProfile.userId) {
          this.metrics.info('custom token userId mismatch');
          this.context.configureCustomIms(this.customIms).catch(() => {
            this.metrics.error('custom token failed to match userId of profile');
            this.setState({error: ErrorTypes.UNKNOWN});
          });
          // Since all the token data comes from the context, this should wait for
          // the next context update to finish initialization and trigger
          // sendConfiguration.
          this.readyProperties = {hash, href, pathname, search};
          return;
        }
      }
    }

    this.sandboxTimer.time(this.initialized ? 'reconnected' : 'connected', `Solution loaded: ${this.metricsAppId}`, {
      appId: this.metricsAppId,
      customIms: this.customIms,
      hash,
      href,
      pathname,
      search
    });

    this.initialized = true;
    this.waitForReady = false;

    this.sendConfiguration(true);
  }

  /**
   * Only send configuration payloads if it's initialized and there is
   * a valid profile. If the app requests sandboxes, ensure that they are present.
   * @param force - If force is true, we always send the configuration event.
   */
  shouldSendConfiguration(force: boolean) {
    const {accountCluster, appProfile, imsOrg, imsProfile, sandbox, subOrg} = this.context;
    const {urlContext} = this.props.config;
    const waitForSandboxes = urlContext?.key === 'sandbox' &&
      (!('optional' in urlContext) || !urlContext.optional) &&
      (!sandbox || (!!sandbox.imsOrg && sandbox.imsOrg !== imsOrg));
    const waitForSubOrg = urlContext?.key === 'subOrg' && !subOrg;
    const profile = this.customIms ? appProfile : imsProfile;
    const orgAccountMismatch = !isOrgInAccount(accountCluster, imsOrg, imsProfile);
    isShellInfoForIncorrectOrg(this);

    if (!force && !this.initialized || orgAccountMismatch || waitForSandboxes || waitForSubOrg) {
      // If solution is not yet ready but Shell is (not initialized and imsProfile available)
      // send a sendConfiguration event with solutionReady false.
      if (!this.initialized && profile && !waitForSandboxes && !waitForSubOrg) {
        this.sandboxTimer.time('sendConfiguration', {solutionReady: false});
      }
      return false;
    }
    return true;
  }

  async sendConfiguration(force = false) {
    // Check to see if there is any reason to delay sending the configuration
    if (this.shouldSendConfiguration(force)) {
      const sent = await this.apiService.sendConfiguration(this);
      sent && this.sandboxTimer.time('sendConfiguration', {
        firstSend: this.apiService.isFirstSend,
        solutionReady: true
      });
    }
  }

  onMessage = (message: AppMessage<any>) => this.messageService.processMessage(message, this);

  /**
   * Get app load performance data.
   * @param iframePerformance - Serialized Iframe performance JSON
   * @param version - SPA version (optional)
   * @returns Performance data
   */
  getLoadInfo({iframePerformance = '', version = ''}: DonePayload): PerformanceRecord {
    const {spaAppId, thunderbird} = this.props.config;
    const {getPerformanceMarks} = this.context;
    const firstRender = appLoadState.updateFirstRender();

    if (getPerformanceMarks) {
      return getPerformanceMarks({
        appDone: this.appDone,
        appId: this.metricsAppId,
        firstRender,
        iframePerformance,
        lastAppId: this.lastAppId,
        spaAppId,
        src: this.state.src || '',
        start: this.sandboxStart,
        thunderbird,
        version
      });
    }

    return {
      appId: this.metricsAppId,
      error: 'getPerformanceMarks not available',
      serviceWorker: isWorkerActivated(),
      version
    };
  }

  onDone(value: DonePayload): PerformanceRecord {
    this.setState({main: value.addMainElement, showWaitMessage: false, spinner: false});
    let loadInfo = this.getLoadInfo(value);
    if (this.currentSection) {
      loadInfo = {...loadInfo, previousSection: this.prevSection, section: this.currentSection};
    }
    this.sandboxTimer.time({event: MetricsEvents.PAGE_LOAD_DONE}, this.metricsAppId, loadInfo);
    // For availability KPI, need an event that only fires once.
    this.appDone || this.metrics.event('Sandbox.appDone', {appId: this.metricsAppId});
    this.appDone = true;
    updateDigitalData(this);
    // Invoke TUX check in Core for all users
    this.context.getTux();
    this.metrics.analytics.trackPage({attributes: {done: true}});
    return loadInfo;
  }

  onNotFound = () => this.setState({notFound: true});

  onFrameReload = (cacheBust: boolean) => {
    this.metrics.event('Sandbox.iframeReload', {cacheBust});
    cacheBust ? this.generateIframeSource(true) : this.setState({frameKey: this.state.frameKey + 1});
  };

  onCSPViolation = (blockedURI: string) => {
    // This allows us to capture cases where the application redirects to the
    // IMS login page. IMS blocks these requests as it cannot be loaded in an
    // iframe. For Workfront now, maybe globally in the future, we want to
    // handle this and sign out the user to ensure they have a valid session on
    // all levels of applications.
    if (this.appId === 'workfront' && blockedURI.indexOf('ims-na1.adobelogin.com') > 0) {
      this.context.onSignOut(true, 'Iframe redirected to SUSI');
      return;
    }
    this.blockedUri = blockedURI;
  };

  handlePostMessage = (type: string, value: any) => this.apiService.handleMessage(type, value, this);

  render() {
    const {appContainer, intl} = this.context;
    const {error, errorCode, main, noSource, notFound, showWaitMessage, src} = this.state;
    if (error) {
      // The error property can be either a string or an object. Grab the
      // properties from the object (or converted string to object) and pass
      // the additional information (rest) into the Error component.
      const {id, ...rest} = typeof error === 'string' ? {errorCode, id: error} : error;
      this.sandboxTimer.time('render', {message: id});
      return (
        <React.Suspense fallback={SandboxSpinner}>
          <ErrorView type={id} {...rest} />
        </React.Suspense>
      );
    }

    const spinner = this.isSpinnerOn();
    const name = intl.formatMessage(CoreStrings.solutionIframe);

    const sandboxProps = main ? {role: 'main'} : {};
    this.sandboxTimer.time('render', {appId: this.metricsAppId, main, spinner, src});
    const showSpinner = !notFound && spinner;
    const permissionsPolicy = `fullscreen;${(this.props.config.permissionsPolicy || []).join(';')}`;

    return (
      <div className={classNames('exc-core-sandbox exc-core-sandbox-common', {appContainer})} {...sandboxProps}>
        {notFound &&
          <React.Suspense fallback={SandboxSpinner}>
            <ErrorView type={noSource ? ErrorTypes.UNKNOWN : ErrorTypes.NOT_FOUND} />
          </React.Suspense>}
        {src && !notFound &&
          <Iframe
            frameKey={String(this.state.frameKey)}
            name={name}
            onFrameSrcViolation={this.onCSPViolation}
            onInvalidSource={(source: string) => this.invalidSource = source}
            onMessage={this.onMessage}
            onMount={this.onMount}
            permissionsPolicy={permissionsPolicy}
            sandboxId={this.sandboxId}
            src={src ? src.toString() : ''}
          />
        }
        {showSpinner && <SandboxSpinner ignoreNav={false} showWaitMessage={showWaitMessage} />}
      </div>
    );
  }
}

Sandbox.contextType = CoreContextType;

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
// eslint-disable-next-line no-unused-vars
import type {DiscoverySource} from './Discovery';
import type {ExperienceLeague} from '@adobe/exc-app/shell';
import type {IMS} from '@adobe/exc-app/user';
import type {Location} from 'history';
import type {ProductContext} from '@adobe/exc-app/ims/ImsProfile';
import {RELEASE_SCOPE, RELEASE_TYPE, THUNDERBIRD} from '@adobe/exc-app/page';

export {RELEASE_SCOPE, RELEASE_TYPE, THUNDERBIRD};
export enum SessionScope {
  /**
   * User - Session is valid for the current user only.
   */
  USER = 'user',
  /**
   * Organization - Session is valid for the current user and organization only.
   */
  ORG = 'org',
  /**
   * Context - Session is valid for the current user, organization and context only.
   * Context depends on the solution - Could be Sandboxes or Sub orgs.
   */
  CONTEXT = 'context'
}

export enum PDH_GLOBAL {
  AA = 'aa',
  WEBSDK = 'websdk',
  BOTH = 'both'
}

export enum HISTORY {
  HASH = 'HASH',
  HISTORY = 'HISTORY',
  SERVER = 'SERVER'
}

export enum RUNTIME {
  LEGACY,
  CDN_LEGACY, // Azure Frontdoor CDN (exc-unifiedcontent.experience.adobe.net)
  CDN // AFD & AWS CloudFront (cdn.experience.adobe.net)
}

export enum THEME {
  DARK = 'darkest',
  LIGHT = 'lightest'
}

export enum HASH_TYPE {
  NOSLASH = 'noslash'
}

export interface HistoryConfig {
  absolutePaths?: boolean;
  addParamsToHash?: boolean;
  spinnerOnChange?: boolean;
  hashType?: HASH_TYPE;
}

export interface HistoryExtended {
  type: HISTORY;
  config: HistoryConfig;
}

interface VisibleName {
  code: string;
  name: RegExp;
}

export interface FulfillableDataItem {
  code: string;
  property: string;
  propertyDefault?: any;
  value: string;
  exists?: boolean;
}

export const FLOODGATE_FLAG_PREFIX = 'fg:';

export interface Access {
  code?: string[];
  codeLogicOp?: string;
  fd?: FulfillableDataItem[];
  fdLogicOp?: string;
  fi?: string[];
  fiLogicOp?: string;
  flag?: string | string[];
  flagLogicOp?: string;
  logicOp?: string;
  subServiceCode?: string[];
  visibleName?: VisibleName[];
}

export interface SessionConfig {
  defaultTTL?: number;
  getFromDiscovery?: boolean;
  multiSessions?: boolean;
  scope: SessionScope;
}

export interface EnvironmentMap<T> {
  [key: string]: T;
}

export interface GainsightTriggers {
  fulfillableItems: string[];
  serviceCode: string;
}

interface Gainsight {
  productKey: string;
  triggers?: GainsightTriggers;
}

export type SandboxEnv = 'dev' | 'qa' | 'stage' | 'prod';

interface NavItem {
  heading?: string;
  icon?: string;
  id?: string;
  items?: NavItem[];
  name?: string;
  url?: string;
}

export interface Redirect {
  path: string;
  options?: {
    end: boolean;
  }
}

export interface SideNav {
  id?: string;
  closeOnLoad?: boolean;
  dev?: string;
  qa?: string;
  stage?: string;
  prod?: string;
  workHubFeatureFlag?: string;
  menu?: NavItem[];
  workHubId?: string;
  workHubSelectedId?: string;
}

export interface Sandbox {
  hideInitialSpinner?: boolean;
  history: HISTORY | HistoryExtended;
  ims?: IMS;
  overrideParams?: boolean;
  pageTimeout?: number;
  pathPrefix?: {
    default: string;
    dev?: string;
    preHash?: boolean;
  };
  sources: EnvironmentMap<string | DiscoverySource>;
  thirdParty?: boolean;
}

export interface UnifiedSearch {
  access?: {
    appIds?: string[];
    flag?: string;
    logicOp?: string;
  };
  compact?: boolean;
  enabled?: boolean;
}

type prodCtxParsingFn<T> = (prodCtx: ProductContext) => T | undefined;

export interface SubOrgContext {
  key: 'subOrg';
  config: {
    customIdName?: string,
    customPathKey?: string;
    dontWaitForLoad?: boolean;
    id?: string;
    name: string | prodCtxParsingFn<string>;
    orgIdName?: string | prodCtxParsingFn<string>;
    preferred?: string | prodCtxParsingFn<boolean>;
    serviceCode: string;
    useRootPathOnChange?: boolean;
  };
  incognito?: boolean;
}

interface SandboxContext {
  key: 'sandbox';
  optional?: boolean;
  incognito?: boolean;
}

export type UrlContext = SubOrgContext | SandboxContext;

export interface Section {
  name: string;
  route: string;
  sessionParameter?: string;
  metricsAppId: string;
}

export interface SpaRoute {
  appId: string;
  path: string;
  distributionList: string[];
  history: HISTORY | HistoryExtended;
  name?: string;
  omegaGlobal?: PDH_GLOBAL;
  omegaSuiteId?: string;
  sources: EnvironmentMap<string>;
  thunderbird?: THUNDERBIRD;
}

export interface SpaList {
  spas: Record<string, SpaRoute[]>,
  emails: string[]
}

export interface LegacyAnalyticsConfig {
  code?: string;
  scripts?: {
    [key: string]: string;
  }
}

export type AnalyticsConfig = LegacyAnalyticsConfig | {
  omegaGlobal: PDH_GLOBAL;
};

export type Parent = 'AEP' | 'CJM' | 'DC' | 'GENSTUDIO' | 'ORION' | 'SAPPHIRE';

export interface BaseRoute {
  access?: Access;
  analytics?: AnalyticsConfig;
  appContainer?: string;
  appId: string;
  /**
   * `appRoot` identifies the root of the app's navigation. This is used in the
   * ShellAppSwitcher to identify the selected state for sub apps and capabilties
   */
  appRoot?: string;
  /**
   * If true, the default runtime instance will be created automatically after
   * the application connects with Unified Shell. This can prevent 408 errors, but may
   * expose other errors if the application fails to start normally.
   */
  autoInstantiateRuntime?: boolean;
  base?: string;
  betaFeedbackConfigId?: string;
  browserParamFilterList?: string[];
  dashboard?: boolean;
  dataPrefetchContracts?: string[];
  decodeUrl?: boolean;
  disableIfAppEnabled?: string[];
  experienceLeague?: ExperienceLeague;
  exportName?: string;
  fiServiceCode?: string;
  gainsight?: Gainsight;
  getPathOrgFromContext?: (location: Location) => string;
  gqlEndpoint?: string;
  helpCenter?: {
    hideDefaultResources?: boolean;
  };
  hero?: {
    icon: string;
    path: string;
    shortTitle: string;
    title: string;
  };
  hideTenant?: boolean;
  metadata?: Record<string, string>;
  metricsAppId?: string;
  mfeAppIds?: string[];
  /**
   * Allow listed nested apps
   */
  nestedApps?: NestedRoute[];
  omegaSuiteId?: string;
  parent?: Parent;
  path: string;
  permissionsPolicy?: string[];
  redirectFrom?: Redirect[];
  refreshOnImsChange?: boolean;
  disableShellFeedbackButton?: boolean;
  releaseScope?: RELEASE_SCOPE;
  releaseType?: RELEASE_TYPE;
  runtime?: RUNTIME;
  sandbox: Sandbox;
  sections?: Section[];
  serviceCode?: string;
  session?: SessionConfig;
  settingsAppId?: string;
  /**
   * Array of cache IDs which this application is allowed to get/set cache data.
   * Used to share cache across apps.
   */
  sharedCaches?: string[];
  sideNav?: SideNav;
  spaAppId?: string;
  subOrgAccessOnlyOnCurrentOrg?: boolean;
  theme?: THEME;
  thunderbird?: THUNDERBIRD;
  unifiedSearch?: UnifiedSearch;
  urlContext?: UrlContext;
  validClientIds?: string[];
  withinSkyline?: boolean;
}

interface AlternateBase {
  flag: string;
  param?: string;
  path?: RegExp;
  signalsInactive?: boolean;
  regex?: boolean;
}

interface AlternateWithConfig extends AlternateBase {
  config: AlternateRoute;
}

interface AlternateWithRedirect extends AlternateBase {
  redirect: string;
}

type AlternateRouteConfig = AlternateWithConfig | AlternateWithRedirect;

export interface AlternateRoute extends BaseRoute {
  alternateRoute?: AlternateRouteConfig[];
}

export interface FFRoute extends BaseRoute {
  // When processing alternate routes, it loops through them in order to find
  // the first that matches. Make sure to order them correctly.
  // For example, if we want to have /data-collection optionally route to
  // /data-collection/home OR optionally load the SPA version OR fall back to
  // the original version, that is the order it should be listed in the array.
  alternateRoute: AlternateRouteConfig[];
}

export type NestedRoute = Omit<BaseRoute, 'path'>;

export type SolutionRoute = BaseRoute | FFRoute | AlternateRoute;

export type Solution = SolutionRoute & {
  distributionList: string[];
  name?: string;
};

{
  "alpha": "Alfa",
  "alphaFeedback": "Feedback alfa",
  "beta": "Beta",
  "betaFeedback": "Feedback beta",
  "customReleaseTrial": "Versione di prova",
  "releaseBadgeAlpha": "Alfa",
  "releaseBadgeBeta": "Beta",
  "releaseBadgeDev": "Dev",
  "releaseBadgePoc": "PoC"
}

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {handleBoolean, handleDate, handleDateRange, handleRange, handleTime, isBool, isDate, isDateRange, isRange, isTime} from './utils';

/**
 * Calls all functions in the order they were chained with the same arguments.
 * See https://github.com/adobe/react-spectrum/blob/3efd6c0ba65d93b66d981b479255a16fc14e54c6/packages/%40react-aria/utils/src/chain.ts#L16
 * for original implementation.
 */
function chain(...callbacks: any[]): (...args: any[]) => void {
  return (...args: any[]) => {
    for (const callback of callbacks) {
      if (typeof callback === 'function') {
        callback(...args);
      }
    }
  };
}

interface SendOmegaEventOptions<T = Event | string | number> {
  onEvent?: (event: T) => void;
  action?: string;
  feature?: string;
  widget?: string;
  widgetType?: string;
  debug?: boolean;
  attributes?: {[key: string]: string};
  search?: {
    term?: string;
    resultcount?: string;
  }
}

/**
 * sendOmegaEvent - A higher-order function that wraps a given event handler function
 *                  to add omega attributes and handle custom omega events. If a handler
 *                  is not provided, the function still adds omega attributes to the target element.
 * @param {string} elementName - The name of the element (e.g., button) or the group name for
 * collection components (e.g., Menu, ActionGroup, ListBox). `elementName` will be used as the base
 * value for the `data-omega-element` attribute. The final attribute value depends on the type of the
 * argument passed to the event handler, representing the type of event or the selected
 * data (`Event`, `DateValue`, `RangeValue`, etc).
 * - For `Event` types, `elementName` is used directly: `data-omega-element="<elementName>"`
 * - For other types, selections are appended to `elementName` inside square brackets. For example,
 * a `DateValue` would result in `data-omega-element="<elementName> [<year>_<month>_<day>]"`,
 * a `RangeValue` would transform into `data-omega-element="<elementName> [<startValue>,<endValue>]"`.
 * Other event types are handled in a similar fashion.
 * @param {React.RefObject<{UNSAFE_getDOMNode?: () => HTMLElement}> | null} ref - A reference object created
 * using React's useRef hook. For handlers passing an `Event` to the callback, `ref` can be `null`, as the target
 * element can be derived from the `Event` object (e.g., Button). In all other cases, `ref` should be provided
 * as it is essential for identifying the target element that will receive the Omega attributes
 * (e.g., Menu, ActionGroup, ListBox, Calendar, RangeSlider etc).
 * @param {object} [options] - An optional object containing additional options.
 * @property {function} [options.onEvent] - The event handler function to be wrapped (optional).
 * @property {string} [options.action] - An optional action value for the `data-omega-action` attribute.
 * @property {string} [options.feature] - An optional feature value for the `data-omega-feature` attribute.
 * @property {string} [options.widget] - An optional widget value for the `data-omega-widget` attribute.
 * @property {string} [options.widgetType] - An optional widget value for the `data-omega-widget-type` attribute.
 * @property {object} [options.attributes] - An optional attributes object containing `key-value` pairs to be used
 * for the `data-omega-attribute-*` attributes. For instance, if `attributes: {version: "beta"}` is passed, then
 * `sendOmegaEvent` will set`data-omega-attribute-version="beta"` to the target element.
 * @property {object} [options.search] - An optional `search` object containing `term` and `resultcount` properties for
 * the `data-omega-search-term` and `data-omega-search-resultcount` attributes respectively.
 * @property {boolean} [options.debug] - A flag indicating whether debug information should be printed to the console.
 *
 * @example
 * ```typescript
 * // With a React Spectrum button or a regular button
 * <Button
 *   variant="cta"
 *   onPress={sendOmegaEvent("open", null, {onEvent: onPressHandler,action: "display"})}
 * >
 *   Open
 * </Button>;
 *
 * // With an ActionGroup collection component
 * const actionGroupRef = useRef(null);
 * const items = [
 *   { label: "React", name: "react" },
 *   { label: "Add", name: "add"},
 *   { label: "Delete", name: "delete" },
 * ];
 *
 * <ActionGroup
 *   items={items}
 *   onAction={sendOmegaEvent("group links", actionGroupRef, {onEvent: onActionHandler})}
 *   ref={actionGroupRef}
 *   overflowMode="collapse"
 * >
 *   {(item) => <Item key={item.name}>{item.label}</Item>}
 * </ActionGroup>;
 *
 * // With a Menu collection component
 * const menuRef = useRef(null);
 * const menuItems = [
 *   { label: "Add", name: "add"},
 *   { label: "Delete", name: "delete"},
 * ];
 *
 * <MenuTrigger>
 *   <Button variant="cta" onPress={sendOmegaEvent("open", null)}>
 *      Open Menu
 *   </Button>
 *   <Menu
 *     items={menuItems}
 *     onAction={sendOmegaEvent("menu links", menuRef, {feature: "menu", widget: "menu widget"})}
 *     ref={menuRef}
 *   >
 *     {(item) => <MenuItem key={item.name}>{item.label}</MenuItem>}
 *   </Menu>
 * </MenuTrigger>;
 *
 * // With a ListBox collection component with multiple selection
 * const listBoxRef = useRef(null);
 * const handleSelection = (keys) => {
 *     setSelectedKeys(keys);
 * };
 *
 * <ListBox
 *   aria-label="Example with onSelectionChange"
 *   selectionMode="multiple"
 *   onSelectionChange={sendOmegaEvent("listbox items", listBoxRef, {onEvent: handleSelection})}
 *   selectedKeys={selectedKeys}
 *   ref={listBoxRef}
 * >
 *   <Item key="one">One</Item>
 *   <Item key="two">Two</Item>
 *   <Item key="three">Three</Item>
 * </ListBox>;
 *
 * // With a RangeSlider component
 * const sliderRef = useRef(null);
 *
 * <RangeSlider
 *   label="Range"
 *   onChangeEnd={sendOmegaEvent("slider", sliderRef, {onEvent: onChangeHandler}
 *   defaultValue={{ start: 12, end: 36 }}
 *   ref={sliderRef}
 * />
 *
 * // With a Calendar component
 * const dateRef = useRef(null);
 *
 * <Calendar
 *   aria-label="Event date"
 *   onChange={sendOmegaEvent("calendar", dateRef, {onEvent: onChangeHandler}}
 *   ref={dateRef}
 * />
 *```
 */
export const sendOmegaEvent = <T extends object | React.ReactText | Set<React.ReactText> | React.ReactText[] | boolean>(
  elementName: string,
  ref: React.RefObject<{UNSAFE_getDOMNode?: () => HTMLElement}> | null = null,
  options: SendOmegaEventOptions<T> = {}
) => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onEvent = () => {},
    action,
    feature,
    widget,
    widgetType,
    attributes = {},
    debug = false,
    search = {}
  } = options;

  const customHandler = (eventData: T | Event) => {
    let target: HTMLElement | null = null;
    let omegaElement = '';

    const handlers = [
      {handler: handleTime, test: isTime},
      {handler: handleDateRange, test: isDateRange},
      {handler: handleDate, test: isDate},
      {handler: handleRange, test: isRange},
      {handler: handleBoolean, test: isBool}
    ];

    for (const {test, handler} of handlers) {
      if (test(eventData)) {
        omegaElement = handler(elementName, eventData as any);
        break;
      }
    }

    if (!omegaElement) {
      if (eventData == null) {
        // When eventData is not defined or empty
        omegaElement = elementName;
      } else if (Array.isArray(eventData) || eventData instanceof Set) {
        // For components with multiple selection (e.g., ListBox, CheckboxGroup)
        const selectedItems = Array.from(eventData);
        omegaElement = selectedItems.length > 0 ? `${elementName} [${selectedItems}]` : elementName;
      } else if (typeof eventData === 'string' || typeof eventData === 'number') {
        // For components where eventData is a string or number (e.g., Menu, ActionGroup, Slider)
        omegaElement = `${elementName} [${eventData}]`;
      } else if ('target' in (eventData as Event)) {
        // For components where eventData is an Event (e.g., Button)
        omegaElement = elementName;
        target = (eventData as Event).target as HTMLElement;
      } else if (debug) {
        console.error(`Error: event type passed to callback is not supported.`); // eslint-disable-line
        return;
      }
    }

    // Determine the target for the Omega attributes if not defined yet
    if (!target && ref && ref.current) {
      target = ref.current.UNSAFE_getDOMNode ? ref.current.UNSAFE_getDOMNode() : null;
    }
    if (!target && debug) {
      console.error("Error: ref or ref.current is not defined or UNSAFE_getDOMNode is missing"); // eslint-disable-line
      return;
    }

    if (target) {
      // add omega attributes to target element
      target.setAttribute('data-omega-element', omegaElement);
      action && target.setAttribute('data-omega-action', action);
      feature && target.setAttribute('data-omega-feature', feature);
      widget && target.setAttribute('data-omega-widget', widget);
      widgetType && target.setAttribute('data-omega-widget-type', widgetType);

      for (const key in attributes) {
        target.setAttribute(`data-omega-attribute-${key}`, attributes[key]);
      }

      if (search.term) {
        target.setAttribute('data-omega-search-term', search.term);
      }
      if (search.resultcount) {
        target.setAttribute('data-omega-search-resultcount', search.resultcount);
      }

      target.setAttribute('data-omega-attribute-omegahandler', 'true');

      // create and dispatch custom omega event
      const omegaEvent = new CustomEvent('omega-event');
      target.dispatchEvent(omegaEvent);

      if (debug) {
        console.log('Target element with Omega annotations: ', target); // eslint-disable-line
      }
    }
  };
  return chain(customHandler, onEvent);
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import BaseService from './BaseService';
import {getUserSandboxes} from '@exc/graphql/src/queries/palm';
import {getValueFromPath} from '@exc/url';
import {history} from '@exc/router';
import metrics, {Level} from '@adobe/exc-app/metrics';
import {staleWhileRevalidate} from '../../utils';
import unifiedShellSession from '../UnifiedShellSessionService';
import {updateValueInPath} from '@exc/url/pathUpdate';

export default class SandboxService extends BaseService {
  isSandbox = true;

  constructor(solutionConfig, callback, settingsService = {}) {
    super(solutionConfig, callback, 'sname', 'shellSandboxes', settingsService);
    this.metrics = metrics.create('exc.core.SandboxService');
    this.solutionConfig = solutionConfig;
    this.hideFromUrl = solutionConfig.urlContext.incognito;
  }

  /**
   * Gets user sandboxes for the current IMS org from GraphQL.
   * @param {string} selectedImsOrg Current IMS Org
   * @returns {Promise<Sandbox[]>} Sandboxes array
   */
  async gqlUserSandboxes(selectedImsOrg) {
    let variables;
    const env = this.auth.env;
    // Based on where AEP (serviceCode acp) was provisioned, we need to get the user sandboxes from
    // platform-stage when Shell is running on QA/Dev which are normally linked to platform-int.
    // The fulfillable data field provides the correct environment to the gql service.
    if (env === 'dev' || env === 'qa') {
      const {projectedProductContext} = await this.auth.fetchUserProfile();
      const {prodCtx} = projectedProductContext.find(({prodCtx: ctx}) =>
        ctx.serviceCode === 'acp' &&
        ctx.owningEntity === selectedImsOrg &&
        ctx.fulfillable_data
      ) || {};
      variables = prodCtx && {fulfillableData: JSON.parse(prodCtx.fulfillable_data)};
    }
    // TO DO: Remove fulfillableData
    return getUserSandboxes(variables);
  }

  async fetch(state) {
    const {hasAEP, sandbox, selectedImsOrg, userSandboxes: excSettingsSandboxes} = state;
    // Don't attempt to fetch sandboxes if the org does not have AEP.
    if (!hasAEP) {
      this.set({
        newSandbox: {sandboxes: [], selected: null},
        oldSandbox: sandbox,
        replace: false,
        sandboxesAvailable: false
      });
      return {sandbox: null, sandboxes: []};
    }

    await super.fetch(state);
    const {urlContext: {optional}} = this.solutionConfig;
    const userId = this.auth.getHashedUserId();
    const localSandboxes = await unifiedShellSession.get(this.storageKey, userId, selectedImsOrg);

    // If this is a login, we should set the sandbox data from settings in local storage
    excSettingsSandboxes && !localSandboxes && unifiedShellSession.update(this.storageKey, excSettingsSandboxes, userId);

    const settingsSandbox = excSettingsSandboxes && (excSettingsSandboxes[selectedImsOrg] || {}).lastSelectedSandbox;
    const selectedSandboxName = getValueFromPath(history.location.pathname, this.urlKey) || localSandboxes?.lastSelectedSandbox || settingsSandbox;
    // Asynchronously update local storage with the most recently fetched Sandboxes
    // On the next refresh, the sandboxes will be up to date. selectedImsOrg passed here is only used
    // to generate fulfillableData. The selectedImsOrg passed to GQL is set in Core by the network configuration.
    const getSandboxes = () => this.gqlUserSandboxes(selectedImsOrg).then(({userSandboxes}) => {
      userSandboxes.forEach(sbox => sbox.imsOrg = selectedImsOrg);
      this.store({sandboxes: userSandboxes}, selectedImsOrg);
      return userSandboxes;
    });
    let sandboxes;

    const errorHandler = error => {
      this.set({
        error,
        newSandbox: {sandboxes: [], selected: null},
        oldSandbox: sandbox,
        sandboxesAvailable: false
      });
      // Remove null palmSandbox entry from localStorage
      unifiedShellSession.remove(this.storageKey, userId, selectedImsOrg);
      // Don't throw an error if Sandboxes are optional
      if (optional) {
        return;
      }
      const errorMessage = `User ${userId} doesn't have access to sandboxes in org ${selectedImsOrg}.`;
      this.metrics.info(errorMessage, {level: Level.WARN});
      throw new Error(errorMessage);
    };

    try {
      // Only fetch sandboxes from gql if they are not in local storage.
      sandboxes = await staleWhileRevalidate(getSandboxes, localSandboxes?.sandboxes);
    } catch (e) {
      return errorHandler(e);
    }

    const selectedSandbox = sandboxes.find(({name}) => name === selectedSandboxName) || sandboxes.find(sb => sb.isDefault) || sandboxes[0];
    this.store({lastSelectedSandbox: selectedSandbox?.name, sandboxes}, selectedImsOrg);
    this.set({newSandbox: {sandboxes, selected: selectedSandbox}, oldSandbox: sandbox});
    return {sandbox: selectedSandbox, sandboxes};
  }

  set({error, newSandbox, oldSandbox, replace = true, sandboxesAvailable = true}) {
    const state = {sandbox: {...oldSandbox, ...newSandbox}, sandboxesAvailable};
    this.updateState(state, error);
    const {name} = newSandbox.selected || {};
    this.select(name);
    if (name !== getValueFromPath(history.location.pathname, this.urlKey)) {
      this.hideFromUrl || updateValueInPath(this.urlKey, name, this.hasTenantInUrl, replace);
    }
  }

  change(selectedImsOrg, selected, oldSandbox) {
    this.store({lastSelectedSandbox: selected.name}, selectedImsOrg);
    this.set({newSandbox: {selected}, oldSandbox, replace: false});
  }

  async store(sandboxValues, selectedImsOrg) {
    await super.store(sandboxValues, selectedImsOrg);

    // Save lastSelectedSandbox into Exc Settings Service
    if (sandboxValues.lastSelectedSandbox) {
      const sandboxes = await unifiedShellSession.get(this.storageKey, this.auth.getHashedUserId()) || {};
      const userSandboxes = {...sandboxes, [selectedImsOrg]: {lastSelectedSandbox: sandboxValues.lastSelectedSandbox}};
      this.settingsService.setSetting({userSandboxes});
    }
  }

  onHistoryChange(location, state) {
    const {sandbox, selectedImsOrg} = state;
    const {sandboxes, selected, enabled} = sandbox;
    const pathSandbox = getValueFromPath(location.pathname, this.urlKey);
    // If the sandbox is manually changed in the URL, set the new sandbox.
    if (pathSandbox && pathSandbox !== (selected || {}).name) {
      const updatedSandbox = sandboxes.find(s => s.name === pathSandbox);
      updatedSandbox && this.change(selectedImsOrg, updatedSandbox, sandbox);
    }
    // If sandboxes are enabled and the link does not have it, add it.
    if (enabled && selected && !pathSandbox) {
      this.hideFromUrl || updateValueInPath(this.urlKey, selected.name, this.hasTenantInUrl, true);
    }
  }
}

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {FetchScope} from '@adobe/exc-app/network';
import type {FulfillableData, UserPermissionsResult, UserSandboxesResult} from '../models/palm';
import {gqlQuery} from '../index';

export const SANDBOX_QUERY = `
  query getUserSandboxes($fulfillableData: FulfillableData) {
    userSandboxes(fulfillableData: $fulfillableData) {
      title
      region
      name
      state
      isDefault
      type
    }
  }
`;

export const PERMISSIONS_QUERY = `
  query effectivePolicies {
    effectivePolicies {
      policies {
        name
        privileges
      }
    }
  }
`;

export const getUserPermissions = (): Promise<UserPermissionsResult> =>
  gqlQuery<UserPermissionsResult, undefined>(
    PERMISSIONS_QUERY,
    undefined,
    {scope: FetchScope.SANDBOX}
  );

export const getUserSandboxes = (fulfillableData?: FulfillableData): Promise<UserSandboxesResult> =>
  gqlQuery<UserSandboxesResult, Partial<FulfillableData>>(
    SANDBOX_QUERY,
    fulfillableData || {}
  );

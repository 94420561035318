/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import Emitter from '@exc/emitter';
import {encodeForRedirect, getTenantAndPath} from '@exc/url';
import {exchangeToken, getPaginatedAccountClusters} from '@exc/graphql/src/queries/auth';
import {extractTokenMetadata, getErrorMessage} from '@exc/shared';
import {flushQueuedMetrics, queueMetrics} from './utils/queued';
import {getImsFilter} from './utils';
import {getQueryValue} from '@exc/url/query';
import {getRefresher} from './utils/refresher';
import {getToken, isSessionEnvValid} from './utils/IMSUtils';
import hashString from 'string-hash';
import {Internal} from '@adobe/exc-app/internal';
import logout from './utils/logout';
import metrics, {Level} from '@adobe/exc-app/metrics';
import {reload} from './utils/reloader';
import {storage} from '@exc/storage';

/**
 * IMS Validate Token scheduler default interval in millis
 */
const QA_INTERVAL = 120000;
const PROD_INTERVAL = 30000;
const BACKGROUND_INTERVAL = 1000 * 60 * 10;
const VALIDATION_DELAY = 30000;

let imsLoaded = false;
const trackedTokens = new Set();

class Auth extends Emitter {
  load(configuration) {
    const {
      accountClusterPromise, env, imsConfig,
      imsObjects: {adobeIMS, adobeid, storedEvents},
      listeners
    } = configuration;
    this._activityMonitor = null;
    this.accountClusterPromise = accountClusterPromise;
    this.env = env;
    this.imsEnv = imsConfig.environment || this.env;
    this.backupAccessTokensInfos = {};
    this.validateAndEmitMap = {};
    this.validateAndRefreshMap = {};

    this.addListenersToAuth(listeners);
    this.logout = logout;
    this.metrics = metrics.create('exc.auth.Auth');
    this.metrics.event('Auth.init');

    // Create a promise to pull the activity monitor and set it as an instance
    // in the class. Pass the promise to the refresher so it can have an
    // instance as well. This is used to get the last active time for the user.
    const activityMonitorPromise = Internal.getActivityMonitor().then(activityMonitor => {
      this._activityMonitor = activityMonitor;
      return activityMonitor;
    });
    this.refresher = getRefresher(activityMonitorPromise);

    const onError = err => {
      // `err` is not always a string, so let's convert to an object for
      // consistent handling later on.
      const errObj = typeof err === 'string' ? {error: err} : err;
      this.metrics.error('Error making a request in IMS after init', errObj);
      const {debug, error, name} = errObj;
      const status = ((debug || {}).url || {}).error;
      // The request was aborted and we don't really know why.
      if ((error === 'aborted' || error === 'initialize_error') && !imsLoaded) {
        this.emit('auth.initializeError');
        return;
      }
      // There are a variety of cases that can trigger rate limit errors.
      if (error === 'rate_limited' || status === 'rate_limited' || name === 'rate_limit') {
        this.emit('auth.rateLimited');
      }
    };
    window['exc-ims-error-handler'] = onError;

    if (!imsLoaded) {
      imsLoaded = true;
      storedEvents.forEach(event => this.emit(event));
      adobeid.onError = onError;
      this.adobeIMS = adobeIMS;
      this.adobeid = adobeid;

      // Initialize the custom token service.
      this.customTokenServicePromise = import('./CustomTokenService').then(({default: CustomTokenService}) => {
        this.customTokenService = new CustomTokenService();
        this.customTokenService.on('rideError', err => this.emit('auth.rideError', err));
      });

      // Enable verbose IMS logging if specified via query param
      getQueryValue('verboseIms') && this.adobeIMS.enableLogging();

      this.isSessionEnvValid(imsConfig.environment, this.imsEnv);
      if (this.isAuthenticated()) {
        const inactivity = getQueryValue('inactivity');
        flushQueuedMetrics();

        if (inactivity && this.imsEnv !== 'prod') {
          const inactivityTimeoutSec = Number(inactivity);
          if (inactivityTimeoutSec >= 30) {
            this.metrics.info(`Setting inactivity period to ${inactivityTimeoutSec} seconds`);
            Internal.configurePolling({inactivityTimeoutSec});
          }
        }
        Internal.addPoller({
          activeFrequency: this.imsEnv === 'prod' ? PROD_INTERVAL : QA_INTERVAL,
          immediate: true,
          inactiveFrequency: BACKGROUND_INTERVAL,
          name: 'ValidateToken',
          pollFn: () => this.validateTokenAndEmit()
        }).then(pollerHandle => this.tokenPoller = pollerHandle);
      }
    }
    return this;
  }

  isAuthenticated(force) {
    return this.adobeIMS.isSignedInUser() || force;
  }

  async signIn(redirect_uri, domain = '', filterOptions = {}) {
    this.clear();
    const tenantMap = await storage.local.get('tenantMap');
    const context = {locale: navigator.language, redirect_uri};

    if (domain && domain.startsWith('@') && domain.includes('.')) {
      // Add domain hint for SSO.
      context.puser = domain;
    }

    if (redirect_uri.includes('old_hash') && !filterOptions.tenantId) {
      const [, oldHash] = decodeURIComponent(redirect_uri).split('old_hash=#');
      oldHash && (filterOptions.tenantId = getTenantAndPath(oldHash).tenant);
    }

    const {tenantId} = filterOptions;
    if (tenantMap && !filterOptions.imsOrgId) {
      const defaultOrg = tenantMap.DEFAULT_ORG || tenantMap.LAST_ORG;
      filterOptions.imsOrgId = tenantId ? tenantMap[tenantId] : defaultOrg;
    }

    context.profile_filter = getImsFilter(filterOptions);
    this.adobeIMS.signIn(context);
  }

  /**
   * A helper to emit and auto clear token status and login status
   * Generates: auth.validatetokensuccess event - on successful validation.
   * Generates: auth.validatetokenfailed event - on invalid/expired token and clears login.
   * @param {boolean} emit - Emit events? (Default: true)
   * @returns {boolean} returns if the session is valid or not
   */
  validateTokenAndEmit = async (emit = true) => {
    // If the token has already been validated, wait 5 minutes until it's
    // validated again. This saves us from triggering a bunch of validation
    // calls on the same token if requested multiple times.
    let token = this.fetchAccessToken();
    const almostExpired = this.refresher.getRefreshAt(token) < Date.now();
    if (!token || almostExpired) {
      // Token might have expired in which case IMS lib no longer has it.
      // Try to get a new token first.
      this.metrics.warn(almostExpired ? 'Token is past 80% threshold' : 'Token is null, refreshing');
      try {
        token = (await this.refreshToken()).token;
      } catch {
        // Actual error is logged in refreshToken.
        this.metrics.error('Failed to refresh token while validating.');
        return {success: false};
      }
      if (!token) {
        this.metrics.error('Token is null');
        return {success: false};
      }
    }
    if (token in this.validateAndEmitMap && this.validateAndEmitMap[token].expires > Date.now()) {
      return this.validateAndEmitMap[token].promise;
    }

    const promise = this.adobeIMS.imsApis.validateToken({client_id: this.adobeid.client_id, token})
      .then(res => {
        this.metrics.event(res.valid ? 'Auth.tokenIsValid' : 'Auth.tokenInvalid');

        if (!res.valid) {
          queueMetrics('Failure to validate token', {data: {adobeIMSResponse: res}});
          emit && this.emit('auth.validatetokenfailed');
          return {success: false};
        }

        emit && this.emit('auth.validatetokensuccess');
        return {success: true};
      })
      .catch(error => {
        // Network is flakey - never emit to log user out.
        if (error?.error === 'networkError') {
          queueMetrics('Network error', {error});
          return {success: false};
        }
        queueMetrics('Failure to validate token', {error});
        emit && this.emit('auth.validatetokenfailed');
        return {success: false};
      });
    this.validateAndEmitMap[token] = {expires: Date.now() + VALIDATION_DELAY, promise};
    return promise;
  };

  async validateTokenAndRefresh({clientId, scope, token, userId}) {
    // If the token has already been validated, wait 5 minutes until it's
    // validated again. This saves us from triggering a bunch of validation
    // calls on the same token if requested multiple times.
    if (token in this.validateAndRefreshMap && this.validateAndRefreshMap[token].expires > Date.now()) {
      return this.validateAndRefreshMap[token].promise;
    }

    const isExcClient = this.isExcClient(clientId);
    const validatePromise = isExcClient ?
      this.adobeIMS.validateToken() :
      this.adobeIMS.imsApis.validateToken({client_id: clientId, token});

    // Validate the token as the first step. If it's successful, the response
    // should say it's valid.
    const promise = validatePromise
      .then(res => {
        let errMessage;
        token = isExcClient ? this.fetchAccessToken() : token;
        // adobeIMS.imsApis.validateToken response is an object and is resolved
        // whether the token is valid or not.
        if (!isExcClient && typeof res === 'object' && !res?.valid) {
          errMessage = `Validation request failed: ${res?.reason || 'Unknown Reason'}`;
        // Refresh the token if it's past the refresh time.
        } else if (this.refresher.getRefreshAt(token) < Date.now()) {
          errMessage = 'Token is past refresh time';
        }
        if (errMessage) {
          const err = new Error(errMessage);
          err.fromValidate = true;
          throw err;
        }
        // adobeIMS.validateToken response is a boolean
        return {clientId, scope, success: true, userId};
      })
      .catch(validateError => {
        const message = validateError.fromValidate ? validateError.message : 'Token validation failed';
        this.metrics.info(message, validateError);
        // The validation was invalid, so now we attempt to refresh the token.
        // If the request succeeds, use the response to determine if the refresh
        // was successful.
        return (isExcClient ? this.refreshToken() : this.refreshCustomToken({clientId, scope, userId}))
          .then(imsData => {
            const response = {imsData, success: !!imsData};
            if (imsData) {
              // Remove the old token and track validation on the new one.
              delete this.validateAndRefreshMap[token];
              this.validateAndRefreshMap[imsData.token] = {expires: Date.now() + VALIDATION_DELAY, promise: Promise.resolve(response)};
            } else {
              this.emit('auth.validatetokenfailed');
            }
            return response;
          })
          .catch(refreshError => {
            this.metrics.error('Token validate and refresh failed', refreshError);
            this.emit('auth.validatetokenfailed');
            return {success: false};
          });
      });
    this.validateAndRefreshMap[token] = {expires: Date.now() + VALIDATION_DELAY, promise};
    return promise;
  }

  /** Gets session Id from access token. */
  getSessionId() {
    const accessTokenInfo = this.fetchAccessTokenInfo();
    return accessTokenInfo && accessTokenInfo.sid;
  }

  /**
   * Gets impersonation id from access token.
   */
  getImpersonationId(token) {
    const impersonationId = this.impersonationId || (extractTokenMetadata(token))?.imp_id;
    return {impersonationId};
  }

  /**
   * Gets userId and hashes it for use in local storage.
   */
  getHashedUserId() {
    const userId = this.getUserId();
    return userId && hashString(userId).toString();
  }

  /**
   * TO DO: Get this value via a more stable IMSLib API
   * Gets authId from access token.
   */
  getAuthId() {
    return this.authId;
  }

  /**
   * TO DO: Get this value via a more stable IMSLib API
   * Gets userId from access token.
   */
  getUserId() {
    const accessTokenInfo = this.adobeIMS.tokenService.getTokenFieldsFromStorage();
    accessTokenInfo || this.metrics.warn('getTokenFieldsFromStorage returned an empty token');
    return (accessTokenInfo || {}).user_id;
  }

  /**
   * Gets hashed authId from profile.
   */
  getHashedAuthId() {
    const authId = this.getAuthId();
    return authId && hashString(authId).toString();
  }

  /**
   * Creates a new CustomAppClient instance with the specified client id and
   * scopes. It also set the usage type to APP so it can be used for the current
   * active application and tracks it with the refresher.
   * @param clientId Custom (e.g. non exc_app) client id.
   * @param scope Custom scopes to fetch for the client.
   * @returns The token using the requested clientId and scope.
   */
  async createCustomClient(clientId, scope) {
    await this.customTokenServicePromise;
    return this.customTokenService.getToken(clientId, scope, this.getUserId(), this);
  }

  /**
   * Get stored profile from IMS lib.
   * @param {boolean} signOutIfNone - Sign out if no profile is available (Default: true)
   * @returns {IMSProfile} profile
   */
  async fetchUserProfile(signOutIfNone = true) {
    const profile = await this.adobeIMS.getProfile();
    if (profile) {
      this.authId = profile.authId || profile.userId;
      return profile;
    }
    // There is a session, so likely the profile is failing to load for some
    // reason related to 429 or other error from the IMS request. There will
    // likely be an error shown to the user, so we don't need to log out.
    if (this.adobeIMS.getAccessToken() || !signOutIfNone) {
      return null;
    }
    this.signOut(true);
  }

  /**
   * Fetch token for custom client ID and scopes. Adds an additional field
   * `userInactiveSince` onto the request if it's a valid number so IMS can
   * handle the case where the user might have been inactive longer than the PBA
   * setting.
   * @async
   * @param {string} clientId - Custom (e.g. non exc_app) client id.
   * @param {string} scope - Custom scopes to fetch.
   * @param {string} userId - User ID
   * @returns {Promise<Object | null>} - Token information or null if invalid or
   *  clientId or scope don't exist.
   */
  async fetchCustomImsAccessToken(clientId, scope, userId = this.getUserId()) {
    if (!clientId || !scope) {
      return null;
    }
    const userInactiveSince = this.getSecondsSinceLastActive();
    const args = {clientId, env: this.imsEnv, scope, userId};
    typeof userInactiveSince === 'number' && (args.userInactiveSince = userInactiveSince);
    const tokenInfo = await getToken(args);
    this.trackToken(tokenInfo.access_token, {clientId, scope, userId});
    return tokenInfo;
  }

  fetchAccessToken() {
    const accessTokenInfo = this.fetchAccessTokenInfo();
    if (accessTokenInfo && accessTokenInfo.token) {
      this.trackToken(accessTokenInfo.token);
      return accessTokenInfo.token;
    }
    this.metrics.warn('fetchAccessToken failed to return a token');
    return null;
  }

  trackToken(token, custom) {
    if (trackedTokens.has(token)) {
      // Token already tracked.
      return;
    }

    // If the token has the `pba` property, disable the idle polling. The
    // property means that there is a token idle restriction defined by the
    // customer and we can't automatically refresh or keep tokens active.
    //
    // ONLY does this on exc_app tokens so that we don't overload metrics with
    // unnecessary logging.
    const {created_at, expires_in, imp_id, pba} = extractTokenMetadata(token) || {};
    if (created_at && expires_in && !custom) {
      sessionStorage.setItem('shellTokenExpireAt', created_at + expires_in);
    }

    this.impersonationId = imp_id;
    const pbaEnabled = (pba || '').toLowerCase().split(',').includes('org');
    Internal.configurePolling({pollWhileInactive: !pbaEnabled});
    pbaEnabled && this.metrics.info('PBA enabled for customer');

    const refreshAt = this.refresher.getRefreshAt(token);
    this.metrics.event('Token.tracked', {refreshAt});

    trackedTokens.add(token);
    const props = {...(custom || {
      clientId: this.getClientId(),
      scope: this.adobeid.scope,
      userId: this.getUserId()
    })};
    this.refresher.trackRefresh({
      ...props,
      eventFn: this.emit.bind(this),
      isExcClient: !custom,
      refreshAt,
      refreshFn: () => custom ? this.refreshCustomToken(props) : this.refreshToken()
    });
  }

  fetchAccessTokenInfo() {
    return this.adobeIMS.getAccessToken();
  }

  async signOut() {
    if (this.isAuthenticated()) {
      await this.logout.invoke();
    }
    this.clear();
    queueMetrics('Auth signOut', {data: {authenticated: this.isAuthenticated()}});
    this.adobeIMS.signOut({redirect_uri: encodeForRedirect()});
  }

  clear() {
    Internal.clearUser();
    storage.local.clearOnLogout();
    storage.session.clear();
    sessionStorage.removeItem('shellTokenExpireAt');
    this.logout.remove();
    this.tokenPoller && Internal.removePoller(this.tokenPoller);
    this.validateAndEmitMap = {};
    this.validateAndRefreshMap = {};
  }

  addListenersToAuth(listeners) {
    listeners &&
    Object.keys(listeners).forEach(event => {
      document.addEventListener(event, listeners[event]);
      this.on(event, listeners[event]);
    });
  }

  getClientId() {
    return this.adobeid.client_id;
  }

  async storeExchangeToken({token: {expires_in, access_token: token}, profile}) {
    const expirems = expires_in < 100000 ? expires_in * 1000 : expires_in;
    await this.updateProfile(profile);
    this.adobeIMS.setStandAloneToken({expirems, token});
    await this.updateTrackedTokens(profile.userId);
  }

  async switchProfile(userId, clusterToken) {
    try {
      if (clusterToken && this.refresher.canUseTokenForUser(clusterToken, userId)) {
        this.metrics.event('Auth.ClusterToken');
        return this.storeExchangeToken(clusterToken);
      }
      await this.adobeIMS.switchProfile(userId, {
        client_id: this.getClientId(),
        prompt: 'none',
        response_type: 'token',
        scope: this.adobeid.scope,
        target_profile: userId
      });
      await this.updateTrackedTokens(userId);
    } catch (error) {
      const message = getErrorMessage(error);
      if (message.includes('invalid_credentials')) {
        try {
          const {exchangeToken: token} = await exchangeToken({targetUserId: userId});
          await this.storeExchangeToken(token);
        } catch (e) {
          this.metrics.event('Fallback Token Exchange Failure', {error: getErrorMessage(e)}, {level: Level.ERROR});
        }
      } else {
        this.metrics.event('Switch Profile Failure', {error: message}, {level: Level.ERROR});
      }
    }
  }

  async updateTrackedTokens(userId) {
    const expirations = this.refresher.expirationTracker;
    let res;

    // Grab the current set of keys that are being checked for expiration. We
    // need to use the current tracked tokens to know which tokens to regenerate
    // using the new userId, so that is why we need to store this list now to
    // remove later.
    const keysToRemove = Object.keys(expirations);

    // Loop through the current set of expirations to know which tokens to
    // regenerate with the new userId from the profile switch. Emits change or
    // fail events for these so that the appropriate services know about it.
    for (const {isExcClient, clientId, scope} of Object.values(expirations)) {
      res = {};
      if (isExcClient) {
        res.profile = await this.adobeIMS.getProfile();
        res.token = this.fetchAccessToken();
      } else {
        const {access_token: token, ...profile} = await this.fetchCustomImsAccessToken(clientId, scope, userId);
        res = {profile, token};
      }
      if (!res.token) {
        this.emit('auth.validatetokenfailed', {clientId, isExcClient});
        continue;
      }
      this.emit('auth.token.change', {...res, clientId, isExcClient, scope, userId});
    }

    // Finally, remove the previous set of expirations from the expirations
    // check so that only the new set are tracked.
    this.refresher.removeExpirationKeys(keysToRemove);
  }

  isSessionEnvValid(imsEnv, buildEnv) {
    if (!isSessionEnvValid(imsEnv, buildEnv)) {
      this.metrics.event('Auth.ims.conflict', {level: Level.ERROR});
      this.signOut(true);
    }
  }

  /**
   * Determines whether or not to sign out the user after an error.
   * @param error
   * @param description
   * @returns {boolean} True if browser is refreshing (sign out or reload)
   */
  async signoutOnError(error, description) {
    const errors = (error || {}).errors || {};
    if (401 in errors) {
      const {success} = await this.validateTokenAndEmit(false);
      queueMetrics('401 error', {data: {validToken: success}, description, error});
      if (!success) {
        try {
          await this.refreshToken();
          const reloaded = await reload({
            initiator: 'Auth',
            reason: 'New Token After 401'
          });
          queueMetrics('401 error, refresh token and reload attempt',
            {data: {reloadAttempt: reloaded, validToken: success}}
          );
          if (!reloaded) {
            await this.signOut();
          }
        } catch (err) {
          queueMetrics('401 error, refresh token and reload error',
            {data: {validToken: success}, error: err}
          );
          await this.signOut();
        }
        return true;
      }
      return false;
    }
    if (429 in errors) {
      queueMetrics('429 error, auth rate limited', {description, error});
      this.emit('auth.rateLimited');
      return false;
    }
    this.metrics.error(`${description} Load error: ${Object.keys(errors).join(', ')}`, error);
    return false;
  }

  /**
   * Get the number of seconds since the last time the user was active.
   * @returns {number | null} - Number of seconds or null if
   *  `this._activityMonitor` is not defined yet.
   */
  getSecondsSinceLastActive() {
    return this._activityMonitor && Math.floor((Date.now() - this._activityMonitor.lastActive) / 1000);
  }

  /**
   * Get a new access token from IMS. Adds an additional field
   * `userInactiveSince` onto the request if it's a valid number so IMS can
   * handle the case where the user might have been inactive longer than the PBA
   * setting.
   * @async
   * @returns {Promise<Object>} - Token and profile data.
   */
  async refreshToken() {
    try {
      const userInactiveSince = this.getSecondsSinceLastActive();
      const refreshArgs = typeof userInactiveSince === 'number' && {userInactiveSince};
      const {profile, tokenInfo: {token}} = await this.adobeIMS.refreshToken(refreshArgs);
      this.metrics.info('Refreshed token');
      this.trackToken(token);
      return {profile, token};
    } catch (e) {
      this.metrics.error(`Failed refreshing ${this.getClientId()} token`, e);
      throw e;
    }
  }

  isExcClient(clientId) {
    return clientId === this.getClientId();
  }

  /**
   *
   */
  async refreshCustomToken({clientId, scope, userId}) {
    const res = await this.fetchCustomImsAccessToken(clientId, scope, userId);
    return {profile: res, token: res.access_token};
  }

  /**
   * Get initial data for Shell - User Profile, Org & Login data from GraphQL
   * @param {boolean} isReload - True for reload, false for Login
   * @returns {Promise<*>} Initial data
   */
  async gqlHandler(isReload) {
    // Grab the org from the path and use that as the selected org. If there
    // is no org yet, use the selected one from IMS.
    try {
      const shellInitData = await this.accountClusterPromise;
      if (!isReload) {
        await this.updateProfile((shellInitData || {}).userProfileJson);
      }
      // Make sure there is an account cluster before proceeding
      if (!shellInitData?.imsExtendedAccountClusterData?.data) {
        throw new Error('GQL failed to return the account cluster');
      }
      // If there is more data to be fetched,
      // we will need to keep fetching until we have all the data.
      while (shellInitData?.imsExtendedAccountClusterData?.next !== null) {
        const {data, next} = shellInitData.imsExtendedAccountClusterData;
        const {imsExtendedAccountClusterData: userAccounts} = await getPaginatedAccountClusters({from: next});
        data.push.apply(data, userAccounts.data);
        shellInitData.imsExtendedAccountClusterData.next = userAccounts.next;
      }
      return shellInitData;
    } catch (error) {
      const reloading = await this.signoutOnError(error, 'GQL');
      if (reloading) {
        error.reload = true;
      }
      throw error;
    }
  }

  async updateProfile(profile) {
    if (!profile) {
      return this.adobeIMS.getProfile();
    }
    // set fetched profile from gql in IMS Session storage
    this.adobeIMS.profileService.saveProfileToStorage(profile);
    this.authId = profile.authId || profile.userId;
    return profile;
  }
}

const instance = new Auth();

export const AuthInstance = config => {
  if (!imsLoaded) {
    instance.load(config);
  }
  return instance;
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {DiscoverySource, FindHostFromContextConfig} from '@exc/core/src/models/Discovery';
import excMetrics from '@adobe/exc-app/metrics';
import {getQueryParams, getQueryValue} from '@exc/url/query';
import {HISTORY, SolutionRoute} from '@exc/core/src/models/Solution';
import type {ProductContext} from '@adobe/exc-app/ims/ImsProfile';
import type {SubOrg} from '@adobe/exc-app/RuntimeConfiguration';

// This file was generated automatically from ../solutions/workfront.ts

const metrics = excMetrics.create('unifiedshell.solution.workfront');

/**
 * Wiki for Workfront's integration and new solution configs:
 * https://git.corp.adobe.com/exc/unified-shell/wiki/Workfront-Integration-and-New-Configs
 */
interface LaneData {
  name: string;
  url: string;
}

const laneToDataMap: Record<string, LaneData> = {
  local: {
    name: '{subdomain} - Local',
    url: 'https://{subdomain}.local.workfront-dev.adobe.com'
  },
  devtest: {
    name: '{subdomain} - Dev',
    url: 'https://{subdomain}.devtest.workfront-dev.adobe.com'
  },
  hub: {
    name: '{subdomain} - Hub',
    url: 'https://{subdomain}.workfront.adobe.com'
  },
  prod: {
    name: '{subdomain} - Production',
    url: 'https://{subdomain}.my.workfront.adobe.com'
  },
  preview: {
    name: '{subdomain} - Preview',
    url: 'https://{subdomain}.preview.workfront.adobe.com'
  },
  testdrive: {
    name: '{subdomain} - Test Drive',
    url: 'https://{subdomain}.testdrive.workfront.adobe.com'
  }
};

const envNameToLaneMap: Record<string, string> = {
  dev: 'devtest',
  hub: 'hub',
  production: 'prod',
  preview: 'preview',
  'test drive': 'testdrive'
};

export const getDataFromLane = (lane?: string): LaneData | undefined => {
  if (!lane) {
    return;
  }
  const sandboxMatch = lane.match(/^sandbox(\d+)$/);
  if (!sandboxMatch) {
    return laneToDataMap[lane];
  }
  const num = parseInt(sandboxMatch[1], 10);
  const zerodNum = num < 10 ? `0${num}` : num;
  return {
    name: `{subdomain} - Sandbox ${num}`,
    url: `https://{subdomain}.sb${zerodNum}.workfront.adobe.com`
  };
};

export const getLaneFromEnv = (env: string): string | undefined => {
  const sandboxMatch = env.match(/^sandbox (\d+)$/);
  return sandboxMatch ? `sandbox${sandboxMatch[1]}` : envNameToLaneMap[env];
};

// These are common sandbox source names for other Solutions that we can map to Workfront lane names
const sandboxSourceToLaneMap: Record<string, string> = {
  local: 'local',
  dev: 'devtest',
  qa: 'devtest',
  hub: 'hub',
  preview: 'preview',
  prod: 'prod'
};

export const parseFulfillableData = (prodCtx: ProductContext) => {
  try {
    const output = JSON.parse(prodCtx.fulfillable_data || '{}');
    if (typeof output === 'string') {
      return JSON.parse(output);
    }
    return output;
  } catch (e) {
    metrics.error('parseFulfillableData: Error parsing fulfillable data', e);
    return {};
  }
};

export const getHostFromContext = (prodCtx: ProductContext, subOrg?: SubOrg, preview = false): string | undefined => {
  const {hostname, lane, subdomain} = parseFulfillableData(prodCtx);
  const shellSourceLane = sandboxSourceToLaneMap[getQueryValue('source')];
  if (subOrg?.id && subOrg.name) {
    const [subdomainString, envName = ''] = subOrg.name.split(' - ');
    return getDataFromLane(shellSourceLane || getLaneFromEnv(envName.toLowerCase()))?.url?.replace('{subdomain}', subdomainString);
  }
  if (lane && subdomain) {
    return getDataFromLane(shellSourceLane || lane)?.url?.replace('{subdomain}', subdomain);
  }
  return hostname.replace(/(my)\.workfront/, `${preview ? 'preview' : 'my'}.workfront`);
};

export const getSubOrgName = (prodCtx: ProductContext): string => {
  const data = parseFulfillableData(prodCtx);
  const {hostname} = data;
  let {lane, subdomain} = data;
  if (lane && subdomain) {
    return getDataFromLane(lane)?.name?.replace('{subdomain}', subdomain) || 'Undefined';
  }
  if (!hostname) {
    metrics.error('getSubOrgName: No hostname found in product context');
    return 'Undefined';
  }
  // The hostname from the product context is a url that looks like
  // https://{subdomain}.{lane}.workfront.com e.g. unifiedshell.devtest.workfront.com
  // We parse this to get the sub org name in the form of {subdomain} - {lane}
  // e.g. unifiedshell - devtest,
  // where lane = environment in Workfront terms.
  [subdomain, lane] = hostname.split('.');
  return `${subdomain.split('https://')[1]} - ${lane}`;
};

export const isPreferredSubOrg = (prodCtx: ProductContext): boolean => {
  const {lane} = parseFulfillableData(prodCtx);
  return lane === 'prod';
};

const SOURCE: DiscoverySource = {
  discovery: '{host}/authn/authenticate/access_token/ims?refreshable',
  findHostFromContext: {
    getHostFromContext: (prodCtx: ProductContext, subOrg?: SubOrg) => getHostFromContext(prodCtx, subOrg),
    serviceCode: 'workfront',
    useAdobeDomain: true
  },
  payload: [],
  requestId: 'header',
  url: '{host}',
  withCredentials: true
};

const PREVIEW_SOURCE: DiscoverySource = {
  ...SOURCE,
  findHostFromContext: {
    ...SOURCE.findHostFromContext,
    getHostFromContext: (prodCtx: ProductContext, subOrg?: SubOrg) => getHostFromContext(prodCtx, subOrg, true)
  } as FindHostFromContextConfig
};

const localSource: DiscoverySource = {
  ...SOURCE,
  discovery: `{host}/authn/authenticate/access_token/ims?refreshable&lane=${getQueryParams()['wfLane'] || 'devtest'}`
};

export const workfront: SolutionRoute = {
  appId: 'workfront',
  autoInstantiateRuntime: true,
  experienceLeague: {
    communities: 'workfront',
    filter: 'Workfront'
  },
  gainsight: {productKey: 'AP-IZHYZRAKQRSY-2'},
  path: '/workfront',
  refreshOnImsChange: true,
  urlContext: {
    key: 'subOrg',
    config: {
      customPathKey: 'name',
      id: 'fulfillable_entity_resource_locator',
      name: getSubOrgName,
      preferred: isPreferredSubOrg,
      serviceCode: 'workfront',
      useRootPathOnChange: true
    }
  },
  sandbox: {
    history: HISTORY.HISTORY,
    sources: {
      local: localSource,
      dev: SOURCE,
      qa: SOURCE,
      stage: SOURCE,
      prod: SOURCE
    },
    pageTimeout: 360000
  },
  access: {
    code: ['workfront'],
    flag: 'workfront-enabled',
    logicOp: 'AND'
  },
  permissionsPolicy: ['clipboard-read', 'clipboard-write']
};

export const workfrontPreview: SolutionRoute = {
  ...workfront,
  path: '/workfront/preview',
  sandbox: {
    ...workfront.sandbox,
    sources: {
      local: localSource,
      dev: PREVIEW_SOURCE,
      qa: PREVIEW_SOURCE,
      stage: PREVIEW_SOURCE,
      prod: PREVIEW_SOURCE
    }
  }
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import amplify from './amplify';
import {ansible} from './ansible';
import {dataComposer} from './dataComposer';
import {HISTORY, PDH_GLOBAL, RUNTIME, SolutionRoute, THUNDERBIRD} from '@exc/core/src/models/Solution';
import permissionsUIConfig from './permissionsUI';
import {default as platformUIDashboardsSolution} from './platformUIDashboards';
import {platformUIDatasets as platformUIDatasetsSolution} from './platformUIDatasets';
import {
  platformUIDecisioningManagementComponents,
  platformUIDecisioningManagementOffers
} from './platformUIDecisioningManagement';
import platformUIEncryptionConfig from './platformUIEncryption';
import {default as platformUILicenseUsageSolution} from './platformUILicenseUsage';
import {platformUIMetricsCatalog as platformUIMetricsCatalogSolution} from './platformUIMetricsCatalog';
import {platformUsecases as platformUIUsecasesSolution} from './platformUIUsecases';
import {puebloBaseConfig} from './pueblo';

// This file was generated automatically from ../solutions/experiencePlatform.ts

const aepGainsight = {
  productKey: 'AP-GLVNMB5BLV8T-2',
  triggers: {
    fulfillableItems: ['acp_b2b_prof', 'acp_b2b_ui'],
    serviceCode: 'acp'
  }
};

export const ansiblePlatform: SolutionRoute = {...ansible, omegaSuiteId: 'platform'};

export const platformBase = {
  appRoot: 'experiencePlatformUI',
  sharedCaches: ['aep-cache'],
  experienceLeague: {
    communities: 'adobe-experience-platform',
    filter: 'Experience Platform'
  },
  fiServiceCode: 'acp',
  gainsight: aepGainsight,
  omegaSuiteId: 'platform',
  parent: 'AEP' as const,
  permissionsPolicy: ['clipboard-write'],
  runtime: RUNTIME.CDN_LEGACY,
  sandbox: {
    history: HISTORY.HISTORY,
    pageTimeout: 40000
  },
  serviceCode: 'acp',
  sideNav: {id: 'aep', workHubId: 'AEP'},
  unifiedSearch: {
    enabled: true
  },
  urlContext: {key: 'sandbox'} as const
};

const platformSpaCommon = {
  ...platformBase,
  nestedApps: [ansiblePlatform],
  analytics: {omegaGlobal: PDH_GLOBAL.BOTH},
  runtime: RUNTIME.CDN,
  sandbox: {
    ...platformBase.sandbox,
    sources: {
      dev: 'https://localhost.dev.cloud.adobe.io:3000',
      dev2: 'https://localhost.corp.adobe.com:3000',
      dev3: 'https://localhost.dev.cloud.adobe.io',
      qa: 'https://experience-qa.adobe.com/solutions/experience-platform-platform-ui-client',
      stage: 'https://experience-stage.adobe.com/solutions/experience-platform-platform-ui-client',
      prod: 'https://experience.adobe.com/solutions/experience-platform-platform-ui-client'
    }
  },
  spaAppId: 'experience-platform-platform-ui-client'
};

// DO NOT REMOVE: This is used by AJO and Data Collection.
export const experiencePlatformSpa: SolutionRoute = {
  ...platformSpaCommon,
  appId: 'experiencePlatformUI',
  path: '/platform',
  dashboard: true,
  access: {code: ['acp']}
};

export const experiencePlatform: SolutionRoute = {
  ...experiencePlatformSpa,
  alternateRoute: [
    {
      flag: 'aep-queries-v-2-enabled',
      param: 'experience-platform-aep-ui-queries_version',
      path: /\/platform\/query(\/.*|$)/,
      redirect: '/platform/v2/query$1'
    },
    {
      flag: 'aep-datasets-v-2-enabled',
      param: 'experience-platform-aep-ui-datasets_version',
      path: /\/platform\/dataset(\/.*|$)/,
      redirect: '/platform/v2/dataset$1'
    },
    {
      flag: 'aep-license-usage-v-2-enabled',
      param: 'experience-platform-aep-ui-license-usage_version',
      path: /\/platform\/licenseUsage(\/.*|$)/,
      redirect: '/platform/license-usage$1'
    }
  ]
};

export const experiencePlatformHomePueblo: SolutionRoute = {
  ...platformBase,
  ...puebloBaseConfig,
  gainsight: aepGainsight,
  omegaSuiteId: 'platform',
  appId: 'experiencePlatformUI-home',
  path: '/platform/home',
  redirectFrom: [{path: '/platform', options: {end: true}}, {path: '/platform/v2/home'}],
  access: {code: ['acp']},
  dataPrefetchContracts: ['dashboard-sandbox']
};

export const platformPermissionsUI: SolutionRoute = {
  ...platformBase,
  ...permissionsUIConfig,
  appId: 'aep-permissionsUI',
  path: '/platform/admin/permissions'
};

export const platformUIEncryption: SolutionRoute = {
  ...platformBase,
  ...platformUIEncryptionConfig
};

const queriesUI: SolutionRoute = {
  appId: 'platformUIQueries',
  path: '/platform/v2/query',
  alternateRoute: [
    {
      flag: 'aep-queries-v-2-enabled',
      param: 'experience-platform-aep-ui-queries_version',
      signalsInactive: true,
      path: /\/platform\/v2\/query(\/.*|$)/,
      redirect: '/platform/query$1'
    }
  ],
  spaAppId: 'experience-platform-aep-ui-queries',
  analytics: {omegaGlobal: PDH_GLOBAL.BOTH},
  omegaSuiteId: 'platform',
  runtime: RUNTIME.CDN,
  sandbox: {
    history: {
      type: HISTORY.HASH,
      config: {addParamsToHash: true}
    },
    sources: {
      dev: 'https://localhost.corp.adobe.com:8014'
    }
  },
  autoInstantiateRuntime: true,
  thunderbird: THUNDERBIRD.SERVICE_WORKER
};

export const platformUIQueries: SolutionRoute = {
  ...platformBase,
  ...queriesUI
};

export const platformUIOffers: SolutionRoute = {
  ...platformBase,
  ...platformUIDecisioningManagementOffers
};

export const platformUIComponents: SolutionRoute = {
  ...platformBase,
  ...platformUIDecisioningManagementComponents
};

export const platformUIDatasets: SolutionRoute = {
  ...platformBase,
  ...platformUIDatasetsSolution
};

export const platformUIMetricsCatalog: SolutionRoute = {
  ...platformBase,
  ...platformUIMetricsCatalogSolution
};

export const platformUIDashboards: SolutionRoute = {
  ...platformBase,
  ...platformUIDashboardsSolution
};

export const platformUILicenseUsage: SolutionRoute = {
  ...platformBase,
  ...platformUILicenseUsageSolution
};

export const platformUIUsecases: SolutionRoute = {
  ...platformBase,
  ...platformUIUsecasesSolution
};

export const platformUnifiedTags: SolutionRoute = {
  ...platformBase,
  appContainer: '#root',
  appId: 'aep-unifiedTags',
  path: '/platform/tags',
  spaAppId: 'exc-unified-tags-ui',
  runtime: RUNTIME.CDN,
  sandbox: {
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  },
  access: {flag: 'tags-enabled'},
  analytics: {omegaGlobal: PDH_GLOBAL.BOTH}
};

export const platformFederatedDataUI: SolutionRoute = {
  ...platformBase,
  ...dataComposer,
  appId: 'aep-federatedDataUI',
  path: '/platform/federated-data',
  sideNav: {id: 'aep', workHubId: 'AEP'}
};

export const platformAudienceOrchestrationUi: SolutionRoute = {
  ...platformBase,
  appId: 'aep-audienceOrchestrationUi',
  path: '/platform/audience/compose',
  runtime: RUNTIME.CDN,
  spaAppId: 'cjm-audience-orchestration-ui',
  analytics: {omegaGlobal: PDH_GLOBAL.BOTH},
  omegaSuiteId: 'platform',
  sandbox: {
    history: {
      type: HISTORY.HASH,
      config: {addParamsToHash: true}
    },
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  },
  thunderbird: THUNDERBIRD.SERVICE_WORKER,
  nestedApps: [ansiblePlatform]
};

export const mexpAccess = {
  code: ['acp'],
  flag: 'project-m-enabled',
  logicOp: 'AND'
};

export const mexpBaseConfig = {
  spaAppId: 'experience-platform-mexp',
  analytics: {
    omegaGlobal: PDH_GLOBAL.BOTH
  },
  runtime: RUNTIME.CDN,
  sandbox: {
    history: HISTORY.HASH,
    sources: {
      dev: 'https://localhost.corp.adobe.com:8443'
    }
  },
  thunderbird: THUNDERBIRD.SERVICE_WORKER
};

export const platformMexp: SolutionRoute = {
  ...platformBase,
  ...mexpBaseConfig,
  appId: 'aep-mexp',
  path: '/platform/mexp',
  access: mexpAccess
};

export const platformAmplify: SolutionRoute = {
  ...platformBase,
  ...amplify,
  access: mexpAccess,
  appId: 'aep-amplify',
  path: '/platform/mexp/plays',
  sandbox: {
    ...amplify.sandbox,
    pathPrefix: {default: '/plays'}
  }
};

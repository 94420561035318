/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {BROWSER_FILTER_PARAMS} from '@exc/shared';
import {
  getCurrentTenantAndPath,
  getQueryParams,
  getTenantAndPath,
  hashToPath,
  SearchParams
} from './query';
import {parse} from 'querystring';

export {
  hashToPath,
  getCurrentTenantAndPath,
  getTenantAndPath
};

/* eslint-disable sort-keys */
const EXPERIENCE_ORIGINS = {
  dev: 'https://localhost.corp.adobe.com:1234',
  qa: 'https://experience-qa.adobe.com',
  stage: 'https://experience-stage.adobe.com',
  prod: 'https://experience.adobe.com'
} as {[key: string]: string};
/* eslint-enable sort-keys */

const IFRAME_DENIED_PARAMS = new Set<string>([
  'shell_customtoken',
  'shell_devmode',
  'shell_ims',
  'shell_source',
  'unified-shell_version'
]);

interface AddIframeSrcParamsOptions {
  addRuntimeParams?: boolean;
  addToQuery?: boolean;
  appId?: string;
  overrideParams?: boolean;
  runtimeParam: string;
  spaApps?: SPAApp[];
  src: URL;
}

interface SPAApp {
  liveVersion: string;
  spaName: string;
}

interface Paths {
  browser: {
    hash: string;
    path: string;
    search: string;
  };
  iframe: {
    hash: string;
    path: string;
    search: string;
  };
}

interface UrlChanges {
  any: boolean;
  hash: boolean;
  pathname: boolean;
  search: boolean;
}

/**
 * Adds a query paramater.
 * @param params - `URL.searchParams` object to remove params from.
 * @param key - key to add.
 * @param value - value to add.
 * @param override - Override existing params (Defaults to false)
 */
export const addQueryParam = (params: URLSearchParams, key: string, value: string | string[], override = false): void => {
  if (!key || IFRAME_DENIED_PARAMS.has(key) || (params.has(key) && !override)) {
    return;
  }
  (Array.isArray(value) ? value : [value]).forEach(v => override ? params.set(key, v) : params.append(key, v));
};

export const denyBrowserParams = (params: string[]) => params.forEach(BROWSER_FILTER_PARAMS.add, BROWSER_FILTER_PARAMS);

/**
 * Cleans the query params by removing any denied params that
 * exist in the browser URL's query or iframe URL's query. This is to remove
 * duplication of params and any unneeded params.
 * @param params - `URL.searchParams` object to remove params from.
 * @param filterParams additional params to filter (optional)
 */
export const cleanQueryParams = (params: URLSearchParams, filterParams?: Set<string>): void => {
  const toDelete: string[] = [];
  for (const key of params.keys()) {
    (BROWSER_FILTER_PARAMS.has(key) || IFRAME_DENIED_PARAMS.has(key) || filterParams?.has(key)) && toDelete.push(key);
  }
  toDelete.push(...Object.keys(parse(window.location.search.slice(1))));
  toDelete.forEach(key => params.delete(key));
};

/**
 * Removes unwanted query parameters from a given path.
 * @param path - Path
 * @param params - Query parameters to remove
 * @returns - Path with parameters removed.
 */
export const removeQueryParamsFromPath = (path: string, params: string[]): string => {
  const url = new URL(`${window.location.origin}${path}`);
  params.forEach(param => url.searchParams.delete(param));
  return `${url.pathname}${url.search}${url.hash}`;
};

/**
 * Filter query parameters
 * @param {URLSearchParams} params - Parameters
 * @param paramsToKeep - Parameters to keep
 * @returns Search string including only those marked to keep.
 */
export const filterSearchParams = (params: URLSearchParams, paramsToKeep: string[]): string => {
  const keepSet = new Set<string>(paramsToKeep);
  const searchParams = new URLSearchParams();
  params.forEach((value, name) => keepSet.has(name) && searchParams.set(name, value));
  const search = searchParams.toString();
  return search ? `?${search}` : '';
};

/**
 * Removes empty query parameters
 * @param query - The query object.
 * @returns Cleaned query object.
 */
export const cleanQuery = (query: SearchParams): SearchParams => {
  for (const key in query) {
    if (key === '') {
      delete query[key];
    }
  }
  return query;
};

/**
 * Get URL pathname without tenant or sandbox.
 * @param path - Full URL pathname.
 * @returns Pathname without tenant or sandbox.
 */
export const getPathWithoutTenant = (path: string): string => path.replace(/^\/@[^/]*/, '').replace(/^((\/[^/]+:))[^/]*/, '');

/**
 * Get URL pathname without sandbox, suborg, or other context value.
 * @param path - Full URL pathname.
 * @returns Pathname without context value, e.g. /@tenant/sname:sandbox/abc -> /@tenant/abc
 */
export const getPathWithoutContext = (path: string): string => {
  let contextMatch = path.match(/^(?:\/@[^/]+)?(\/[^/]+:[^/]+)/) || [];
  return contextMatch.length ? path.replace(contextMatch[1], '') : path;
};

/**
 * Get URL pathname without tenant, context, or subpaths.
 * @param path - Full URL pathname.
 * @returns Pathname without context value, tenant, or subpaths. e.g. /@tenant/sname:sandbox/abc -> abc
 */
export const getPathPrefix = (path = '') => {
  const cleaned = getPathWithoutTenant(getPathWithoutContext(path));
  const matchGroup = cleaned.replace(/^\//, '').match(/^([^/]*)/);
  return matchGroup && matchGroup[1];
};

/**
 * Adds necessary parameters to iframe source URL.
 * @param options - Function arguments.
 * @param [options.addToQuery] - Should add shell query params to iframe (Default: true)
 * @param [options.overrideParams] - When addToQuery is set, should shell query params override iframe params (Default: false)
 * @param [options.runtimeParam] - Runtime script URL/param.
 * @param [options.spaApps] - SPA config for pipeline apps (optional)
 * @param [options.src] - Iframe source url.
 */
export const addIframeSrcParams = ({
  addRuntimeParams = true,
  addToQuery = true,
  appId,
  overrideParams = false,
  runtimeParam,
  spaApps,
  src
}: AddIframeSrcParamsOptions): void => {
  const {hash, host, origin} = window.location;
  const srcParams = src.searchParams;
  if (addRuntimeParams) {
    addQueryParam(srcParams, '_mr', runtimeParam, true);
    addQueryParam(srcParams, 'shell_domain', host, true);
  }

  appId && addQueryParam(srcParams, 'appId', appId, true);

  // Pass all non-config query params through to the iframe.
  const url = new URL(`${origin}${hash.slice(1)}`);
  const queryParams = getQueryParams();

  if (addToQuery) {
    const hashQueryParams = getQueryParams(url.search.slice(1));
    Object.keys(queryParams).forEach(key => addQueryParam(srcParams, key, queryParams[key], overrideParams));
    Object.keys(hashQueryParams).forEach(key => addQueryParam(srcParams, key, hashQueryParams[key], overrideParams));
  }

  // Add SPA version if the argument is provided.
  if (spaApps?.length) {
    spaApps.forEach(spa => {
      if (spa?.spaName) {
        const {liveVersion, spaName} = spa;
        const param = `${spaName}_version`;
        const version = queryParams[param] || liveVersion;
        version && addQueryParam(srcParams, param, version, true);
      }
    });
  }
};

/**
 * Encodes the current URL so it can be properly passed to IMS.
 * @param tenant - Whether or not to keep the tenant in the URL.
 * @returns URL with encoded hash.
 */
export const encodeForRedirect = (tenant = true) => {
  const {hash, origin, pathname, search = ''} = window.location;
  let oldHash = hash.replace(/^#/, '');
  tenant || (oldHash = getPathWithoutTenant(oldHash));
  return `${origin}${pathname}${search}#old_hash=${encodeURIComponent(`#${oldHash}`)}`;
};

/**
 * Get the context from the path.
 * @param path - Full URL pathname.
 * @returns context.
 */
export const getContextFromPath = (path: string) => (path.match(/^(?:\/@[^/]+)?(\/[^/]+:[^/]+)/) || [])[1];

/**
 * Get tenant from path.
 * @param path - Full URL pathname.
 * @param name - Get tenant name only (Without @)
 * @returns Tenant (if none exist, return ''.
 * If none exist and the name was requested, return undefined).
 */
export const getTenantFromPath = (path: string, name = false): string => /^#?((?:\/@([^/]+))?)/.exec(path)![name ? 2 : 1];

/**
 * Get sandbox from path.
 * @param path - Full URL pathname.
 * @param param - context parameter to search for.
 * @returns Sandbox Name (if none exist, return undefined)
 */
export const getValueFromPath = (path: string, param: string): string => new RegExp(`^#?(?:/@[^/]+)?(/${param}:([^/]+))?`).exec(path)![2];

/**
 * Determines if link is a unified shell path .
 * @param url Href of application
 * @param env Current environment.
 */
export const getUnifiedShellPath = (env: string, url = ''): null | string => {
  const matches = env === 'dev' ?
    url.match(/localhost\.corp\.adobe\.com:\d{4}(\/#)?(\/.*)/) :
    url.match(/experience(?:|-qa|-stage)\.adobe\.com(\/#)?(\/.*)/);
  return matches && getPathWithoutTenant(matches[2]);
};

export const getUnifiedShellUrl = (env: string, path = ''): string => {
  const origin = EXPERIENCE_ORIGINS[env];
  const {hash, search} = window.location;
  const tenant = getTenantFromPath(hash);
  return `${origin}/${search || ''}#${tenant}${path}`;
};

/**
 * Returns the full Unified Shell url with the workspace or nav item `path`
 * appended to the end. Meaning, a path of `/analytics/some/deep/path` would
 * become https://experience.adobe.com/#/@tenant/analytics/some/deep/path.
 * @param path Relative url of workspace or nav item.
 * @returns Full Unified Shell url with workspace or nav item path.
 */
export const getFullUrl = (path: string): string => {
  if (/^https?:/.test(path)) {
    return path;
  }
  const {hash, origin, search} = window.location;
  const tenant = getTenantFromPath(hash);
  return `${origin}/${search || ''}#${tenant}${path}`;
};

/**
 * Get URL pathname with tenant.
 * @param pathname - Old URL pathname with tenant.
 * @param  path - New path to be used, has no tenant.
 * @param hideTenant - Whether or not to keep tenant in URL.
 * @returns New path updated with or without tenant.
 */
export const getUpdatedPath = (pathname: string, path: string, hideTenant = false): string => {
  if (!hideTenant) {
    // Adds tenantId from old pathname to path
    path = pathname.replace(/^(\/?@[^/]*)\/.*/, `$1${path}`);
  }
  return path.startsWith('/') ? path : `/${path}`;
};

/**
 * Compares two paths. This is especially useful because it sorts
 * the search params prior to the comparison since the order in the URL doesn't
 * matter but a string compare will fail.
 * @param path1 - First path to compare.
 * @param path2 - Second path to compare.
 * @returns Specific sections that have or have not changed.
 */
export const hasChanges = (path1: string, path2: string): UrlChanges => {
  const url1 = new URL(`${window.location.origin}${path1}`);
  const url2 = new URL(`${window.location.origin}${path2}`);

  url1.searchParams.sort();
  url2.searchParams.sort();

  let res = {
    hash: url1.hash !== url2.hash,
    pathname: url1.pathname !== url2.pathname,
    search: url1.search !== url2.search
  } as UrlChanges;
  return Object.assign(res, {any: res.hash || res.pathname || res.search});
};

export const isExperienceOrigin = (url?: string): boolean => {
  let origin = url ? (new URL(url)).origin : window.location.origin;
  return /experience(|-qa|-stage)\.adobe\.com/.test(origin);
};

/**
 * Splits a path with the separator. This returns everything after
 * the first separator and ensures that it starts with a `/` if it did at the
 * beginning.
 * @param path - The path to split.
 * @param separator - The character or string to split on.
 * @returns The remains of the path after the `separator`.
 */
export const splitPath = (path: string, separator: string): string => {
  const idx = path.indexOf(separator);
  const res = idx > -1 ? path.slice(idx + separator.length) : path;
  return res.startsWith('/') ? res : `/${res}`;
};

/**
 * Get browser and iframe pathnames and search strings.
 * @param iframePath - Pathname of iframe.
 * @param iframeSearch - Search from iframe.
 * @param iframeHash - Hash from iframe.
 * @param absolutePaths - Whether or not the solution uses absolute paths.
 * @param basePath - Solution basePath.
 * @param configURL - config URL.
 * @returns Browser and iFrame path and search strings.
 */
export const getPaths = (
  iframePath: string,
  iframeSearch: string,
  iframeHash: string,
  absolutePaths: boolean,
  basePath: string,
  configURL: URL
): Paths => {
  const {hash, origin} = window.location;
  const browser = new URL(`${origin}${hash.slice(1)}`);
  const iframe = new URL(`${origin}${iframePath}${iframeSearch}${iframeHash}`);
  // Update the pathname to be the actual path of the solution. For example,
  // changing /@tenant/analytics/path/subpath => /path/subpath. This tells us
  // where the browser is currently pointed.
  browser.pathname = splitPath(browser.pathname, basePath);
  // Update the pathname to be the actual path of the iframe. For example,
  // changing https://analytics.com/root/path/subpath => /path/subpath where
  // https://analytics.com/root is the configURL that was initially loaded.
  // This tells us where the iframe is currently pointed.
  if (!absolutePaths) {
    iframe.pathname = splitPath(iframe.pathname, configURL.pathname);
  }
  // Clean up the query params so that they can be compared apples to apples.
  // Specifically removing _mr and other browser query params (e.g. shell
  // version or dev mode)
  cleanQueryParams(browser.searchParams);
  cleanQueryParams(iframe.searchParams);
  return {
    browser: {
      hash: browser.hash,
      path: browser.pathname,
      search: browser.search
    },
    iframe: {
      hash: iframe.hash,
      path: iframe.pathname,
      search: iframe.search
    }
  };
};

/**
 * Adds a search param to a URL.
 * @param url - URL.
 * @param name - Parameter name.
 * @param value - Parameter value.
 * @returns URL - URL with parameter added.
 */
export const addParamToUrl = (url: string, name: string, value: string): string => {
  const inputUrl = new URL(url);
  inputUrl.searchParams.set(name, value);
  return inputUrl.toString();
};

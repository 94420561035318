{
  "alpha": "Alfa",
  "alphaFeedback": "Feedback alfa",
  "beta": "Beta",
  "betaFeedback": "Feedback beta",
  "customReleaseTrial": "Versão de avaliação",
  "releaseBadgeAlpha": "Alfa",
  "releaseBadgeBeta": "Beta",
  "releaseBadgeDev": "Desenvolvedor",
  "releaseBadgePoc": "POC"
}

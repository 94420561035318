/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import classnames from 'classnames';
import {IntlProvider} from '@quarry/intl';
import {ProgressCircle} from '@adobe/react-spectrum';
import React, {FC} from 'react';
import {useIntl} from 'react-intl';
import * as waitLocaleData from './__localization__/ShellWait';
import './ShellWait.scss';

export interface ShellWaitProps {
  centered: boolean;
  size: 'L' | 'S' | 'M';
  [key: string]: any;
}

const I18nShellWait: FC<ShellWaitProps> = ({centered = true, size, ...otherProps}) => {
  const {formatMessage} = useIntl();
  delete otherProps.className;
  return (
    <div className={classnames('shell-wait', {centered})}>
      <ProgressCircle
        alignSelf={centered ? 'center' : 'flex-start'}
        aria-label={formatMessage({
          defaultMessage: 'Loading',
          id: 'shellWaitLabel'
        }) || 'Loading'}
        aria-live="polite"
        id="spinner"
        isIndeterminate
        justifySelf={centered ? 'center' : 'flex-start'}
        size={size || 'L'}
        {...otherProps}
      />
    </div>
  );
};

const ShellWait: FC<ShellWaitProps> = ({locale = 'en-US', ...otherProps}) => (
  <IntlProvider locale={locale} messages={waitLocaleData}>
    <I18nShellWait {...otherProps} />
  </IntlProvider>
);

export default React.memo(ShellWait);

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import Metrics from '@adobe/exc-app/metrics';
import {queueMetrics} from './queued';

export interface AddAdditionalProps {
  distinctDomain?: boolean;
}

const KEY = 'logoutUrls';
const metrics = Metrics.create('exc.auth.utils.Logout');

export default {
  add(url: string, {distinctDomain}: AddAdditionalProps = {}): void {
    let logoutUrls: string[] = JSON.parse(localStorage.getItem(KEY) || '[]') || [];

    // If the `distinctDomain` property is true, filter out any logout URLs
    // that match the `url` host name. This ensures that applications adding
    // logout URLs won't accidentally be adding multiple.
    if (distinctDomain) {
      try {
        const {host} = new URL(url);
        logoutUrls = logoutUrls.filter(logoutUrl => !logoutUrl.includes(host));
      } catch {
        metrics.error('Invalid URL when processing logoutUrl add', {url});
      }
    }

    // Only add the logout URL if it doesn't already exist in the list.
    if (logoutUrls.indexOf(url) === -1) {
      logoutUrls.push(url);
      localStorage.setItem(KEY, JSON.stringify(logoutUrls));
    }
  },

  async invoke(): Promise<void> {
    const logoutUrls: string[] = JSON.parse(localStorage.getItem(KEY) || '[]') || [];
    try {
      await Promise.all(logoutUrls.map(url => fetch(url, {credentials: 'include', redirect: 'manual'})));
    } catch (error) {
      if (error instanceof Error) {
        queueMetrics('logoutUrls error', {data: {logoutUrls}, error});
      }
    }
  },

  remove(): void {
    localStorage.removeItem(KEY);
  }
};

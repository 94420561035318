/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, PDH_GLOBAL, SolutionRoute, THUNDERBIRD} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/platformUIEncryption.ts

const platformUIEncryption: SolutionRoute = {
  appId: 'platformUIEncryption',
  parent: 'AEP',
  path: '/platform/encryption',
  spaAppId: 'experience-platform-aep-ui-encryption',
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      dev: 'https://localhost.corp.adobe.com:8015'
    }
  },
  access: {
    code: ['acp'],
    fi: ['acp:acp_ps_shld', 'acp:acp_hc_shld'],
    flag: 'cmk-enabled',
    logicOp: 'AND'
  },
  omegaSuiteId: 'platform',
  analytics: {omegaGlobal: PDH_GLOBAL.BOTH},
  thunderbird: THUNDERBIRD.SERVICE_WORKER
};

export default platformUIEncryption;

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import CoreContext from '../context';
import {getPathWithoutContext, getTenantAndPath} from '@exc/url';
import {getQueryParams} from '@exc/url/query';
import hashString from 'string-hash';
import React, {useContext, useEffect, useState} from 'react';
import Sandbox from './Sandbox';
import SandboxSpinner from './SandboxSpinner';
import {updatePath} from '@exc/url/pathUpdate';

/**
 * If there are two configs for a given route, Sandbox selector waits to recieve feature flags
 * to determine the correct sandbox to load.
 */
export const AppSelectorSandbox = props => {
  const {config, params} = props;
  const {featureFlags} = useContext(CoreContext);
  const [activeConfig, setActiveConfig] = useState(null);
  const [key, setKey] = useState('current');

  const processAltRoute = (_config, urlPath) => {
    // Finds the first alternate route that matches in the array. If incorrect
    // matching is happening, check the order of the array to make sure it is
    // correctly ordered.
    const route = _config.alternateRoute.find(altRoute => {
      const {flag, param, path, redirect, regex, signalsInactive} = altRoute;

      if (path) {
        // If there is a path but it does not match, this route is invalid.
        if (!path.test(urlPath)) {
          return false;
        }
        // If there is a redirect property defined, do a regex replace with the
        // path and the redirect, so that additional values can be copied over
        // such as paths.
        if (redirect) {
          altRoute.redirect = urlPath.replace(path, redirect);
        }
      }
      const flagValue = featureFlags?.[flag];
      const flagEnabled = regex && flagValue ? new RegExp(flagValue).test(urlPath) : flagValue === 'true';
      const flagOrParam = flagEnabled || !!(param && getQueryParams()[param]);
      return signalsInactive ? !flagOrParam : flagOrParam;
    });

    // No alternate route has matched. This means the current route should be
    // the activeConfig.
    if (!route) {
      // If the original config is the same as the config being checked, update
      // the key to be 'current'. Otherwise, update the key to be the hash of
      // the config being checked.
      setKey(config.appId === _config.appId ? 'current' : `alt-${hashString(_config.appId)}`);
      return setActiveConfig(_config);
    }

    // If there is a redirect property defined, update the path to the redirect
    // path in the matched alternate route.
    if (route.redirect) {
      return updatePath({path: route.redirect}, true);
    }

    // The current key needs to be updated to be an alternative version, as the
    // remainder of this function both have the alternative matching.
    setKey(`alt-${hashString(JSON.stringify(route))}`);

    // If the newly matched config has an alternateRoute defined, process that
    // now too. Recursion!!!
    if (route.config.alternateRoute) {
      return processAltRoute(route.config, urlPath);
    }
    setActiveConfig(route.config);
  };

  useEffect(() => {
    // Ignore feature flag changes if they are undefined, empty.
    if (!featureFlags || !Object.keys(featureFlags).length) {
      return;
    }
    const {path} = getTenantAndPath(getPathWithoutContext(window.location.hash.replace(/^#/, '')));
    processAltRoute(config, path);
  }, [
    JSON.stringify(params),
    JSON.stringify(featureFlags)
  ]);

  return activeConfig ?
    <Sandbox {...props} config={activeConfig} isAlternateRoute={key.startsWith('alt')} key={key} /> :
    <div className="exc-core-sandbox-common"><SandboxSpinner /></div>;
};

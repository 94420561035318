/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, RELEASE_TYPE, RUNTIME, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/coPilotReview.ts

const coPilotReview: SolutionRoute = {
  appId: 'coPilotReview',
  path: '/co-pilot/review',
  permissionsPolicy: ['clipboard-write'],
  releaseType: RELEASE_TYPE.POC,
  spaAppId: 'experience-platform-co-pilot-review',
  runtime: RUNTIME.CDN,
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  },
  access: {flag: 'co-pilot-review-enabled'}
};

export default coPilotReview;

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {Children, cloneElement, isValidElement, ReactElement, ReactNode, useEffect, useRef, useState} from 'react';
import {createHashHistory} from 'history';
import type {History, Location} from 'history';
import type {Key} from 'path-to-regexp';
import {parse} from 'querystring';
import {pathToRegexp} from 'path-to-regexp';

let userConfirmationCallback: ((message: string, callback: (result: boolean) => void) => void) | undefined;

export const setUserConfirmationCallback = (fn: (message: string, callback: (result: boolean) => void) => void) => {
  userConfirmationCallback = fn;
};

const defaultHistory = createHashHistory({
  getUserConfirmation(message: string, callback: (result: boolean) => void) {
    userConfirmationCallback?.(message, callback);
  }
});
export {defaultHistory as history};

interface RouterProps {
  children: ReactNode | ReactNode[];
  history?: History;
}

function matchRoute(pathTemplate: string, testPath: string, exact: boolean): [boolean, Record<string, string>] {
  const exactModifier = exact || pathTemplate.endsWith('/') ? '' : '/(.*)?';
  const keys: Key[] = [];

  // Can we cache these? or even the children maybe using keys on routes for simplicity
  const regexp = pathToRegexp(`${pathTemplate}${exactModifier}`, keys);
  const matchingRoute = regexp.exec(testPath);
  if (!matchingRoute) {
    return [false, {}];
  }
  // construct the params thing for backwards compat
  const params: Record<string, string> = {};
  keys.forEach((key, index) => {
    const routeParam = matchingRoute[index + 1];
    if (routeParam) {
      params[key.name] = routeParam;
    }
  });
  return [true, params];
}

export function Router({children, history}: RouterProps) {
  const routerHistory = useRef(history || defaultHistory);
  const [currentLocation, setCurrentLocation] = useState<Location>(routerHistory.current.location);

  useEffect(() => {
    const updateRoute = (location: Location) => {
      if (location !== currentLocation) {
        setCurrentLocation(location);
      }
    };

    const unlisten = routerHistory.current.listen(updateRoute);
    updateRoute(routerHistory.current.location);
    return () => unlisten();
  }, []);

  let params: Record<string, string> = {};

  // iterate over each child to test whether the redirect or route matches.
  const childrenArray = Children.toArray(children);
  const matchingChild = childrenArray.find(child => {
    if (!isValidElement(child)) {
      return false;
    }

    const {exact, path, redirect} = child.props;
    // test all routes with paths
    if (path) {
      const [isMatch, matchParams] = matchRoute(path, currentLocation.pathname, exact);
      if (isMatch) {
        params = matchParams;
      }

      // test redirects
      if (typeof redirect === 'function' && isMatch) {
        const redirectResult = redirect({route: `${currentLocation.pathname}${currentLocation.search}${currentLocation.hash}`});
        if (redirectResult) {
          routerHistory.current.replace(redirectResult);
          return true;
        }
        return false;
      }

      return isMatch;
    }

    // if there is a route with no path, and we are here, then this is it!
    return true;
  }) as ReactElement;

  // this is for backwards compatibility only with older router
  const {hash, pathname, search} = currentLocation;
  const cleanQuery = search.startsWith('?') ? search.substring(1) : search;
  const legacyProps = {
    hash: hash.substring(1),

    // The match function is only used in sandbox today, and strictly to figure
    // out whether during a redirect whether the new app that is being loaded
    // has the same appId as the new one.
    // We skip parsing or returning redirects, since they redirect us to another
    // route that we will find anyways later in the children array (as opposed
    // to following them and having to do a recursive function).
    match: (testPath: string) => Children.toArray(children).find(child => {
      if (child && isValidElement(child) && child.props.path) {
        const {exact, path, redirect} = child.props;
        const [isMatch] = matchRoute(path, testPath, exact);
        return typeof redirect === 'function' ? false : isMatch;
      }
      return false;
    }),
    params,
    path: pathname,
    query: parse(cleanQuery),
    querystring: cleanQuery
  };

  if (!matchingChild) {
    throw new Error(`Cannot find component for matching route ${pathname}`);
  }

  return cloneElement(matchingChild, {legacyProps});
}

export interface LegacyRouteProps {
  hash: string;
  match: (path: string) => Record<string, any> | null;
  params: Record<string, string>;
  path: string;
  query: Record<string, string>;
  querystring: string;
}

interface RouteProps {
  children?: ReactElement;
  exact?: boolean;
  path?: string;
  redirect?: ({route}: {route: string}) => string | void;
  legacyProps?: LegacyRouteProps;
}

export function Route({children, legacyProps}: RouteProps) {
  return children ? cloneElement(children, legacyProps) : null;
}

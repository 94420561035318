/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {CACHE_SCOPE, DiscoveryCacheConfig, DiscoveryPayloadKeys} from '@exc/core/src/models/Discovery';
import {HISTORY, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/audiencemanager.ts

const payload: DiscoveryPayloadKeys = ['imsOrg', 'path'];

const cache: DiscoveryCacheConfig = {
  scope: CACHE_SCOPE.LOCAL,
  ttl: 1200 // 20 minutes
};

const audienceManager: SolutionRoute = {
  appId: 'audiencemanager',
  experienceLeague: {
    communities: 'adobe-audience-manager',
    filter: 'Audience Manager'
  },
  path: '/audience-manager',
  serviceCode: 'dma_audiencemanager',
  refreshOnImsChange: true,
  analytics: {code: 'aam'},
  gainsight: {productKey: 'AP-LTAZSTOEXW3L-2'},
  sandbox: {
    pageTimeout: 60000,
    history: {
      type: HISTORY.SERVER,
      config: {
        absolutePaths: true
      }
    },
    sources: {
      dev: {
        cache,
        discovery: 'https://localhost.demdex.com/portal/unifiedshell',
        payload,
        withCredentials: true
      },
      qa: {
        cache,
        discovery: 'https://qa.aam.adobe.com/portal/unifiedshell',
        payload,
        withCredentials: true
      },
      stage: {
        cache,
        discovery: 'https://stage.aam.adobe.com/portal/unifiedshell',
        payload,
        withCredentials: true
      },
      prod: {
        cache,
        discovery: 'https://audience-manager.adobe.com/portal/unifiedshell',
        payload,
        withCredentials: true
      }
    }
  },
  access: {code: ['dma_audiencemanager']}
};

export default audienceManager;

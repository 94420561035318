/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, PDH_GLOBAL, RUNTIME, SolutionRoute, THUNDERBIRD} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/userPreferences.ts

const userPreferences: SolutionRoute = {
  appId: 'userPreferences',
  autoInstantiateRuntime: true,
  path: '/preferences',
  runtime: RUNTIME.CDN,
  spaAppId: 'exc-exc-preferences',
  sandbox: {
    history: HISTORY.HASH,
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  },
  thunderbird: THUNDERBIRD.SERVICE_WORKER,
  analytics: {omegaGlobal: PDH_GLOBAL.WEBSDK}
};

export default userPreferences;

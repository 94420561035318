/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {
  ActiveProductContext,
  ImsProfile,
  ProductContext,
  ProjectedProductContext
} from '@adobe/exc-app/ims/ImsProfile';
import type {ErrorInfo} from './Common';
import {HISTORY} from './Solution';
import type {Section, SessionConfig} from './Solution';
import type {Session} from '@adobe/exc-app/session';
import type {SubOrg} from '@adobe/exc-app/RuntimeConfiguration';

export interface DiscoveryOptions {
  /**
   * Application environment e.g. prod, stage, dev
   */
  appEnv?: string;
  /**
   * Application ID.
   */
  appId: string;
  /**
   * Current base path.
   */
  basePath: string;
  /**
   * App history type
   */
  history: HISTORY;
  /**
   * Application sections
   */
  sections?: Section[];
  /**
   * Application service code as it appears in the product context.
   */
  serviceCode?: string;
  /**
   * Session Config
   */
  sessionConfig?: SessionConfig;
  /**
   * The request is needed for session acquisition
   * This will make Discovery skip the cache to force a new session.
   */
  sessionRequest?: boolean;
}

interface RedirectDiscoveryConfig {
  /**
   * Error codes to redirect for.
   */
  code: number[];
  /**
   * Path to redirect to.
   */
  path: string;
}

export enum CACHE_SCOPE {
  /**
   * Do not cache (Default).
   */
  NONE = 'NONE',
  /**
   * Cache in local storage
   */
  LOCAL = 'LOCAL'
}

export enum PATH_CACHE_METHOD {
  /**
   * Each path makes a unique cache entry (Default)
   */
  CACHE_PATH,
  /**
   * Cache query params only and augment them on top of the discovery path provided.
   * For example: input is /path, we send to discovery "", output is /?param=value
   * Gets cached: /?param=value
   * Gets returned: /path?param=value
   * Second run: input is /path2, output using cache is /path2?param=value
   */
  CACHE_PARAMS,
  /**
   * Cache default path only and use it when the input is empty.
   * For example: original input is /myPath, we send to discovery "", output is /defaultPath
   * Gets cached: /defaultPath
   * Gets returned: /myPath
   * Second run: original input is "", output using cache is /defaultPath
   */
  CACHE_DEFAULT_PATH
}

export interface DiscoveryCacheConfig {
  /**
   * Query parameters which may impact discovery result.
   * Any variation of these parameters will be kept in a seperate cache entry.
   */
  keepParams?: string[];
  /**
   * Only keep the cache for the current IMS session.
   */
  limitToImsSession?: boolean;
  /**
   * How path will be cached and augmented
   */
  pathCacheMethod?: PATH_CACHE_METHOD;
  /**
   * Query parameters which are ignored by the discovery service.
   * If part of the path, these parameters will be removed from the discovery result.
   */
  removeParams?: string[];
  /**
   * Discovery cache scope
   */
  scope: CACHE_SCOPE;
  /**
   * Time-to-live: How long is this cache entry valid for (seconds).
   */
  ttl?: number;
}

export interface HostParams {
  /**
   * IMS org ID.
   */
  org: string;
  /**
   * Projected product context from IMS profile.
   */
  projectedProductContext: ProjectedProductContext[];
  /**
   * Current sub org.
   */
  subOrg?: SubOrg;
}

export interface FindHostFromContextConfig {
  /**
   * Callback that handles getting or constructing host from product context data.
   */
  getHostFromContext: (prodCtx: ProductContext, subOrg?: SubOrg) => string | undefined;
  /**
   * Service code for app.
   */
  serviceCode: string;
  /**
   * Whether or not to use adobe.com domain.
   */
  useAdobeDomain?: boolean;
}

export type DiscoveryPaths = {
  /**
   * Full path, including solution name.
   */
  fullPath?: string;
  /**
   * Path
   */
  path?: string;
};

export type DiscoveryPayload = DiscoveryPaths & {
  /**
   * Analytics Company (When using the Sub Org context)
   */
  company?: string;
  /**
   * Active product context - Used by Adobe Analytics to determine login company (sub org)
   */
  activeProductContext?: ActiveProductContext;
  /**
   * Current IMS org ID
   */
  imsOrg?: string;
  /**
   * IMS profile
   */
  imsProfile?: ImsProfile;
  /**
   * User Locale
   */
  locale?: string;
  /**
   * Tenant ID
   */
  tenant?: string;
};

export type DiscoveryPayloadKeys = (keyof DiscoveryPayload)[];

export interface DiscoverySource {
  /**
   * Discovery Cache Config
   */
  cache?: DiscoveryCacheConfig;
  defaults?: {[key: string]: string};
  /**
   * Discovery endpoint
   */
  discovery: string;
  /**
   * Configuration of strings used to find host from product context.
   */
  findHostFromContext?: FindHostFromContextConfig;
  /**
   * Retrieve the error id from the failure response for Unified Shell to show
   * a custom message based on it.
   */
  getErrorInfo?: (res: Record<string, string>) => ErrorInfo;
  /**
   * Next discovery: Used when chaining multiple discoveries together.
   */
  next?: DiscoverySource;
  /**
   * If true, this discovery also provides a session.
   */
  includesSession?: boolean;
  /**
   * List of data to include in payload
   */
  payload: DiscoveryPayloadKeys;
  /**
   * Instead of showing an error page, Unified shell will navigate
   * to a path after a discovery failure.
   */
  redirectOnFailure?: RedirectDiscoveryConfig;
  /**
   * Reduce profile in payload
   */
  reduceProfile?: boolean;
  /**
   * Minimal payload to have before calling Discovery.
   * Must be a subset of "payload".
   */
  requiredPayload?: DiscoveryPayloadKeys;
  /**
   * If true, a random requestId will be added to the request for logging purposes.
   */
  requestId?: 'query' | 'header';
  /**
   * Simple requests allow the browser to skip the OPTIONS call.
   * See: https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#simple_requests
   */
  simpleRequest?: boolean;
  /**
   * Solution URL (Used when URL is known pre-discovery)
   */
  url?: string;
  /**
   * If true, include cookies in the discovery request
   */
  withCredentials?: boolean;
  /**
   * If true, client ID will be added to the request (x-api-key header).
   */
  xApiKey?: boolean;
}

export interface DiscoveryResponse {
  /**
   * IMS client ID to be used by solution
   */
  client_id?: string;
  /**
   * Discovery URL used to get this response (For logging purposes).
   */
  discoveryUrl: string;
  /**
   * Signals that this URL should not be cached.
   */
  dontCache?: string;
  /**
   * Timestamp (ms) when the discovery response expires
   */
  expires: number;
  /**
   * Timestamp (ms) when the discovery request was fulfilled
   */
  fulfilled: number;
  /**
   * Hostname string
   */
  host?: string;
  /**
   * Error message (If discovery fails)
   */
  message?: string;
  /**
   * Token scope (Used with IMS client ID)
   */
  scope?: string;
  /**
   * Session ID
   */
  session?: string;
  /**
   * Session Expiry
   */
  sessionExpires?: number;
  /**
   * Solution URL
   */
  url?: string;
}

export interface CachedSession extends Session {
  /**
   * Hashed value of the session context.
   * Used to verify if the session is valid for the current context.
   * i.e. if a session scope is a specific org, another org will yield a different hash.
   */
  hash?: string;
}

export enum DiscoveryResponseSource {
  CACHE = 'cache',
  DIRECT = 'direct',
  MEMORY = 'memory'
}

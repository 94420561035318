{
  "alpha": "Alpha",
  "alphaFeedback": "Alpha-Feedback",
  "beta": "Beta",
  "betaFeedback": "Beta-Feedback",
  "customReleaseTrial": "Testversion",
  "releaseBadgeAlpha": "Alpha",
  "releaseBadgeBeta": "Beta",
  "releaseBadgeDev": "Entw.",
  "releaseBadgePoc": "POC"
}

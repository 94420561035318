/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {
  allOrgsInProfile,
  getAvatarUrls,
  getOrgMetadata,
  isCustomTokenValid,
  isInternal,
  staleWhileRevalidate
} from './utils';
import {AuthMessages, AuthType, ErrorTypes, HTMLStatus, PingResult, RecentStatus} from './enums';
import {checkCDN, waitForPingSuccess} from './cdnConnect';
import CoreContext from './context';
import {CustomLabelType} from '@adobe/exc-app/topbar';
import {debounceRaF} from '@exc/dom';
import {decodeBase64String, decompressString, parseIfJson} from './utils/decodeString';
import decodeUrl from './utils/decodeUrl';
import {defaultTheme, Provider as SpectrumV3Provider} from '@adobe/react-spectrum';
import excMetrics, {Level} from '@adobe/exc-app/metrics';
import {getBestSupportedLocale, getLocaleRegion, SUPPORTED_LOCALES} from './utils/locale';
import {getBootstrap, UNIFIED_SHELL_APIKEY, UNIFIED_SHELL_APPID} from './services/BootstrapService';
import {getContextService} from './services/ContextService/ContextService';
import {getHeroConfig, HERO_CONFIGS} from './Branding';
import {
  getPathPrefix,
  getPathWithoutTenant,
  getTenantFromPath,
  getUnifiedShellUrl,
  getValueFromPath,
  hashToPath,
  isExperienceOrigin
} from '@exc/url';
import {getQueryParams, getQueryValue} from '@exc/url/query';
import {getSkylineInstance, isSkyline} from '@exc/url/skyline';
import hashString from 'string-hash';
import {hasPermission, hasValidRole} from './utils/permission';
import {history, setUserConfirmationCallback} from '@exc/router';
import {initializeShellDataForHeader} from '@exc/graphql/src/queries/auth';
import {injectIntl} from 'react-intl';
import {Internal} from '@adobe/exc-app/internal';
import {NOOP, SHELL_HEIGHT, wait} from '@exc/shared';
import {onConsent} from './utils/consent';
import orchestrationService from './services/OrchestrationService';
import {overrideFeatureFlags} from './modules/DebugModules/AccessOverrides/common';
import {PREF_APPID, PREF_GROUPID, setUnauthorizedHandler} from '@exc/graphql';
import PropTypes from 'prop-types';
import {PROVIDERS} from '@adobe/exc-app/featureflags';
import {queueMetrics, reload} from '@exc/auth';
import React from 'react';
import {RELEASE_TYPE} from './models/Solution';
import ServiceWorkerControllerService from './services/ServiceWorker/ServiceWorkerControllerService';
import {SettingsLevel} from '@adobe/exc-app/settings';
import {SHELL_FG_PROJECT, SHELL_FLAG_PREFIX} from './utils/floodgate';
import {SHELL_LD_PROJECT} from './utils/launchDarkly';
import ShellWrapper, {waitForShellHeader} from './Shell';
import {storage} from '@exc/storage';
import TranslationService from './services/TranslationService';
import unifiedShellSession from './services/UnifiedShellSessionService';
import {updatePath, updateValueInPath} from '@exc/url/pathUpdate';
import WorkHubService from './services/WorkHubService';

const DebugView = React.lazy(() => import('./modules/Debug'));
const ErrorView = React.lazy(() => import('./modules/Error'));
const ShellNoAccess = React.lazy(() => import('./ShellNoAccess'));
const ShellRolesModal = React.lazy(() => import('@exc/shell/src/ShellRolesModal'));
const ShellRolesModalInvite = React.lazy(() => import('@exc/shell/src/ShellRolesModal/ShellRolesModalInvite'));
const TuxContainer = React.lazy(() => import('@exc/tux/src/TuxContainer'));
const UserConsentDialog = React.lazy(() => import('./modules/UserConsent'));
const DialogContainer = React.lazy(
  () => import('@react-spectrum/dialog').then(module => ({default: module.DialogContainer}))
);
const HistoryBlockerDialog = React.lazy(() => import('./modules/HistoryBlockerDialog'));
const BetaAgreement = React.lazy(() => import('@exc/shell/src/BetaAgreement/BetaAgreement'));

const RESETTABLE_ERRORS = [ErrorTypes.SANDBOX_FAIL, ErrorTypes.SUBORG_FAIL, ErrorTypes.TIMEOUT];
const LAST_LOGGED_IN = 'LAST_LOGGED_IN';
const AVATAR_SIZES = [110, 115];
const DAY = 3600 * 1000 * 24;
const MONTH = DAY * 30;
const TEN_YEARS = DAY * 3650;
const TOAST_TIMEOUT = 11000;

const FULL_ZOOM_WIDTH = 768;
const SHELL_SIDE_NAV_WIDTH = 222;
const SIDE_NAV_FULL_ZOOM_WIDTH = 768;

// This supports assets/content hub ask to decode and decompress
// fulfillable_data in the IMS product contexts. Should future apps need this
// they will need to be added to this list. But for now this is a special case
const SERVICE_CODES_USING_GZIP = ['dma_aem_cloud'];

const RECENTS_RESET = {
  cursor: {isSet: false, value: null},
  recents: [],
  status: RecentStatus.LOADING
};

const cleanTenant = tenant => tenant && tenant.split(':')[0];

class Core extends React.Component {
  constructor(props) {
    super(props);

    this.metrics = excMetrics.create('exc.core.Core');
    this.serviceWorkerService = new ServiceWorkerControllerService();
    this.appAssemblyPromise = import('./services/AppAssemblyService')
      .then(module => this.appAssemblyModule = module);
    this.postAuth = import('./postAuth').then(module => this.initializeServices(module));
    this.shellTimer = props.shellTimer || this.metrics.start('Shell');
    this.bootstrap = getBootstrap();
    this.config = this.bootstrap.config;
    this.metricsEnv = this.bootstrap.metricsEnv;
    this.cdn = this.bootstrap.activeCdn;
    this.tenantMap = {};
    const hero = HERO_CONFIGS.cloud;
    this.favicon = hero.icon;
    this.nonSupportedBrowser = false;
    setUserConfirmationCallback((message, callback) => {
      this.setState({historyDialogProps: {callback, message}});
    });
    this.authProcessingInitialized = false;

    this.state = {
      accessOverridesEnabled: false,
      accessToken: null,
      activeFeatureFlags: {},
      activeFulfillableItems: {},
      activeProductContext: null,
      afterPrintHandler: false,
      appProfile: null,
      appToken: null,
      auth: null,
      backgroundReady: false,
      beforePrintHandler: false,
      coachMark: {},
      coachMarkPosition: null,
      customEnvLabel: [],
      customHeroClick: false,
      customLeftNavClick: false,
      customSearch: {},
      debug: false,
      defaultComponent: props.defaultComponent,
      environment: this.config.environment,
      error: null,
      featureFlags: null,
      feedback: {},
      feedbackConfig: null,
      gainsightRoles: null,
      globalDialogOpen: false,
      helpCenter: {},
      hero,
      imsOrgs: [],
      initialAppLoaded: false,
      innerModalAppId: null,
      isAWSOrg: null,
      leftNavCloseOnSelection: false,
      locale: 'en-US',
      modal: false,
      onCustomButtonClick: NOOP,
      orgRegion: null,
      orgsMap: [],
      orgToSubOrgsMap: {},
      profile: null,
      pulse: {},
      recentSandboxes: null,
      recentsData: RECENTS_RESET,
      sandbox: {
        enabled: false,
        incognito: false,
        onChange: this.onSandboxChange,
        sandboxes: [],
        selected: null
      },
      selectedImsOrg: null,
      selectedOrgRegion: null,
      selectedSubOrg: null,
      shellResponse: {},
      showNetworkDisconnected: getQueryValue('showDisconnect'),
      showRolesInvite: null,
      showRolesModal: null,
      showUserConsent: null,
      sidebar: null,
      sidenav: null,
      sideNavOpen: this.getSideNavState(true),
      sideNavOpenByHover: false,
      solutionConfig: {},
      theme: 'lightest',
      toastPlacement: 'top center',
      userAccounts: null,
      workHubResponse: null,
      workspaces: []
    };

    const {endpoints} = this.config;
    this.allOrgs = new Set;
    this.authPromise = new Promise(resolve => this.authResolve = resolve);
    this.ffPromises = {};
    this.translationService = new TranslationService(endpoints.translation);
    this.workHubService = new WorkHubService(this.props.listedApps);
    this.hasTenantInUrl = true;
    this.history = props.history || history;
    this.orgRegions = {};
    this.orgTenantMap = {};
    this.debouncedUpdateCollapsedState = debounceRaF(this.updateCollapsedState);
    this.debouncedUpdateTargetNode = debounceRaF(this.updateTargetNode);
    this.tenantOrgMap = {};
    this.userFulfillableData = {};
    this.userFulfillableItems = {};
    this.userServiceCodes = {};
    this.userSubServices = {};
    this.userVisibleNames = {};
    this.unlisten = this.history.listen(this.onHistoryChange);
    this.getLDFeatureFlags = this.getLDFeatureFlags.bind(this);
    this.settingsHandler = this.settingsHandler.bind(this);
    this.setSetting = this.setSetting.bind(this);
    this.showToast = this.showToast.bind(this);
    this.settingsRef = {
      getSetting: this.getSetting,
      setSetting: this.setSetting,
      settingsHandler: this.settingsHandler
    };
    this.agreements = {
      clear: this.clearAgreementResponse,
      get: this.getAgreementResponse,
      show: this.showBetaAgreement
    };
    // If HTML timeout is falsy (0), it means the HTML is fresh, we don't need to wait for it.
    this.htmlReady = this.serviceWorkerService.getWaitTimeout() ? HTMLStatus.PENDING : HTMLStatus.OK;
    this.availability = {html: this.htmlReady, ping: PingResult.PENDING};
    this.pingPromise = checkCDN().then(pingResponse => {
      if (pingResponse.success) {
        this.availability.ping = PingResult.SUCCESS;
        this.metrics.event('PingCDN.Success');
      } else {
        this.availability.ping = PingResult.FAILED;
        this.metrics.event('PingCDN.Fail', pingResponse, {level: Level.ERROR});
      }
      return pingResponse;
    });

    orchestrationService.waitForBackground().then(() => this.setState({backgroundReady: true}));
    orchestrationService.waitForFirstLoad(true).then(() => this.setState({initialAppLoaded: true}));

    // Tokens are handled in sessionstorage. If there are lingering local storage cached tokens,
    // remove them completely.
    storage.local.remove('shellCachedTokens');

    // The userConsentShown flag helps determine when we want to show the dialog
    // for some cases like org switching. We store and update the value without
    // state so we don't re-render
    this.userConsentShown = false;
    // The firstTimeUser flag supports TUX delivery logic. It is also stored
    // and updated without state to avert re-rendering
    this.firstTimeUser = null;
  }

  toggleDebugModal(open) {
    if (open && (this.componentModal || this.state.showUserConsent)) {
      return;
    }
    this.setState({debug: open});
  }

  componentDidMount() {
    // Normally Core would be mounted with auth empty, but in case it's not - Run onAuth.
    this.props.auth && this.onAuth();
  }

  initializeServices(postAuthModule) {
    const {
      eventObserver,
      getAEMOrg,
      getBroadcastChannel,
      getCapabilityWithIconUrl,
      getPerformanceMarks,
      setupServices
    } = postAuthModule;
    setupServices(this);
    eventObserver.registerKeyHandler({ctrlKey: true, key: 'i'}, () => this.toggleDebugModal(true));
    eventObserver.registerKeyHandler({key: 'Escape'}, () =>
      this.state.showNetworkDisconnected && this.setState({showNetworkDisconnected: null}));
    this.getPerformanceMarks = getPerformanceMarks;
    this.getCapabilityWithIconUrl = getCapabilityWithIconUrl;
    this.authChannel = getBroadcastChannel(this.props.showSpinner, this);

    // Need to listen for updates to Launch Darkly flags. It's possible to force
    // fetch flags from the API.
    this.services.getFlagService(PROVIDERS.LAUNCH_DARKLY).on('change', featureFlags => {
      // Unified Shell is the only project that matters for this component,
      // thus it's the only one we care about.
      if (SHELL_LD_PROJECT in featureFlags) {
        // Set the updated flags. Also, run the check for access overrides so
        // that it can be updated properly if enabled.
        this.setState({featureFlags: featureFlags[SHELL_LD_PROJECT]}, async () => {
          await this.authPromise;
          const {accessOverridesEnabled, auth, featureFlags: flags, selectedImsOrg} = this.state;
          const hashedAuthId = auth.getHashedAuthId();
          // Update the feature flags if access overrides are enabled.
          accessOverridesEnabled && this.checkToSetAccessOverrides(
            selectedImsOrg,
            hashedAuthId,
            accessOverridesEnabled
          );
          // Set activeFeatureFlags to the new basic flags if access overrides
          // are not enabled.
          accessOverridesEnabled || this.setState({activeFeatureFlags: flags});
        });
      }
    });

    // If this is the Skyline domain, kick off the discovery call immediately
    // in order to get the AEM org that is used by this instance, in addition
    // to creating the session now. When the original discovery call is made
    // from Sandbox, it will use the cached response.
    if (isSkyline()) {
      this.aemPromise = getAEMOrg(this.props.listedApps, this.state.environment);
    }

    this.services.dataPrefetchService.on('prefetch', prefetched => this.handlePrefetch(prefetched));

    // This state update is necessary to trigger a context update for the
    // sandboxes, as currently they depend on discovery service coming from context.
    this.setState({servicesLoaded: true});
    return postAuthModule;
  }

  onAuth() {
    const {auth} = this.props;

    if (this.props.error || this.state.error) {
      return;
    }

    this.setState({auth}, () => this.authProcessing());
    window.addEventListener('resize', this.debouncedUpdateTargetNode);
    window.addEventListener('resize', this.debouncedUpdateCollapsedState);
  }

  getSessionId = () => this.bootstrap.getSid() || 'unknown';

  componentWillUnmount() {
    this.unlisten && this.unlisten();
    window.removeEventListener('resize', this.debouncedUpdateTargetNode);
    window.removeEventListener('resize', this.debouncedUpdateCollapsedState);
  }

  componentDidUpdate() {
    if (this.props.error && !this.state.error) {
      this.setState({error: this.props.error});
    }
    // Process when we get auth.
    if (this.props.auth && !this.state.auth) {
      this.onAuth();
    }
  }

  updateLastRecent = recentTimestamp => {
    const {selectedImsOrg} = this.state;
    this.lastRecents = {...(this.lastRecents || {}), [selectedImsOrg]: recentTimestamp};
    this.setSetting({lastRecents: this.lastRecents});
  };

  handlePrefetch = ({key, value}) => {
    if (key.startsWith('recents-') && value?.recents?.length > 0) {
      const {clickTimestamp} = value.recents[0];
      const {selectedImsOrg} = this.state;
      if (clickTimestamp > (this.lastRecents?.[selectedImsOrg] || 0)) {
        this.updateLastRecent(clickTimestamp);
      }
    }
  };

  updateCollapsedState = () => {
    const oldOpen = this.state.sideNavOpen;
    if (window.innerWidth <= FULL_ZOOM_WIDTH && oldOpen) {
      // Since this is a temporary close, we don't need to save to settings / session
      this.setState({sideNavOpen: false, sideNavOpenByHover: false});
    } else if (window.innerWidth > FULL_ZOOM_WIDTH) {
      const savedState = this.getSideNavState(true);
      savedState && savedState !== oldOpen && this.toggleSideNav(savedState);
    }
  };

  getSideNavState(skipSetState) {
    const getOpen = (val, defaultValue = null) => typeof val === 'boolean' ? val : defaultValue;

    // Grab the shell open setting and use as a backup to any existing session
    // storage setting there might be.
    const open = getOpen(this.state?.settings?.shellNav?.open);
    let sideNavOpen = getOpen(storage.session.getSync('sideNavOpen'), open);
    if (sideNavOpen === null) {
      sideNavOpen = true;
    }
    if (this.state?.solutionConfig?.sideNav?.closeOnLoad || window.innerWidth <= FULL_ZOOM_WIDTH) {
      sideNavOpen = false;
    }
    if (!skipSetState) {
      this.setState({sideNavOpen, sideNavOpenByHover: false});
    }
    return sideNavOpen;
  }

  async setWorkHub(selectedImsOrg) {
    await this.authPromise;
    const {locale, sandbox, solutionConfig} = this.state;
    const {workHubId} = solutionConfig?.sideNav || {};
    const {urlContext} = solutionConfig || {};
    const imsOrgId = selectedImsOrg || this.state.selectedImsOrg;

    // Don't execute if not a Work-Hub enabled left nav.
    if (!workHubId || !this.isSideNavEnabled()) {
      return;
    }
    /*
    Call WorkHub if...
    1. Sandboxes are enabled for the app, AEP is provisioned for the user on the org, Sandboxes are required for the app, A sandbox is selected
    2. Sandboxes are enabled for the app, Sandboxes are NOT required
    3. Sandboxes are NOT enabled for the app
    */
    const sandboxesEnabled = urlContext?.key === 'sandbox';
    const sandboxesRequired = !urlContext?.optional;

    if (!(!sandboxesEnabled || !sandboxesRequired || this.orgHasAEP() && sandbox?.selected)) {
      return;
    }
    const workHubResponse = await this.workHubService.getWorkHub({
      capability: workHubId,
      imsOrgId,
      locale: locale || 'en-US',
      sandbox: sandbox?.selected?.name || 'none'
    });
    workHubResponse && this.setState({workHubResponse: workHubResponse.items});
  }

  /**
   * Gets the following data from GraphQL:
   *   1. Pulse (Timeline, Org Settings & Preferences) - To update the Shell header.
   *   2. Next profile - Only if we are using a cached profile (Reload), used to ensure a new open tab will always contain a fresh profile.
   *   3. Avatar - To update in both current and next profile with avatar, in case any SPA uses the avatar (i.e. Preferences)
   * @param {string} locale Current locale to get localized notifications.
   * @param {IMSProfile} profile Current profile
   */
  async getHeaderData(locale, profile) {
    const {auth} = this.state;
    const pulseDataPromise = orchestrationService.waitForBackground().then(() => initializeShellDataForHeader({
      config_version: 0,
      getProfile: !this.props.isLogin,
      imageKeySize: AVATAR_SIZES,
      locale,
      settings: {
        lastClickTimestamp: '',
        lastNotificationSeenTimestamp: '',
        lastSeenAnnouncementsTimestamp: ''
      }
    }));

    const {
      getPPSProfile,
      timeline: timelineResponse,
      userOrgPreferences,
      getSettings: settingsResponse,
      userProfileJson} = await pulseDataPromise || {};

    const {avatar, avatarSrc: userAvatar} = getAvatarUrls(getPPSProfile, AVATAR_SIZES);

    if (userAvatar && profile.avatarSrc !== userAvatar) {
      // Different or no avatar in profile. Update state.
      profile.avatar = avatar;
      profile.avatarSrc = userAvatar;
      this.setState({profile, userAvatar});
    }
    this.setState({pulseLoaded: true, settingsResponse, timelineResponse, userOrgPreferences});

    // In case we used a stored profile, we got a fresh profile and store it in the session.
    // This ensures on browser refresh profile will be fresh.
    if (userProfileJson) {
      if (userAvatar) {
        // Add the avatar to the profile we are caching.
        // If the user refresh the browser, the avatar will show right away.
        userProfileJson.avatar = avatar;
        userProfileJson.avatarSrc = userAvatar;
      }
      auth.updateProfile(userProfileJson);
    }
  }

  /**
   * Common function to handle errors in feature flag fetching for solution access.
   * @param {Error} erorr Error message
   * @param {string} provider Provider name
   */
  handleFeatureFlagApiError(error, provider) {
    const access = this.state.solutionConfig.access || {};
    const flagsRequired = access.flag && (!access.code || access.logicOp === 'AND');
    if (flagsRequired) {
      this.showApiFailureErrorScreen({
        api: 'User Data',
        error,
        failureMessage: `Feature Flag ${provider} API failed.`
      });
      throw error;
    }
  }

  /**
   * Wrapper function to send requests for both feature flag providers
   * @param {boolean} setState Whether to set the state after getting the data.
   * @param {string} selectedImsOrg Currently selectedImsOrg.
   */
  async getFeatureFlagData(setState = true, selectedImsOrg = this.state.selectedImsOrg) {
    await this.authPromise;
    const {auth, locale, profile, sandbox} = this.state;
    const ffPromise = this.createFFPromise(selectedImsOrg);

    this.services || await this.postAuth;

    const context = {
      hashedAuthId: auth.getHashedAuthId(),
      imsInfo: {imsProfile: profile},
      imsOrg: selectedImsOrg,
      internal: this.internal,
      locale,
      ...(sandbox && {sandbox})
    };
    // Get the data from the services
    const launchDarklyFlags = await this.getLaunchDarklyData(context, ffPromise);
    const floodgateFlags = await this.getFloodgateData(context, ffPromise);
    // Merge the flags. All FG flags receive a prefix to avoid collision with LD flags.
    const featureFlags = {...launchDarklyFlags, ...floodgateFlags};
    // Resolve the flag promise
    ffPromise.resolve(featureFlags);
    // Update App Assembly
    this.appAssemblyService.setFeatureFlags(featureFlags);
    const shellResponse = this.getShellConfiguration(selectedImsOrg);
    this.logMetricsForShellInfo(shellResponse, 'getFeatureFlagData');
    // Get and set the state if needed
    const state = {
      activeFeatureFlags: featureFlags,
      featureFlags,
      shellResponse
    };
    setState && this.setState(state);
    // We can now check for feature flag overrides
    this.checkToSetAccessOverrides(selectedImsOrg);
    return state;
  }

  async getFloodgateData(context, flagPromise) {
    const fgDataTimer = this.metrics.start('FloodgateData');
    let floodgateFlags = {};

    try {
      let flags = await this.services.getFlagService(PROVIDERS.FLOODGATE).getFeatureFlags(
        [SHELL_FG_PROJECT],
        {...context, sandbox: null}
      );
      flags = flags[SHELL_FG_PROJECT];
      // We add the prefix `fg:` to floodgate flags to:
      // - prevent a collision of matching feature flags in Launch Darkly
      // - diiferentiate between flags by provider used for solution access
      // The prefix only applies to Core, not any feature flag services
      for (const key in flags) {
        floodgateFlags[`${SHELL_FLAG_PREFIX}${key}`] = flags[key];
      }
    } catch (error) {
      flagPromise.reject();
      this.metrics.error('Failed to retrieve feature flags from floodgate via GQL', error);
      floodgateFlags = {};
      this.handleFeatureFlagApiError(error, PROVIDERS.FLOODGATE);
    }
    fgDataTimer.time('done', 'floodgate feature flags were pulled from GQL');
    return floodgateFlags;
  }

  async getLaunchDarklyData(context, flagPromise) {
    const ldDataTimer = this.metrics.start('LaunchDarklyData');
    let featureFlags;

    try {
      const flags = await this.services.getFlagService(PROVIDERS.LAUNCH_DARKLY).getFeatureFlags(
        [SHELL_LD_PROJECT],
        context
      );
      featureFlags = flags[SHELL_LD_PROJECT];
    } catch (error) {
      flagPromise.reject();
      this.metrics.error('Failed to retrieve feature flags from launch darkly via GQL', error);
      featureFlags = {};
      this.handleFeatureFlagApiError(error, PROVIDERS.LAUNCH_DARKLY);
    }
    ldDataTimer.time('done', 'launch darkly feature flags were pulled from GQL');
    return featureFlags;
  }

  createFFPromise(selectedImsOrg) {
    if (!this.ffPromises[selectedImsOrg]) {
      const promise = {};
      promise.promise = new Promise((resolve, reject) => {
        promise.reject = reject;
        promise.resolve = featureFlags => {
          promise.featureFlags = featureFlags;
          resolve(featureFlags);
        };
      });
      promise.promise.catch(() => null);
      this.ffPromises[selectedImsOrg] = promise;
    }
    return this.ffPromises[selectedImsOrg];
  }

  // Get launch darkly feature flags for a list of project IDs plus overrides from any other project
  async getLDFeatureFlags(projectIds = [SHELL_LD_PROJECT]) {
    await this.authPromise;
    this.services || await this.postAuth;

    const {auth, locale, profile: imsProfile, selectedImsOrg} = this.state;
    const hashedAuthId = auth.getHashedAuthId();

    const flags = await this.services.getFlagService(PROVIDERS.LAUNCH_DARKLY)?.getFeatureFlags(projectIds, {
      hashedAuthId,
      imsInfo: {imsProfile},
      imsOrg: selectedImsOrg,
      internal: this.internal,
      locale
    });
    return overrideFeatureFlags(flags, hashedAuthId);
  }

  configureNetwork(selectedImsOrg, skipWorkHub, selectedOrgRegion = this.state.selectedOrgRegion) {
    const {sandbox: {selected: selectedSandbox} = {}, selectedImsOrg: stateOrg} = this.state;
    const imsOrg = selectedImsOrg || stateOrg;
    //configure network runtime API
    this.bootstrap.configureNetwork(imsOrg, selectedSandbox, selectedOrgRegion);
    if (selectedSandbox) {
      const setPermissionContext = () => this.services.permissionService.setContext(
        imsOrg, selectedSandbox, this.orgHasAEP()
      );
      this.services ? setPermissionContext() : this.postAuth.then(setPermissionContext);
    }
    skipWorkHub || this.setWorkHub(imsOrg);
    this.createFFPromise(imsOrg);
  }

  /**
   * Shows API failure error screen and sends metrics event.
   * @param {string} failureMessage Custom failure message
   */
  async showApiFailureErrorScreen({
    api,
    error = {},
    errorMessageType = ErrorTypes.UNKNOWN,
    failureMessage = 'API failed.',
    showError = true
  }) {
    await this.pingPromise;

    if (this.state.error) {
      // Another error is already shown.
      return;
    }

    let errorData = {
      api,
      availability: this.availability,
      existingErrorShown: !!this.state.error,
      showError
    };
    if (error?.message) {
      const {message, errors, requestId, stack} = error;
      errorData = {...errorData, error: message, errors, requestId, stack};
    }

    if (error.reload) {
      this.props.showSpinner();
    }
    this.metrics.event(
      'Core.apiFailure',
      `${failureMessage}${showError && !this.state.error ? ' Showing message to user.' : ''}`,
      {level: Level.ERROR},
      errorData
    );
    showError && this.setState({error: errorMessageType});
  }

  /**
   * Get user orgs from GraphQL with IMS fallback, and store to session.
   * @param {object} gqlResponse - GraphQL call response
   * @returns {Promise<{loginOrg, userOrgs}>} User Orgs
   */
  async getUserOrgData(gqlResponse) {
    const {getSettings = {}, imsExtendedAccountClusterData: {data: userAccounts}} = gqlResponse || {};
    const {defaultOrg = '', lastLoggedInOrg = '', userSandboxes = {}} = getSettings?.settings || {};

    // Put all org data into local and session storage
    const {orgAccountMap, userOrgs} = await this.storeOrgData(userAccounts);
    this.setState({hasAccountClusterData: true, userAccounts});
    const isDefaultOrgSet = defaultOrg && defaultOrg !== LAST_LOGGED_IN && this.isValidOrg(userOrgs, defaultOrg);
    const loginOrg = isDefaultOrgSet ? defaultOrg : lastLoggedInOrg;
    isDefaultOrgSet && (this.tenantMap.DEFAULT_ORG = defaultOrg);

    return {
      lastLoggedInOrg,
      loginOrg,
      orgAccountMap,
      userAccounts,
      userOrgs,
      userSandboxes
    };
  }

  /**
   * Store org data does:
   * 1. Concatenates list of all the orgs accross all accounts
   * 2. Adds user specific data to unifiedShellSession
   * 3. Creates orgAccountMap to put in session storage
   * @returns {Promise<{userOrgs}>} User Orgs
   */
  async storeOrgData(userAccounts) {
    let userOrgs = [];
    const localSession = await unifiedShellSession.get() || {};
    localSession.shellOrgAccountMap = {};
    const orgAccountMap = {};

    userAccounts.forEach(({owningOrg, orgs, userId, userType}) => {
      // Type2e accounts only have the owningOrg property since they are 1:1
      // with the org assigned. Non-type2e accounts have a list of orgs assigned
      // and may have an owningOrg as well. Need to ensure that both are
      // included in the orgs list.
      if (userType === AuthType.TYPE2E) {
        orgs = [owningOrg];
      } else if (owningOrg) {
        orgs = [...orgs, owningOrg];
      }
      userOrgs = userOrgs.concat(orgs);

      const hashedUserId = hashString(userId).toString();
      localSession[hashedUserId] = {...localSession[hashedUserId], userOrgs: orgs, userType};
      orgs.forEach(({imsOrgId, tenantId}) => {
        // If the org is already mapped to a type2e account, don't override it
        if (orgAccountMap[imsOrgId] && userType !== AuthType.TYPE2E) {
          this.metrics.event('Auth.duplicateOrg', `${orgAccountMap[imsOrgId]} and ${userId} both have access to org ${imsOrgId}`, {level: Level.WARN});
          return;
        }
        // Return a map with the real IDs, but pushed the hashed values in local storage
        orgAccountMap[imsOrgId] = userId;
        this.tenantMap[tenantId] = imsOrgId;
        localSession.shellOrgAccountMap[imsOrgId] = hashedUserId;
      });
    });

    localSession.userOrgs = userOrgs;
    await unifiedShellSession.set(localSession);
    this.metrics.event('Auth.orgsStored', {
      totalAccounts: userAccounts.length,
      totalOrgs: userOrgs.length
    });
    return {orgAccountMap, userOrgs};
  }

  setProductContextValues = async productContexts => {
    for (const ctx of productContexts) {
      // Function to update service mappings
      const updateServices = (map, servicesKey, canSplit = true) => {
        map[owningEntity] = map[owningEntity] || {};
        // Putting concatenated array of service keys into a set to de-dupe the
        // list of keys before converting back to an array.
        map[owningEntity][serviceCode] = Array.from(new Set((
          map[owningEntity][serviceCode] || []).concat(canSplit ? servicesKey.split(',') : servicesKey)
        ));
      };

      const {
        enable_sub_service,
        fulfillable_data,
        services_enabled,
        owningEntity,
        serviceCode,
        user_visible_name
      } = ctx.prodCtx;
      // Keep track of all of the org ids that are in this user's product context.
      this.allOrgs.add(owningEntity);
      // Create a map of org id to list of service codes.
      this.userServiceCodes[owningEntity] = this.userServiceCodes[owningEntity] || [];
      this.userServiceCodes[owningEntity].push(serviceCode);
      // Create map of org id / service code to FIs.
      services_enabled && updateServices(this.userFulfillableItems, services_enabled);
      // Create map of org id / service code to fulfillable data (limited to a list of apps).
      // First, parse the data string to remove extra escaped characters for the encoding check
      if (SERVICE_CODES_USING_GZIP.includes(serviceCode) && fulfillable_data) {
        let fulfillableData = parseIfJson(fulfillable_data);
        const decoded = fulfillableData && decodeBase64String(fulfillableData);
        if (decoded) {
          fulfillableData = JSON.parse(await decompressString(decoded));
        }
        fulfillableData && updateServices(this.userFulfillableData, fulfillableData, false);
      }
      // Create map of org id / sub service codes
      enable_sub_service && updateServices(this.userSubServices, enable_sub_service);
      // Adds a region to the region map. Only region from ACP service code is required.
      if (serviceCode === 'acp') {
        this.orgRegions[owningEntity] = this.orgRegions[owningEntity] || ctx.prodCtx.region;
      }
      // Adds a user visible name to the visible names map.
      user_visible_name && updateServices(this.userVisibleNames, user_visible_name, false);
    }
  };

  storeTenantMap = selectedImsOrg => orchestrationService.waitForBackground().then(() => storage.local.set(
    'tenantMap', {...this.tenantMap, LAST_ORG: selectedImsOrg}, MONTH
  ));

  getBetaAgreements = () => this.getSetting({betaAgreements: {}}, SettingsLevel.USERORG).then(res => res?.settings?.betaAgreements);

  async authProcessing() {
    const authProcessingTimer = this.metrics.start('AuthProcessing');

    const {auth} = this.state;
    const getOrgsPromise = auth.gqlHandler(!this.props.isLogin);

    this.getAccountCluster = () => getOrgsPromise
      .then(response => this.getUserOrgData(response))
      .catch(error => this.showApiFailureErrorScreen({
        api: 'User Data',
        error,
        failureMessage: 'API for getting user accounts failed on orgAccountMap request.'
      }));
    // Call GraphQL to fetch profile, org, languages, and spectrum theme.
    const getOrgsData = () => getOrgsPromise.then(response => {
      // Get the preferred languages from the IMS account cluster
      const {imsExtendedAccountClusterData: {preferredLanguages = []}} = response;
      this.metrics.info(`Auth account cluster data: preferredLanguages ${preferredLanguages}`);
      this.onLanguageChange(preferredLanguages, true);
      const clusterLanguage = getBestSupportedLocale(preferredLanguages, SUPPORTED_LOCALES);
      if (this.state.profile) {
        const profileLanguage = getBestSupportedLocale(this.state.profile?.preferred_languages, SUPPORTED_LOCALES);
        if (clusterLanguage !== profileLanguage) {
          auth.updateProfile({...this.state.profile, preferred_languages: preferredLanguages});
          reload({
            data: {actual: clusterLanguage, cached: profileLanguage},
            initiator: 'Core',
            reason: 'Cached locale is incorrect'
          });
        }
      }
      // Handle user consent
      const userConsentResponse = response?.getConsentPermissions || null;
      // Save the settings data for future use
      const settings = response?.getSettings?.settings || null;
      settings && this.setState({settings, userConsentResponse}, () => this.processSettings());
      return this.getUserOrgData(response);
    }).catch(error => {
      // On login, show the user the error screen. Otherwise, throw the error but don't show
      // the error screen in case there are cached orgs that can be used
      if (this.props.isLogin) {
        this.showApiFailureErrorScreen({
          api: 'User Data',
          error,
          failureMessage: 'API for getting user accounts failed on login.'
        });
      } else {
        throw error;
      }
    });
    const getOrgsMap = () => getOrgsData().then(data => data?.userOrgs);

    let orgsMap;
    let profile;
    let loginOrgsData;

    setUnauthorizedHandler(this.on401);
    // This prevents checking the HTML through the service worker on app switch.
    if (this.htmlReady === HTMLStatus.PENDING) {
      this.htmlReady = await this.serviceWorkerService.checkHtmlForUpdates(this.state.solutionConfig);
      this.availability.html = this.htmlReady;
      if (this.htmlReady === HTMLStatus.UNREACHABLE || this.htmlReady === HTMLStatus.TIMEOUT) {
        // Service worker failed to download HTML, check ping.
        const {success} = await this.pingPromise;
        if (!success) {
          // Both PING and HTML failed - Show offline error.
          waitForPingSuccess(() => reload({
            initiator: 'Core',
            reason: 'Ping success after offline'
          }));
          this.setState({error: ErrorTypes.OFFLINE});
          return;
        }
      }
    }

    try {
      // If this is not a login but the userId is not in local storage this is the first
      // time the user has switched to this account in the current session. We should treat
      // it like a login in order to get the correct data from GQL for the current account.
      if (this.props.isLogin) {
        // Wait for GraphQL if this is a login.
        loginOrgsData = await getOrgsData();
        orgsMap = loginOrgsData.userOrgs;
        profile = await auth.fetchUserProfile();
      } else {
        const [cachedProfile, cachedOrgs] = await Promise.all([
          auth.fetchUserProfile(false),
          unifiedShellSession.get('userOrgs')
        ]);
        profile = cachedProfile;
        const {avatarSrc: userAvatar} = profile || {};
        if (userAvatar && !this.state.userAvatar) {
          this.setState({userAvatar});
        }
        if (allOrgsInProfile(cachedOrgs, profile, this.metrics)) {
          orgsMap = cachedOrgs;
        }
        orgsMap = await staleWhileRevalidate(getOrgsMap, orgsMap, true, async error =>
          this.showApiFailureErrorScreen({
            api: 'User Data',
            error,
            failureMessage: 'API for getting user orgs failed on reload.',
            showError: false
          }));
      }
    } catch (error) {
      // If fresh login, there is no cache so if GQL and IMS api fail, show error screen
      this.showApiFailureErrorScreen({
        api: 'User Data',
        error,
        failureMessage: 'API for getting user orgs failed.'
      });
      return;
    }

    // Generate org / tenant maps from orgsMap
    orgsMap.forEach(({imsOrgId, tenantId}) => {
      let tenantKey = tenantId;
      // Handle duplicate orgs
      if (tenantKey in this.tenantOrgMap) {
        this.metrics.warn(`Duplicate tenant ID: IMS orgs ${imsOrgId} and ${this.tenantOrgMap[tenantKey]} both contain dma_tartan and linked to tenant ${tenantKey}`);
        tenantKey = `${tenantKey}:${imsOrgId.slice(0, 4)}`;
      }
      this.orgTenantMap[imsOrgId] = tenantKey;
      this.tenantOrgMap[tenantKey] = imsOrgId;
    });

    const pathOrg = this.getPathOrg();
    const userOrgs = await unifiedShellSession.get('userOrgs', auth.getHashedUserId());

    if (!(userOrgs && orgsMap)) {
      this.metrics.warn('Org list missing', {
        hash: auth.getHashedUserId(),
        isLogin: this.props.isLogin,
        orgsMap: !!orgsMap,
        userOrgs: !!userOrgs
      });
    }

    const orgData = await this.getSelectedImsOrg(orgsMap, profile, pathOrg, loginOrgsData);
    const {selectedImsOrg} = orgData;
    // If the selected org is not in the current account, switch to that account.
    if (selectedImsOrg && this.isValidOrg(orgsMap, selectedImsOrg) && !this.isValidOrg(userOrgs, selectedImsOrg)) {
      const {orgAccountMap} = await this.getAccountCluster() || {};
      orgAccountMap && await this.onType2eOrgChange(orgAccountMap[selectedImsOrg], true);
      // Fulfill authPromise so any processes awaiting it can start. As of now this includes only custom tokens.
      this.authResolve(auth);
    } else {
      // Fulfill authPromise so any processes awaiting it can start. As of now this includes only custom tokens.
      this.authResolve(auth);
      await this.authProcessWithProfile(profile, orgData, orgsMap, loginOrgsData);
    }

    authProcessingTimer.time('done');
    this.shellTimer.time(this.props.isLogin ? 'isLoginFlow.done' : 'done', {
      region: this.config.region,
      satellite: !!window._satellite
    }); // step 9 ready
  }

  async authProcessWithProfile(profile, orgData, orgsMap, loginOrgsData = {}) {
    const {auth} = this.state;
    const {onLocale} = this.props;
    const {userSandboxes} = loginOrgsData;

    profile = profile || await auth.fetchUserProfile(); // possibly false as argument here!?
    const accessToken = auth.fetchAccessToken();

    // Get user's Spectrum theme from local storage
    // Set state asap to reduce flickers
    const storedThemes = await storage.local.get('spectrumTheme');
    const hashedAuthId = auth.getHashedAuthId();
    if (storedThemes?.[hashedAuthId]) {
      this.setState({theme: storedThemes[hashedAuthId]});
    }

    // Handle languages preferences
    const queryLocale = getQueryValue('locale');
    const forcedLocale = queryLocale ? getBestSupportedLocale([queryLocale], SUPPORTED_LOCALES) : undefined;
    // Check local storage for user account language preferences, these are the
    // source of truth over the IMS profile language preferences
    const storedLanguages = storage.local.getSync('accountLanguagePreferences')?.[hashedAuthId];
    let preferredLanguages = profile.preferred_languages;
    if (storedLanguages && storedLanguages.join(',').toLowerCase() !== preferredLanguages?.join(',').toLowerCase()) {
      profile.preferred_languages = preferredLanguages = storedLanguages;
      auth.updateProfile(profile);
    }
    this.metrics.info('authProcessWithProfile preferredLanguages', {
      profileLanguages: profile.preferred_languages,
      storedLanguages
    });
    const locale = forcedLocale || getBestSupportedLocale(preferredLanguages, SUPPORTED_LOCALES);
    onLocale(locale);
    document.documentElement.setAttribute('lang', locale);
    const productContext = profile.projectedProductContext;
    await this.setProductContextValues(productContext);

    // Track the number of orgs that a user has with the dma_tartan service code and the number without.
    // This will help us determine the need for this service code in the future.
    const numWithTartan = Object.keys(this.orgTenantMap).length;
    this.metrics.info('User dma_tartan orgs information', {
      with: numWithTartan,
      without: this.allOrgs.size - numWithTartan
    });

    // Grab the org from the path and use that as the selected org. If there
    // is no org yet, use the selected one from IMS.
    const pathOrg = this.getPathOrg();
    const tenantInPath = !!pathOrg;

    orgsMap = orgsMap || await unifiedShellSession.get('userOrgs');
    const {activeOrg, selectedImsOrg} = orgData || await this.getSelectedImsOrg(orgsMap, profile, pathOrg, loginOrgsData);
    const {orgName: selectedImsOrgName, orgRegion: selectedOrgRegion} = getOrgMetadata(selectedImsOrg, orgsMap);
    // Re-configure network so sandbox GQL call has the correct selectedImsOrg and selectedOrgRegion
    this.configureNetwork(selectedImsOrg, false, selectedOrgRegion);

    const tenantId = this.orgTenantMap[selectedImsOrg] || selectedImsOrg;
    this.metrics.event('Auth.done', {
      selectedOrg: selectedImsOrg,
      selectedTenant: cleanTenant(tenantId)
    });

    const imsOrgs = orgsMap.map(org => ({
      label: org.orgName,
      value: org.imsOrgId
    }));

    imsOrgs.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
    const wasTenantSelected = pathOrg === activeOrg;
    const showNoAccessShell = !selectedImsOrg;
    // If the user does not have a selected IMS org, that means they don't have
    // any because the fallback picks one. Sets the state so that it will render
    // that view to the user.
    if (showNoAccessShell) {
      this.metrics.event('Shell.noAccess', {level: Level.WARN});
      this.setState({locale, profile, showNoAccessShell});
      return;
    }
    this.internal = isInternal((profile?.email || '').toLowerCase());
    const impersonationIdData = auth.getImpersonationId(accessToken);
    this.isImpersonating = !!impersonationIdData?.impersonationId;

    // Falls back to the org id if there is no tenant available for the org
    Internal.setUser({
      accountType: profile.account_type,
      authId: profile.authId,
      authSystem: 'ims',
      data: {internal: this.internal, tenantId: cleanTenant(tenantId), ...impersonationIdData},
      displayName: profile.displayName,
      groupId: selectedImsOrg,
      groupName: selectedImsOrgName,
      groupRegion: selectedOrgRegion,
      language: locale,
      sessionId: profile.session,
      userId: profile.userId
    });

    const appAssemblyTimer = this.metrics.start('AppAssembly');
    this.appAssemblyModule || await this.appAssemblyPromise;
    const {default: AppAssemblyService} = this.appAssemblyModule;
    // Instantiate the App Assembly Service when we have the values we need
    this.appAssemblyService = new AppAssemblyService({
      cdn: this.cdn,
      environment: this.config.serviceEnvironment,
      imsOrgs,
      isDemoMode: this.bootstrap.isDemoMode(),
      listedApps: this.props.listedApps,
      locale,
      orgTenantMap: this.orgTenantMap,
      profile,
      userFulfillableData: this.userFulfillableData,
      userFulfillableItems: this.userFulfillableItems,
      userRoles: profile.roles,
      userServiceCodes: this.userServiceCodes,
      userSubServices: this.userSubServices,
      userVisibleNames: this.userVisibleNames
    });
    appAssemblyTimer.time('done');

    // We can now check for some overrides
    this.checkToSetAccessOverrides(selectedImsOrg, hashedAuthId);

    // Redirect to profile page if profile is in path
    if (getPathWithoutTenant(hashToPath()) === '/profile') {
      return window.location.assign(this.appAssemblyService.getProfileComponent(tenantId).editProfileLink);
    }
    // Reset orgToSubOrgsMap so it can be generated in the context service.
    const orgToSubOrgsMap = {};

    this.setState(
      {
        accessToken,
        activeFulfillableData: this.userFulfillableData,
        activeFulfillableItems: this.userFulfillableItems,
        imsOrgs,
        locale,
        orgsMap,
        orgToSubOrgsMap,
        preferredLanguages,
        profile,
        selectedImsOrg,
        selectedImsOrgName,
        selectedOrgRegion,
        showNoAccessShell,
        userSandboxes
      },
      async () => {
        // Because of timing - trigger the userTheme again, which requires settings and profile
        this.userThemeHandler();
        this.storeTenantMap(selectedImsOrg);
        this.refreshContext(selectedImsOrg);
        // Notify hidden tabs so they can check if their session is dead.
        // This is needed after account switching (T2E), because any inactive tabs
        // will be running against an invalid token & session. Informing inactive tabs allows
        // them to reload themselves with a token acquired against the active T2E account.
        if (this.props.isLogin && this.sessionId) {
          this.authChannel?.postMessage({
            message: AuthMessages.LOGIN,
            sessionId: this.sessionId
          });
        }

        /**
         * Separate method for processing the last few pieces of authProcessing
         * so that it can be used in a `then` statement after a possible account
         * cluster fetch by the AEM Skyline process.
         * @async
         * @returns {Promise}
         */
        const completeLoading = async () => {
          const {solutionConfig} = this.state;
          // Only await on the feature flag data request when there is a feature
          // flag required to determine access of the current application. Doing
          // it all the time will cause unnecessary delays to other apps.
          const access = solutionConfig.access || {};
          try {
            const ffPromise = this.getFeatureFlagData();
            access.flag && await ffPromise;
          } catch {
            // Error handling already covered in getFeatureFlagData.
            return;
          }

          // The tenant is in the URL and it was not the current IMS org in the session.
          if (tenantInPath && !wasTenantSelected) {
            let selectedSubOrg;
            // While doing org changes, we have already kicked off the sub-org
            // fetch for this new org. Wait for that to finish and use that in
            // the org change call. Otherwise, onImsOrgChange will unset the
            // sub-org value and we'll get into a weird state.
            if (this.contextService?.isSubOrg && this.contextFetchPromise) {
              ({selectedSubOrg} = (await this.contextFetchPromise) || {});
            }
            // If the tenant is already in the URL, we don't need to update the path.
            // Instead, trigger an org change to let IMS know that this is the current org
            await this.onImsOrgChange(selectedImsOrg, activeOrg, selectedSubOrg, true);
          } else {
            // The tenant is not in the URL or it is and was already the current IMS
            // org in the session. Get shell configuration and update the URL.
            const shellResponse = this.getShellConfiguration(selectedImsOrg);
            this.logMetricsForShellInfo(shellResponse, 'completeLoading');
            const hero = getHeroConfig(shellResponse, solutionConfig, this.state.hero);
            this.setState({favicon: hero.icon, hero, shellResponse});
            // If the org isn't authorized for the solution or the solution
            // requires sandboxes but the org doesn't have AEP, redirect to home
            if (!await this.isOrgPermissionedOnSolution(selectedImsOrg)) {
              return this.homeRedirectToast({selectedImsOrg, solutionConfig, tenant: tenantId});
            }
            updatePath({tenant: tenantId}, true, this.hasTenantInUrl, this.contextKey);
          }
          this.authProcessingInitialized = true;
        };

        // Call GraphQL to fetch IMS Avatar and Pulse data.
        this.getHeaderData(locale, profile);

        // Special processing for the Skyline use case. Grabs the orgId from the
        // AEM discovery response so that the org can be handled appropriately.
        if (isSkyline()) {
          return this.processSkylineOrg(selectedImsOrg, profile, completeLoading);
        } else if (getQueryParams()['repo'] && !this.authProcessingInitialized) {
          return this.correctAEMOrg(selectedImsOrg, profile.userId, completeLoading);
        }
        completeLoading();
      }
    );
  }

  /**
   * If the URL contains the repo param, check if we are on the org corresponding
   * to the AEM instance in the repo param and switch to the correct org if necessary
   * @param {string} selectedImsOrg The currently selected org
   * @param {string} userId The user ID
   * @param {function} completeLoading Function to finish loading if not switching orgs
   * @returns {string | undefined} The correct org ID or undefined if the org is not found
   */
  correctAEMOrg = async (selectedImsOrg, userId, completeLoading) => {
    if (!this.aemPromise) {
      this.aemPromise = import('./utils/aem-fallback').then(({getOrgFromRepo}) => getOrgFromRepo());
    }
    const imsOrg = await this.aemPromise;
    if (imsOrg && imsOrg !== selectedImsOrg) {
      const {orgAccountMap} = await this.getAccountCluster() || {};
      if (!orgAccountMap) {
        return;
      }
      this.updateUrlOnOrgChange(imsOrg, selectedImsOrg, true, null);
      return orgAccountMap[imsOrg] === userId ?
        this.onImsOrgChange(imsOrg, selectedImsOrg, null, true) :
        this.onType2eOrgChange(orgAccountMap[imsOrg], true);
    }
    completeLoading();
  };

  /**
   * Determines what the organization is for the current instance (in subdomain
   * e.g. author-p58154-e463621). If it is the same as is currently selected by
   * the Unified Shell, nothing happens. If it is different and is a type2e, the
   * profile is switched. If it is different and is not type2e, the normal org
   * change happens.
   *
   * If the discovery call that was triggered earlier in the loading flow
   * returns an imsOrg, it is the org related to the current instance and this
   * function will end early. If, however, no org is returned, it is the
   * old-style endpoint and extra processing needs to happen.
   *
   * Upon completion, if the org did not change or there was an error, the
   * callback is called, which handles the non-Skyline related finishing touches
   * such as fetching feature flags.
   * @param {string} selectedImsOrg Current org that the user has activated.
   * @param {Object} profile IMS profile for the currently authenticated user.
   * @param {function} callback Callback to be used if no org changes were made.
   * @returns {Promise} Depending on the response of the discovery
   *   call, imsOrg in response means undefined is returned, empty response
   *   means promise is returned.
   */
  async processSkylineOrg(selectedImsOrg, profile, callback) {
    const accountClusterPromise = this.getAccountCluster();

    /**
     * Scenarios:
     *   1. Instance is not within the current org but is within the current profile (change org)
     *   2. Instance is not within the current org nor the current profile (change profiles)
     * @async
     * @param {string} imsOrg - Org ID of the IMS org associated with the instance.
     * @param {Object} orgAccountMap - Map of org to account.
     * @returns {Promise}
     */
    const processAEMOrg = async (imsOrg, orgAccountMap) => {
      if (!imsOrg || imsOrg === selectedImsOrg) {
        this.metrics.info('AEM instance on same org', {imsOrg, same: imsOrg === selectedImsOrg});
        return callback();
      }
      const usersMatch = orgAccountMap[imsOrg] === profile.userId;
      const type = usersMatch ? 'ORG' : 'Type2e';
      this.metrics.info(`AEM instance required ${type} switch`, {
        imsOrg,
        type,
        userId: orgAccountMap[imsOrg]
      });
      usersMatch ?
        this.onImsOrgChange(imsOrg, selectedImsOrg, null, true) :
        this.onType2eOrgChange(orgAccountMap[imsOrg], true);
    };

    try {
      const {imsOrg} = await this.aemPromise || {};
      // The new endpoint is used in this AEM instance, so we can process the
      // org immediately w/ the results of the account cluster call that does
      // NOT include AEM.
      if (imsOrg) {
        const {orgAccountMap} = await accountClusterPromise || {};
        processAEMOrg(imsOrg, orgAccountMap);
        return;
      }
    } catch (e) {
      // Don't do anything here because it will be handled in the actual
      // discovery call later.
      this.metrics.error('Skyline org discovery failed', e);
    }

    // The Skyline instance has not been updated. Log it so that we can track
    // when we can remove this fallback code.
    this.metrics.info('Skyline fallback');

    const {processFallback} = await import('./utils/aem-fallback');
    processFallback({
      accountClusterPromise,
      completeCallback: callback,
      processAEMOrg
    });
  }

  /**
   * On login or a full page refresh, chooses the correct ims org and stores
   * that data in the unified shell session.
   * @param {Object} orgsMap List of all ims orgs in the entire user account.
   * @param {Object} profile IMS profile
   * @param {string} pathOrg Org in the url
   * @param {Object} loginOrgsData GraphQL SSO information such as the last
   * logged in org (lastLoggedInOrg) or the user's default org (loginOrg).
   */
  getSelectedImsOrg = async (orgsMap, profile, pathOrg, loginOrgsData) => {
    const localSession = await unifiedShellSession.get() || {};
    this.sessionId = this.getSessionId();
    if (this.sessionId && !localSession.session) {
      await unifiedShellSession.update('session', this.sessionId);
    }

    const fallbackOrgId = await this.getFallbackOrg(orgsMap);
    let selectedImsOrg = fallbackOrgId;
    const {lastLoggedInOrg, loginOrg} = loginOrgsData || {};
    const activeOrg = localSession.activeOrg || loginOrg;

    if (this.isValidOrg(orgsMap, pathOrg)) {
      selectedImsOrg = pathOrg;
    } else if (this.isValidOrg(orgsMap, activeOrg)) {
      selectedImsOrg = activeOrg;
    } else if (this.isValidOrg(orgsMap, loginOrg)) {
      selectedImsOrg = loginOrg;
    } else if (this.isValidOrg(orgsMap, lastLoggedInOrg)) {
      selectedImsOrg = lastLoggedInOrg;
    }
    unifiedShellSession.update('activeOrg', selectedImsOrg);

    return {
      activeOrg,
      selectedImsOrg
    };
  };

  /**
   * Gets the org id from the tenant in the URL.
   */
  getPathOrg = (location = this.history.location) => {
    const pathTenant = getTenantFromPath(location.pathname, true);
    const {solutionConfig} = this.state;
    let pathOrg = pathTenant;

    // For cases where a solution wants to gather the pathOrg. Specifically,
    // Analytics checks the search string for `current_org` and provides that
    // value as the pathOrg.
    if (!pathOrg && solutionConfig.getPathOrgFromContext) {
      pathOrg = solutionConfig.getPathOrgFromContext(location);
    }
    if (pathTenant && pathTenant.indexOf('AdobeOrg') === -1) {
      pathOrg = this.tenantOrgMap[pathTenant];
      if (Object.keys(this.tenantOrgMap).length && !pathOrg) {
        this.metrics.warn(`User doesn't have access to ${pathTenant} tenant`);
      }
    }
    return pathOrg;
  };

  /**
   * Navigate to the /home application. Depending on the TLD, this will either
   * be a full redirect to the Experience Cloud domain in addition to the /home
   * path change. Otherwise it will just update the path.
   * @param {boolean} hasTenantInUrl If the tenant is in the URL.
   * @param {boolean} replace If the history should be replaced or pushed.
   * @param {string} tenant Tenant value to put in the URL.
   */
  pageRedirect({env, hasTenantInUrl = true, path = '/home', replace, tenant}) {
    // If we are not on the Experience Cloud domain, we are likely on AEM. Any
    // navigation to /home must include a redirect to the Experience Cloud domain.
    if (!isExperienceOrigin()) {
      return window.open(getUnifiedShellUrl(env || this.config.environment, `@${tenant}${path}`), '_self');
    }
    const url = {path};
    tenant && (url.tenant = tenant);
    updatePath(url, replace, hasTenantInUrl);
  }

  /**
   * Get a fallback org in a standard way. Currently it sorts the list of org
   * ids in ascending order and takes the first one.
   * @param {string[]} orgs List of org ids.
   * @returns {string} The fallback org.
   */
  getFallbackOrg = async (orgs = []) => {
    const orgAccountMap = await unifiedShellSession.get('shellOrgAccountMap') || {};
    // First check the current account for the fallback org, then fallback to any org in the list
    const userHash = this.state.auth.getHashedUserId();
    const fallbackOrg = orgs.find(org => orgAccountMap[org.imsOrgId] === userHash) || orgs[0];
    return fallbackOrg?.imsOrgId;
  };

  isValidOrg = (orgs, selectedImsOrg) => selectedImsOrg && orgs?.find(org => org.imsOrgId === selectedImsOrg);

  handleSideNavMouseLeave = () => {
    setTimeout(() => {
      // find where the mouse is
      const hoveredElements = document.querySelectorAll(':hover');
      const elementMouseIsOver = hoveredElements[hoveredElements.length - 1];
      const mouseOnSideNav = elementMouseIsOver?.className?.toLowerCase().includes('sidenav');

      // if mouse is not over the side nav, close side nav
      if (!mouseOnSideNav && this.state.sideNavOpenByHover) {
        this.setState({sideNavOpen: false, sideNavOpenByHover: false});
        this.fireSideNavOmegaEvent('mouseleave', false);
      }
    }, 500);
  };

  /**
   * This sets every sideNav open related value to the argument passed in.
   * State changes are only used to managed temporary open / closed states such
   * as on hover or resize while session and settings are used as sources of truth
   * for user preference.
   * @param {boolean} toggleState true to open, false to close
   */
  sideNavSetAllToState = async toggleState => {
    const {settings} = await this.getSetting({shellNav: null});
    // Fetch from settings to ensure we're not overriding any entries.
    storage.session.set('sideNavOpen', toggleState);
    if (!settings.shellNav || settings.shellNav.open !== toggleState) {
      this.setSetting({shellNav: {...settings?.shellNav, open: toggleState}});
    }
    this.setState({sideNavOpen: toggleState, sideNavOpenByHover: false});
  };

  fireSideNavOmegaEvent = (uiInteraction, open) => {
    let action = 'hamburger click';
    let widgetType = 'button';

    switch (uiInteraction) {
      case 'nav':
        action = 'SideNavItem click';
        widgetType = 'SideNavItem';
        break;
      case 'hover':
      case 'mouseleave':
        action = 'hamburger hover';
        break;
    }

    this.metrics.analytics.trackEvent({
      action,
      attributes: {opened: open},
      element: open ? 'nav open' : 'nav close',
      feature: 'left nav',
      type: 'button',
      widget: {name: 'nav interaction', type: widgetType}
    });
  };

  /**
   * Toggles the side nav visibility and does other misc bookkeeping
   * depending on what type of toggle is being done.
   * @param {boolean || undefined} toggleTo What state to toggle the side nav visibility to if no uiInteraction provided
   * @param {string || undefined} uiInteraction ui interaction that's toggling side nav
   * @returns
   */
  toggleSideNav = async (toggleTo, uiInteraction) => {
    const {customLeftNavClick, leftNavCloseOnSelection, sideNavOpen, sideNavOpenByHover} = this.state;
    // toggle to opposite of current state if no e provided
    const toggleState = typeof toggleTo === 'boolean' ? toggleTo : !sideNavOpen;

    // toggle from non-ui interactions
    if (!uiInteraction) {
      return this.state.sideNavOpen !== toggleState && this.sideNavSetAllToState(toggleState);
    }

    let open;
    switch (uiInteraction) {
      case 'nav':
        if (window.innerWidth <= SIDE_NAV_FULL_ZOOM_WIDTH) {
          // Navigating should close the side nav if its opened only thru hover
          open = sideNavOpenByHover ? false : sideNavOpen;
          sideNavOpenByHover && this.setState({sideNavOpen: open, sideNavOpenByHover: false});
        }
        // If the custom left nav is in use, close based on the config set
        if (leftNavCloseOnSelection) {
          customLeftNavClick && customLeftNavClick(false);
        }
        break;
      case 'hover':
        open = !sideNavOpen;
        open && this.setState({sideNavOpen: open, sideNavOpenByHover: true});
        break;
      case 'menu':
        // Clicking the menu should lock open the side nav if its opened thru hover
        open = sideNavOpenByHover ? true : !sideNavOpen;
        this.sideNavSetAllToState(open);
        // Call the customLeftNav callback function
        customLeftNavClick && customLeftNavClick(open);
        break;
      case 'mouseleave':
        // Fires its own event, so doesn't need to set open
        this.handleSideNavMouseLeave();
        break;
    }

    // Don't fire an event if we aren't doing anything
    if (open === undefined) {
      return;
    }

    this.fireSideNavOmegaEvent(uiInteraction, open);
  };

  orgHasAEP = orgId => this.userServiceCodes[orgId || this.state.selectedImsOrg]?.includes('acp');

  getUserOrgContext() {
    const {
      environment,
      profile: imsProfile,
      sandbox,
      selectedImsOrg: imsOrg,
      selectedSubOrg: subOrg
    } = this.state;
    return {
      environment,
      imsOrg,
      imsProfile,
      sandbox: sandbox.selected,
      subOrg
    };
  }

  getComponentContext = () => {
    const {
      accessOverridesEnabled,
      accessToken,
      activeFeatureFlags,
      activeFulfillableItems,
      activeProductContext,
      appContainer,
      appProfile,
      appToken,
      auth,
      coachMark,
      coachMarkPosition,
      customSearch,
      featureFlags,
      gainsightRoles,
      globalDialogOpen,
      hero,
      imsOrgs,
      locale,
      orgPermissioned,
      preferredLanguages,
      profile,
      sandbox,
      sandboxesAvailable,
      selectedOrgRegion,
      selectedImsOrg,
      selectedImsOrgName,
      shellResponse,
      sideNavOpen,
      solutionConfig,
      theme: userTheme,
      userAccounts,
      userConsent,
      workHubResponse
    } = this.state;
    const tenant = cleanTenant((this.orgTenantMap || {})[selectedImsOrg] || '');
    const theme = solutionConfig?.theme || userTheme;
    return {
      ...this.getUserOrgContext(),
      ...(this.services || {}),
      accessOverridesEnabled,
      accountCluster: userAccounts,
      activeProductContext,
      aemInstanceId: getSkylineInstance(),
      agreements: this.agreements,
      apiKey: UNIFIED_SHELL_APIKEY,
      apiMode: this.bootstrap.apiMode,
      appContainer,
      appProfile,
      appToken,
      availability: this.availability,
      avatar: profile && profile.avatar || '',
      cdn: this.cdn,
      coachMark,
      coachMarkPosition,
      configureCustomIms: this.configureCustomIms,
      contextFetchPromise: this.contextFetchPromise,
      contextId: this.contextService?.getId(),
      contextKey: this.contextKey,
      customSearch,
      enableFeedback: config => this.enableFeedback(config),
      favicon: this.favicon,
      featureFlags: activeFeatureFlags || featureFlags,
      fulfillableItems: activeFulfillableItems || this.userFulfillableItems,
      gainsightRoles,
      getCapabilityWithIconUrl: this.getCapabilityWithIconUrl,
      getCustomToken: this.getCustomToken,
      getPerformanceMarks: this.getPerformanceMarks,
      getShellConfigurationExtended: () => this.getShellConfigurationExtended(),
      getSubOrgFromProductContext: prodCtx => this.contextService?.isSubOrg ?
        this.contextService.getSubOrgFromProductContext(prodCtx) :
        undefined,
      getTux: this.getTux,
      globalDialogOpen,
      hashedAuthId: auth && auth.getHashedAuthId(),
      hero,
      htmlReady: this.htmlReady,
      imsClientId: this.config.ims.client_id,
      imsEnvironment: this.config.ims.environment,
      imsInfo: {
        imsOrg: selectedImsOrg,
        imsOrgName: selectedImsOrgName,
        imsProfile: profile,
        imsToken: accessToken,
        locale,
        tenant
      },
      imsOrgName: selectedImsOrgName,
      imsOrgs: imsOrgs.filter(({value}) => /@AdobeOrg$/.test(value)),
      imsSessionId: this.getSessionId(),
      imsToken: accessToken,
      internal: this.internal,
      intl: this.props.intl,
      lastRecentTs: this.lastRecents?.[selectedImsOrg] || 0,
      locale,
      localeOriginal: getQueryValue('locale') || getBestSupportedLocale(preferredLanguages, SUPPORTED_LOCALES, true),
      metricsEnv: this.metricsEnv,
      nonSupportedBrowser: this.nonSupportedBrowser,
      on401: this.on401,
      onSignOut: this.onSignOut,
      orgPermissioned,
      orgRegion: selectedOrgRegion,
      pageRedirect: this.pageRedirect,
      preferredLanguages: preferredLanguages || [],
      pulseButton: this.state.pulse.customButton,
      resetConfigStates: (appId, nextAppId, nextSideNav, nextIms, force) => this.resetConfigStates(appId, nextAppId, nextSideNav, nextIms, force),
      sandboxes: sandbox.sandboxes,
      sandboxesAvailable,
      setConfigStates: config => this.setConfigStates(config),
      setLogoutUrl: ({url, ...rest}) => this.state.auth.logout.add(url, rest),
      setSolutionConfig: this.setSolutionConfig,
      setSubOrgs: this.setSubOrgs,
      settingsService: this.settingsRef,
      shellHeight: SHELL_HEIGHT,
      shellInfo: shellResponse,
      shellSideNavPresent: this.isSideNavEnabled() && (this.state.sidenav?.visible !== false && this.state.sidebar?.visible !== false) && this.state.sideNavOpen,
      shellSideNavWidth: this.state.sideNavOpenByHover ? 0 : SHELL_SIDE_NAV_WIDTH,
      showToast: (messageId, variant = 'info') => this.showToast(messageId, {timeout: TOAST_TIMEOUT, variant}),
      sideNavOpen,
      tenant,
      theme,
      toggleComponentModal: (open, data, callback) => open ? this.renderComponentModal(data, callback) : this.removeComponentModal(data),
      toggleSideNav: this.toggleSideNav,
      translationService: this.translationService,
      updateConsent: permissions => this.setState({userConsent: {...this.state.userConsent, ...permissions}}),
      userConsent,
      workHubResponse
    };
  };

  setConfigStates(config) {
    const {coachMark, customEnvLabel, customSearch, helpCenter, hero, pulse, showRolesPicker, sidebar} = config;
    if (hero) {
      config.hero = getHeroConfig(this.state.shellResponse, this.state.solutionConfig, hero);
    }
    helpCenter && Object.assign(config, {helpCenter: {...this.state.helpCenter, ...helpCenter}});

    // Some calls to setConfigStates will exlude `customEnvLabel` as a key and we will ignore those
    // Other calls may specify `customEnvLabel` as `null` or `undefined` and we want to process them
    if ('customEnvLabel' in config) {
      // Ensure we are working with an array from here forward
      const labelsList = Array.isArray(customEnvLabel) ? customEnvLabel : [customEnvLabel];
      // Ensure each value is truthy and each string is converted to a default CustomLabelDesc
      const normalizedCustomLabels = labelsList.reduce((list, val) => {
        val && list.push(typeof val === 'string' ? {type: CustomLabelType.ENVIRONMENT, value: val} : val);
        return list;
      }, []);
      Object.assign(config, {customEnvLabel: normalizedCustomLabels});
    }

    customSearch && Object.assign(config, {
      customSearch: {
        // If an enabled value is not explicitly set, maintain the current state
        enabled: customSearch.enabled === undefined ? this.state.customSearch.enabled : customSearch.enabled,
        onClick: this.onCustomSearchClick,
        open: customSearch.open || false
      }
    });

    // This is now deprecated in favor of the sidenav config, which should be used instead.
    // Remove this if statement once all solutions have been transitioned to sidenav
    if (sidebar) {
      Object.assign(config, {
        sidebar: {
          ...sidebar,
          config: sidebar.config || this.state.sidebar?.config
        }
      });
      if (sidebar.collapsed !== undefined) {
        this.setState({sideNavOpen: !sidebar.collapsed});
        storage.session.set('sideNavOpen', !sidebar.collapsed);
      }
    }

    const sidenav = config['sidenav/collapsed'] !== undefined || config['sidenav/visible'] !== undefined || config['sidenav/config'];

    // The application has triggered the roles modal. Open it with the fromAPI
    // argument being true as there is some different handling in this case.
    if (showRolesPicker) {
      this.setState({showRolesModal: 'API'});
    }

    if (sidenav) {
      Object.assign(config, {
        sidenav: {
          collapsed: config['sidenav/collapsed'] !== undefined ? config['sidenav/collapsed'] : this.state.sidenav?.collapsed,
          config: config['sidenav/config'] || this.state.sidenav?.config,
          visible: config['sidenav/visible'] !== undefined ? config['sidenav/visible'] : this.state.sidenav?.visible
        }
      });
      if (config['sidenav/collapsed'] !== undefined) {
        this.setState({sideNavOpen: !config['sidenav/collapsed']});
        storage.session.set('sideNavOpen', !config['sidenav/collapsed']);
      }
    }

    if (pulse) {
      config.pulse = {...this.state.pulse, ...pulse};
    }

    this.metrics.log('Shell.setConfigStates', {keys: Object.keys(config)});
    this.setState(config);

    coachMark && this.debouncedUpdateTargetNode();
  }

  async enableFeedback(programs) {
    if (!this.feedbackService) {
      const {default: FeedbackService} = await import('./services/FeedbackService');
      this.feedbackService = new FeedbackService(this.history);

      // Handle the config change event from the service. When the service runs
      // the check, this will always fire, either with a config based on a
      // matched path or null if nothing matched. Both are valid since we need
      // to hide the icon and button if there are no active beta programs on the
      // current path.
      this.feedbackService.on('CONFIG', config => {
        const {sandbox, subOrg} = this.getUserOrgContext();
        const feedbackConfig = !config ? null : {
          ...config,
          custom: {
            ...config.custom || {},
            sandbox,
            subOrg
          }
        };
        this.setState({feedbackConfig, releaseType: this.bootstrap.isDemoMode() ? RELEASE_TYPE.GA : (config?.releaseType || null)});
      });
    }
    this.feedbackService.addConfig(programs);
  }

  /**
   * Returns a clean state for the config values. This is used when an app
   * unmounts or a new app is loaded.
   * @param {Object} nextSideNav The sideNav configuration of the next app.
   * @returns State object
   */
  getResetConfigState = (nextSideNav, nextIms) => ({
    activeProductContext: null,
    afterPrintHandler: null,
    appContainer: undefined,
    beforePrintHandler: null,
    coachMark: {},
    coachMarkPosition: null,
    customButtons: [],
    customEnvLabel: [],
    customHeroClick: null,
    customLeftNavClick: null,
    customSearch: {enabled: false, open: false},
    digitalData: null,
    feedback: {},
    globalDialogOpen: false,
    helpCenter: null,
    leftNavCloseOnSelection: false,
    modal: false,
    onCustomButtonClick: NOOP,
    orgToSubOrgsMap: {},
    pulse: {},
    selectedSubOrg: null,
    workHubResponse: null,
    workspaces: [],
    // Only reset the custom profile and token if the next IMS config is unset
    // or the custom token is invalid for the next IMS config.
    ...(nextIms && isCustomTokenValid(nextIms, this.state.appToken) ? {} : {appProfile: null, appToken: null}),
    // Only reset if there's not another sidenav config.
    ...((!nextSideNav || !Object.keys(nextSideNav).length) ? {sidebar: null, sidenav: null} : {})
  });

  /**
   * Resets config states when an app change happens.
   * @param {string} appId The old appId.
   * @param {string} nextAppId The new appId.
   * @param {Object} nextSideNav The sideNav configuration of the new app.
   * @param {Object} nextIms The IMS configuration of the new app.
   * @param {boolean} force Sometimes the new app will have the same appId if there are two
   * configurations for one route. In this case, we should force reset the config states.
   */
  resetConfigStates = (appId, nextAppId, nextSideNav, nextIms, force = false) => {
    // Same app is being loaded next. Don't reset so experience doesn't shift.
    // If state is already reset for current app (this.appId updated), don't reset again.
    if (!force && (appId === nextAppId || this.appId === nextAppId)) {
      return;
    }

    // Reset the beta feedback service on a config change.
    this.feedbackService?.clear();

    this.setState(this.getResetConfigState(nextSideNav, nextIms));
  };

  async getLastSelectedSubOrgs() {
    let {lastSelectedSubOrgs} = this.state.settings || {};
    if (!this.state.settings) {
      this.metrics.info('Retrieving lastSelectedSubOrgs from settings');
      const {settings} = await this.getSetting({lastSelectedSubOrgs: null}) || {};
      lastSelectedSubOrgs = settings?.lastSelectedSubOrgs;
      lastSelectedSubOrgs && this.setState({settings: {...this.state.settings, lastSelectedSubOrgs}});
    }
    return lastSelectedSubOrgs;
  }

  async getShellConfigurationExtended() {
    const {auth, selectedImsOrg} = this.state;
    const unifiedShellSessionData = (await unifiedShellSession.get())?.[auth.getHashedUserId()];
    const lastSelectedSubOrgs = await this.getLastSelectedSubOrgs();
    return this.appAssemblyService.fetchShell(
      selectedImsOrg,
      this.orgTenantMap[selectedImsOrg],
      unifiedShellSessionData || {},
      lastSelectedSubOrgs || {}
    );
  }

  /**
   * Synchronous version of getShellConfiguration which does not include adding
   * the sub-orgs to the shellResponse data. This is the default action unless
   * subOrgsInShellInfo is set on the application config.
   * @returns {Object} Shell information.
   */
  getShellConfiguration(selectedImsOrg) {
    const selectedOrg = selectedImsOrg || this.state.selectedImsOrg;
    return this.appAssemblyService.fetchShell(selectedOrg, this.orgTenantMap[selectedOrg]);
  }

  onImsOrgChange = async (selectedImsOrg, currentImsOrg, selectedSubOrg, init) => {
    this.metrics.event('Shell.orgChange', {currentImsOrg, selectedImsOrg, selectedSubOrg});
    this.setState({imsOrgChanging: true});
    if (this.contextService?.isSubOrg && selectedSubOrg && selectedImsOrg === currentImsOrg) {
      const {activeProductContext} = this.contextService.change(selectedImsOrg, selectedSubOrg, false);
      return this.setState({activeProductContext, selectedImsOrg, selectedSubOrg});
    }

    try {
      unifiedShellSession.update('activeOrg', selectedImsOrg);
      const subOrgState = selectedSubOrg &&
        this.contextService?.isSubOrg &&
        this.contextService.change(selectedImsOrg, selectedSubOrg, false);
      const {orgName: selectedImsOrgName, orgRegion: selectedOrgRegion} = getOrgMetadata(selectedImsOrg, this.state.orgsMap);
      Internal.setUser({
        data: {internal: this.internal, tenantId: cleanTenant(this.orgTenantMap[selectedImsOrg])},
        groupId: selectedImsOrg,
        groupName: selectedImsOrgName,
        groupRegion: selectedOrgRegion
      });

      this.setSetting({lastLoggedInOrg: selectedImsOrg});
      this.storeTenantMap(selectedImsOrg);

      const state = {
        recentsData: RECENTS_RESET,
        selectedImsOrg,
        selectedImsOrgName,
        selectedOrgRegion,
        selectedSubOrg,
        ...subOrgState
      };

      // Using the direct bootstrap configureNetwork because we need to set the
      // selectedImsOrg but don't need any of the other stuff at this time. Upon
      // saving state and fetching context, it will fire again with the Core
      // version.
      this.bootstrap.configureNetwork(selectedImsOrg, false, selectedOrgRegion);

      // Only fetch launch darkly on non-init (authorize) workflows because
      // authorize does it already and onImsOrgChange only happens when the
      // org needs to be changed due to the path's tenant.
      if (!init) {
        const ffData = await this.getFeatureFlagData(false, selectedImsOrg, selectedImsOrgName);
        Object.assign(state, ffData);
      }

      this.setState(state, async () => {
        if (!await this.isOrgPermissionedOnSolution(selectedImsOrg)) {
          this.homeRedirectToast({
            selectedImsOrg,
            solutionConfig: this.state.solutionConfig,
            tenant: this.orgTenantMap[selectedImsOrg],
            toastMessage: 'orgSwitchRedirectToast'
          });
        }
        // On org change, re-fetch current URL context. This is done before the
        // network is configured so that:
        // 1. the permission check has a chance to go through before fetching context.
        // 2. the latest context is available.
        this.refreshContext(undefined, true);
        this.contextFetchPromise && await this.contextFetchPromise;
        this.configureNetwork(selectedImsOrg, false, selectedOrgRegion);
        // Ensure this runs after `configureNetwork`.
        this.metrics.analytics.trackPage();
      });
    } catch (error) {
      this.metrics.error(error.message);
      this.showToast('orgFailToast', {variant: 'error'});
      return;
    }

    // Since sideNav is force disabled on sandbox or suborg failure, an org change may fix the issue.
    // We should re-enable sideNav if it is a part of the app.
    if (this.state.error === ErrorTypes.SANDBOX_FAIL || this.state.error === ErrorTypes.SUBORG_FAIL) {
      if (this.isSideNavEnabled()) {
        const nav = {visible: true};
        this.setState({sidebar: nav, sidenav: nav});
      }
    }

    // Clear Active Product Context
    selectedSubOrg || this.setState({activeProductContext: null});

    // if org changed and no sub org selected, reset selectedSubOrg
    if (selectedImsOrg !== currentImsOrg && !selectedSubOrg) {
      this.setState({selectedSubOrg: {id: null, name: null}});
    }

    this.setState({imsOrgChanging: false});
    return true;
  };

  /**
   * Checks whether an ims org is permissioned on a given solution.
   * @param {string} orgId The IMS org.
   * @param {Object} solutionConfig Optional solution config.
   * @returns {boolean} If the org is permissioned.
   */
  async isOrgPermissionedOnSolution(orgId, solutionConfig) {
    const {selectedImsOrg, solutionConfig: config} = this.state;
    let {featureFlags} = this.state;
    const {access = {}, urlContext} = solutionConfig || config;

    // Only wait for flags if the solution requires it.
    if (access?.flag || access?.sandboxFlag) {
      try {
        featureFlags = featureFlags || await this.createFFPromise(selectedImsOrg).promise;
      } catch {
        // Failed to fetch feature flags, but don't die here. An empty object
        // is fine b/c if the access uses OR, feature flags may not be required
        // to determine access.
        featureFlags = {};
      }
    }
    // If the app does not require sandboxes or they are optional, it shouldn't
    // affect the outcome of this permissions check, so it's treated as
    // supported. If sandboxes are required, check the available service codes
    // for the org to see if ACP is enabled.
    const sandboxesSupported = (urlContext?.key !== 'sandbox' || urlContext?.optional) ? true : this.orgHasAEP(orgId);
    // We intentionally do NOT include feature flag overrides in this check.
    // This is to protect against a user being able to give themselves access
    const hasPermissionForSolution = hasPermission(access, {
      featureFlags,
      fulfillableData: this.userFulfillableData[orgId],
      fulfillableItems: this.userFulfillableItems[orgId],
      serviceCodes: this.userServiceCodes[orgId],
      userSubServices: this.userSubServices[orgId],
      userVisibleNames: this.userVisibleNames[orgId]
    });
    const orgPermissioned = hasPermissionForSolution && sandboxesSupported;
    this.setState({orgPermissioned});
    return orgPermissioned;
  }

  /**
   * Displays a toast with a custom message.
   * @param {string} messageId The message ID of the localized string to populate the toast.
   * @param {Object} options Optional object of objects such as variant.
   */
  async showToast(messageId, options) {
    const {showShellToast} = await import('./modules/ShellToast');
    await waitForShellHeader();
    this.setState({toastPlacement: options.position || 'top center'});
    showShellToast(messageId, this.state.locale, options);
  }

  homeRedirectToast({
    eimEvent = 'Shell.notPermissioned',
    selectedImsOrg,
    solutionConfig,
    tenant,
    toastMessage = 'orgHomeRedirectToast'
  }) {
    this.pageRedirect({hasTenantInUrl: this.hasTenantInUrl, replace: true, tenant});
    this.metrics.event(eimEvent, {appId: solutionConfig.appId, selectedImsOrg});
    this.showToast(toastMessage, {timeout: TOAST_TIMEOUT, variant: 'info'});
  }

  updateUrlOnOrgChange = async (selectedImsOrg, currentImsOrg, replace, selectedSubOrg) => {
    // Since the org has not changed, the subOrg must have changed.
    // We must update the path before updating.
    // state or localStorage to enable navigation blocking for subOrgs.
    if (selectedImsOrg === currentImsOrg && this.contextService?.isSubOrg) {
      return updateValueInPath(this.contextKey, this.contextService.getSubOrgPathname(selectedSubOrg), this.hasTenantInUrl, false);
    }

    // If the `selectedImsOrg` ends in AdobeID, that means it's an attempt to
    // load an account instead of an org. This will only happen in the following
    // scenarios:
    //  - In Type1 and click on Type2E org in switcher
    //  - In Type2E and click on Type2E org in switcher
    //  - In Type2E and click on Type1 account in switcher
    if (!(/@AdobeOrg$/.test(selectedImsOrg))) {
      return this.onType2eOrgChange(selectedImsOrg);
    }

    // If user is on skyline domain, this means they are in an AEM instance and if they switch orgs
    // then they need to be taken to /experiencemanager on the experience domain.
    const tenant = this.orgTenantMap[selectedImsOrg] || selectedImsOrg;
    if (isSkyline()) {
      return this.pageRedirect({path: '/experiencemanager', tenant});
    }

    // If there is a new subOrg and the new subOrg belongs to the new org, we can add it to the path as well.
    if (selectedSubOrg?.owningEntity === selectedImsOrg) {
      updatePath({subOrg: this.contextService?.getSubOrgPathname(selectedSubOrg), tenant}, replace, this.hasTenantInUrl, this.contextService?.getUrlKey());
    } else {
      updatePath({tenant}, replace, this.hasTenantInUrl);
    }

    // An org switch for a tenantless solution won't change the URL, so no history update is fired.
    // To counter this, we should check if the org change was a type2e org change and in that case
    // switch the profile. In the type2e case, authProcessWithProfile will not call onImsOrgChange
    // if the URL is tenantless, so we need to call it here. In the non-type2e org change case,
    // onHistoryChange will not call onImsOrgChange, so we need to call it here.
    if (this.state.solutionConfig.hideTenant) {
      await this.checkForType2eChange(selectedImsOrg);
      this.onImsOrgChange(selectedImsOrg, currentImsOrg, selectedSubOrg);
    }
  };

  onType2eOrgChange = async (userId, init = false) => {
    const {auth, userAccounts} = this.state;
    const switchTimer = this.metrics.start('SwitchAccount');
    // Remember the initial user hash so we can log the before/after user hashes to EIM.
    const fromUser = auth.getHashedUserId();
    const selectedAccount = userAccounts.find(account => account.userId === userId);
    try {
      await auth.switchProfile(userId, selectedAccount?.exchangeToken);
    } catch {
      return this.showToast('type2eError', {variant: 'error'});
    }
    switchTimer.time('switchDone', {from: fromUser, to: hashString(userId).toString()});
    await this.authProcessWithProfile();
    switchTimer.time('authProcessDone');
    this.setState({recentsData: RECENTS_RESET});

    // If user is on skyline domain, this means they are in an AEM instance and if they switch orgs
    // then they need to be taken to /experiencemanager on the experience domain.
    const tenant = this.orgTenantMap[this.state.selectedImsOrg];
    if (isSkyline() && !init) {
      return this.pageRedirect({path: '/experiencemanager', tenant});
    }
    updatePath({tenant}, false, this.hasTenantInUrl, this.contextKey);
  };

  /**
   * Log info for mismatched shellInfo
   */
  logMetricsForShellInfo = (shellResponse, location) => {
    const {orgId} = shellResponse;
    // Log if shell info is being fetched for a potentially wrong org
    const pathOrg = this.getPathOrg();
    if (orgId !== this.state.selectedImsOrg || pathOrg && orgId !== pathOrg) {
      this.metrics.warn(`ShellInfo: Generated in ${location}`, {
        pathOrg: pathOrg ?? 'n/a',
        selectedOrgInState: this.state.selectedImsOrg ?? 'n/a',
        shellInfoOrgId: orgId ?? 'n/a'
      });
    }
  };

  /**
   * There are only certain errors that make sense to clear on navigation.
   * Currently those include url context and timeout errors, as those may be solved by a new route/org.
   * @param {string} error - current error in state
   */
  clearErrorByType = error => {
    const update = {};
    if (RESETTABLE_ERRORS.includes(error)) {
      update.error = null;
    }
    if (this.isSideNavEnabled()) {
      const nav = {visible: true};
      Object.assign(update, {sidebar: nav, sidenav: nav});
    }
    this.setState(update);
  };

  checkForType2eChange = async pathOrg => {
    // If the new org is from a different account, trigger a type2e switch.
    const orgAccountMap = await unifiedShellSession.get('shellOrgAccountMap') || {};
    const requestedAccount = orgAccountMap[pathOrg];
    if (orgAccountMap[this.state.selectedImsOrg] !== requestedAccount) {
      const accountData = await this.getAccountCluster();
      if (accountData) {
        this.onType2eOrgChange(accountData.orgAccountMap[pathOrg]);
      }
      return true;
    }
    return false;
  };

  onHistoryChange = async location => {
    const pathOrg = this.getPathOrg(location);
    const pathSubOrg = getValueFromPath(location.pathname, this.contextKey);
    const {basePath, error, orgToSubOrgsMap, sandbox, selectedImsOrg, selectedSubOrg} = this.state;
    const orgChange = pathOrg && selectedImsOrg && pathOrg !== selectedImsOrg;

    if (basePath && getPathPrefix(location.pathname) !== getPathPrefix(basePath)) {
      // On app switch, we should reset the suborg in case its needed by the discovery call
      this.metrics.event('Shell.resetSubOrg', {basePath, pathname: location.pathname});
      const stateConfig = {selectedSubOrg: null};
      // If the history change is both an org and solution change, update the selected ims org so that
      // it is available in setSolutionConfig to properly initialize the new app. The rest of the org
      // change will still be handled by onImsOrgChange
      if (orgChange) {
        stateConfig.selectedImsOrg = pathOrg;
      }
      this.setState(stateConfig);
    }

    // The history has changed, so close the modal since it doesn't make sense
    // to keep it open anymore.
    this.removeComponentModal();

    // The hash path may have updated, try launching the roles modal invite again
    this.showRolesModalInvite();

    // If the user was on a page with an error message and they navigate to a new route, we should remove
    // the error from the state in case the navigation fixes the issue.
    error && !orgChange && this.clearErrorByType(error);
    // If the selectedImsOrg is different than what is in state, this is an org change.
    // We can skip modifying the URL context value here because it will be updated in onImsOrgChange.
    if (!orgChange) {
      this.contextService?.onHistoryChange(location, {orgToSubOrgsMap, pathOrg, sandbox, selectedImsOrg, selectedSubOrg});
    }

    // If the org is not set, update the URL with the current one.
    if (!pathOrg && this.hasTenantInUrl && selectedImsOrg) {
      setTimeout(() => updatePath({tenant: this.orgTenantMap[selectedImsOrg] || selectedImsOrg}, true, this.hasTenantInUrl, this.contextKey), 0);
    }

    const subOrgChange = this.contextService?.isSubOrg && pathSubOrg && selectedSubOrg && pathSubOrg !== this.contextService?.getSubOrgPathname(selectedSubOrg);
    // Compare the new org with the current one. If they are different, trigger an org change.
    // For a solution that supports suborgs, compare the pathSubOrg with the current one.
    // If they are both present and different, trigger an org change. If there is no pathOrg,
    // that means the solution has hideTenant and this can be skipped when tenant id isn't
    // the part of the history that changed.
    if (orgChange || subOrgChange) {
      // If the new org is from a different account, trigger a type2e switch.
      const type2eSwitch = await this.checkForType2eChange(pathOrg);
      if (!type2eSwitch) {
        // Replace argument is `true` because the URL has already been updated,
        // so we don't want to push anything else to the history.
        const newSubOrg = subOrgChange && (this.state.orgToSubOrgsMap[pathOrg] || []).find(so => so.orgIdName === pathSubOrg);
        // If its a suborg change but the orgToSubOrgsMap has been wiped, that means the history event fired
        // before the new context service could initialize. It will update the suborg when it does, so we can skip this.
        if (subOrgChange && !this.state.orgToSubOrgsMap[pathOrg]) {
          return;
        }
        return this.onImsOrgChange(pathOrg, selectedImsOrg, newSubOrg || this.state.selectedSubOrg);
      }
    }
  };

  /**
   * Signout method that triggers the actual IMS logout. If the event is triggered by the user
   * via the signout button, the iframe is not killed via showSpinner. This allows blockNavigation
   * to work correctly. In all other cases, the iframe is killed and the ims signout process is
   * kicked off.
   * @param {boolean} authExpired false means the event was triggered by the user.
   * @param {String} message Descriptive message to be sent to metrics.
   */
  silentSignOut = async authExpired => {
    authExpired && this.props.showSpinner();
    await this.state.auth.signOut();
  };

  on401 = async () => this.state.auth.validateTokenAndEmit();

  /**
   * Signout method triggered by signout button or solution triggered event.
   * @param {boolean} authExpired false means the event was triggered by the user.
   * @param {String} message Descriptive message to be sent to metrics.
   */
  onSignOut = async (authExpired, message = 'User signed out') => {
    this.metrics.event('Shell.signOut', undefined, message, {authExpired});
    queueMetrics(message, {data: {authExpired}});
    this.silentSignOut(authExpired, message);
    this.authChannel?.postMessage({message: AuthMessages.LOGOUT});
  };

  onCustomSearchClick = () => {
    const {enabled, onClick, open} = this.state.customSearch;
    open && this.metrics.analytics.trackEvent({
      action: 'click',
      element: 'custom search',
      feature: 'shell header',
      type: 'button',
      widget: {name: 'custom search', type: 'button'}
    });
    this.setState({customSearch: {enabled, onClick, open: !open}});
  };

  onSandboxChange = selected => {
    // To allow navigation blocking to stop sandbox changes, the change must be a URL update.
    // After the URL is updated, onHistoryChange is called. This calls the onHistoryChange handler
    // for the context (suborg) service and updates the selected suborg there. The context service then
    // calls its updateState function (as defined in setupContextService) that calls configureNetwork
    // and updates the permission service.
    updateValueInPath('sname', selected.name, this.hasTenantInUrl, false);
  };

  /**
   * Fetches the current URL context, e.g. sandboxes or suborgs
   * @param {string} selectedImsOrg The current ims org.
   * subOrgs on login and we need to select the subOrg associated with the login APC.
   */
  refreshContext(selectedImsOrg = this.state.selectedImsOrg) {
    if (!this.contextService) {
      return;
    }
    const {featureFlags, orgToSubOrgsMap, profile, sandbox, selectedSubOrg, shellResponse, solutionConfig, userSandboxes} = this.state;
    this.contextFetchPromise = this.contextService.fetch({
      featureFlags,
      hasAEP: this.orgHasAEP(),
      orgToSubOrgsMap,
      profile,
      sandbox,
      selectedImsOrg,
      selectedSubOrg,
      shellResponse,
      solutionConfig,
      userSandboxes
    }).catch(error => {
      const nav = {visible: false};
      // If the request failed for getting sandboxes or suborgs, show an error to the user
      // so they don't experience a weird UI. Additionally,
      // remove the side nav since the app won't load.
      this.contextService?.isSandbox && this.setState({sidebar: nav, sidenav: nav}, () =>
        this.showApiFailureErrorScreen({
          api: 'Sandbox Data',
          error,
          errorMessageType: ErrorTypes.SANDBOX_FAIL,
          failureMessage: 'Sandbox data fetch failed.'
        })
      );

      this.contextService?.isSubOrg && this.setState({sidebar: nav, sidenav: nav}, () =>
        this.showApiFailureErrorScreen({
          api: 'Suborgs',
          error,
          errorMessageType: ErrorTypes.SUBORG_FAIL,
          failureMessage: 'Suborgs fetch failed.'
        })
      );
    });
  }

  /**
   * Sets up the context service
   * @param {Object} solutionConfig The solution config of the application.
   */
  setupContextService = solutionConfig => {
    this.contextService = getContextService(
      solutionConfig,
      (ctx, error) => this.setState(ctx, async () => {
        if (!this.contextService?.isSandbox) {
          return;
        }
        this.configureNetwork();

        const {sandbox, selectedImsOrg, tenant} = this.state;
        const {appId, urlContext: {optional}} = solutionConfig;
        // If there are no sandboxes, the user should know that the reason they
        // can't load the app is because they don't have any and sandboxes are
        // required. Send the user to the landing page and show a toast.
        if (!error && !(sandbox?.sandboxes || []).length && !optional) {
          this.pageRedirect({hasTenantInUrl: this.hasTenantInUrl, replace: true, tenant: this.orgTenantMap[selectedImsOrg]});
          this.showToast('noSandboxesToast', {timeout: TOAST_TIMEOUT, variant: 'error'});
          this.metrics.event('Shell.noSandboxes', {appId, selectedImsOrg});
          return;
        }
        // If the org is not permissioned on the current solution, send the user
        // back to the home app.
        if (!await this.isOrgPermissionedOnSolution(selectedImsOrg, solutionConfig)) {
          setTimeout(() => this.homeRedirectToast({selectedImsOrg, solutionConfig, tenant}), 100);
        }
      }),
      this.settingsRef
    );
    this.contextKey = this.contextService && this.contextService.getUrlKey();
    this.contextService?.isSandbox && this.setState({sandboxesAvailable: true});
  };

  setSolutionConfig = solutionConfig => {
    const {
      environment,
      hero,
      sandbox,
      selectedImsOrg,
      shellResponse,
      sidebar,
      sidenav
    } = this.state;
    const {
      appContainer,
      appId,
      betaFeedbackConfigId: jiraConfigId,
      decodeUrl: configDecodeUrl,
      hideTenant,
      releaseType,
      sandbox: {ims} = {},
      sideNav: configSideNav,
      path,
      urlContext
    } = solutionConfig;

    configDecodeUrl && decodeUrl(this.history);

    this.setupContextService(solutionConfig);

    const heroConfig = getHeroConfig(shellResponse, solutionConfig, hero);
    // Because we don't clear sandboxes on solution change we must reset the sandboxes if the new
    // solution doesn't support them.
    const sandboxConfig = this.contextService?.isSandbox ?
      Object.assign(sandbox, {enabled: true, incognito: urlContext?.incognito, onChange: this.onSandboxChange}) :
      {enabled: false, incognito: urlContext?.incognito, onChange: NOOP, sandboxes: [], selected: null};

    let sideNavOpen = this.getSideNavState();
    if (configSideNav?.closeOnLoad) {
      sideNavOpen = false;
    }

    // Updates the state based on the existence (or non-existence) of a custom
    // ims configuration for the solution.
    this.configureCustomIms(ims);

    // Reset the beta feedback service on a config change.
    this.feedbackService?.clear();

    // Set the appId to prevent second state reset by resetConfigStates
    this.appId = appId;

    this.setState({
      ...this.getResetConfigState(configSideNav, ims),
      // appContainer can only be set for landing on QA and Stage since it is
      // being powered by Pueblo. On Production it is not, and appContainer is not
      // supported.
      appContainer: appId === 'landing' && environment === 'prod' ? null : appContainer,
      error: null,
      favicon: hero.icon,
      helpCenter: {solution: solutionConfig.appId, solutionConfig, solutionParent: solutionConfig.appRoot},
      hero: heroConfig,
      orgPermissioned: false,
      sandbox: sandboxConfig,
      sidebar: {...sidebar, config: configSideNav || null, visible: !!configSideNav || null},
      sidenav: {...sidenav, config: configSideNav || null, visible: !!configSideNav || null},
      sideNavOpen,
      solutionConfig,
      urlContext
    }, async () => {
      const workHubPromise = this.setWorkHub();

      // Honor the old-style (solution config) beta feedback but still use the
      // new-style set-up. Converts the original values into the new config and
      // sets the path to trigger on any sub-path of the root application path.
      // Setting releaseType will add buttons and labels to the UI. (Unless in demo mode)
      // Setting jiraConfigId (from betaFeedbackConfigId in solution config)
      // will route Open Feedback to create JIRA tickets.
      const isDemoMode = this.bootstrap.isDemoMode();
      const lifecycleType = isDemoMode ? RELEASE_TYPE.GA : releaseType;
      const feedbackReleaseType = [RELEASE_TYPE.ALPHA, RELEASE_TYPE.BETA].includes(lifecycleType);
      (feedbackReleaseType || jiraConfigId) && this.enableFeedback([{
        jiraConfigId,
        paths: [path, `${path}/(.*)`],
        releaseType: lifecycleType
      }]);

      // updatePath triggers onHistoryChange which relies on solution config values from the state.
      // This code has been moved to the callback of setState so we can ensure that the new solution config values
      // have been set in state before onHistoryChange is called.
      this.metrics.event('Shell.solutionConfig.set', {solutionConfig});
      this.hasTenantInUrl = !hideTenant;
      const tenant = this.orgTenantMap[selectedImsOrg] || selectedImsOrg;
      // Check if the org is allowed to access the new solution.
      if (selectedImsOrg && !await this.isOrgPermissionedOnSolution(selectedImsOrg, solutionConfig)) {
        // If this is triggered during an org change, onImsOrgChange will handle this correctly instead.
        if (this.state.imsOrgChanging) {
          return;
        }
        return this.homeRedirectToast({selectedImsOrg, solutionConfig, tenant});
      }
      // If the selected sandbox is defined, the previous solution and the new solution both support sandboxes
      // therefore we don't need to fetch sandboxes again. Only fetch if coming from a solution without sandboxes to a
      // solution with sandboxes enabled and the imsOrg is set (it is not a refresh or login).
      sandboxConfig.selected || selectedImsOrg && this.refreshContext();
      updatePath({tenant}, true, this.hasTenantInUrl, this.contextKey);
      // Roles Modal requires data from settings to determine whether to
      // show. Needs work hub for recommendations, so awaits it.
      await workHubPromise;
      this.showRolesModalInvite();
    });
  };

  getTux = async () => {
    // Check for the force query param
    const force = getQueryValue('forcetux');
    // TUX should NOT display during automated test runs
    if (!force && (this.internal || this.bootstrap.inAutomation())) {
      return;
    }
    // The TUX survey is intentionally delayed so the user has time to
    // navigate to their primary application of usage
    // The delay is skipped when using the force query param
    force || await wait(10000);
    // We don't want to inundate users with multiple modals
    // If one is open, we suppress TUX
    const {modal, showRolesInvite, showRolesModal, showUserConsent} = this.state;
    const blockingDialogs = [modal, showRolesInvite, showRolesModal, showUserConsent];
    if (this.userConsentShown || blockingDialogs.some(state => !!state)) {
      // In this case, ensure we prevent further sampling during the user's session
      await unifiedShellSession.update('tuxSessionCheckCompleted', true);
    }
    const {
      environment,
      featureFlags,
      locale,
      settings,
      shellResponse,
      solutionConfig
    } = this.state;
    // This should never happen with the delay but to capture our intent,
    // return early if we're missing the data we need
    if (!featureFlags || !shellResponse || !solutionConfig) {
      return;
    }
    // Support feature flag for disabled apps
    const disabledApps = featureFlags['tux-disabled-apps'];
    // Check to see if TUX should be displayed first
    const {displayTuxSurvey} = await this.postAuth;
    const tuxConfig = await displayTuxSurvey({
      disabledApps,
      environment,
      firstTimeUser: this.firstTimeUser,
      force,
      isLogin: this.props.isLogin,
      locale,
      metrics: this.metrics,
      settings,
      settingsService: this.settingsRef,
      shellResponse,
      showToast: this.showToast,
      solutionConfig,
      translationService: this.translationService
    }).catch(err => this.metrics.error('Error processing display TUX survey', err));
    // Store flag to prevent further sampling during the user's session
    // We use unifiedShellSession (localStorage) to ensure this works across tabs
    await unifiedShellSession.update('tuxSessionCheckCompleted', true);
    // If a config is not returned, the survey should NOT display
    if (!tuxConfig) {
      return;
    }
    // Update state to enable rendering
    this.setState({showTux: !!tuxConfig, tuxConfig});
  };

  renderHistoryDialog = () => {
    const {locale, historyDialogProps} = this.state;
    return (
      <React.Suspense fallback={null}>
        <HistoryBlockerDialog
          hide={() => this.setState({historyDialogProps: null})}
          locale={locale}
          {...historyDialogProps}
        />
      </React.Suspense>
    );
  };

  renderTuxSurvey = () => {
    const {tuxConfig} = this.state;
    return (
      <React.Suspense fallback={null}>
        <TuxContainer hide={() => this.setState({showTux: null})} {...tuxConfig} />
      </React.Suspense>
    );
  };

  logBetaAgreement = (agreementId, action) => {
    const {auth, selectedImsOrg, selectedImsOrgName} = this.state;
    const date = Date.now();
    /**
     * This needs to be sent on a delay because another omega
     * event is triggered on the button click that calls this function.
     * Once this race condition is fixed in EIM, we can remove the timeout.
     */
    setTimeout(() => this.metrics.analytics.trackEvent({
      action,
      attributes: {
        agreementId,
        appId: this.appId,
        authId: auth?.getHashedAuthId(),
        date,
        orgId: selectedImsOrg,
        orgName: selectedImsOrgName,
        userId: auth?.getHashedUserId()
      },
      element: agreementId,
      feature: 'shell agreements',
      type: 'modal',
      widget: {
        name: `${agreementId} agreement`,
        type: 'agreement modal'
      }
    }), 1000);
  };

  showBetaAgreement = async (agreementId, options) => {
    // Get agreements from settings
    const betaAgreements = await this.getBetaAgreements();
    // If the agreement has already been accepted, return the status
    if (betaAgreements?.[agreementId]?.accepted) {
      return Promise.resolve(betaAgreements[agreementId]);
    }
    this.logBetaAgreement(agreementId, 'show agreement');
    let agreementPromiseResolve;
    const agreementPromise = new Promise(resolve => agreementPromiseResolve = resolve);
    this.setState({agreementPromiseResolve, betaAgreementConfig: {id: agreementId, ...options}});
    return agreementPromise;
  };

  getAgreementResponse = async id => {
    const betaAgreements = await this.getBetaAgreements();
    return betaAgreements?.[id] ?? {};
  };

  clearAgreementResponse = async id => {
    const betaAgreements = await this.getBetaAgreements();
    const updatedAgreements = {...betaAgreements, [id]: null};
    return this.setSetting({betaAgreements: updatedAgreements}, SettingsLevel.USERORG).then(() => true).catch(() => false);
  };

  closeBetaAgreement = async accepted => {
    const {agreementPromiseResolve, betaAgreementConfig: {id: betaAgreementId}} = this.state;
    const agreementStatus = {accepted, date: Date.now()};
    this.logBetaAgreement(betaAgreementId, accepted ? 'accept' : 'decline');
    // Resolve the promise with the agreement status
    agreementPromiseResolve(agreementStatus);
    // Update agreements in settings
    const betaAgreements = await this.getBetaAgreements();
    const updatedBetaAgreements = {...betaAgreements, [betaAgreementId]: agreementStatus};
    this.setSetting({betaAgreements: updatedBetaAgreements}, SettingsLevel.USERORG);
    this.setState({agreementPromiseResolve: null, betaAgreementConfig: null});
  };

  renderBetaAgreement = () => (
    <React.Suspense fallback={null}>
      <DialogContainer
        isKeyboardDismissDisabled
        type="modal"
      >
        <BetaAgreement
          close={this.closeBetaAgreement}
          config={this.state.betaAgreementConfig}
          settingsService={this.settingsRef}
        />
      </DialogContainer>
    </React.Suspense>
  );

  isSideNavEnabled = (solutionConfig = this.state.solutionConfig) => {
    const {sideNav} = solutionConfig;
    const {featureFlags = {}, sidenav: stateSidenav} = this.state;
    return (stateSidenav?.config?.menu || stateSidenav?.config?.workHubId) || (sideNav && (!sideNav.workHubFeatureFlag || featureFlags?.[sideNav.workHubFeatureFlag] === 'true'));
  };

  updateTargetNode = () => {
    const {coachMark} = this.state;
    if (!coachMark.enabled) {
      return;
    }
    const targetNode = document.querySelector(coachMark.element);
    if (!targetNode) {
      this.setState({coachMark: {...coachMark, hidden: true}});
      return;
    }
    // Get the position of the leftmost edge of the element and the width of the element
    const {left, width} = targetNode.getBoundingClientRect();
    // Calculation for the middle of the element minus indicator width
    const coachMarkPosition = left + (width / 2) - 16;
    this.setState({coachMark: {...coachMark, hidden: false}, coachMarkPosition});
    return coachMarkPosition;
  };

  /**
   * Gets user setting.
   * @param {Object} settings The setting to be fetched.
   * @param {SettingsLevel} level The level of setting to get.
   */
  async getSetting(settings, level = SettingsLevel.USER) {
    return this.settingsHandler({
      groupId: PREF_GROUPID,
      level,
      settings
    }, 'GET', true, PREF_APPID);
  }

  /**
   * Updates user setting.
   * @param {Object} settings The setting to be updated.
   * @param {SettingsLevel} level The level of setting to set.
   */
  async setSetting(settings, level = SettingsLevel.USER) {
    return this.settingsHandler({
      groupId: PREF_GROUPID,
      level,
      settings
    }, 'POST', true, PREF_APPID);
  }

  /**
   * Read/Upsert/Delete settings.
   * @param {Object} data Required: JSONObject contaning requested groupId,
   * settings, and corresponding settings level(optional).
   * GET ex- {groupId: 'group1', level: SettingsLevel.USER,
   * settings: {key1: default value to fallback}}.
   * POST ex- {groupId: 'group1', settings: {key1: value1}}.
   * @param {string} method Required: Method Type: GET/POST/DELETE.
   * @param {Object} isUnifiedShell Optional: To be set to true
   * by unified shell apps to consume Unified Shell Application space.
   * @param {Object} appId - Optional: Used by unifiedShell apps to
   * consume settings from different app space overriding unified shell app space.
   * ex- settingsHandler(data, 'GET', false, 'not-unifiedshell-appId')
   * @param {boolean} enableSandboxForDifferentAppSpace - Optional: Used by unifiedShell apps that
   * consume settings from Unified Shell Application space or
   * different app space overriding unified shell app space to enable sandbox default: false.
   * ex- settingsHandler(data, 'GET', false, 'not-unifiedshell-appId', true)
   *
   * @returns {Promise} - Settings Response.
   */
  async settingsHandler(data, method, isUnifiedShell, appId, enableSandboxForDifferentAppSpace) {
    const {accessToken = this.state.auth.fetchAccessToken(), solutionConfig, selectedImsOrg, sandbox} = this.state;
    const environment = this.config.serviceEnvironment;
    const {clientId} = this.bootstrap;
    const selectedAppId = appId || isUnifiedShell && UNIFIED_SHELL_APPID ||
    // pick solutionConfig.settingsAppId if present rather than solutionConfig.appId
    solutionConfig.settingsAppId || solutionConfig.appId;
    const settingsPackageLoadTimer = this.metrics.start('SettingsLoadJS');
    const SettingsService = await import('@exc/settings');
    settingsPackageLoadTimer.time('done', `Core: Settings Request for appId: ${selectedAppId} and orgId: ${selectedImsOrg}`);
    SettingsService.configure({
      env: environment,
      imsInfo: {accessToken, clientId}
    });
    let selectedSandbox = undefined;
    if (sandbox && sandbox.selected && !sandbox.selected.isDefault && (!(appId || isUnifiedShell && UNIFIED_SHELL_APPID) || enableSandboxForDifferentAppSpace)) {
      selectedSandbox = sandbox.selected.name;
    }
    return SettingsService.settingsHandler({
      appId: selectedAppId,
      data,
      imsOrgId: selectedImsOrg,
      method,
      sandbox: selectedSandbox
    });
  }

  /**
   * Process functionality that requires settings data to be available.
   */
  async processSettings() {
    // A unique visitor will NOT have seen, submitted, or dismissed user consent
    const {userConsent} = this.state.settings || {};
    this.firstTimeUser = !userConsent || JSON.stringify(userConsent) === '{}';
    this.lastRecents = this.state.settings?.lastRecents || {};

    // UserConsent requires data from settings so we wait until the
    // settings data is in state to trigger the consent dialog
    this.showUserConsent();

    // Roles Modal requires data from settings to determine whether or not to show
    this.showRolesModalInvite();

    // Process theme related data now that settings is available.
    this.userThemeHandler();

    // Process sandbox recents data now that settings is available.
    this.userRecentSandboxesHandler();

    // Process the side nav state.
    this.getSideNavState();

    // Process user roles for gainsight
    this.handleGainsightRoles();
  }

  /**
   * Handles shell theme on load and on toggle by user.
   * @param {string} theme Optional, theme string indicating new spectrum theme
   * @param {boolean} onToggle The user is toggling the theme
   */
  userThemeHandler = async (theme, onToggle) => {
    const {auth, settings} = this.state;
    const themeParam = getQueryValue('theme');
    // If theme is set via query param, use it and don't do anything with
    // storage or settings. This is purely an override value.
    if (themeParam) {
      this.setState({theme: themeParam});
      return;
    }
    // If no theme is given, settings service is the source of truth
    if (!theme) {
      theme = settings?.theme;
    }
    const shellTheme = this.state.theme;
    // If localStorage (state) and theme match and this is not a user toggle
    // from the profile popover, then we do nothing. Also, if there is no data
    // from settings, we should be using the default theme set in state.
    if (!onToggle && (!theme || (shellTheme === theme))) {
      return;
    }
    // If they do NOT match, then theme (settings service || user toggled value)
    // is the source of truth and we update the state, localStorage and settings
    this.setState({settings: {...settings, theme}, theme});
    storage.local.set('spectrumTheme', {[auth.getHashedAuthId()]: theme}, TEN_YEARS);
    if (theme !== settings?.theme || onToggle) {
      this.setSetting({theme});
    }
  };

  orderRecentSandboxes = (sandbox, recentSandboxes, sandboxInRecents) => {
    // If sandbox is already in recents,
    // remove it from its place, and add it back to the top of the list.
    // If array length already 5, remove least recent sandbox.
    if (sandboxInRecents) {
      recentSandboxes = recentSandboxes.filter(sb => sb.name !== sandbox.name);
    } else if (recentSandboxes.length === 5) {
      recentSandboxes.splice(4);
    }
    return [sandbox, ...recentSandboxes];
  };

  /**
   * Handles recent sandboxes on load and on sandbox switch by user.
   * @param {string} sandbox Optional, the sandbox just selected by the user
   */
  userRecentSandboxesHandler = sandbox => {
    const {settings} = this.state;
    let {recentSandboxes} = this.state;
    if (!settings) {
      return;
    }
    recentSandboxes = recentSandboxes || settings.recentSandboxes;
    recentSandboxes = Array.isArray(recentSandboxes) ? recentSandboxes : [];

    // If no new sandbox passed in, just set state.
    if (!sandbox) {
      this.setState({recentSandboxes});
      return;
    }
    // If sandbox already in recents, don't add it again. Otherwise, update.
    const sandboxInRecents = recentSandboxes.filter(({name}) => name === sandbox.name);
    recentSandboxes = this.orderRecentSandboxes(sandbox, recentSandboxes, sandboxInRecents.length);
    this.setState({recentSandboxes});
    this.setSetting({recentSandboxes});
  };

  /**
   * Create a custom IMS client with the provided client_id and scopes.
   * @returns A promise that resolves with the profile and token.
   */
  getCustomToken = async ({client_id, scopes}) => {
    await this.authPromise;
    return this.state.auth.createCustomClient(client_id, scopes);
  };

  /**
   * Updates state with the provided customIms data if provided or unsets the
   * custom IMS data if previously set and customIms is not provided.
   * @param {Object=} customIms The custom IMS configuration data.
   * @returns A void promise.
   */
  configureCustomIms = async customIms => {
    await this.authPromise;
    const {appToken} = this.state;

    // customIms is not defined, so we shouldn't do anything with the token, but
    // we still need to update the state to ensure the profile and token are
    // updated.
    if (!customIms) {
      return;
    }

    // If we're trying to use the same token, no more processing.
    if (isCustomTokenValid(customIms, appToken)) {
      return;
    }

    try {
      const {profile, token} = await this.getCustomToken(customIms);
      this.setState({appProfile: profile, appToken: token});
    } catch (e) {
      this.metrics.error('Error fetching custom token', e);
      throw e;
    }
  };

  onCustomTokenChange(appProfile, appToken) {
    const {sandbox = {}} = this.state.solutionConfig || {};
    const {ims = {}} = sandbox;

    // If the client_id is the same as the customIms version, it should be set
    // in state so that the application can use it.
    if (isCustomTokenValid(ims, appToken)) {
      this.setState({appProfile, appToken});
    }
  }

  renderNoAccess() {
    const {
      defaultComponent,
      hasAccountClusterData,
      locale,
      profile,
      userAccounts
    } = this.state;
    if (!hasAccountClusterData) {
      return defaultComponent;
    }

    const account = userAccounts.find(({userId, userType}) => userId === profile.userId && userType !== AuthType.TYPE2E);
    const hasOtherAccounts = account && userAccounts.length > 1;
    return (
      <React.Suspense fallback={defaultComponent}>
        <ShellNoAccess hasOtherAccounts={!!hasOtherAccounts} locale={locale} />
      </React.Suspense>
    );
  }

  showUserConsent = async () => {
    // Use the settings data to populate the user consent object.
    const {locale, settings, userConsentResponse} = this.state;
    const {isLogin} = this.props;
    const {
      metrics,
      onUserConsentDismissed: hide,
      onUserConsentSubmit: onSubmit,
      setSetting,
      showToast,
      userConsentShown
    } = this;

    const userConsent = {};
    (userConsentResponse?.permissions || []).forEach(({name, enabled}) => {
      userConsent[name] = enabled;
    });
    onConsent(userConsent, this.metrics);

    const {showUserConsent} = await this.postAuth;
    const consentProps = await showUserConsent({
      hide,
      isLogin,
      locale,
      metrics,
      onSubmit,
      setSetting,
      settings,
      showToast,
      userConsentResponse,
      userConsentShown
    });

    // Load event for user consent so that we can capture the data in EIM in the
    // case that it's not there already but is in settings. Also capture whether
    // or not the dialog was shown.
    this.metrics.event('Shell.UserConsent.Load', {
      consent: userConsent,
      lastUpdated: settings?.userConsent?.last_update_dts,
      show: !!consentProps
    });

    // Always set userConsent into state, but optionally add the other
    // properties as user consent may not always be shown.
    let state = {showUserConsent: !!consentProps, userConsent};
    consentProps && (state = {...state, consentProps});
    this.setState(state);
  };

  renderUserConsentModal = () => {
    const {consentProps} = this.state;
    // We set userConsentShown in case the dialog was just shown
    // (i.e. org switching use case where isLogin is still true)
    this.userConsentShown = true;
    // Add a new class to the body for our CSS to handle Gainsight guides that
    // interfere with the Consent Modal.
    // We check that the clasName isn't already there because render COULD
    // get called more than once
    if (!document.body.className.includes('consent-active')) {
      document.body.className += ' consent-active';
    }
    return (
      <React.Suspense fallback={null}>
        <DialogContainer
          hide={() => this.setState({showUserConsent: null})}
          isKeyboardDismissDisabled={false}
          type="fullscreen"
        >
          <UserConsentDialog {...consentProps} />
        </DialogContainer>
      </React.Suspense>
    );
  };

  showRolesModalInvite = async () => {
    const {activeFeatureFlags, settings, solutionConfig} = this.state;
    const {metrics, setSetting} = this;
    const {showRolesModalInvite} = await this.postAuth;
    showRolesModalInvite({
      featureFlags: activeFeatureFlags,
      metrics,
      setSetting,
      settings,
      showRolesInvite: () => this.setState({showRolesInvite: true}),
      solutionConfig
    });
  };

  handleGainsightRoles = async submittedRoles => {
    const {settings} = this.state;
    const {handleGainsightRoles} = await this.postAuth;
    const updatedRoles = await handleGainsightRoles(settings, submittedRoles);
    updatedRoles && this.setState(updatedRoles);
  };

  renderRolesModal = () => {
    this.metrics.analytics.trackEvent({
      action: 'display',
      element: 'roles selection modal',
      feature: 'roles pilot',
      type: 'modal',
      widget: {name: 'roles selection modal', type: 'job function-modal'}
    });
    const {
      auth,
      featureFlags,
      settings: {userRoles: settingsUserRoles},
      showRolesModal,
      userRoles,
      workHubResponse
    } = this.state;
    const hashedAuthId = auth.getHashedAuthId();
    return (
      <React.Suspense fallback={null}>
        <DialogContainer
          hide={() => this.setState({showRolesModal: null})}
          settingsService={this.settingsRef}
          type="modal"
        >
          <ShellRolesModal
            featureFlags={featureFlags}
            fromAPI={showRolesModal === 'API'}
            handleGainsightRoles={this.handleGainsightRoles}
            hashedAuthId={hashedAuthId}
            hide={() => this.setState({showRolesModal: null})}
            settingsService={this.settingsRef}
            showToast={this.showToast}
            userOrgContext={this.getUserOrgContext()}
            userRoles={userRoles || settingsUserRoles}
            workHubResponse={workHubResponse}
          />
        </DialogContainer>
      </React.Suspense>
    );
  };

  renderRolesInvite = () => (
    <React.Suspense fallback={null}>
      <ShellRolesModalInvite
        show={this.state.showRolesInvite}
        showRolesModal={() => this.setState({showRolesModal: 'TOAST'})}
      />
    </React.Suspense>
  );

  toggleAccessOverrides = async override => {
    // If the overrides on being toggled on (true), fetch and update
    if (override) {
      const {auth, selectedImsOrg} = this.state;
      const hashedAuthId = auth.getHashedAuthId();
      return this.checkToSetAccessOverrides(selectedImsOrg, hashedAuthId, override);
    }
    // Otherwise, reset the state
    this.setState({
      accessOverridesEnabled: override,
      activeFeatureFlags: this.state.featureFlags,
      activeFulfillableItems: this.userFulfillableItems
    });
  };

  checkToSetAccessOverrides = async (selectedImsOrg, hashedAuthId, enabledState) => {
    const {auth, featureFlags, solutionConfig: {fiServiceCode}} = this.state;
    const {appAssemblyService, userFulfillableItems} = this;
    const {setAccessOverrides} = await this.postAuth;
    let stateUpdate = await setAccessOverrides({
      appAssemblyService,
      enabledState,
      featureFlags,
      fiServiceCode,
      hashedAuthId: hashedAuthId || auth?.getHashedAuthId(),
      selectedImsOrg,
      userFulfillableItems
    });
    // If there are overrides, we need to update the shell response data
    let shellResponse;
    if (stateUpdate) {
      shellResponse = this.appAssemblyService.fetchShell(selectedImsOrg, this.orgTenantMap[selectedImsOrg]);
      stateUpdate = {...stateUpdate, shellResponse};
    }
    stateUpdate && this.setState(stateUpdate);
  };

  renderDebugTool() {
    const {
      accessOverridesEnabled,
      accessToken,
      auth,
      debug,
      defaultComponent,
      featureFlags,
      locale,
      orgsMap,
      profile,
      sandbox: {selected},
      selectedImsOrg,
      solutionConfig: {fiServiceCode}
    } = this.state;
    const hashedAuthId = auth && auth.getHashedAuthId();
    return !debug ? null : (
      <React.Suspense fallback={defaultComponent}>
        <DebugView
          accessOverridesEnabled={accessOverridesEnabled}
          close={() => this.toggleDebugModal(false)}
          featureFlags={featureFlags}
          fiServiceCode={fiServiceCode}
          getFlagService={this.services?.getFlagService}
          hashedAuthId={hashedAuthId}
          internal={this.internal}
          locale={locale}
          onImsOrgChange={this.updateUrlOnOrgChange}
          onLanguageChange={this.onLanguageChange}
          orgRegions={this.orgRegions}
          orgsMap={orgsMap}
          profile={profile}
          selectedImsOrg={selectedImsOrg}
          selectedSandbox={selected}
          serviceCodes={this.userServiceCodes}
          serviceEnvironment={this.config.serviceEnvironment}
          settingsService={this.settingsRef}
          showToast={this.showToast}
          toggleAccessOverrides={this.toggleAccessOverrides}
          userFulfillableItems={this.userFulfillableItems}
          userToken={accessToken}
        />
      </React.Suspense>
    );
  }

  async renderComponentModal({component, data}, closeCallback) {
    this.modalContainer = this.modalContainer || (await import('@react/react-spectrum/ModalContainer')).default;
    this.ComponentModalComp = this.ComponentModalComp || (await import('./modules/ComponentModal')).default;
    const appConfig = this.props.listedApps.find(config => config.appId === component);
    const params = {};

    if (!appConfig) {
      this.metrics.error(`Invalid app config for ${component}`);
      return;
    }

    // If the main application has specified a component path for the component
    // to load at in the modal, use it by setting it in the params, which is
    // added to the end of URL in Sandbox.
    if (data?.componentPath) {
      params[0] = data?.componentPath;
    }

    this.componentModalCloseCallback = closeCallback;
    this.componentModal = this.modalContainer.show(
      <CoreContext.Provider value={this.getComponentContext()}>
        <this.ComponentModalComp
          bootstrapData={data}
          config={appConfig}
          locale={this.state.locale}
          params={params}
          path="/"
        />
      </CoreContext.Provider>
    );
  }

  removeComponentModal(data) {
    this.componentModal && this.modalContainer.hide(this.componentModal);
    this.componentModalCloseCallback && this.componentModalCloseCallback(data);
    this.componentModal = null;
    this.componentModalCloseCallback = null;
  }

  /**
   * Updates state upon submission of user consent. This will also hide the
   * modal as this is the only callback called when submission is completed.
   * @param {Record<string, number>} permissionsObj Map of permissions to value
   * @param {Record<string, boolean | string>[]} settingsPermissions Array of
   *   permissions which is set in settings.
   * @param {boolean} success If the consent submission was successful.
   */
  onUserConsentSubmit = ({permissionsObj, settingsPermissions, success}) => {
    if (!success) {
      return this.onUserConsentClose();
    }

    const updatedPermissionKeys = Object.keys(permissionsObj);
    const permissions = (this.state.settings?.userConsent?.permissions || [])
      .filter(permission => !updatedPermissionKeys.includes(permission.name));

    const consentData = {
      last_update_dts: new Date().toISOString().replace(/Z$/, '+00:00'),
      permissions: settingsPermissions.concat(permissions)
    };

    this.onUserConsentClose({
      settings: {
        ...this.state.settings,
        userConsent: consentData,
        userConsentDismissed: 0
      },
      userConsent: {...(this.state.userConsent || {}), ...permissionsObj},
      userConsentResponse: {
        ...this.state.userConsentResponse,
        ...consentData
      }
    });
  };

  /**
   * Updates state upon dismissal of user consent. This will set the dismissed
   * count and hide the modal.
   * @param {number} userConsentDismissed New number of times dismissed.
   */
  onUserConsentDismissed = userConsentDismissed => {
    const state = {};
    if (typeof userConsentDismissed === 'number') {
      state.settings = {...this.state.settings, userConsentDismissed};
    }
    this.onUserConsentClose(state);
  };

  onUserConsentClose = (state = {}) => {
    this.setState({...state, showUserConsent: false});
    // Remove the class when consent is closed so that the Gainsight guides can
    // start working again.
    document.body.className = document.body.className.replace('consent-active', '').replace(/\s{2,}/, ' ');
  };

  showRideError = async error => {
    if (this.state.showUserConsent) {
      this.onUserConsentDismissed();
    }
    if (this.componentModal) {
      this.removeComponentModal();
    }

    const locale = this.state.locale;
    await import('./modules/RideError').then(RideError => {
      RideError.default.show(error, locale);
    });
  };

  onLanguageChange = (languages, onInitialize = false) => {
    const {auth, preferredLanguages, profile} = this.state;
    const {onLocale} = this.props;
    // Format the languages for easy comparison and best supported locale handling
    const formatLanguages = langArray => langArray.map(language => {
      // Check for a region with the language (['en'] vs ['en-us']);
      if (language.length === 2) {
        language = getLocaleRegion(language);
      }
      const parts = language.replace('_', '-').split('-');
      return `${parts[0]}-${parts[1].toUpperCase()}`;
    });
    languages = languages && formatLanguages(languages);
    // Check if languages match, including order. Update on a mismatch.
    if (onInitialize) {
      // If there are no languages, which would only happen if the account cluster
      // did not include language preferences
      if (!languages?.length) {
        return;
      }
      // For the account cluster flow from Auth/GraphQL, the languages in state
      // would be the stored languages from the account cluster or the IMS profile
      // languages (which could be old). The account cluster is the source of truth.
      const languagesInState = preferredLanguages && formatLanguages(preferredLanguages);
      if (languagesInState && languages.every((lang, index) => lang === languagesInState[index])) {
        return;
      }
    }

    this.metrics.analytics.trackEvent({
      action: `${languages[0]}, ${languages[1]}`,
      element: 'submit',
      feature: 'language_selector',
      type: 'button',
      widget: {name: 'language interactions', type: 'button'}
    });

    const locale = getBestSupportedLocale(languages, SUPPORTED_LOCALES);
    onLocale(locale);

    if (!onInitialize) {
      // Fetch the updated account cluster and update the profile with the correct languages
      this.postAuth.then(({updateProfileOnLanguageChange}) => updateProfileOnLanguageChange(this, locale, profile));
    }

    // Store for future use for speed/to avoid updates
    storage.local.set('accountLanguagePreferences', {[auth.getHashedAuthId()]: languages});

    this.setState({locale, preferredLanguages: languages, showLanguagePicker: false});
    this.setWorkHub();
  };

  isGlobalSearchEnabled = () => {
    const {featureFlags, solutionConfig, profile, selectedImsOrg, shellResponse} = this.state;

    if (!this.orgHasAEP(selectedImsOrg)) {
      return false;
    }

    const {access, enabled} = solutionConfig?.unifiedSearch || {};

    // If search is not enabled via solution config, return false, since
    // global search isn't enabled for this application or org.
    if (!access && !enabled || !profile) {
      return false;
    }
    // If access property is undefined, global search is enabled for everyone.
    if (!access) {
      return true;
    }
    const {appIds, flag, logicOp} = access;
    const hasFF = flag && featureFlags?.[flag] === 'true';
    const hasAppIds = appIds && shellResponse.services && shellResponse.services.concat(shellResponse.solutions).some(({appId, enabled: appEnabled}) => appIds.includes(appId) && appEnabled);
    return !!(logicOp === 'AND' ? hasAppIds && hasFF : hasAppIds || hasFF);
  };

  /**
   * Fetches recents using RecentsService if context is present
   * Also sets recents in state, to return recents set in previous call
   * in case getRecents returns undefined
   * Also sets cursor for getting older recents in state
   * @returns recentData object with array of recent objects and status
   */
  initRecents = async () => {
    let {recents, status} = this.state.recentsData;
    const recentsFromCache = await this.services.recentsService.getRecents(this.getUserOrgContext());
    if (recentsFromCache) {
      recents = recentsFromCache.recents || [];
      status = recentsFromCache.recents ? RecentStatus.SUCCESS : RecentStatus.ERROR;
      const cursor = {isSet: true, value: recentsFromCache.cursor || null};
      this.setState({recentsData: {cursor, recents, status}});
    }
    return {list: recents, status};
  };

  /**
   * For loading older recents
   * @returns whether yet more recents are available and recents array with older recents
   */
  loadMoreRecents = async () => {
    // As this is only called via config, do not expect a case where cursor is not already set.
    // If not set or set but value is null, return whatever is in recents state.
    const {recentsData} = this.state;
    if (recentsData.cursor.isSet && recentsData.cursor.value) {
      const {cursor, recents} = await this.services.recentsService.getOlderRecents(recentsData.recents, recentsData.cursor.value);
      recentsData.recents = recents;
      recentsData.cursor.value = cursor;
      this.setState({recentsData});
      return {isMore: !!cursor, moreRecents: recents};
    }
    return {isMore: false, moreRecents: recentsData.recents};
  };

  hasAssistantPermission = async () => {
    const {sandbox: {selected}, selectedImsOrg, solutionConfig: {appId}} = this.state;
    this.services || await this.postAuth;

    // All permissions must have a selected org.
    if (!selectedImsOrg) {
      return;
    }

    // CJA: Must have an org and a selected sandbox.
    if (appId === 'platformAnalytics') {
      try {
        const context = this.getUserOrgContext();
        const {value: {permissions}} = await this.services.dataPrefetchService.getData('cja-permissions-org', context, appId);
        return !!permissions?.includes('Omni.Tools.AIAssistantDocumentation');
      } catch {
        return false;
      }
    }

    // Default: Must have a selected sandbox. This uses the `parent` property so
    // it can be used on all AEP capabilities.
    if (selected) {
      const {permissions} = await this.services.permissionService.get({permissions: ['enable-ai-assistant']}, selectedImsOrg, selected);
      return !!permissions?.['enable-ai-assistant'];
    }
  };

  render() {
    const {
      appContainer,
      accessOverridesEnabled,
      accessToken,
      backgroundReady,
      basePath,
      coachMark,
      afterPrintHandler,
      beforePrintHandler,
      activeFeatureFlags,
      customEnvLabel,
      customHeroClick,
      customButtons,
      customSearch,
      defaultComponent,
      digitalData,
      environment,
      error,
      featureFlags,
      feedback,
      feedbackConfig,
      globalDialogOpen,
      hasAccountClusterData,
      helpCenter,
      hero,
      initialAppLoaded,
      imsOrgs,
      locale,
      modal,
      onCustomButtonClick,
      orgToSubOrgsMap,
      profile,
      pulseLoaded,
      recentSandboxes,
      releaseType,
      sandbox,
      selectedImsOrg,
      selectedImsOrgName,
      selectedSubOrg,
      settings,
      settingsResponse,
      shellResponse,
      showLanguagePicker,
      showNetworkDisconnected,
      showNoAccessShell,
      sidebar,
      sidenav,
      sideNavOpen,
      sideNavOpenByHover,
      solutionConfig,
      solutionLoaded,
      theme: userTheme,
      timelineResponse,
      userAvatar,
      userOrgPreferences,
      workHubResponse,
      workspaces
    } = this.state;
    let content = this.props.children;
    showNoAccessShell && (content = this.renderNoAccess());
    error && (content = <ErrorView type={error} />);

    // This does the equivalent of a lodash groupBy(customButtons, 'scope');
    const scopedCustomButtons = (customButtons || []).reduce((r, v, i, a, k = v['scope']) => ((r[k] || (r[k] = [])).push(v), r), {});
    const globalSearchConfig = {
      accessToken,
      compact: solutionConfig?.unifiedSearch?.compact,
      enabled: this.isGlobalSearchEnabled(),
      internal: this.internal,
      parent: solutionConfig?.parent,
      recents: {
        getRecentsData: () => this.state.recentsData,
        list: () => this.initRecents(),
        loadMore: () => this.loadMoreRecents()
      },
      selectedImsOrg,
      selectedOrgName: selectedImsOrgName
    };

    // HelpCenter restricts some things to only supported user roles
    // But we don't need to update state or rerender Core for this value
    // So we just get it and send it to the Shell/HelpCenter
    const isSupportedUser = hasValidRole(['SUPPORT_ADMIN'], selectedImsOrg, profile?.roles || []);

    const sideNavEnabled = this.isSideNavEnabled() && (sidenav?.visible !== false && sidebar?.visible !== false);
    const theme = solutionConfig?.theme || userTheme;

    return (
      <SpectrumV3Provider
        colorScheme={theme ? theme.replace(/est$/, '') : 'light'}
        locale={locale}
        theme={defaultTheme}
      >
        {this.renderDebugTool()}
        <CoreContext.Provider value={this.getComponentContext()}>
          <ShellWrapper
            accessOverridesEnabled={accessOverridesEnabled}
            accessToken={accessToken}
            afterPrintHandler={afterPrintHandler}
            agreements={this.agreements}
            appContainer={appContainer}
            appId={solutionConfig.appId}
            appRoot={solutionConfig.appRoot}
            appTheme={solutionConfig.theme}
            authId={profile?.authId || profile?.userId}
            backgroundReady={backgroundReady}
            basePath={basePath}
            beforePrintHandler={beforePrintHandler}
            clientId={this.config.ims.client_id}
            closeLanguagePicker={cancelled => {
              cancelled && this.metrics.analytics.trackEvent({
                action: 'cancel',
                element: 'cancel',
                feature: 'language_selector',
                type: 'button',
                widget: {name: 'language interactions', type: 'button'}
              });
              this.setState({showLanguagePicker: false});
            }}
            coachMark={coachMark}
            customButtons={scopedCustomButtons}
            customEnvLabel={customEnvLabel}
            customHeroClick={customHeroClick}
            customSearch={customSearch}
            defaultComponent={defaultComponent}
            digitalData={digitalData}
            disableShellFeedbackButton={solutionConfig.disableShellFeedbackButton}
            environment={environment}
            featureFlags={activeFeatureFlags || featureFlags}
            feedback={feedback}
            feedbackConfig={feedbackConfig}
            getCapabilityWithIconUrl={this.getCapabilityWithIconUrl}
            getComponentContext={this.getComponentContext}
            getLDFeatureFlags={this.getLDFeatureFlags}
            globalDialogOpen={globalDialogOpen}
            globalSearch={globalSearchConfig}
            hasAccountClusterData={hasAccountClusterData}
            hasAssistantPermission={this.hasAssistantPermission}
            helpCenter={{...helpCenter, appConfiguration: solutionConfig.helpCenter, isSupportedUser}}
            hero={hero}
            imsOrgs={imsOrgs}
            initialAppLoaded={initialAppLoaded}
            internal={this.internal}
            isImpersonating={this.isImpersonating}
            locale={locale}
            modal={modal}
            networkErrorProps={{onDismiss: () => this.setState({showNetworkDisconnected: null}), showNetworkDisconnected}}
            noAccess={showNoAccessShell}
            noSolution={!solutionConfig || !solutionConfig.path}
            onCustomButtonClick={onCustomButtonClick}
            onGlobalDialogOpen={globalDialogOpen => this.setState({globalDialogOpen})}
            onImsOrgChange={this.updateUrlOnOrgChange}
            onLanguageChange={this.onLanguageChange}
            onSetUserTheme={this.userThemeHandler}
            onSignOut={this.onSignOut}
            orgToSubOrgsMap={orgToSubOrgsMap}
            parentId={solutionConfig.parent}
            profile={profile}
            pulse={{
              accessToken,
              additionalCount: this.state.pulse.count,
              customButton: this.state.pulse.button,
              enabled: !!pulseLoaded && !!accessToken && Object.keys(shellResponse).length > 0,
              environment: this.config.serviceEnvironment,
              locale,
              profile,
              selectedImsOrg,
              settingsResponse,
              settingsService: this.settingsRef,
              shellResponse,
              timelineResponse,
              userOrgPreferences
            }}
            recentSandboxes={recentSandboxes}
            releaseType={releaseType || solutionConfig.releaseType}
            sandbox={sandbox}
            selectedImsOrg={selectedImsOrg}
            selectedImsOrgName={selectedImsOrgName}
            selectedSubOrg={selectedSubOrg}
            selectedTenantId={cleanTenant(this.orgTenantMap[selectedImsOrg])}
            serviceEnvironment={this.config.serviceEnvironment}
            settingsData={settings}
            settingsService={this.settingsRef}
            shellResponse={shellResponse}
            showDebugModal={() => this.toggleDebugModal(true)}
            showLanguagePicker={showLanguagePicker}
            showToast={this.showToast}
            sideNav={{
              buttonEnabled: sideNavEnabled || this.state.customLeftNavClick,
              config: sidebar?.config?.menu || sidebar?.config?.workHubId ? sidebar?.config : sidenav?.config,
              enabled: sideNavEnabled,
              open: sideNavOpen,
              openByHover: sideNavOpenByHover,
              toggle: this.toggleSideNav
            }}
            solutionLoaded={solutionLoaded}
            solutionParent={solutionConfig.parent}
            subOrgAccessOnlyOnCurrentOrg={orgToSubOrgsMap && solutionConfig.subOrgAccessOnlyOnCurrentOrg}
            theme={theme}
            toastPlacement={this.state.toastPlacement}
            translationService={this.translationService}
            userAvatar={userAvatar}
            userRecentSandboxesHandler={this.userRecentSandboxesHandler}
            workHubResponse={workHubResponse}
            workspaces={workspaces}
          >
            <React.Fragment>
              {content}
            </React.Fragment>
          </ShellWrapper>
        </CoreContext.Provider>
        {this.state.showRolesInvite && this.renderRolesInvite()}
        {this.state.showRolesModal && this.renderRolesModal()}
        {this.state.showUserConsent && this.renderUserConsentModal()}
        {this.state.showTux && this.renderTuxSurvey()}
        {this.state.historyDialogProps && this.renderHistoryDialog()}
        {this.state.betaAgreementConfig?.id && this.renderBetaAgreement()}
      </SpectrumV3Provider>
    );
  }
}

Core.defaultProps = {
  onLocale: NOOP,
  showSpinner: NOOP
};

Core.propTypes = {
  auth: PropTypes.object,
  config: PropTypes.object,
  isLogin: PropTypes.bool,
  metrics: PropTypes.object,
  onLocale: PropTypes.func,
  org: PropTypes.string,
  showSpinner: PropTypes.func
};

export default injectIntl(Core);

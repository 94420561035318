{
  "alpha": "Alpha",
  "alphaFeedback": "Alpha 反馈",
  "beta": "Beta",
  "betaFeedback": "Beta 反馈",
  "customReleaseTrial": "试用版",
  "releaseBadgeAlpha": "Alpha",
  "releaseBadgeBeta": "Beta",
  "releaseBadgeDev": "开发",
  "releaseBadgePoc": "POC"
}

{
  "alpha": "Alfa",
  "alphaFeedback": "Feedback de la versión alfa",
  "beta": "Beta",
  "betaFeedback": "Comentarios sobre la beta",
  "customReleaseTrial": "Versión de prueba",
  "releaseBadgeAlpha": "Alpha",
  "releaseBadgeBeta": "Beta",
  "releaseBadgeDev": "Dev",
  "releaseBadgePoc": "POC"
}

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {Access, FulfillableDataItem} from '../models/Solution';
import type {Role} from '@adobe/exc-app/ims/ImsProfile';

interface ServiceItems {
  [key: string]: string[];
}

interface Services {
  featureFlags: {[key: string]: string},
  fulfillableData: ServiceItems,
  fulfillableItems: ServiceItems,
  serviceCodes: string[],
  userSubServices?: ServiceItems,
  userVisibleNames?: ServiceItems
}

/**
 * Validates that some or all (depending on `codeLogicOp`) of the service codes
 * are enabled on the user's profile.
 * @param {Object} access - Object containing access properties.
 * @param {Object} services - The services used for checking permissions.
 * @param {string[]} [services.serviceCodes] - Service codes for the user.
 * @returns {boolean} - Valid or not.
 */
const hasValidCodes = (access: Access, {serviceCodes = []}: Services): boolean => {
  const {code = [], codeLogicOp = 'OR'} = access;
  return codeLogicOp === 'OR' ?
    code.some(accessCode => serviceCodes.indexOf(accessCode) > -1) :
    code.every(accessCode => serviceCodes.indexOf(accessCode) > -1);
};

/**
 * Validates that the feature flag is enabled.
 * @param {Object} access - Object containing access properties.
 * @param {Object | Array} [access.flag] - Feature flag or array of feature flags to validate.
 * @param {Object} services - The services used for checking permissions.
 * @param {Object} [services.featureFlags] - All feature flags for user.
 * @returns {boolean} - Valid or not.
 */
const hasValidFeatureFlag = (access: Access, {featureFlags = {}}: Services): boolean => {
  const {flag = '', flagLogicOp = 'OR'} = access;
  const flags = Array.isArray(flag) ? flag : [flag];
  return flagLogicOp === 'OR' ?
    flags.some(f => featureFlags?.[f] === 'true') :
    flags.every(f => featureFlags?.[f] === 'true');
};

/**
 * Validates that some or all (depending on `fdLogicOp`) of the fulfillable
 * data are enabled on each of the items associated service code.
 * @param {Object} access - Object containing access properties.
 * @param {Object} services - The services used for checking permissions.
 * @param {Object} [services.fulfillableData] - Fulfillable data associated
 *   with service code.
 * @returns {boolean} - Valid or not.
 */
const hasValidFulfillableData = (access: Access, {fulfillableData = {}}: Services): boolean => {
  const {fd = [], fdLogicOp = 'OR'} = access;
  const checkFn = (fdConfig: FulfillableDataItem) => {
    const {code, exists = true, property, propertyDefault, value} = fdConfig;
    return (fulfillableData[code] || []).some((item: any) => {
      const propertyValue = item[property] || propertyDefault;
      const hasValue = propertyValue === value || propertyValue?.includes(value);
      return exists === hasValue;
    });
  };
  return fdLogicOp === 'OR' ? fd.some(checkFn) : fd.every(checkFn);
};

/**
 * Validates that some or all (depending on `fiLogicOp`) of the fulfillable
 * items are enabled on each of the items associated service code.
 * @param {Object} access - Object containing access properties.
 * @param {Object} services - The services used for checking permissions.
 * @param {Object} [services.fulfillableItems] - Fulfillable items associated
 *   with service code.
 * @returns {boolean} - Valid or not.
 */
const hasValidFulfillableItems = (access: Access, {fulfillableItems = {}}: Services): boolean => {
  const {fi = [], fiLogicOp = 'OR'} = access;
  const callback = (item: string) => {
    const [fiCode, fiValue] = item.split(':');
    return (fulfillableItems[fiCode] || []).indexOf(fiValue || '') > -1;
  };
  return fiLogicOp === 'OR' ? fi.some(callback) : fi.every(callback);
};

/**
 * Validates enablement against user roles
 * @param {string[]} roles - Array (list) of role strings.
 * @param {string} org - The currently selected IMS org.
 * @param {Role[]} userRoles - The current user's enabled roles.
 * @returns {boolean} - Valid or not.
 */
export const hasValidRole = (roles: string[], org: string, userRoles: Role[]): boolean =>
  userRoles.some(({named_role, organization}) => organization === org && roles.includes(named_role));

/**
 * Validates that any of the subservices are enabled on a service code.
 * @param {Object} access - Object containing access properties.
 * @param {Object} services - The services used for checking permissions.
 * @param {Object} [services.userSubServices] - Product context subservice items
 *   associated with service code.
 * @returns {boolean} - Valid or not.
 */
export const hasValidSubService = (access: Access, {userSubServices = {}}: Services): boolean => {
  const {code = [], subServiceCode = []} = access;
  const expandedCode: string[] = [...code];
  const alteredSubServiceCode: string[] = [];
  // Look through all of the subServiceCodes and parse out the service code and
  // the subServiceCode. This is because the it can take the form:
  // <serviceCode>:<subServiceCode> in addition to a normal string.
  subServiceCode.forEach((ssCode: string) => {
    if (ssCode.indexOf(':') > -1) {
      const [newCode, newSubServiceCode] = ssCode.split(':');
      expandedCode.push(newCode);
      alteredSubServiceCode.push(newSubServiceCode);
    } else {
      alteredSubServiceCode.push(ssCode);
    }
  });
  return expandedCode
    .some((codeKey: string) => (userSubServices[codeKey] || [])
      .some((subServiceKey: string) => alteredSubServiceCode.includes(subServiceKey || '')));
};

/**
 * Validates that any of the user visible names are enabled on a service code.
 * @param {Object} access - Object containing access properties.
 * @param {Object} services - The services used for checking permissions.
 * @param {Object} [services.userVisibleNames] - Product context user visible
 *   names associated with service code.
 * @returns {boolean} - Valid or not.
 */
export const hasValidVisibleName = (access: Access, {userVisibleNames = {}}: Services): boolean => {
  const {visibleName = []} = access;
  return visibleName.some(({code, name}) => userVisibleNames[code]?.some((userVisibleName: string) => name.test(userVisibleName)));
};

/* eslint-disable @typescript-eslint/ban-types */
const VALIDATION_MAP: Record<string, Function> = {
  code: hasValidCodes,
  fd: hasValidFulfillableData,
  fi: hasValidFulfillableItems,
  flag: hasValidFeatureFlag,
  subServiceCode: hasValidSubService,
  visibleName: hasValidVisibleName
};
/* eslint-enable @typescript-eslint/ban-types */

/**
 * Checks if the provided arguments are enough to identify that an organization
 * has permission to the application. Uses the `access` property from the
 * solution configuration, feature flags from launch darkly, and the service
 * codes that the user has in their product context.
 * @param {Object} access - The access solution configuration.
 * @param {Object} services - The services used for checking permissions.
 * @param {Object} [services.featureFlags] - All feature flags for user.
 * @param {Object} [services.fulfillableData] - Fulfillable data associated
 *   with service code.
 * @param {Object} [services.fulfillableItems] - Fulfillable items associated
 *   with service code.
 * @param {string[]} [services.serviceCodes] - Service codes for the user.
 * @param {Object} [services.userSubServices] - Product context subservice items
 *   associated with service code.
 * @returns {boolean} - Whether the user has permission to the application.
 */
export const hasPermission = (access: Access, services: Services): boolean => {
  const {logicOp = 'OR'} = access;
  let valid = true;

  // Loops through each of the access properties and runs their associated
  // validation methods. It exits early if certain conditions are met due to
  // the optional logic operator.
  for (const key of Object.keys(VALIDATION_MAP)) {
    // Don't validate this property if it doesn't exist in the access object or
    // is not a valid value (e.g. empty array).
    if (!(key in access) || !(access as any)[key] || !(access as any)[key].length) {
      continue;
    }
    valid = VALIDATION_MAP[key](access, services);

    // If the logic operator is OR and this property is valid, that's all that
    // is needed because OR only needs 1 truthy value.
    if (logicOp === 'OR' && valid) {
      return true;
    }

    // If the logic operator is AND and this property is invalid, that's all
    // that is needed because AND needs every value to be truthy.
    if (logicOp === 'AND' && !valid) {
      return false;
    }
  }
  return valid;
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import capabilities from './services/CapabilityConstants/capabilities';
import {getBootstrap} from './services/BootstrapService';
import {getPathWithoutTenant, hashToPath} from '@exc/url';
import {history} from '@exc/router';
import metrics from '@adobe/exc-app/metrics';
import {NOOP, runWithTimeout, wait} from '@exc/shared';
import PropTypes from 'prop-types';
import React from 'react';
import {RELEASE_TYPE} from './models/Solution';
import {runInBackground} from './utils';
import {Shell} from '@exc/shell';
import {updatePath} from '@exc/url/pathUpdate';

const postAuthPromise = import('./postAuth');
let shellHeaderResolve;

// A promise to be resolved when the shell header is loaded. Waiting for this prevents
// toasts from firing before the React Spectrum styling loads.
const shellHeaderPromise = new Promise(resolve => shellHeaderResolve = resolve);
export const waitForShellHeader = () => shellHeaderPromise;
export const MAX_WAIT_MS = 250;

export function getSelectedWorkspace(ws) {
  const {hash, search} = history.location;
  let {pathname} = history.location;
  const {origin} = window.location;
  pathname = getPathWithoutTenant(pathname);
  const {searchParams} = new URL(`${origin}${search}`);
  const extendedPath = `${pathname}${search}${hash}`;

  return getWorkspaces(ws).find(w => {
    if (/^http/.test(w.url)) {
      return false;
    }
    const selected = w.selectOn && w.selectOn.find(s => s.test(extendedPath));
    if (selected) {
      return selected;
    }
    const url = new URL(`${origin}${w.url}`);
    const {hash: wsHash, pathname: wsPath, searchParams: wsSearchParams} = url;

    if (!(new RegExp(`^${wsPath}([#/?]|$)`).test(pathname))) {
      return false;
    }
    const params = Array.from(wsSearchParams.keys());
    return hash === wsHash && params.every(key => searchParams.get(key) === wsSearchParams.get(key));
  });
}

const BACKCOMPAT_URL = {
  '/cjm': '/journey-optimizer'
};

const SELECTION_URL = {
  '/fusion/connections-page': '/fusion/[0-9]+/connections',
  '/fusion/data-stores-page': '/fusion/[0-9]+/data-stores',
  '/fusion/data-structures-page': '/fusion/[0-9]+/udts',
  '/fusion/keys-page': '/fusion/[0-9]+/keys',
  '/fusion/org-overview-page': '/fusion/organization/[0-9]+',
  '/fusion/scenario-page': '/fusion/[0-9]+/scenario',
  '/fusion/team-overview-page': '/fusion/[0-9]+/team',
  '/fusion/templates-page': '/fusion/templates',
  '/fusion/webhooks-page': '/fusion/[0-9]+/hooks',
  '/journey-optimizer/platform/components/placements': '/journey-optimizer/platform/components',
  '/journey-optimizer/platform/offers/offers': '/journey-optimizer/platform/offers'
};

export function getSelectedSideNavItemId(navMenu, config) {
  // If there is a specific selected id defined in the configuration, use that
  // without going through any of the other logic.
  if (config?.workHubSelectedId) {
    return config?.workHubSelectedId;
  }

  let pathname = getPathWithoutTenant(hashToPath());
  if (!pathname.endsWith('/')) {
    pathname = `${pathname}/`;
  }

  // Uses the BACKCOMPAT_URL object to determine if the url being matched needs
  // to be converted to the new format of the path. This will only be necessary
  // before Work Hub service has outdated paths.
  // Uses SELECTION_URL object to get all matching paths that should
  // cause left nav item to be selected.
  const itemMatch = (url, enabled = true) => {
    const key = Object.keys(BACKCOMPAT_URL).find(prefix => url.startsWith(prefix));
    const newUrl = key ? url.replace(key, BACKCOMPAT_URL[key]) : url;
    const regex = new RegExp(`^((${newUrl})|(${SELECTION_URL[newUrl]}))($|/|#|\\?)`);
    return regex.test(pathname) && enabled;
  };
  const workhubNavs = navMenu.reduce((arr, current) => {
    const items = [];
    (current.items || [current]).forEach(item => {
      if (item.selectOn) {
        item.selectOn.forEach(path => items.push({...item, url: path}));
        (item.url || item.urlTemplate) && items.push(item);
      } else {
        items.push(item);
      }
    });
    return [...arr, ...items];
  }, []);

  // Add extra paths given in selectOn attributes in capabilities
  const extraPaths = capabilities.reduce((arr, current) => (
    current.selectOn && workhubNavs.some(n => n.id === current.id) ?
      [...arr, ...current.selectOn.map(path => ({...workhubNavs.find(n => n.id === current.id), urlTemplate: path.path}))] :
      arr
  ), []);
  const allNavs = workhubNavs.concat(extraPaths);

  // This sorts nav items for matching from greatest length to shortest (by `/`).
  // The idea is to match largest paths first.
  const sortedNavMenu = [...allNavs].sort((a, b) => {
    const aLen = (a.urlTemplate || a.url).split('/').length;
    const bLen = (b.urlTemplate || b.url).split('/').length;
    if (aLen < bLen) {
      return 1;
    } else if (aLen > bLen) {
      return -1;
    }
    return 0;
  });

  for (const item of sortedNavMenu) {
    if ((item.urlTemplate || item.url) && itemMatch(item.urlTemplate || item.url, item.enabled)) {
      return item.id;
    }
  }
  return '';
}

/**
 * This function generates a list of workspaces, using BFS to identify menu
 * workspaces.
 * @param {Object[]} workspaces Array of workspaces.
 * @returns {Object[]} Array of workspaces including menu workspaces.
 */
function getWorkspaces(workspaces) {
  const queue = [];
  const menus = [];
  workspaces.forEach(w => {
    queue.push(w);
    while (queue.length) {
      const ws = queue.shift();
      menus.push(ws);
      ws.menu && ws.menu.forEach(m => queue.push(m));
    }
  });

  menus.sort((a, b) => a.url.split('/').length - b.url.split('/').length);
  menus.reverse();
  return menus;
}

export default class ShellWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.metrics = metrics.create('exc.core.Shell');
    const bootstrap = getBootstrap();
    this.xql = bootstrap.config.endpoints.xql;
    this.devMode = bootstrap.isDevMode();
    this.deferredLoadStarted = false;
    window.addEventListener('afterprint', this.afterPrint);
    window.addEventListener('beforeprint', this.beforePrint);
  }

  loadShellHeaderAsync = () => {
    if (!this.deferredLoadStarted) {
      // Wait 250ms or until the browser is idle - whichever comes first.
      // Then import shell header.
      const deferredPromise = runWithTimeout(runInBackground(true), MAX_WAIT_MS)
        .then(() => import('./imports/js-deferred'));

      this.deferredLoadStarted = true;
      // When the shell header has loaded, resolve the shell header promise.
      deferredPromise.then(shellHeaderResolve);

      Promise.all([postAuthPromise, deferredPromise]).then(([{showInitialToasts}]) => showInitialToasts({
        getComponentContext: this.props.getComponentContext,
        metrics: this.metrics
      }));

      this.setState({deferredPromise});
    }
  };

  componentDidMount() {
    this.deferredLoadStarted || wait(MAX_WAIT_MS).then(this.loadShellHeaderAsync);
  }

  onHeroClick() {
    const {customHeroClick, basePath, hero, noSolution} = this.props;
    if (noSolution) {
      updatePath({path: '/home'});
      return;
    }
    // A lot of apps have custom hero clicks that don't make sense when they are
    // part of composed apps, so if an app is composed we disable it.
    if (!hero.composed && customHeroClick) {
      customHeroClick();
      return;
    }
    updatePath({path: hero.path || basePath});
  }

  afterPrint = ev => {
    if (this.props.afterPrintHandler) {
      this.props.afterPrintHandler(ev);
    }
  };

  beforePrint = ev => {
    if (this.props.beforePrintHandler) {
      this.props.beforePrintHandler(ev);
    }
  };

  render() {
    const props = this.props;
    const {
      appId,
      digitalData,
      environment,
      featureFlags,
      feedbackConfig,
      hero,
      locale,
      networkErrorProps,
      noAccess,
      pulse,
      selectedImsOrg,
      selectedImsOrgName,
      selectedTenantId,
      serviceEnvironment,
      shellResponse,
      showDebugModal,
      sideNav: {config},
      workHubResponse
    } = props;
    this.deferredLoadStarted || (appId && this.loadShellHeaderAsync());
    const {deferredPromise} = this.state;
    const customButtons = props.customButtons || {};
    const profile = props.profile || {};
    const router = React.Children.only(props.children);
    const selectedWorkspace = getSelectedWorkspace(props.workspaces);
    const selectedSideNavItemId = workHubResponse ?
      getSelectedSideNavItemId(workHubResponse, config) :
      getSelectedSideNavItemId(config?.menu || []);

    const helpCenterConfig = {
      canAuthorize: environment === 'prod',
      customButtons,
      digitalData,
      environment,
      featureFlags,
      feedbackAttachmentsEnabled: true,
      feedbackConfig,
      imsPayload: {
        accessToken: props.accessToken,
        clientId: props.clientId,
        displayName: profile.displayName,
        email: profile.email,
        environment: serviceEnvironment,
        firstName: profile.first_name,
        lastName: profile.last_name,
        orgId: selectedImsOrg,
        orgName: selectedImsOrgName,
        userId: profile.userId
      },
      onCustomButtonClick: props.onCustomButtonClick,
      ...props.helpCenter,
      services: shellResponse.services,
      showDebugTab: props.internal && featureFlags?.['demo-quiet-mode'] === 'true',
      solutions: shellResponse.solutions,
      solutionTitle: hero.title,
      xqlEndpoint: this.xql
    };

    return (
      <Shell
        accessOverridesEnabled={props.accessOverridesEnabled}
        agreements={props.agreements}
        appContainer={props.appContainer}
        appId={props.appId}
        appRoot={props.appRoot}
        appTheme={props.appTheme}
        authId={props.authId}
        backgroundReady={props.backgroundReady}
        basePath={props.basePath}
        clientId={props.clientId}
        closeLanguagePicker={props.closeLanguagePicker}
        coachMark={props.coachMark}
        currentActiveTenantId={selectedTenantId}
        customButtons={customButtons}
        customEnvLabel={props.customEnvLabel}
        customHeroClick={props.customHeroClick}
        customProfileButtons={customButtons.userProfile}
        customSearch={props.customSearch}
        defaultComponent={props.defaultComponent}
        deferredComponentsPromise={deferredPromise}
        devMode={this.devMode}
        disableShellFeedbackButton={props.disableShellFeedbackButton}
        environment={environment}
        featureFlags={featureFlags}
        feedback={props.feedback}
        filteredSandboxes={props.filteredSandboxes}
        getCapabilityWithIconUrl={props.getCapabilityWithIconUrl}
        getLDFeatureFlags={props.getLDFeatureFlags}
        globalSearch={props.globalSearch}
        hasAccountClusterData={props.hasAccountClusterData}
        hasAssistantPermission={props.hasAssistantPermission}
        helpCenterConfig={helpCenterConfig}
        imsOrgs={props.imsOrgs}
        initialAppLoaded={props.initialAppLoaded}
        internal={props.internal}
        isImpersonating={props.isImpersonating}
        landingpage={shellResponse.landingpage}
        locale={locale}
        modal={props.modal}
        networkErrorProps={networkErrorProps}
        noAccess={noAccess}
        onCustomButtonClick={props.onCustomButtonClick}
        onCustomProfileButtonClick={props.onCustomButtonClick}
        onGlobalDialogOpen={props.onGlobalDialogOpen}
        onImsOrgChange={props.onImsOrgChange}
        onLanguageChange={props.onLanguageChange}
        onSetUserTheme={props.onSetUserTheme}
        onSignOutClick={props.onSignOut}
        onSolutionClick={this.onHeroClick.bind(this)}
        orgToSubOrgsMap={props.orgToSubOrgsMap}
        parentId={props.parentId}
        preferredLanguages={props.preferredLanguages}
        profile={profile}
        pulse={pulse}
        recentSandboxes={props.recentSandboxes}
        registerKeyHandler={(...args) => postAuthPromise.then(({eventObserver}) => eventObserver.registerKeyHandler(...args))}
        releaseType={props.releaseType}
        sandbox={props.sandbox}
        selectedImsOrg={props.selectedImsOrg}
        selectedSideNavItemId={selectedSideNavItemId}
        selectedSubOrg={props.selectedSubOrg}
        selectedWorkspaceId={selectedWorkspace && selectedWorkspace.uid}
        serviceEnvironment={props.serviceEnvironment}
        services={shellResponse.services}
        settingsData={props.settingsData}
        settingsService={props.settingsService}
        showDebugModal={showDebugModal}
        showLanguagePicker={props.showLanguagePicker}
        showToast={props.showToast}
        sideNav={props.sideNav}
        solutionIcon={hero.icon}
        solutionReleaseType={hero.releaseType}
        solutionShortTitle={hero.shortTitle}
        solutionTitle={hero.title}
        solutions={shellResponse.solutions}
        subOrgAccessOnlyOnCurrentOrg={props.subOrgAccessOnlyOnCurrentOrg}
        theme={props.theme}
        toastPlacement={props.toastPlacement}
        translationService={props.translationService}
        userAvatar={props.userAvatar}
        userRecentSandboxesHandler={props.userRecentSandboxesHandler}
        userToken={props.accessToken}
        workHubResponse={workHubResponse}
        workspaces={props.workspaces}
      >
        <React.Suspense fallback={props.defaultComponent}>{router}</React.Suspense>
      </Shell>
    );
  }
}

ShellWrapper.propTypes = {
  accessToken: PropTypes.string,
  appContainer: PropTypes.string,
  backgroundReady: PropTypes.bool,
  basePath: PropTypes.string,
  children: PropTypes.element.isRequired,
  customEnvLabel: PropTypes.array,
  customSearch: PropTypes.object,
  defaultComponent: PropTypes.object,
  devMode: PropTypes.bool,
  disableShellFeedbackButton: PropTypes.bool,
  environment: PropTypes.string,
  getLDFeatureFlags: PropTypes.func,
  globalSearch: PropTypes.shape({
    accessToken: PropTypes.string,
    enabled: PropTypes.bool,
    selectedImsOrg: PropTypes.string
  }),
  hasAccountClusterData: PropTypes.bool,
  helpCenter: PropTypes.object,
  hero: PropTypes.object,
  imsOrgs: PropTypes.array,
  initialAppLoaded: PropTypes.bool,
  locale: PropTypes.string,
  noAccess: PropTypes.bool,
  onImsOrgChange: PropTypes.func,
  onSignOut: PropTypes.func,
  profile: PropTypes.object,
  recentSandboxes: PropTypes.array,
  releaseType: PropTypes.oneOf(Object.values(RELEASE_TYPE)),
  sandbox: PropTypes.shape({
    enabled: PropTypes.bool,
    onChange: PropTypes.func,
    sandboxes: PropTypes.array,
    selected: PropTypes.object
  }),
  selectedImsOrg: PropTypes.string,
  serviceEnvironment: PropTypes.string,
  settingsData: PropTypes.object,
  settingsService: PropTypes.object,
  shellResponse: PropTypes.object,
  sideNav: PropTypes.shape({
    buttonEnabled: PropTypes.bool,
    config: PropTypes.object,
    enabled: PropTypes.bool,
    open: PropTypes.bool,
    openByHover: PropTypes.bool,
    toggle: PropTypes.func
  }),
  toastPlacement: PropTypes.string,
  userAvatar: PropTypes.string,
  userRecentSandboxesHandler: PropTypes.func,
  workspaces: PropTypes.array
};

ShellWrapper.defaultProps = {
  accessToken: null,
  appContainer: null,
  backgroundReady: false,
  customEnvLabel: [],
  customSearch: {
    enabled: false,
    onClick: NOOP,
    open: false
  },
  environment: 'dev',
  hasAccountClusterData: false,
  helpCenter: {
    featured: [],
    resources: []
  },
  hero: {
    icon: null,
    releaseType: RELEASE_TYPE.GA,
    shortTitle: '',
    title: ''
  },
  imsOrgs: [],
  initialAppLoaded: false,
  locale: 'en-US',
  noAccess: false,
  profile: {},
  releaseType: RELEASE_TYPE.GA,
  sandbox: {
    enabled: false,
    onChange: NOOP,
    sandboxes: [],
    selected: null
  },
  selectedImsOrg: null,
  sideNav: {
    buttonEnabled: false,
    config: {},
    enabled: false
  },
  solutionParent: '',
  toastPlacement: 'top center',
  toggleSideNav: NOOP,
  workspaces: []
};

{
  "alpha": "Alpha",
  "alphaFeedback": "Alpha 피드백",
  "beta": "Beta",
  "betaFeedback": "Beta 피드백",
  "customReleaseTrial": "체험판",
  "releaseBadgeAlpha": "Alpha",
  "releaseBadgeBeta": "Beta",
  "releaseBadgeDev": "개발",
  "releaseBadgePoc": "POC"
}

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, RUNTIME, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/assetsMicroFrontends.ts

const getAssetsMFEStorybook = (name: string, spaAppId: string):SolutionRoute => {
  const repoName = /^(^cq-)?(.+)/i.exec(spaAppId)?.[2];
  return {
    appId: `${repoName}-storybook`,
    spaAppId,
    path: `/assets-micro-frontends/${repoName}`,
    sandbox: {
      pathPrefix: {default: '/static-assets/storybook/index.html'},
      history: HISTORY.HISTORY,
      sources: {
        dev: 'https://localhost.corp.adobe.com:8443/',
        dev443: 'https://localhost.corp.adobe.com:443/',
        prod: ''
      }
    },
    runtime: RUNTIME.CDN,
    urlContext: {
      key: 'sandbox',
      optional: true
    }
  };
};

export const assetsMicroFrontendsReportingStorybook: SolutionRoute = getAssetsMFEStorybook(
  'Assets Micro Frontends Reporting storybook',
  'cq-assets-reporting'
);

export const assetsMicroFrontendsSelectorsStorybook: SolutionRoute = getAssetsMFEStorybook(
  'Assets Micro Frontends Selectors storybook',
  'CQ-assets-selectors'
);

export const assetsMicroFrontendsCollectionsStorybook: SolutionRoute = getAssetsMFEStorybook(
  'Assets Micro Frontends Collections storybook',
  'cq-assets-collections'
);

export const assetsMicroFrontendsAdminStorybook: SolutionRoute = getAssetsMFEStorybook(
  'Assets Micro Frontends Admin Settings storybook',
  'CQ-assets-admin'
);

export const assetsMicroFrontendsExpressEditorStorybook: SolutionRoute = getAssetsMFEStorybook(
  'Assets Micro Frontends Express Editor storybook',
  'CQ-assets-express-editor'
);

export const assetsMicroFrontendsTaxonomyStorybook: SolutionRoute = getAssetsMFEStorybook(
  'Assets Micro Frontends Taxonomy storybook',
  'cq-assets-taxonomy'
);

export const assetsMicroFrontendsAppContainerStorybook: SolutionRoute = getAssetsMFEStorybook(
  'Assets Micro Frontends App Container storybook',
  'CQ-app-container'
);

export const assetsMicroFrontendsAssetsUploadStorybook: SolutionRoute = getAssetsMFEStorybook(
  'Assets Micro Frontends Assets Upload storybook',
  'CQ-assets-upload'
);

export const assetsMicroFrontendsAssetsSampleMfeStorybook: SolutionRoute = getAssetsMFEStorybook(
  'Assets Micro Frontends Assets Sample MFE storybook',
  'CQ-assets-sample-mfe'
);

export const assetsDynamicMediaMicroFrontendsStorybook: SolutionRoute = getAssetsMFEStorybook(
  'Assets Dynamic Media Micro Frontends storybook',
  'CQ-assets-dm'
);


/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

type varArgsFn = (...args: any[]) => void;

/**
 * Debounces events.
 * @param fn Function to execute.
 * @returns Debouncing function.
 */
export const debounceRaF = (fn: varArgsFn): varArgsFn => {
  let timeout: any;
  return function (...args: string[]): void {
    if (timeout) {
      window.cancelAnimationFrame(timeout);
    }
    timeout = window.requestAnimationFrame(() => {
      fn.apply(this, args);
    });
  };
};

export {preload} from './preload';

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, PDH_GLOBAL, RUNTIME, SolutionRoute} from '@exc/core/src/models/Solution';
import type {ProductContext} from '@adobe/exc-app/ims/ImsProfile';

// This file was generated automatically from ../solutions/accwebui.ts

const getSubOrgName = (prodCtx: ProductContext): string => {
  const {tenantid} = JSON.parse(prodCtx.fulfillable_data || '{}');
  return tenantid || prodCtx.tenant_id;
};

const accwebui: SolutionRoute = {
  appId: 'accwebui',
  appContainer: '#root',
  path: '/acc',
  permissionsPolicy: ['clipboard-write'],
  spaAppId: 'campaign-acc-web-ui',
  gainsight: {productKey: 'AP-3QN514NBNJA6-2'},
  omegaSuiteId: 'cc',
  analytics: {omegaGlobal: PDH_GLOBAL.BOTH},
  runtime: RUNTIME.CDN,
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      dev: 'https://localhost.corp.adobe.com:12399'
    }
  },
  urlContext: {
    key: 'subOrg',
    config: {
      customPathKey: 'name',
      dontWaitForLoad: true,
      id: 'ident',
      name: getSubOrgName,
      orgIdName: getSubOrgName,
      serviceCode: 'dma_campaign_classic'
    }
  },
  access: {code: ['dma_campaign_classic']}
};

export default accwebui;

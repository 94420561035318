/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {APIProcessingService as APIProcessingServiceType} from './APIProcessingService';
import {AppConfigureService, OutgoingConfiguration} from './AppConfigureService';
import type {
  AppSandbox,
  AppSandboxProps,
  ContextUpdateAction,
  OutgoingMessage
} from '../models/AppSandbox';
import type {CoreContext} from '../models/Context';
import {debounce} from '../utils';
import {Events} from '@exc/shared';
import {getCustomImsData, getSpaAppConfig, hasConfigChanges, isValidCustomImsToken} from '../utils/appSandboxUtils';
import {getPathWithoutTenant, getUpdatedPath, hashToPath} from '@exc/url';
import {history as hashHistory} from '@exc/router';
import {isSkyline} from '@exc/url/skyline';
import {MessageService} from './MessageService';
import orchestrationService from './OrchestrationService';
import type {ShellRedirectOptions} from '@adobe/exc-app/page';
import {Solution} from '../models/Solution';

interface ShellRedirectInput extends ShellRedirectOptions {
  path?: string;
  url?: string;
}

export const SECONDARY_IGNORED_EVENTS = [
  Events.TITLE,
  Events.SHELL_REDIRECT
];

const configureService = new AppConfigureService();

export class APIService {
  private readonly messageService: MessageService;
  private readonly sendHistoryMessage: (message: OutgoingMessage<any>, app: AppSandbox) => void;
  private readonly apiProcessingPromise: Promise<APIProcessingServiceType>;
  private lastConfiguration?: OutgoingConfiguration;
  private firstSend = true;
  private historyUpdateInProgress = false;
  private waitForProcessing = false;

  constructor(messageService: MessageService) {
    this.messageService = messageService;
    this.apiProcessingPromise = import('../postAuth')
      .then(({APIProcessingService}) => new APIProcessingService(messageService));

    // Debounce the history message so that we can wait for additional updates
    // from the framed application so that we know we're sending to the right
    // place. Additionally, this should solve issues around navigation loops
    // because it's letting the app send all the events it needs.
    this.sendHistoryMessage = debounce(messageService.sendMessage.bind(messageService) as () => void, 100);
  }

  /**
   * Returns true if the last payload sent was the first sent by this instance.
   */
  public get isFirstSend() {
    return this.firstSend;
  }

  /**
   * Unblocks navigation (If blocked).
   */
  public async unblockNavigation() {
    (await this.apiProcessingPromise).unblockNavigation();
  }

  private getNextConfig = (props: AppSandboxProps): Solution | undefined => {
    const {hash} = window.location;
    const match = props.match(hashToPath({hash}));
    return match ? match.props?.children?.props?.config : undefined;
  };

  public unmount(app: AppSandbox) {
    const {appId, context, props} = app;
    this.unblockNavigation();
    if (!app.secondary) {
      // Secondary sandboxes (i.e. SandboxInner) should not update Unified Shell state.
      const {appId: nextAppId, sandbox, sideNav: nextSideNav} = this.getNextConfig(props) || {};
      const {ims} = sandbox || {};
      context.resetConfigStates(appId, nextAppId, nextSideNav, ims);
    }
  }

  /**
   * Process and respond to messages coming from the iframe (AppSandbox).
   * @param type - Message type
   * @param value - Message payload
   * @param app - AppSandbox object.
   */
  public async handleMessage(type: string, value: any, app: AppSandbox): Promise<void> {
    const {context} = app;

    if (app.secondary && SECONDARY_IGNORED_EVENTS.includes(type)) {
      // These events do not apply to secondary sandboxes (i.e. SandboxInner), ignore.
      return;
    }

    this.waitForProcessing && await this.apiProcessingPromise;
    switch (type) {
      case Events.DONE: {
        // Secondary sandboxes (i.e. SandboxInner) should not update Unified Shell state.
        app.secondary || context.setConfigStates({solutionLoaded: true});
        const performanceData = app.onDone(value);
        this.messageService.sendMessage({type: Events.DONE, value: performanceData}, app);
        orchestrationService.onLoaded();
        break;
      }
      case Events.READY:
        app.onReady(value);
        break;
      case Events.SHELL_REDIRECT:
        this.redirectHandler(value, app);
        break;
      case Events.TITLE:
        app.titleService?.update(value.title);
        break;
      default: {
        this.waitForProcessing = true;
        (await this.apiProcessingPromise).handleMessage(type, value, app, this.lastConfiguration);
        this.waitForProcessing = false;
      }
    }
  }

  /**
   * Sends the configuration payload to the iframe.
   * @param app - AppSandbox object.
   * @returns true if configuration was sent, false otherwise.
   */
  public async sendConfiguration(app: AppSandbox): Promise<boolean> {
    let imsData: Partial<OutgoingConfiguration> = {};
    const {customIms, metrics} = app;

    if (customIms) {
      const data = getCustomImsData(app);
      // If there is no custom ims data, don't send the configuration.
      if (!data) {
        metrics.info('sendConfiguration had no required customIms data');
        return false;
      }
      if (!isValidCustomImsToken(app)) {
        metrics.warn('sendConfiguration had invalid custom token data', {customIms});
      }
      metrics.info('sendConfiguration has valid customIms data');
      imsData = data;
    }

    this.firstSend = !this.lastConfiguration;
    const config = await configureService.getConfigurePayload(app);
    const value = {...config, ...imsData};
    const changed = configureService.getChangedConfig(value, this.lastConfiguration);
    this.lastConfiguration = value;

    configureService.setGainsight(app, config.gainsight);
    this.messageService.sendMessage(
      {changed, type: Events.CONFIGURE, value} as OutgoingMessage<OutgoingConfiguration>,
      app
    );
    (await this.apiProcessingPromise).processOpenDataRequests(app, this.lastConfiguration);
    return true;
  }

  /**
   * Process delayed incoming data messages.
   * @param app App Sandbox object
   */
  public async processEventQueue(app: AppSandbox) {
    (await this.apiProcessingPromise).processEventQueue(app);
  }

  public updateAppHistory(nextPath: string, app: AppSandbox) {
    if (!this.historyUpdateInProgress) {
      const {context: {environment}, props: {hash, querystring: query}} = app;
      const {solutionConfig: {sandbox: {pathPrefix}}} = app;
      const prefix = environment === 'dev' && pathPrefix?.dev || pathPrefix?.default || '';
      const path = prefix + (/^\//.test(nextPath) ? nextPath : `/${nextPath}`);

      this.sendHistoryMessage({
        type: Events.HISTORY,
        value: path + (query ? `?${query}` : '') + (hash ? `#${hash}` : '')
      }, app);
    }
  }

  /**
   * Determines if the IMS values have changed enough for the iframe source to
   * be updated. This is specific to org and sub-org changes.
   * @param prevContext Previous context from Core.js.
   * @param app App Sandbox object.
   * @returns True if IMS change has occurred.
   */
  private shouldTriggerFrameUpdate(prevContext: CoreContext | undefined, app: AppSandbox): boolean {
    const {context, solutionConfig: {refreshOnImsChange, urlContext}} = app;
    // If sandbox is showing an explicit error, then we should try
    // updating the iframe in hopes of resolving the issue on new discovery.
    if (app.inErrorState()) {
      return true;
    }

    if (!refreshOnImsChange) {
      return false;
    }

    const orgConfigChanged = hasConfigChanges(prevContext, context, ['imsOrg'], true);
    const subOrgConfigChanged = hasConfigChanges(prevContext, context, ['subOrg']);
    const subOrgEnabled = urlContext?.key === 'subOrg';

    // If nothing changed, don't update.
    if (!orgConfigChanged && !subOrgConfigChanged) {
      return false;
    }

    // Checks for validity of a sub-org. If the sub-org itself is valid, the id
    // is set. The default value set by Core.js sets an object without a valid
    // id property.
    const validSubOrg = !!context.subOrg?.id;

    // A valid sub-org change happens if the sub-org value has changed and is
    // valid in terms of having a valid id.
    const validSubOrgChange = subOrgConfigChanged && validSubOrg;

    // Checks for a valid org change. If the org changed happened but sub-orgs
    // are enabled, there will be an update in the future that includes the
    // updated sub-orgs, so we need to wait for that.
    if (orgConfigChanged && !subOrgEnabled) {
      return true;
    }

    // The sub org has changed to a valid value.
    return validSubOrgChange;
  }

  /**
   * After the component has updated, we may need to send certain updates to the iframe.
   */
  public onContextUpdate(prevContext: CoreContext | undefined, app: AppSandbox): ContextUpdateAction {
    const {context, customIms, solutionConfig: {spaAppId, urlContext}, source} = app;

    // Don't send a configuration update if nothing has changed, or the src has not been set
    // unless the sandbox is in an error state
    if ((!source && !app.inErrorState()) || JSON.stringify(prevContext) === JSON.stringify(context)) {
      return {generateSource: false};
    }

    // Send event if Coach Marks position updated in Core state
    hasConfigChanges(prevContext, context, ['coachMarkPosition']) && this.messageService.sendMessage({
      type: Events.COACH_MARK_POSITIONED, value: context.coachMarkPosition
    }, app);

    // Send the open/closed value of a global shell component to module runtime
    // When open, module runtime needs to hide the accessibility tree
    // i.e. Unified Nav or Global Search
    hasConfigChanges(prevContext, context, ['globalDialogOpen']) && this.messageService.sendMessage({
      type: Events.GLOBAL_DIALOG_OPEN, value: context.globalDialogOpen
    }, app);

    // If the feature flags have changed, determine if we should re-render the
    // app in case of SPA and AB testing version change.
    if (spaAppId && context.featureFlags && hasConfigChanges(prevContext, context, ['featureFlags'])) {
      const {ABTesting, liveVersion, spaName} = getSpaAppConfig(spaAppId, app);
      const srcVersion = source?.searchParams.get(`${spaName}_version`);
      // If AB testing is enabled and the version of the SPA app that was
      // previously loaded is different from the newly generated one, update
      // the state's src property to reload the app.
      if (ABTesting && srcVersion !== liveVersion) {
        return {generateSource: true};
      }
    }

    // If refreshOnImsChange is set and IMS has actually changed, refresh the
    // iframe source.
    if (this.shouldTriggerFrameUpdate(prevContext, app)) {
      const useRootPathOnChange = !!(urlContext && 'config' in urlContext && urlContext.config?.useRootPathOnChange);
      return {
        generateSource: true,
        useRootPath: useRootPathOnChange && hasConfigChanges(prevContext, context, ['subOrg'])
      };
    }

    // If the imsToken has changed but the application has customIms enabled,
    // don't bother sending the configuration because the actual token hasn't
    // changed. `imsToken` is ONLY for EXC tokens, `appToken` is what is
    // needed if customIms is set.
    const sendConfiguration = !(hasConfigChanges(prevContext, context, ['imsToken']) && customIms);

    sendConfiguration && !app.secondary && this.apiProcessingPromise
      .then(processingService => processingService.sendCustomSearchValue(app));
    return {sendConfiguration};
  }

  private redirectHandler(value: ShellRedirectInput, app: AppSandbox) {
    const {discovery, replace = true, url: urlStr} = value;
    const {solutionConfig: {hideTenant}} = app;
    let {path = ''} = value;
    // If the path is a full url, we need to ensure that it's valid, meaning
    // it's a supported hostname that is delivered by Unified Shell. It can
    // be adobeaemcloud.com or experience.adobe.com.
    if (urlStr) {
      const url = new URL(urlStr);
      // The full URL MUST be either AEM Skyline OR Unified Shell.
      if (!isSkyline(url) && !/^https:\/\/experience(-qa|-stage)?\.adobe\.com/.test(url.toString())) {
        return;
      }
      // If the origin is the same as the new url, only change the hash.
      if (url.origin === window.location.origin) {
        path = getPathWithoutTenant(url.hash.substring(1));
      } else {
        return window.open(url.toString(), '_self');
      }
    }
    discovery && (this.historyUpdateInProgress = true);
    const updatedPath = getUpdatedPath(
      hashToPath({removePrecedingSlash: true}), path, hideTenant
    );
    hashHistory[replace ? 'replace' : 'push'](updatedPath);
    this.historyUpdateInProgress = false;
    discovery && app.generateIframeSource(true);
  }

  /**
   * If the config has changed, reset state and load the new iframe source
   * This should only happen on an org change where an alternate app needs
   * to be loaded at the same route.
   */
  public resetOnNewConfig(prevProps: AppSandboxProps, app: AppSandbox): boolean {
    const {context, solutionConfig} = app;
    const {config: prevConfig} = prevProps;
    if (prevConfig !== solutionConfig) {
      const {appId, sandbox: {ims}, sideNav} = solutionConfig;
      context.resetConfigStates(prevConfig.appId, appId, sideNav, ims, true);
      return true;
    }
    return false;
  }
}

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {assetsBase} from './ddam';
import {experiencePlatformSpa} from './experiencePlatform';
import {HISTORY, PDH_GLOBAL, RUNTIME, SolutionRoute, THUNDERBIRD} from '@exc/core/src/models/Solution';
import {puebloBaseConfig} from './pueblo';

// This file was generated automatically from ../solutions/orion.ts

const access = {flag: 'project-orion-enabled'};
const sideNav = {id: 'orion', workHubId: 'ORION'};

const baseConfig = {
  access,
  analytics: {omegaGlobal: PDH_GLOBAL.BOTH},
  appRoot: 'orion',
  omegaSuiteId: 'platform',
  parent: 'ORION' as const,
  sideNav,
  urlContext: {key: 'sandbox'} as const
};

const baseOrionSpaSandbox = {
  history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
  sources: {
    proxy: 'https://local.orion.adobe.com',
    dev: 'https://local.orion.adobe.com:8888'
  }
};

export const orion: SolutionRoute = {
  ...baseConfig,
  appId: 'orion',
  path: '/orion/projects',
  redirectFrom: [{path: '/orion', options: {end: true}}],
  sandbox: baseOrionSpaSandbox,
  spaAppId: 'dms-mobile-taurus'
};

export const orionAssets: SolutionRoute = {
  ...baseConfig,
  ...assetsBase,
  appContainer: undefined,
  appId: 'orion-assets',
  metricsAppId: 'orion-assets',
  path: '/orion/assets',
  unifiedSearch: undefined
};

export const orionCampaigns: SolutionRoute = {
  ...baseConfig,
  appContainer: '#root',
  autoInstantiateRuntime: true,
  appId: 'orionCampaignsApp',
  path: '/orion/campaigns',
  spaAppId: 'ajo-mobile-ajo-campaigns-ui',
  thunderbird: THUNDERBIRD.SERVICE_WORKER,
  analytics: {omegaGlobal: PDH_GLOBAL.BOTH},
  omegaSuiteId: 'platform',
  runtime: RUNTIME.CDN,
  sandbox: {
    history: {
      type: HISTORY.HASH,
      config: {addParamsToHash: true}
    },
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  },
  access: {
    ...access,
    fi: ['acp:cjm_msg', 'acp:cjm_foundation']
  }
};

export const orionHome: SolutionRoute = {
  ...baseConfig,
  ...puebloBaseConfig,
  appContainer: '#container',
  appId: 'orion-home',
  dataPrefetchContracts: ['recents-sandbox'],
  analytics: {omegaGlobal: PDH_GLOBAL.BOTH},
  omegaSuiteId: 'platform',
  path: '/orion/home'
};

// The solution config for the Journeys Orchestration app
export const orionJourneys: SolutionRoute = {
  ...baseConfig,
  appContainer: undefined,
  appId: 'orion-journeys',
  path: '/orion/journeys',
  runtime: RUNTIME.CDN_LEGACY,
  sandbox: {
    history: HISTORY.HISTORY,
    ims: {
      client_id: 'voyager',
      scopes: 'AdobeID,openid,additional_info.projectedProductContext,read_organizations'
    },
    sources: {
      dev: 'https://localhost.corp.adobe.com/',
      qa: 'https://journeys-dev.adobe.com/',
      stage: 'https://journeys-stage.adobe.com/',
      prod: 'https://journeys.adobe.com/'
    }
  },
  access: {
    ...access,
    fi: ['acp:cjm_orch', 'acp:cjm_foundation']
  }
};

export const orionPlatform: SolutionRoute = {
  ...baseConfig,
  ...experiencePlatformSpa,
  appContainer: undefined,
  appId: 'orion-platform',
  appRoot: 'orion-home',
  omegaSuiteId: 'platform',
  parent: 'ORION' as const,
  path: '/orion/platform',
  sideNav // This is necessary because the experiencePlatformSpa will override the setting in baseConfig. This will be a problem when AEP has a sideNav.
};

export const orionSchemas: SolutionRoute = {
  ...baseConfig,
  appId: 'orion-schemas',
  path: '/orion/schemas',
  spaAppId: 'dms-mobile-taurus',
  sandbox: {
    ...baseOrionSpaSandbox,
    pathPrefix: {default: '/schemas'}
  }
};

export const orionStorybook: SolutionRoute = {
  appId: 'orion-storybook',
  path: '/orion/storybook',
  spaAppId: 'dms-mobile-taurus',
  sandbox: {
    pathPrefix: {default: '/static-assets/storybook/index.html'},
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://local.orion.adobe.com:9002',
      prod: ''
    }
  }
};

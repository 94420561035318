/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, RELEASE_TYPE, RUNTIME, SolutionRoute, THUNDERBIRD} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/advertising-ssc.ts

const advertisingssc: SolutionRoute = {
  appId: 'advertising-ssc',
  betaFeedbackConfigId: 'advertising-ssc',
  path: '/advertising/ssc',
  releaseType: RELEASE_TYPE.ALPHA,
  runtime: RUNTIME.CDN,
  spaAppId: 'AMO-ad-opt-ui',
  sandbox: {
    history: HISTORY.SERVER,
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  },
  thunderbird: THUNDERBIRD.SERVICE_WORKER,
  access: {flag: 'advertising-ssc-enabled'}
};

export default advertisingssc;

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {CustomLabelType} from '@adobe/exc-app/topbar';

/**
 * Gets display name for org switcher button based on selected orgs and sub orgs.
 * @param {string[]} imsOrgs List of IMS orgs.
 * @param {string} selectedImsOrg The current selected IMS org.
 * @param {string} selectedSubOrg The current selected sub org if existing.
 * @param {Object<String, Object[]>} orgToSubOrgsMap Maps IMS org ids to list of sub orgs.
 */
export const getOrgDisplayName = (imsOrgs = [], selectedImsOrg, selectedSubOrg, orgToSubOrgsMap = {}) => {
  if (Object.keys(orgToSubOrgsMap).length && selectedSubOrg && orgToSubOrgsMap[selectedImsOrg] && orgToSubOrgsMap[selectedImsOrg].length > 1) {
    return selectedSubOrg.name;
  }
  const imsOrgDisplayName = imsOrgs.find(({value}) => value === selectedImsOrg);
  return imsOrgDisplayName && imsOrgDisplayName.label;
};

/**
 * Takes the config value of `customEnvLabel` and filters the items.
 * The returned object uses the CustomLabelType enum for keys.
 * It returns a single CustomLabelDesc for CustomLabelType.RELEASE or undefined.
 * All other keys return an array.
 * @param {Array<CustomLabelDesc>} customEnvLabel
 * @returns {Object<CustomLabelType, CustomLabelDesc[] | CustomLabelDesc | undefined>}
 */
export const segmentCustomLabels = customEnvLabel => ({
  [CustomLabelType.ENVIRONMENT]: customEnvLabel.filter(l => l.type === CustomLabelType.ENVIRONMENT),
  [CustomLabelType.RELEASE]: customEnvLabel.find(l => l.type === CustomLabelType.RELEASE)
});

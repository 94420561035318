/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {storage} from '@exc/storage';

class UnifiedShellSessionService {
  /**
   * Internal function to get unifiedShellSession value from local storage.
   * @async
   * @returns {Object} Returns value from local storage or an empty object.
   */
  async _get() {
    return await storage.local.get('unifiedShellSession') || {};
  }

  /**
   * Gets value from unifiedShellSession stored in localStorage.
   * If an imsOrg is supplied it assumes it follows the pattern {key: {imsOrg: value}}.
   * If no parameters are provided, returns the entire unifiedShellSession value.
   * @async
   * @param {string=} key Key to be queried.
   * @param {string=} imsOrg ImsOrg to be queried within object returned by key.
   * @param {string=} userId UserId if key is stored within a particular userId.
   * @returns Returns value from local storage and undefined if no value exists.
   */
  async get(key, userId, imsOrg) {
    let localSession = await this._get();
    if (!key) {
      return localSession;
    }
    if (userId) {
      localSession = localSession[userId] || {};
    }
    if (!imsOrg) {
      return localSession[key];
    }
    return (localSession[key] || {})[imsOrg];
  }

  /**
   * Sets unifiedShellSession to localStorage.
   * @param {Object} session The full unifiedShellSession object.
   */
  async set(session) {
    await storage.local.set('unifiedShellSession', session);
  }

  /**
   * Updates key in unifiedShellSession with a provided value, optionally keyed by imsOrg.
   * @param {string} key Key to update in unifiedShellSession in local storage.
   * @param {*} value Value found in unifiedShellSession.
   * @param {string} userId UserId if key is stored within a particular userId.
   * @param {string} imsOrg If imsOrg is provided the updated value will be keyed first by key and then by imsOrg.
   * @param {boolean=} override If the new value should override the existing completely.
   */
  async update(key, value, userId, imsOrg, override) {
    let session = await this._get();
    let localSession = session;
    let updatedSession;

    if (userId) {
      localSession = session[userId] || {};
    }

    const isObject = typeof value === 'object' && !Array.isArray(value);

    const getValue = prev => {
      if (override) {
        return value;
      }
      return isObject ? {...prev, ...value} : value;
    };

    imsOrg && (updatedSession = {...localSession, [key]: {
      ...localSession[key],
      [imsOrg]: getValue((localSession[key] || {})[imsOrg])
    }});
    imsOrg || (updatedSession = {...localSession, [key]: getValue(localSession[key])});

    if (userId) {
      session[userId] = updatedSession;
    } else {
      session = updatedSession;
    }

    this.set(session);
  }

  /**
   * Removes key from unifiedShellSession.
   * @param {string} key Key to be removed from unifiedShellSession.
   * @param {string} userId UserId if key is stored within a particular userId.
   * @param {string} imsOrg If imsOrg is provided the value to delete will be keyed first by key and then by imsOrg.
   */
  async remove(key, userId, imsOrg) {
    let localSession = await this._get();

    if (userId) {
      let userLocalSession = localSession[userId] || {};
      userLocalSession = this._remove(userLocalSession, key, imsOrg);
      localSession[userId] = userLocalSession;
    } else {
      localSession = this._remove(localSession, key, imsOrg);
    }

    this.set(localSession);
  }

  _remove(session, key, imsOrg) {
    if (imsOrg) {
      delete (session[key] || {})[imsOrg];
    } else {
      delete session[key];
    }
    return session;
  }
}

export default new UnifiedShellSessionService;

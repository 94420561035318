/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, RUNTIME, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/adobepassunifiedshellconsoleclient.ts

const adobePassUnifiedShellConsoleClient: SolutionRoute = {
  access: {
    code: ['dma_primetime'],
    flag: 'pass-authentication-enabled',
    logicOp: 'AND'
  },
  appId: 'adobepassunifiedshellconsoleclient',
  experienceLeague: {
    communities: 'adobe-primetime',
    filter: 'Pass'
  },
  permissionsPolicy: ['clipboard-write'],
  path: '/pass/authentication',
  spaAppId: 'AdobePass-adobepass-unifiedshell-console-client',
  serviceCode: 'dma_primetime',
  runtime: RUNTIME.CDN,
  sandbox: {
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  }
};

export default adobePassUnifiedShellConsoleClient;

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {getErrorMessage} from '@exc/shared';
import {getLocalCache} from '@exc/storage';
import {getNavigationsData} from '@exc/graphql/src/queries/workhub';
import {getQueryValue} from '@exc/url/query';
import metrics, {Level} from '@adobe/exc-app/metrics';
import type {NavigationsDataVariables, WorkHubItem} from '@exc/graphql/src/models/workhub';
import type {
  PreReleaseConfigsCache,
  WorkHubExtended,
  WorkHubItemExtended,
  WorkHubMetricsOutput,
  WorkHubOptions,
  WorkHubResponse
} from '../models/WorkHub';
import {RELEASE_SCOPE, RELEASE_TYPE, Solution} from '../models/Solution';
import {staleWhileRevalidate} from '../utils';

const STORAGE_KEY = 'unifiedShellWorkHub';
const EXPIRY = 24 * 60 * 60 * 1000;
const localCache = getLocalCache<WorkHubExtended|PreReleaseConfigsCache>(STORAGE_KEY);

export default class WorkHubService {
  private readonly promises: Record<string, Promise<WorkHubExtended|undefined>> = {};
  private readonly metrics = metrics.create('exc.core.services.WorkHubService');
  private readonly fetched = new Set;
  private appConfigs: Solution[];

  constructor(appConfigs: Solution[] = []) {
    this.appConfigs = appConfigs;
  }

  /**
   * This method utilizes the appConfigs passed in at contruction time and reduces
   * the data to a hashmap of pre-release appConfig paths and release types.
   * This hashmap is cached with a key that pins it to the SPA release version.
   * @returns A hashmap of pre-release appConfig paths
   * @private
   */
  private async getPrereleasePaths(): Promise<Record<string, RELEASE_TYPE>> {
    const key = `preReleasePaths-${window.config?.spaVersion}`;
    const cached = await localCache.get(key) as PreReleaseConfigsCache;
    if (cached) { // If yes, use them
      return cached.items;
    }

    // Filter out GA apps and recreate a hashmap of appConfig URLs and release types
    const configs = this.appConfigs.reduce((acc, app) => {
      if (app.releaseType && app.releaseType !== RELEASE_TYPE.GA && (!app.releaseScope || app.releaseScope === RELEASE_SCOPE.SELF)) {
        const paths = [app.path, ...(app.redirectFrom?.map(r => r.path) || [])];
        paths.forEach(path => acc[path] = app.releaseType as RELEASE_TYPE);
      }
      return acc;
    }, {} as Record<string, RELEASE_TYPE>);

    // Store the preReleaseConfigs
    const fulfilled = Date.now();
    const expires = fulfilled + EXPIRY;
    localCache.set(key, {expires, fulfilled, items: configs});
    return configs;
  }

  /**
   * Returns an existing response for a Work Hub request.
   * Looks at the cache first, and then at pending requests.
   * @param {string} key - unique key (imsOrgId:locale:product)
   * @returns The existing response if available.
   * @private
   */
  private async getExistingResponse(key: string): Promise<WorkHubResponse|undefined> {
    try {
      // Look for existing result (or pending result) in memory
      const existingPromise = this.promises[key];

      // Look for cached result. Note localCache already verifies expiry.
      let response = await localCache.get(key);

      // If no cached, use a pending response if we have one.
      if (existingPromise && !response) {
        response = await existingPromise;
      }

      if (response && Date.now() < response.expires) {
        return response as WorkHubResponse;
      }
    } catch (err) {
      this.metrics.warn('Failed to get Work Hub response from cache or memory', {err});
    }
  }

  /**
   * Gets Work Hub response from cache or fetch
   * @param options - parameters to send to Work Hub
   * @returns The augmented Work Hub response
   */
  async getWorkHub(options: WorkHubOptions): Promise<WorkHubResponse|undefined> {
    const {capability, imsOrgId, locale, sandbox} = options;

    // Prevent storing data with null id
    if (!imsOrgId) {
      return;
    }

    const key = `${imsOrgId}:${sandbox}:${locale}:${capability}`;
    const response = await this.getExistingResponse(key);

    // Don't need to go through the staleWhileRevalidate workflow every single
    // time. It's more of a once per page load kind of experience. If the
    // key has been fetched this session, return the cached response.
    if (this.fetched.has(key) && response) {
      this.metrics.event('Core.workHubCachedResponse', this.getMetricsOutput(response.items), {level: Level.INFO});
      return response;
    }

    // A request must be made to get the latest. Add the key to the fetched set
    // so subsequent requests do not make additional requests this load.
    this.fetched.add(key);

    const getWorkHubPromise = (): Promise<WorkHubExtended|undefined> => {
      const workHubPromise = Promise.all([this.getPrereleasePaths(), this.fetchWorkhub({capability, locale})])
        .then(resps => this.mergeAppData(resps))
        .then(workHubResponse => this.onComplete(key, workHubResponse))
        .catch(error => {
          !!response && this.onError(key, error);
          return undefined;
        })
        .finally(() => delete this.promises[key]);
      this.promises[key] = workHubPromise;
      return workHubPromise;
    };

    // If we have no response, wait for this promise.
    // Otherwise, it used for a future request (stale-while-revalidate)
    return staleWhileRevalidate<WorkHubResponse|undefined>(getWorkHubPromise, response);
  }

  /**
   * Updates Work Hub response with releaseType information
   * @param param0 - preReleasePaths and workHubResponse
   * @returns The updated response
   */
  private mergeAppData([preReleasePaths, workHubResponse]: [Record<string, RELEASE_TYPE>, WorkHubItem[]]): WorkHubItemExtended[] {
    return workHubResponse.map(item => ({...item, releaseType: preReleasePaths[item.urlTemplate]} as WorkHubItemExtended));
  }

  /**
   * Fetches Work Hub response from service
   * @param options - parameters to send to Work Hub
   * @returns A promise with the Work Hub response
   */
  private async fetchWorkhub({capability, locale}: WorkHubOptions): Promise<WorkHubItem[]> {
    let variables: NavigationsDataVariables = {capability, locale};
    const workhubVersion = getQueryValue('workHubVersion');
    if (workhubVersion) {
      variables = {...variables, workhubVersion};
    }
    const response = await getNavigationsData(variables);
    if (!(response.getNavigationsData && Array.isArray(response.getNavigationsData))) {
      throw new Error('Invalid Workhub data returned');
    }

    return response.getNavigationsData;
  }

  /**
   * Caches a Work Hub response after its complete.
   * @param {string} key - Work Hub key (imsOrgId:locale:product)
   * @param items - Work Hub items from response
   * @returns Cacheable Work Hub response with extended data
   * @private
   */
  private onComplete(key: string, items: WorkHubItemExtended[]): WorkHubExtended {
    const fulfilled = Date.now();
    const expires = fulfilled + EXPIRY;
    const cacheResponse: WorkHubExtended = {expires, fulfilled, items};
    this.metrics.event('Core.workHubFetchSuccess', this.getMetricsOutput(items), {level: Level.INFO});
    this.metrics.event('WorkHub.cacheSet', {expires, key});
    localCache.set(key, cacheResponse);
    return cacheResponse;
  }

  /**
   * Logs an error for background, non-blocking Work Hub failures.
   * @param {string} key
   * @param {Error} error - Error information
   * @private
   */
  private onError(key: string, error: Error): void {
    // The request failed for this key, so don't cache that response in the
    // fetched set. Now subsequent requests will re-fetch.
    this.fetched.delete(key);
    const message = getErrorMessage(error);
    this.metrics.event('WorkHub.backgroundError', {key, message}, {level: Level.ERROR});
  }

  /**
   * Prepares data to be sent to metrics.
   * @param items Response data.
   * @returns Data to be sent to metrics.
   */
  private getMetricsOutput(items: WorkHubItem[]): WorkHubMetricsOutput {
    const getData = ({displayName, id}: WorkHubItem): Record<string, string> => ({displayName, id});
    return {
      count: items.length,
      disabled: items.filter(item => !item.enabled).map(getData),
      enabled: items.filter(item => !!item.enabled).map(getData),
      items: items.map(item => item.displayName) // This is to keep backcompat if it was in-use.
    };
  }
}

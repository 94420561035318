/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {AppController} from '@exc/shared';
import type {Application} from '@adobe/exc-app/metrics/Application';
import type {AppSandbox} from '../models/AppSandbox';
import {COMPONENT} from '@adobe/exc-app/component';
import {ContextWaitingState} from '../enums';
import type {CoreContext} from '../models/Context';
import {deepEqual, getHistoryType} from './index';
import {digitalDataUpdated, extractTokenMetadata} from '@exc/shared';
import type {DiscoverySource} from '../models/Discovery';
import {getBootstrap} from '../services/BootstrapService';
import {getConfiguration} from './config';
import {getContextFromPath, getPathWithoutContext, getTenantAndPath, hashToPath} from '@exc/url';
import {getImpl} from '@adobe/exc-app/src/Global';
import {getQueryValue} from '@exc/url/query';
import {HASH_TYPE, HISTORY, RELEASE_TYPE, RUNTIME, SolutionRoute} from '../models/Solution';
import type {ImsExtendedAccount, ImsExtendedOrgData} from '@exc/graphql/src/models/auth';
import type {ImsProfile, ProjectedProductContext} from '@adobe/exc-app/ims/ImsProfile';
import {Internal} from '@adobe/exc-app/internal';
import {isSkyline} from '@exc/url/skyline';
import {Level} from '@adobe/exc-app/metrics/Level';
import type MetricsConfiguration from '@adobe/exc-app/metrics/Configuration';
import type {RuntimeConfiguration} from '@adobe/exc-app/RuntimeConfiguration';
import {SPA_ROOT, THUNDERBIRD_SPA_ROOT} from '../constants';
import type {SPAConfig, UnifiedShellConfig} from '../models/UnifiedShellConfig';
import {storage} from '@exc/storage';
import {THUNDERBIRD} from '@adobe/exc-app/page';

// AEM proxies Content server at /ui - The proxy is used to serve Unified Shell
// or any SPA Pipeline HTML.
const AEM_BASE_PATH = '/ui';

/**
 * Returns Metrics (EIM) config for a given app
 * @param app - App Sandbox object.
 * @returns Metrics Configuration
 */
export const getMetricsConfig = async (app: AppSandbox): Promise<MetricsConfiguration> => {
  const {environment, metricsEnv} = app.context;
  return {
    ...await Internal.configureMetrics(),
    application: {id: app.metricsAppId, parent: app.solutionConfig.parent},
    environment: metricsEnv || environment
  };
};

/**
 * Prefetch data needed for the current application.
 * @param app - App Sandbox object.
 */
export const prefetchOnLoad = async (app: AppSandbox) => {
  const {appId, context, solutionConfig: {dataPrefetchContracts}} = app;
  const {imsOrg} = context;

  if (dataPrefetchContracts?.length && imsOrg) {
    const dataPrefetchService = context.dataPrefetchService || (await import('../postAuth')).dataPrefetchService;
    app.connected || dataPrefetchContracts.forEach(key => {
      if (!dataPrefetchService.isContextMissing(key, context)) {
        dataPrefetchService.getData(key, context, appId);
      }
    });
  }
};

/**
 * Returns the current environment (qa/stage/prod).
 * @param context - Core Context object
 * @param solutionConfig - Solution Config
 */
export const getAppEnvironment = (context: CoreContext, solutionConfig: SolutionRoute): string => {
  const source = getQueryValue('source');
  const qaSources = getQueryValue('source_qa')?.split(',') || [];
  const {appId, parent} = solutionConfig;
  const qaSource = (qaSources.includes(appId) || (parent && qaSources.includes(parent))) ? 'qa' : null;
  return source || qaSource || context.environment;
};

/**
 * Checks if the current application environment is different from the current
 * Shell environment.
 * @param env - Application environment which may be different from Shell environment.
 * @param context - Core Context object.
 */
export const isDifferentAppEnvironment = (env: string, context: CoreContext): boolean =>
  env !== context.environment && ['dev', 'qa', 'stage', 'prod'].includes(env);

/**
 * Gets the source environment being loaded.
 * 1. Uses `metricsEnv` first to see if it's set to `dev` since it uses the
 *    `shell_devmode` query param and `devmodeEnabled` property in the
 *    `unifiedShellConfig` development local storage config.
 * 2. Uses `shell_source` query param
 * 3. Uses the Shell's environment
 * @returns The source environment.
 */
export const getSourceEnvironment = (context: CoreContext, solutionConfig: SolutionRoute) =>
  context.metricsEnv === 'dev' ? 'dev' : getAppEnvironment(context, solutionConfig);

/**
 * Checks if the org exists within the current ims profile.
 * @param accounts - ims account cluster
 * @param imsOrg - IMS Org
 * @param imsProfile - IMS Profile
 * @returns Returns true if the org is found in the current account
 */
export const isOrgInAccount = (accounts?: ImsExtendedAccount[], imsOrg?: string, imsProfile?: ImsProfile) => {
  const {userId, projectedProductContext = []} = imsProfile || {};
  // In order for check to proceed we need the imsOrg and either the account cluster or the profile
  if (!imsOrg || !(accounts || imsProfile)) {
    return false;
  }
  if (accounts) {
    const {owningOrg, orgs: accountOrgs = []} = accounts.find(acct => acct.userId === userId) || {};
    const orgs = (owningOrg ? accountOrgs.concat([owningOrg]) : accountOrgs);
    return orgs.some((o: ImsExtendedOrgData) => o.imsOrgId === imsOrg);
  }
  // Fallback to the profile check, if it is available before accounts become ready
  return projectedProductContext.some(
    ({prodCtx}: ProjectedProductContext) => prodCtx.owningEntity === imsOrg
  );
};

/**
 * Returns true if quiet toast mode is enabled.
 * @param app - App Sandbox object
 */
export const getQuietToastEnabled = async (app: AppSandbox): Promise<boolean> => {
  const {featureFlags, internal} = app.context;
  return internal &&
    !app.secondary &&
    ['cjm-platform', 'experiencePlatformUI'].includes(app.solutionConfig.appId) &&
    featureFlags?.['demo-quiet-mode'] === 'true' &&
    (await storage.local.get('shellQuietToastEnabled') || false);
};

/**
 * Constructs baseUrl using basePath.
 * @param basePath The base path of the url.
 * @returns The full base url.
 */
export const setBaseUrl = (basePath: string): string => {
  const baseUrl = new URL(window.location.origin);
  isSkyline() && (baseUrl.pathname = AEM_BASE_PATH);
  baseUrl.hash = basePath;
  baseUrl.search = window.location.search;
  return baseUrl.toString().replace(/\/$/, '');
};

/**
 * Returns SPA Config object
 * @param spaAppId - SPA Pipeline App ID
 */
export const getSpaApp = (spaAppId: string): SPAConfig | undefined => ((window.config as UnifiedShellConfig)?.solutions || {})[spaAppId];

export const getAnalyticsSolution = ({analytics = {}, appId, omegaSuiteId = appId}: SolutionRoute): string | undefined => {
  // Trigger gainsight callback when it's available with the appId of the
  // application that is loading.
  if ('omegaGlobal' in analytics) {
    return omegaSuiteId;
  }
  if ('code' in analytics) {
    return analytics.code;
  }
};

export const getDiscoverySource = (app: AppSandbox): DiscoverySource | undefined => {
  const {context, solutionConfig: {sandbox}} = app;
  const env = getAppEnvironment(context, app.solutionConfig) || '';
  const srcEnv = sandbox.sources[env];
  return (srcEnv && typeof srcEnv === 'object') ? srcEnv : undefined;
};

/**
 * Configure Application data for the Metrics SDK (EIM).
 * @param id - Application ID
 * @param solutionConfig - Solution Config
 * @param spaApp - Spa Pipeline Config
 * @param component - Component ID
 */
export const setMetricsApplication = (id: string, solutionConfig: SolutionRoute, spaApp?: SPAConfig, component?: COMPONENT) => {
  const {releaseType = RELEASE_TYPE.GA, parent} = solutionConfig;
  // Set the application in metrics
  const solution = {id, parent, releaseType, version: spaApp?.liveVersion};
  const application = component ? {component, solution} : {solution};
  Internal.setApplication(application as Application);
};

const replaceLegacyCDN = (url: string) => url
  .replace('cdn.', 'exc-unifiedcontent.')
  .replace('experience-qa.adobe.net', 'experience-qa.adobe.com');

/**
 * Returns runtime.js param (_mr) to use for the current application
 * @param app - App Sandbox object.
 */
export const getRuntimeParam = (app: AppSandbox): string => {
  const bootstrap = getBootstrap();
  const {cdn, environment} = bootstrap.config;
  const {solutionConfig: {runtime, spaAppId}} = app;
  // The older version of runtime loader prohibits using the AWS CDN,
  // therefore all SPAs must be routed to the AFD CDN unless they indicate
  // that they've updated the loader script via the config by using RUNTIME.CDN
  const runtimeScript = document.getElementById('runtimeScript');
  const runtimeUrl = runtimeScript?.getAttribute('data-src');
  if (!runtimeUrl) {
    throw new Error('Missing Module Runtime src');
  }
  if (runtime === RUNTIME.CDN) {
    // Use the new _mr secure format.
    const envPrefix = ['stage', 'prod'].includes(environment) ? environment.substring(0, 1) : 'q';
    const cdnProvider = cdn.includes('cdn.') ? 'cf' : 'fd';
    const runtimeFile = runtimeUrl.split('/').pop()?.replace('.js', '');
    return `${envPrefix}_${cdnProvider}_${runtimeFile}`;
  }

  const runtimeOrigin = !!spaAppId || runtime === RUNTIME.CDN_LEGACY ? replaceLegacyCDN(cdn) : origin;
  return new URL(runtimeUrl, runtimeOrigin).toString();
};

/**
 * Initializes App Sandbox:
 * 1. Updates Core with the current config
 * 2. Initializes Metrics
 * 3. Prefetch data if needed.
 * 4. Add dev util (On non-prod)
 * @param app - App Sandbox object.
 */
export const initSandbox = (app: AppSandbox): void => {
  const {context, metricsAppId, solutionConfig} = app;
  const {environment} = context;
  const {exportName = 'application'} = solutionConfig;
  if (!app.secondary) {
    // Secondary sandboxes (i.e. SandboxInner) should not update Unified Shell and EIM state.
    context.setSolutionConfig(solutionConfig);
    setMetricsApplication(metricsAppId, solutionConfig);
  }
  prefetchOnLoad(app);

  // Development mode configuration config function on the window which allows
  // developers to get the configuration for the current application, so they
  // don't have to generate it themselves.
  ['qa', 'stage'].includes(environment) && ((window as any).shellDev = {getConfig: () => {
    const wrapper = {devmodeEnabled: true, solutions: {[exportName]: solutionConfig}};
    // eslint-disable-next-line no-console
    console.log(`window.localStorage.setItem('unifiedShellConfig', JSON.stringify(${JSON.stringify(wrapper, null, 2)}));`);
  }});
};

export const hasConfigChanges = (
  prevContext: CoreContext | undefined,
  context: CoreContext,
  keys: (keyof CoreContext)[], ignorePreviousEmpty = false
): boolean =>
  !!prevContext && keys.some(key => (!ignorePreviousEmpty || prevContext[key] !== undefined) && !deepEqual(prevContext[key], context[key]));

/**
 * Gets the config for the SPA app by id. It uses the window.config.solutions
 * object to get the base config and uses feature flags to get a specific
 * version, if available.
 * @param spaAppId The AppId of the SPA-Pipeline SPA.
 * @param context - Core Context object.
 * @param metrics - App Sandbox metrics object.
 * @returns App config if set, undefined otherwise.
 */
export const getSpaAppConfig = (spaAppId: string, {context, metrics}: AppSandbox): Partial<SPAConfig> & {spaName: string} => {
  const {featureFlags = {}} = context;
  const featureKey = `${spaAppId}_controlrollout`;
  const spaApp: Partial<SPAConfig> & {spaName: string} = {...(getSpaApp(spaAppId) || {}), spaName: spaAppId};

  // If the AB testing flag isn't set, the feature flag isn't available for
  // the current spaApp, or the flag exists but is set to the LIVEVERSION
  // string, use the configuration provided by the content server.
  if (!spaApp.ABTesting || !(featureKey in featureFlags) || featureFlags[featureKey] === 'LIVEVERSION') {
    return spaApp;
  }

  // Use the liveversion from the feature flag.
  const configVersion = spaApp.liveVersion;
  spaApp.liveVersion = featureFlags[featureKey];
  metrics.info(`${spaAppId} is using AB testing`, {
    configVersion,
    liveVersion: spaApp.liveVersion,
    spaAppId
  });
  return spaApp;
};

export const getBasePath = (app: AppSandbox): string => {
  const {props: {params, path}, solutionConfig} = app;
  const context = getContextFromPath(path);
  const {path: justPath = '', tenant} = getTenantAndPath(getPathWithoutContext(path));
  let basePath = justPath.replace(params[0] || '', '')
    .replace(/\/$/, '') // Remove trailing slash
    .replace(/\/{2,}/, '/'); // Remove any double slashes
  // Ensure correct context is on the basepath
  if (context) {
    basePath = `${context}${basePath}`;
  }
  // Ensure correct base path if switching between tenanted and tenantless
  if (tenant && !solutionConfig.hideTenant) {
    basePath = `/@${tenant}${basePath}`;
  }
  // Secondary sandboxes (i.e. SandboxInner) should not update Unified Shell state.
  app.secondary || app.context.setConfigStates({basePath});
  return basePath;
};

/**
 * Determines whether we should wait for context updates to load the iframe.
 * This happens in the following cases:
 *  1. We want to delay discovery until we know that the org is permissioned on the solution.
 *  2. We want to delay discovery until we have all the url context information.
 *  3. IMS profile is required for discovery calls
 *  4. Access flag is defined in solution config, so feature flags are necessary
 *  5. SPA apps with AB testing enabled
 */
export const shouldWaitForContextUpdates = (app: AppSandbox, src?: string | DiscoverySource): ContextWaitingState | false => {
  const {
    context: {discoveryService, featureFlags, imsProfile, orgPermissioned, subOrg},
    solutionConfig: {access: {flag} = {}, spaAppId, urlContext}
  } = app;

  if (src) {
    const discoveryUrl = typeof src === 'object';
    const urlNeedsImsInfo = discoveryUrl || !!src.match(/{[A-Za-z]+}/g);
    if (discoveryUrl) {
      if (!orgPermissioned) {
        return ContextWaitingState.WAIT_FOR_PERMISSION;
      }
      if (!discoveryService?.canGeneratePayload(src, app)) {
        return ContextWaitingState.WAIT_FOR_DISCOVERY;
      }
    }
    if (urlNeedsImsInfo && !imsProfile) {
      return ContextWaitingState.WAIT_FOR_PROFILE;
    }
  }

  if (flag && !featureFlags ||
    (spaAppId && getSpaApp(spaAppId)?.ABTesting && !featureFlags)) {
    return ContextWaitingState.WAIT_FOR_FLAGS;
  }

  // Stateless SPAs can start loading their iframe before sub org information is available.
  // For those SPAs "dontWaitForLoad" is true, Sandbox will instead wait for subOrg data
  // inside the "shouldSendConfiguration" method.
  if (urlContext?.key === 'subOrg' && !urlContext.config.dontWaitForLoad && !subOrg) {
    return imsProfile ? ContextWaitingState.WAIT_FOR_SUBORG : ContextWaitingState.WAIT_FOR_PROFILE;
  }

  return false;
};

/**
 * Method to log shell info mismatches for EXC-34406. Can be removed after the ticket is resolved.
 */
export const isShellInfoForIncorrectOrg = (app: AppSandbox) => {
  const {context, metrics} = app;
  const {shellInfo, imsOrg} = context;
  // If the org is different from what shellInfo was generated with, log to metrics.
  if ((shellInfo?.orgId || imsOrg) && shellInfo?.orgId !== imsOrg) {
    app.shellInfoIncorrect = true;
    return metrics.warn('ShellInfo: mismatch', {
      imsOrg: imsOrg ?? 'n/a',
      shellInfoOrgId: shellInfo?.orgId ?? 'n/a'
    });
  }
  // If a mismatch has been previously recorded and it has been fixed, log to metrics.
  if (app.shellInfoIncorrect) {
    app.shellInfoIncorrect = false;
    return metrics.info('ShellInfo: Fixed to be generated for correct org', {imsOrg: shellInfo?.orgId});
  }
};

/**
 * Gets the application source for the current environment - URL (String) or Discovery (Object)
 * @param app - App Sandbox object
 */
export const getApplicationSourceUrl = (app: AppSandbox) => {
  const {
    context,
    historyType,
    metrics,
    solutionConfig: {
      appId,
      sandbox: {
        pathPrefix: {default: prefix, dev: devPrefix, preHash} = {default: ''},
        sources
      },
      spaAppId,
      thunderbird = THUNDERBIRD.OFF
    }
  } = app;
  const {config: {cdn, iframeUseSameOrigin}} = getBootstrap();
  const env = getAppEnvironment(context, app.solutionConfig);
  let srcEnv = sources[env];
  const isHash = historyType === HISTORY.HASH;

  // If SpaAppId exists, set the config URL for the respective environment
  if (env && spaAppId && srcEnv === undefined) {
    const sameOrigin = iframeUseSameOrigin || thunderbird !== THUNDERBIRD.OFF;
    let spaOrigin = sameOrigin ? origin : cdn;
    let spaPath = thunderbird === THUNDERBIRD.SERVICE_WORKER ? THUNDERBIRD_SPA_ROOT : SPA_ROOT;
    // For skyline applications, we need to use the skyline path and window
    // origin if thunderbird is also enabled.
    if (thunderbird !== THUNDERBIRD.OFF && isSkyline()) {
      spaPath = `${AEM_BASE_PATH}${spaPath}`;
    } else if (isDifferentAppEnvironment(env, context)) {
      // If the env is different from the current Shell environment, we shouldn't
      // be using the origin or cdn as they are the wrong environment. Grab the
      // correct value from the configuration.
      spaOrigin = getConfiguration({environment: env}, 'none').endpoints.cdn;
    }
    srcEnv = `${spaOrigin}${spaPath}/${spaAppId}`;
    // Only add prefix if it's set. Not doing this can lead to an extra # at the
    // end of the srcEnv if no prefix exists.
    prefix && (srcEnv = `${srcEnv}${isHash && !preHash ? '#' : ''}${prefix}`);
  } else if (srcEnv && spaAppId && (devPrefix || prefix) && env === 'dev') {
    // If env is dev, append dev path prefix if it exists, even if it's an empty string.
    // Setting dev path prefix to '' will tell the framed application to ignore default path prefix.
    // Else, append default path prefix if it exists.
    srcEnv = `${srcEnv}${isHash && !preHash ? '#' : ''}${devPrefix ?? prefix}`;
  }

  if (!env || !srcEnv) {
    metrics.error(`No matching configuration source for env ${env} in app ${appId}`);
    return;
  }
  return srcEnv;
};

export const updateSourceWithParams = (src: URL, forceReload: boolean, app: AppSandbox): URL => {
  const {props, solutionConfig} = app;
  const {decodeUrl, sandbox: {history}} = solutionConfig;
  const historyType = getHistoryType(solutionConfig);
  const {origin} = window.location;
  const {absolutePaths = false, addParamsToHash, hashType} = (typeof history === 'object' && history.config) || {};
  const isHashType = historyType === HISTORY.HASH;
  const pathType = isHashType ? 'hash' : 'pathname';
  const updatedSrc = new URL(src.toString());
  let urlHashAsPath = hashToPath();
  // Append the correct path from the outer frame (useful for deep linking)
  // This should correct to the "right" url (e.g. the page that is initially
  // loaded) if our src is just the base (eg '/').
  const params = props.params[0] || '';
  if (params) {
    let path = hashType === HASH_TYPE.NOSLASH ? params : `/${params}`;
    // In some cases, like analytics, encoded URLs (for example links pasted
    // into slack) can break when loading in the iframe, so we clean them up
    if (!isHashType && decodeUrl) {
      path = path.replace(/%23/, '#').split('#')[0];
      urlHashAsPath = urlHashAsPath.replace(/%23/, '#');
    }

    // Replacing any duplicate slashes (e.g. //something) with a single slash
    // so that the URL is not broken.
    updatedSrc[pathType] = `${absolutePaths ? '' : src[pathType]}${path}`
      .replace(/([/]+)/g, '/');
  }

  const url = new URL(`${origin}${urlHashAsPath}`);

  // If the history type isn't hash, the hash should be set.
  if (!isHashType) {
    updatedSrc.hash = url.hash;
  }

  // Append the search parameters to the hash if the solution config property
  // is set.
  if (isHashType && addParamsToHash) {
    updatedSrc.hash += url.search;
  }

  forceReload && updatedSrc.searchParams.set('shell_forceReload', Date.now().toString());

  return updatedSrc;
};

export const updateTemplatedURL = (urlString: string, app: AppSandbox, extraParams: Record<string, string> = {}): string => {
  // Skyline url strings are allowed to be paths since the discovery call is
  // being made to the same origin that the user is already on.
  const {context, metrics, props: {params}} = app;
  let targetUrl = urlString;
  if (isSkyline() && targetUrl.startsWith('/')) {
    targetUrl = `${window.location.origin}${targetUrl}`;
  }
  const templateStrings = targetUrl.match(/{[A-Za-z0-1]+}/g);
  const url = new URL(window.location.href);
  let securityTest = targetUrl;
  if (templateStrings) {
    templateStrings.forEach(string => {
      const contextString = string.substring(1, string.length - 1);
      const replacement =
        contextString in context && context[contextString as keyof CoreContext] as string ||
        url.searchParams.get(contextString) || params[contextString] || extraParams[contextString];
      if (replacement) {
        targetUrl = targetUrl.replace(string, replacement);
        // Build a url for later host comparison
        securityTest = securityTest.replace(string, 'test');
      }
    });
    const realUrl = new URL(targetUrl);

    // If we have the full URL, and it is an adobe domain, skip checks and return the url.
    if (realUrl.protocol === 'https:' && realUrl.origin.endsWith('adobe.com')) {
      return targetUrl;
    }

    if (!securityTest.startsWith('https:')) {
      // Non adobe.com are not allowed through templates.
      metrics.event('Sandbox.nonAdobeTemplateDetected', {url: realUrl.toString()}, {level: Level.ERROR});
      throw Error('Forbidden URL template detected');
    }

    const realUrlParts = realUrl.host.split('.');

    const testUrl = new URL(securityTest).host.split('.');
    if (realUrlParts[realUrlParts.length - 1] !== testUrl[realUrlParts.length - 1] ||
      realUrlParts[realUrlParts.length - 2] !== testUrl[realUrlParts.length - 2]) {
      // The discovery template is being missused, possibly to direct a request with
      // a working token header to a malicious domain.
      metrics.event('Sandbox.urlInconsistencyDetected', {test: testUrl.toString(), url: realUrl.toString()}, {level: Level.ERROR});
      throw Error('URL inconsistency detected');
    }
  }
  return targetUrl;
};

/**
 * Returns true if the app is alive and well.
 * @param app - App Sandbox object.
 */
export const isAppHealthy = (app: AppSandbox) => app.mounted && !app.inErrorState() && !app.inNotFoundState();

export const updateDigitalData = ({secondary, solutionConfig}: AppSandbox, version = ''): void => {
  if (secondary) {
    // Only primary sandboxes should update digital data.
    return;
  }
  // Builds the digital data object in case it doesn't exist yet.
  const digitalData: Record<string, any> = window.digitalData = window.digitalData || {};
  let ddObj = digitalData;
  ['page', 'solution'].forEach((key: string) => {
    ddObj[key] = ddObj[key] || {};
    ddObj = ddObj[key];
  });
  const solution: {name: string, version?: string} = {name: getAnalyticsSolution(solutionConfig) || 'exc'};
  version && (solution.version = version);
  digitalData.page.solution = {...digitalData.page.solution, ...solution};
  digitalDataUpdated('page');
};

/**
 * Parse out the custom ims information from the context and return it if the
 * customIms property is set on the AppSandbox instance.
 * @param app - AppSandbox object.
 * @returns Custom IMS data if enabled, undefined otherwise.
 */
export const getCustomImsData = (app: AppSandbox): Partial<RuntimeConfiguration & CoreContext> | undefined => {
  const {context, customIms} = app;
  const {appProfile: imsProfile, appToken: imsToken, imsInfo} = context;
  if (customIms && imsToken) {
    return {
      imsClientId: customIms.client_id,
      imsInfo: {...imsInfo, imsProfile, imsToken},
      imsProfile,
      imsToken
    };
  }
};

/**
 * Determines if the custom IMS token is valid for the current application.
 * Compares the client_id and user_id from the token to the customIms object and
 * imsProfile userId respectively.
 * @param app - AppSandbox object.
 * @returns Whether the custom IMS token is valid.
 */
export const isValidCustomImsToken = (app: AppSandbox): boolean => {
  const {context, customIms} = app;
  const {appToken, imsProfile} = context;

  // If customIms is not set, the token is valid.
  if (!customIms) {
    return true;
  }

  // Return early if there is no custom token as this means it's already invalid.
  if (!appToken) {
    return false;
  }

  const {client_id, user_id} = extractTokenMetadata(appToken) ?? {};
  const isValidClientId = client_id === customIms.client_id;
  const isValidUserId = user_id === imsProfile.userId;

  // Warn for specific validity checks when invalid.
  isValidClientId || app.metrics.warn('Custom IMS token client_id does not match customIms.client_id');
  isValidUserId || app.metrics.warn('Custom IMS token user_id does not match imsProfile.userId');
  return isValidClientId && isValidUserId;
};

export const getAppController = (): AppController => getImpl('internal') as unknown as AppController;

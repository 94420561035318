/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {Internal} from '@adobe/exc-app/internal';
import Metrics, {Level} from '@adobe/exc-app/metrics';
import {storage} from '@exc/storage';

export interface ReloadReason {
  data?: Record<string, string|number>;
  forced?: boolean;
  initiator: string;
  reason: string;
}

const metrics = Metrics.create('exc.storage.Reloader');
let readPreviousResolve: () => void;
const readPreviousPromise = new Promise<void>(resolve => readPreviousResolve = resolve);
let previousReloadReason: ReloadReason|null = null;

if (storage.session.get) {
  // Check storage for a reload reason from the previous session.
  storage.session.get<ReloadReason>('reloadReason').then(reasonData => {
    previousReloadReason = reasonData;
    if (reasonData) {
      const {data = {}, forced = false, initiator, reason} = reasonData;
      // Log reload reason to EIM and clean storage.
      metrics.event(`Reload.Reason`, {forced, initiator, reason, ...data});
      storage.session.remove('reloadReason');
      // Forget previous reason after 10 minutes.
      setTimeout(() => previousReloadReason = null, 600 * 1000);
    }
    readPreviousResolve();
  });
} else {
  metrics.event(`Reload.NoStorage`, {level: Level.ERROR});
  Internal.flush();
}

/**
 * Reloads Unified Shell, storing reload reason in session storage so it can be
 * logged to EIM after reload. Reload reason will also be used to prevent reload loops.
 * @param {ReloadReason} reason - Reload reason
 * @param {boolean} force - Force reload - Will reload even after a previous reload.
 *
 * @returns {boolean} true if browser was reloaded, false otherwise.
 */
export const reload = async (reasonData: ReloadReason, force = false): Promise<boolean> => {
  await readPreviousPromise;
  if (previousReloadReason && previousReloadReason.initiator === reasonData.initiator) {
    if (!force) {
      metrics.event('Reload.doubleReloadBlocked', {
        currentReload: reasonData,
        previousReload: previousReloadReason
      }, {level: Level.ERROR});
      return false;
    }
    // eslint-disable-next-line no-param-reassign
    reasonData = {forced: true, ...reasonData};
  }

  const {data = {}, forced = false, initiator, reason} = reasonData;
  metrics.event('Reload.WillReload', {forced, initiator, reason, ...data});
  await storage.session.set('reloadReason', reasonData);
  location.reload();
  return true;
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HASH_TYPE, HISTORY, RUNTIME, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/softwareDistribution.ts

const softwareDistribution: SolutionRoute = {
  appId: 'softwareDistribution',
  path: '/downloads',
  runtime: RUNTIME.CDN_LEGACY,
  sandbox: {
    pageTimeout: 60000,
    history: {
      type: HISTORY.SERVER,
      config: {
        absolutePaths: true,
        hashType: HASH_TYPE.NOSLASH
      }
    },
    sources: {
      local: {
        discovery: 'https://localhost/bin/softwaredistribution/login.html?configid=ims',
        payload: ['path'],
        requestId: 'query',
        withCredentials: true
      },
      dev: {
        discovery: 'https://downloads-dev.experiencecloud.adobe.com/bin/softwaredistribution/login.html?configid=ims',
        payload: ['path'],
        requestId: 'query',
        withCredentials: true
      },
      dev2: {
        discovery: 'https://downloads-dev2.experiencecloud.adobe.com/bin/softwaredistribution/login.html?configid=ims',
        payload: ['path'],
        requestId: 'query',
        withCredentials: true
      },
      dev3: {
        discovery: 'https://downloads-dev3.experiencecloud.adobe.com/bin/softwaredistribution/login.html?configid=ims',
        payload: ['path'],
        requestId: 'query',
        withCredentials: true
      },
      qa: {
        discovery: 'https://downloads-qa.experiencecloud.adobe.com/bin/softwaredistribution/login.html?configid=ims',
        payload: ['path'],
        requestId: 'query',
        withCredentials: true
      },
      qacdn: {
        discovery: 'https://publish-p11553-e21242.adobeaemcloud.com/bin/softwaredistribution/login.html?configid=ims',
        payload: ['path'],
        requestId: 'query',
        withCredentials: true
      },
      prod: {
        discovery: 'https://downloads.experiencecloud.adobe.com/bin/softwaredistribution/login.html?configid=ims',
        payload: ['path'],
        requestId: 'query',
        withCredentials: true
      },
      prod2: {
        discovery: 'https://downloads-temp.experiencecloud.adobe.com/bin/softwaredistribution/login.html?configid=ims',
        payload: ['path'],
        requestId: 'query',
        withCredentials: true
      },
      stagecdn: {
        discovery: 'https://publish-p2606-e4990.adobeaemcloud.com/bin/softwaredistribution/bin/softwaredistribution/login.html?configid=ims',
        payload: ['path'],
        requestId: 'query',
        withCredentials: true
      },
      stage: {
        discovery: 'https://downloads-stage.experiencecloud.adobe.com/bin/softwaredistribution/login.html?configid=ims',
        payload: ['path'],
        requestId: 'query',
        withCredentials: true
      }
    }
  },
  serviceCode: '',
  hideTenant: true
};

export default softwareDistribution;

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, SolutionRoute} from '@exc/core/src/models/Solution';
import type {ProductContext} from '@adobe/exc-app/ims/ImsProfile';
import type {SubOrg} from '@adobe/exc-app/RuntimeConfiguration';

// This file was generated automatically from ../solutions/fusion.ts

export const orgLaneMap = new Map();

export const getLane = (resourceLocator: string): string => {
  const url = resourceLocator.toString().split('https://');
  return url[1].split('.')[0];
};

export const hostByLaneMap: Record<string, string> = {
  app: 'https://fusion.adobe.com/',
  'app-az': 'https://az.fusion.adobe.com/',
  'app-eu': 'https://eu.fusion.adobe.com/',
  'app-qa': 'https://qa.fusion.adobe.com/',
  'app-qa-az': 'https://qa-az.fusion.adobe.com/'
};

export const getProdHostByLane = (lane: string): string => hostByLaneMap[lane];

export const getHostFromContext = (prodCtx: ProductContext, subOrg?: SubOrg): string => {
  if (subOrg?.id && subOrg?.name) {
    const subOrgNameSplit = subOrg.name.split(' ');
    const orgId = subOrgNameSplit[subOrgNameSplit.length - 1];
    return getProdHostByLane(orgLaneMap.get(orgId));
  }
  return getProdHostByLane(getLane(prodCtx.fulfillable_entity_resource_locator));
};

const SOURCE = {
  discovery: '{host}api/v2/ims/login',
  findHostFromContext: {
    getHostFromContext: (prodCtx: ProductContext, subOrg?: SubOrg) => getHostFromContext(prodCtx, subOrg),
    serviceCode: 'workfront_fusion',
    useAdobeDomain: true
  },
  payload: [],
  url: '{host}',
  withCredentials: false
};

export const getSubOrgName = (prodCtx: ProductContext): string => {
  const resource_locator = prodCtx.fulfillable_entity_resource_locator.toString();
  const orgId = resource_locator.match(/organizations\/\d*/g).toString().split('/')[1];
  const lane = getLane(resource_locator);
  orgLaneMap.set(orgId, lane);
  return `Workfront Fusion ${orgId}`;
};

const fusion: SolutionRoute = {
  access: {code: ['workfront_fusion']},
  permissionsPolicy: ['clipboard-read', 'clipboard-write'],
  appId: 'fusion',
  analytics: {
    code: 'fsn',
    scripts: {
      dev: 'https://assets.adobedtm.com/1ed8f06230f1/f8f03239676c/launch-75960f7df5cb-development.min.js',
      qa: 'https://assets.adobedtm.com/1ed8f06230f1/f8f03239676c/launch-75960f7df5cb-development.min.js',
      stage: 'https://assets.adobedtm.com/1ed8f06230f1/f8f03239676c/launch-2dc429e27f6e-staging.min.js',
      prod: 'https://assets.adobedtm.com/1ed8f06230f1/f8f03239676c/launch-f47b872ee6d0.min.js'
    }
  },
  gainsight: {productKey: 'AP-IZHYZRAKQRSY-2'},
  experienceLeague: {
    communities: 'workfront',
    filter: 'Workfront'
  },
  path: '/fusion',
  refreshOnImsChange: true,
  sandbox: {
    history: HISTORY.HISTORY,
    ims: {
      client_id: 'workfront-fusion-web',
      scopes: [
        'additional_info.projectedProductContext',
        'AdobeID',
        'openid',
        'workfront.fusion-license-reporting',
        'workfront.fusion-templates',
        'workfront.fusion-log-retrieval',
        'workfront.fusion-account-manager'
      ].join(',')
    },
    sources: {
      dev: 'https://master.fusion.adobe.com/',
      qa: 'https://dev.fusion.adobe.com/',
      stage: SOURCE,
      prod: SOURCE
    }
  },
  urlContext: {
    key: 'subOrg',
    config: {
      customPathKey: 'name',
      id: 'fulfillable_entity_resource_locator',
      name: getSubOrgName,
      serviceCode: 'workfront_fusion',
      useRootPathOnChange: true
    }
  }
};

export default fusion;

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, PDH_GLOBAL, RELEASE_TYPE, RUNTIME, SolutionRoute, THUNDERBIRD} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/genStudioDraftPreviewApp.ts

const genStudioDraftPreviewApp: SolutionRoute = {
  appId: 'GenStudioDraftPreviewApp',
  path: '/genstudio-draft-preview',
  analytics: {omegaGlobal: PDH_GLOBAL.WEBSDK} as const,
  runtime: RUNTIME.CDN,
  spaAppId: 'GenStudio-draft-preview-app',
  sandbox: {
    history: HISTORY.HISTORY,
    ims: {
      client_id: 'genstudio',
      scopes: 'additional_info.projectedProductContext,additional_info.ownerOrg,AdobeID,openid,session,read_organizations,ab.manage'
    },
    overrideParams: true,
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013',
      dev443: 'https://localhost.corp.adobe.com:443',
      prod: '' // Remove this line before the production release.
    }
  },
  thunderbird: THUNDERBIRD.SERVICE_WORKER,
  access: {
    code: ['dx_genstudio'],
    flag: 'GenStudio'
  },
  releaseType: RELEASE_TYPE.POC
};

export default genStudioDraftPreviewApp;

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, PDH_GLOBAL, RELEASE_TYPE, RUNTIME, SolutionRoute, THUNDERBIRD} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/berlinHome.ts

const berlinHome: SolutionRoute = {
  appId: 'berlin-home',
  analytics: {omegaGlobal: PDH_GLOBAL.WEBSDK},
  path: '/berlin-home',
  releaseType: RELEASE_TYPE.POC,
  runtime: RUNTIME.CDN,
  sharedCaches: ['aem-headless'],
  spaAppId: 'experience-platform-project-berlin-home',
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013',
      prod: '' // Remove this line before the production release.
    }
  },
  thunderbird: THUNDERBIRD.SERVICE_WORKER
};

export default berlinHome;

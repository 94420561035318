/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
type Range = {start: number, end: number};
type DateObject = {year: number, month: number, day: number};
type Time = {hour: number, minute: number, second: number};
type DateRange = {start: DateObject, end: DateObject};

const hasProperties = (obj: any, props: string[]): obj is Record<string, any> =>
  typeof obj === 'object' && props.every(prop => prop in obj);

export const isBool = (obj: any): obj is boolean => typeof obj === 'boolean';
export const isRange = (obj: any): obj is Range => hasProperties(obj, ['start', 'end']);
export const isDate = (obj: any): obj is DateObject => hasProperties(obj, ['year', 'month', 'day']);
export const isDateRange = (obj: any): obj is DateRange => hasProperties(obj, ['start', 'end']) && isDate(obj.start) && isDate(obj.end);
export const isTime = (obj: any): obj is Time => hasProperties(obj, ['hour', 'minute', 'second']);

/**
 * Handler functions to generate `omegaElement` strings based on the type of data received.
 * Different handlers are used depending on the type of component.
 */

// For Time components (e.g., Timefield)
export const handleTime = (elementName: string, time: Time) => `${elementName} [${time.hour}_${time.minute}_${time.second}]`;
// For Date Range components (e.g., DateRangePicker)
export const handleDateRange = (elementName: string, range: DateRange) => `${elementName} [${range.start.year}_${range.start.month}_${range.start.day},${range.end.year}_${range.end.month}_${range.end.day}]`;
// For Date components (e.g., Calendar, DatePicker)
export const handleDate = (elementName: string, date: DateObject) => `${elementName} [${date.year}_${date.month}_${date.day}]`;
// For Range components (e.g., RangeSlider)
export const handleRange = (elementName: string, range: Range) => `${elementName} [${range.start},${range.end}]`;
// For Boolean components (e.g., Checkbox, Switch)
export const handleBoolean = (elementName: string, bool: boolean) => bool ? `${elementName} [selected]` : `${elementName} [deselected]`;

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {CheckTokenResponse} from '../models';
import * as cookie from './cookie';
import {fetch, FetchScope} from '@adobe/exc-app/network';
import hashString from 'string-hash';

/* eslint-disable sort-keys */
const ENVS: Record<string, string> = {
  test: 'stg1',
  dev: 'stg1',
  qa: 'stg1',
  stage: 'stg1',
  prod: ''
};
/* eslint-enable sort-keys */

function getIMSOrigin(env: string) {
  const imsExt = /^(prod)$/.test(env) ? '' : `-${ENVS[env]}`;
  return `https://adobeid-na1${imsExt}.services.adobe.com`;
}

const handleTokenResponse = async (res: Response): Promise<CheckTokenResponse> => {
  let data;
  try {
    data = await res.json();
  } catch {
    // No JSON.
    throw new Error(`Error parsing JSON: ${res.status} ${res.statusText}`);
  }

  if (!res.ok || !data.access_token) {
    throw data;
  }

  return data;
};

interface GetTokenProps {
  clientId: string;
  env: string;
  scope: string;
  userId: string;
  userInactiveSince?: number;
}

/**
 * Retrieves the token from IMS. Adds an additional field
 * `userInactiveSince` onto the request if it's a valid number so IMS can
 * handle the case where the user might have been inactive longer than the PBA
 * setting.
 * @async
 * @param {GetTokenProps} props - Token props.
 * @param {string} clientId - Client id to fetch token with.
 * @param {string} env - IMS environment to use.
 * @param {string} scope - Scopes to pull for the token.
 * @param {string} userId - ID of the user to fetch.
 * @param {number} userInactiveSince - Time in seconds since the user was active.
 * @returns {Promise<CheckTokenResponse>} - The token data.
 */
export const getToken = async ({clientId, env, scope, userId, userInactiveSince}: GetTokenProps): Promise<CheckTokenResponse> => {
  let url = `${getIMSOrigin(env)}/ims/check/v6/token?client_id=${clientId}&scope=${scope}&user_id=${userId}`;
  userInactiveSince && (url = `${url}&userInactiveSince=${userInactiveSince}`);
  let res = await fetch(url, {credentials: 'include', method: 'POST', scope: FetchScope.ORG});
  let data;

  try {
    data = await handleTokenResponse(res);
  } catch {
    // NOTE: this is a hack to enable e2e tests for Cypress/wdio.
    // If the response fails, try again with the V1 endpoint.
    url = `${getIMSOrigin(env)}/ims/check/v1/token?client_id=${clientId}&scope=${scope}`;
    res = await fetch(url, {credentials: 'include', scope: FetchScope.ORG});
    data = await handleTokenResponse(res);
  }

  return data || {};
};

const IMS_ENV_COOKIE = 'shellImsEnv';

/**
 * Validates that the current environment on which we have a session is the same as the
 * requested one. This works around various IMS bugs.
 * @param {string} curImsEnv Currently selected IMS env
 * @param {string} buildEnv Current build environment (dev|qa|stage|prod)
 * @returns {boolean}
 */
export function isSessionEnvValid(curImsEnv: string, buildEnv: string): boolean {
  const prevImsEnv = cookie.get(IMS_ENV_COOKIE);
  const standardImsEnv = buildEnv === 'prod' ? 'prod' : 'stage';
  let conflictingEnv;

  // The default IMS env is being used but the previous IMS environment is
  // different, we should sign out of the previous environment.
  if (standardImsEnv === curImsEnv && prevImsEnv && curImsEnv !== prevImsEnv) {
    conflictingEnv = prevImsEnv;
  }
  // The default IMS env is NOT being used (e.g. `shell_ims`)
  if (standardImsEnv !== curImsEnv) {
    // No previous IMS env, this means that the user hasn't gone through the
    // updated code yet. Sign out of the standard env just to be safe.
    if (!prevImsEnv) {
      conflictingEnv = standardImsEnv;

    // There is a previous IMS env and it's not the same as the current one.
    // Sign out of the previous IMS env so we can use the current one.
    } else if (prevImsEnv && curImsEnv !== prevImsEnv) {
      conflictingEnv = prevImsEnv;
    }
  }

  // The auth env wasn't updated, so we can continue on.
  if (!conflictingEnv) {
    cookie.set(IMS_ENV_COOKIE, curImsEnv, true);
    return true;
  }

  cookie.del(IMS_ENV_COOKIE, true);
  return false;
}

/**
 * Returns token key.
 * @param clientId Client id string.
 * @param scope List of scopes.
 * @param userId User ID.
 */
export const getTokenKey = (clientId: string, scope: string, userId: string): string =>
  btoa([clientId, scope, hashString(userId).toString()].join('|'));

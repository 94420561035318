/*************************************************************************
 * Copyright 2020 Adobe
 * All Rights Reserved.
 *
 * NOTICE: Adobe permits you to use, modify, and distribute this file in
 * accordance with the terms of the Adobe license agreement accompanying
 * it. If you have received this file from a source other than Adobe,
 * then your use, modification, or distribution of it requires the prior
 * written permission of Adobe.
 **************************************************************************/

/**
 * APIs to get or set settings, preferences or configuration data that can be stored and retrieved
 * at an IMS user and/or an IMS org level. An app in unified shell can consume Settings service.
 *
 * To consume this API, add the following import to your code.
 *
 * ```typescript
 * import settings from '@adobe/exc-app/settings';
 * ```
 *
 * The default export is an object of type [SettingsApi](../interfaces/_settings_.settingsapi.md)
 *
 * API reference: [scroll down](#index)
 *
 * ### Sample code
 *
 * ```typescript
 * import settings, {SettingsLevel} from '@adobe/exc-app/settings';
 *
 * async function updateSettings(type: string, value: number) {
 *   const data = await settings.get({
 *     groupId: 'test-groupId',
 *     level: SettingsLevel.USER,
 *     settings: {key1: null}
 *   });
 *   data = data || {};
 *   data[type] = value;
 *   await settings.set({
 *     groupId: 'test-groupId',
 *     level: SettingsLevel.USER,
 *     settings: {key1: data}
 *   });
 * }
 * ```
 *
 * ### SettingsLevel
 *
 * Can be optionally specified to define the level at which settings are saved.
 *
 * * `SettingsLevel.USER` - use this level when you want to get/set settings per user.
 * * `SettingsLevel.USERORG` - should be used when settings are for user + org combination.
 * * `SettingsLevel.ORG` - use this level when you want to get/set settings per org.
 *
 * By default settings are saved at level `SettingsLevel.USERORG`. You can optionally override this
 * in the get/set API calls using the `level` parameter.
 *
 * ### Groups
 *
 * Apps can group their settings into different groups by different group IDs and keep multiple
 * settings in different groups. Apps are free to define their own groups for a particular selected
 * settings level.
 *
 * You can specify this in the get/set API calls using the `groupId` parameter.
 *
 * ### Sharing settings
 *
 * Set settingsAppId in the solution specific route configuration in order to share settings with
 * other applications.
 * @packageDocumentation
 * @module settings
 */

import {getImpl} from './src/Global';
import {SettingsLevel} from './settings/SettingsLevel';

/**
 * A map of settings in the settings service.
 */
export interface Settings {
  [key: string]: any;
}

/**
 * The response from the settings service.
 */
export interface SettingsResponse<T extends Settings> {
  /**
   * The map of settings being worked upon.
   */
  settings: T;
}

/**
 * The input parameters for the settings API.
 */
export interface Parameters<T extends Settings> {
  /**
   * A unique identifier.
   */
  groupId: string;

  /**
   * A map of the settings key-value pairs. In case of `set` API, the value is saved and for the
   * `get` API, the value is the fallback in case the key isn't present in the settings store.
   */
  settings: T;

  /**
   * The type of store to get/set the settings from/to. Defaults to `SettingsLevel.USERORG`.
   */
  level?: SettingsLevel;
}

/**
 * APIs to get or set settings, preferences or configuration data that can be stored and retrieved
 * at an IMS user and/or an IMS org level. An app in unified shell can consume Settings service.
 */
export interface SettingsApi {
  /**
   * Gets settings based on the specified parameters such as groupId
   * and settings - keys with default values to fallback.
   * @param params Parameters used to identify settings to retrieve.
   * @returns A promise for the specified settings.
   */
  get<T extends Settings>(params: Parameters<T>): Promise<SettingsResponse<T>>;

  /**
   * Creates or updates settings based on the specified parameters such as groupId and settings
   * key-value pairs.
   * @param params Parameters to identify the settings to create or update.
   * @returns A promise for the operation response.
   */
  set<T extends Settings>(params: Parameters<T>): Promise<SettingsResponse<T>>;
}

const settings: SettingsApi = {
  get: params => getImpl('settings')().get(params),
  set: params => getImpl('settings')().set(params)
};

export {SettingsLevel};
export default settings;

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import SandboxService from './SandboxService';
import SubOrgService from './SubOrgService';

export function getContextService(solutionConfig, callback, settingsService) {
  const {urlContext = {}} = solutionConfig;

  switch (urlContext.key) {
    case 'sandbox':
      return new SandboxService(solutionConfig, callback, settingsService);
    case 'subOrg':
      return new SubOrgService(solutionConfig, callback, settingsService);
  }
}

/*************************************************************************
 * Copyright 2021 Adobe
 * All Rights Reserved.
 *
 * NOTICE: Adobe permits you to use, modify, and distribute this file in
 * accordance with the terms of the Adobe license agreement accompanying
 * it. If you have received this file from a source other than Adobe,
 * then your use, modification, or distribution of it requires the prior
 * written permission of Adobe.
 **************************************************************************/

/**
 * @ignore
 */
export enum SettingsLevel {
  /**
   * Settings specific to the org.
   */
  ORG = 'org',

  /**
   * Settings specific to the user.
   */
  USER = 'user',

  /**
   * Settings specific to the user-org combination.
   */
  USERORG = 'userorg'
}

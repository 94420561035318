/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, RUNTIME, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/cm-console-ui.ts

const cmConsoleUI: SolutionRoute = {
  spaAppId: 'primetime-cm-console-ui',
  appId: 'cm-console-ui',
  appRoot: 'primetime',
  experienceLeague: {
    communities: 'adobe-primetime',
    filter: 'Primetime'
  },
  path: '/cm-console',
  serviceCode: 'dma_primetime',
  runtime: RUNTIME.CDN,
  sandbox: {
    history: HISTORY.HISTORY,
    ims: {
      client_id: 'cm-console-ui',
      scopes: 'AdobeID,openid,dma_group_mapping,read_organizations,additional_info.projectedProductContext'
    },
    sources: {
      dev: 'https://localhost.corp.adobe.com:12399'
    },
    pageTimeout: 30000
  }
};

export default cmConsoleUI;

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, PDH_GLOBAL, RUNTIME, THUNDERBIRD} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/pueblo.ts

export const puebloBaseConfig = {
  appContainer: '#container',
  analytics: {
    omegaGlobal: PDH_GLOBAL.BOTH
  } as const,
  runtime: RUNTIME.CDN,
  sandbox: {
    history: HISTORY.HASH,
    sources: {
      dev: 'https://localhost.corp.adobe.com:23999'
    }
  },
  spaAppId: 'exc-pueblo',
  thunderbird: THUNDERBIRD.SERVICE_WORKER
};

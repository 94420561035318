/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

export const SUPPORTED_LOCALES = ['de-DE', 'en-US', 'es-ES', 'fr-FR', 'it-IT', 'ja-JP', 'ko-KR', 'pt-BR', 'zh-CN', 'zh-TW'];

/* eslint-disable sort-keys */
const LOCALE_MAPPING: Record<string, string[]> = {
  'en-US': ['en-AE', 'en-GB', 'en-HR', 'en-KE', 'en-IL', 'en-MU', 'en-NG', 'en-TT', 'en-US', 'en-XM', 'en-MO', 'en-LK', 'en-VN'],
  'de-DE': ['de-DE'],
  'es-ES': ['es-BO', 'es-DO', 'es-PA', 'es-ES', 'es-LA', 'es-MX', 'es-NA', 'es-PY', 'es-SV', 'es-UY'],
  'fr-FR': ['fr-CA', 'fr-FR', 'fr-MA', 'fr-XM'],
  'it-IT': ['it-IT'],
  'ja-JP': ['ja-JP'],
  'ko-KR': ['ko-KR'],
  'pt-BR': ['pt-BR'],
  'zh-CN': ['zh-CN', 'zh-HANS', 'zh-HANS-CN'],
  'zh-TW': ['zh-TW', 'zh-HANT', 'zh-HANT-TW']
};
/* eslint-enable sort-keys */

/**
 * Takes a locale that's missing a region 'en' and does best guess 'en-US'
 * @param locale
 * @returns {string} Best Suitable Locale.
 */
export const getLocaleRegion = (locale: string): string => Object.keys(LOCALE_MAPPING).find(language => language.startsWith(locale)) || 'en-US';

/**
 *
 * @param locale
 */
const getLocaleFromMapping = (locale: string): string => {
  const formattedLocale = locale.replace('_', '-');
  for (const language in LOCALE_MAPPING) {
    const prefix = formattedLocale.split('-')[0];
    const postfix = formattedLocale.split('-')[1] || '';
    if (!language.startsWith(prefix)) {
      continue;
    }
    if (LOCALE_MAPPING[language].includes(`${prefix}-${postfix.toUpperCase()}`)) {
      return language;
    }
  }
  return locale;
};

/**
 * Format locale into expected format (lowercase prefix, dash, uppercase postfix)
 * @param {string} locale Locale to format
 * @returns Formated locale
 */
const formatLocale = (locale = 'en-US'): string => {
  const formattedLocale = locale.replace('_', '-');
  const [prefix, postfix] = formattedLocale.split('-');
  return `${prefix.toLowerCase()}-${postfix.toUpperCase()}`;
};

/**
 * Get Best Locale Setting using User Preferences and Supported Locales.
 * @param {Array.<string>} preferredLanguages User's preferred languages.
 * @param {Array.<string>} supportedLanguages List of Supported Locales.
 * @param {boolean} original Should the function return the original form of the selected locale?
 * @returns {string} Best Suitable Locale.
 */
export const getBestSupportedLocale = (preferredLanguages: string[], supportedLanguages: string[], original = false): string => {
  const mappedPreferredLanguages = (preferredLanguages || []).map(getLocaleFromMapping);
  const firstLocale = mappedPreferredLanguages && mappedPreferredLanguages.length > 0 ? mappedPreferredLanguages[0] : 'en-US';
  const secondLocale = mappedPreferredLanguages && mappedPreferredLanguages.length > 1 ? mappedPreferredLanguages[1] : 'en-US';
  const firstLocalePrefix = firstLocale.split('-')[0];
  const secondLocalePrefix = secondLocale.split('-')[0];
  let locale = supportedLanguages.find(lang => lang.toUpperCase() === firstLocale.toUpperCase());
  let originalLocale = preferredLanguages?.[0];
  if (!locale && (firstLocalePrefix !== secondLocalePrefix)) {
    locale = supportedLanguages.find(lang => lang.split('-')[0] === firstLocalePrefix);
  }
  if (!locale) {
    locale = supportedLanguages.find(lang => lang.toUpperCase() === secondLocale.toUpperCase()) ||
      supportedLanguages.find(lang => lang.split('-')[0] === secondLocalePrefix);
    originalLocale = preferredLanguages?.[1];
  }
  if (original) {
    return locale ? formatLocale(originalLocale) : 'en-US';
  }
  return locale || 'en-US';
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {CoreContext} from './models/Context';
import React from 'react';

export default React.createContext<CoreContext>({
  appContainer: '',
  environment: '',
  imsOrg: '',
  imsOrgName: '',
  locale: '',
  shellHeight: 49,
  shellSideNavWidth: 0,
  tenant: ''
} as CoreContext);

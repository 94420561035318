/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {debounce} from '../utils';
import type {Hero} from '../models/Hero';
import type {SolutionRoute} from '../models/Solution';
import type {WorkHubItem} from '@exc/graphql/src/models/workhub';

// Capability applicationId:id that should be mapped to a specific path. Using
// a key that is combination of applicationId and id, both from WorkHub, is
// necessary in case there is a different mapping for the same id in a different
// application within WorkHub.
const CAPABILITY_OVERRIDE: Record<string, string> = {
  'CJM:assets': '/journey-optimizer/assets'
};

/**
 * Update method type required because debounce is used.
 */
type Update = (detail?: string, force?: boolean) => void;

/**
 * Title Service that manages various aspects of the current application that is
 * loaded and makes sure the page title reflects that.
 */
export default class TitleService {
  private _app?: string;
  private _appConfig: SolutionRoute;
  private _capability?: string;
  private _detail?: string;
  private _divider = '|';
  private _workHubResponse?: WorkHubItem[];

  /**
   * Constructor for the TitleService class. Uses the provided arguments to set
   * up the initial version of the service. If the app portion of the title is
   * available, it triggers an update of the page title.
   * @param appConfig - Application configuration for the current app.
   * @param hero - Hero configuration for the current app.
   * @param workHubResponse - Work Hub response for the composed app.
   */
  constructor(appConfig: SolutionRoute, hero?: Hero, workHubResponse?: WorkHubItem[]) {
    this._appConfig = appConfig;
    this._workHubResponse = workHubResponse;

    // Set some initial values.
    this._app = hero?.title || hero?.shortTitle;
    this._capability = this.getCapabilityName();

    // Set initial title.
    this._app && this.update();
  }

  /**
   * Sets the Hero. With the hero, the app portion of the title can also be
   * determined by the values within the Hero. It also triggers an update of the
   * page title with the newly set app instance variable.
   * @public
   * @param hero - The Hero object for the current application.
   */
  public set hero(hero: Hero) {
    this._app = hero?.title || hero?.shortTitle;
    this.update();
  }

  /**
   * Sets the Work Hub response. With the response, the capability portion of
   * the title can also be determined. It also triggers an update of the page
   * title with the newly set capability instance variable.
   * @public
   * @param workHubResponse - Response from Work Hub for the composed app.
   */
  public set workHubResponse(workHubResponse: WorkHubItem[]) {
    this._workHubResponse = workHubResponse;
    this._capability = this.getCapabilityName();
    this.update();
  }

  /**
   * Build the title string out of the 3 parts: detail, capability, and app. If
   * any of the pieces aren't available, they are not included. Additionally,
   * if the first part is the same as the next part, one of them is removed to
   * reduce duplicates.
   * @private
   * @returns Formatted title string.
   */
  private format(): string {
    const output = [this._detail, this._capability, this._app].reduce((prev: string, value: string | undefined, idx: number): string => {
      if (!value) {
        return prev;
      }
      const toAdd = idx === 0 ? value : ` ${this._divider} ${value}`;
      return `${prev}${toAdd}`;
    }, '');
    // If there a duplicate entries (e.g. "Assets | Assets"), remove one of them.
    return output.replace(/^([a-zA-Z ]*) \| \1/, '$1');
  }

  /**
   * Gets the capability name from the Work Hub repsonse. The urlTemplate from
   * Work Hub is matched against the path in the application config to find the
   * best match. If there is an application that has a special case, like Assets,
   * the CAPABILITY_OVERRIDE object is used.
   * @private
   * @returns The matched Work Hub capability name or an empty string if none found.
   */
  private getCapabilityName(): string {
    const {path} = this._appConfig;
    const capability = this._workHubResponse?.find(({applicationId, enabled, id, urlTemplate}) => {
      urlTemplate = CAPABILITY_OVERRIDE[`${applicationId}:${id}`] || urlTemplate;
      // If urlTemplate path and config path are not an exact match, check if
      // full hash contains urlTemplate path
      if (urlTemplate !== path && urlTemplate.startsWith(path)) {
        return window.location.hash.includes(urlTemplate) && enabled;
      }
      return urlTemplate === path && enabled;
    });
    return capability?.displayName || '';
  }

  /**
   * Update the application with a new detail title from the application. This
   * method uses `debounce` to ensure that the title isn't being updated too
   * frequently.
   *
   * `Force` is specifically used to regenerate the capability section when the
   * Sandbox does not change but the sub-application has. For example, in AEP
   * and AJO AEP, /journey-optimizer/platform/schemas to
   * /journey-optimizer/platform/segments does not actually change the sandbox
   * application since it's part of the same monolith.
   * @public
   * @param detail - The detail title for the current page.
   * @param force - To force the capability name change.
   */
  public update: Update = debounce((detail?: string, force?: boolean): void => {
    // Always regenerate capability on AEP and AJO. Competition between title updates from Sandbox and
    // proxied config changes through APIProcessingService may cause the force param to be missed.
    if (force || ['experiencePlatformUI', 'cjm-platform'].includes(this._appConfig.appId)) {
      this._capability = this.getCapabilityName();
    }
    // If a detail is provided that includes sections separated by a pipe, only
    // use the first section.
    this._detail = (detail || '').split(' | ')[0];
    document.title = this.format();
  }, 500);
}

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, PDH_GLOBAL, RUNTIME, SolutionRoute, THUNDERBIRD} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/platformUIDecisioningManagement.ts

export const platformUIDecisioningManagementOffers: SolutionRoute = {
  appId: 'platformUIDecisioningManagementOffers',
  path: '/platform/offers',
  redirectFrom: [{path: '/platform/v2/offers'}],
  spaAppId: 'cjm-ajo-ui-decisioning-management',
  analytics: {omegaGlobal: PDH_GLOBAL.BOTH},
  omegaSuiteId: 'platform',
  runtime: RUNTIME.CDN,
  sandbox: {
    pathPrefix: {default: '/offers'},
    history: {
      type: HISTORY.HASH,
      config: {addParamsToHash: true}
    },
    sources: {
      dev: 'https://localhost.corp.adobe.com:8026'
    }
  },
  autoInstantiateRuntime: true,
  thunderbird: THUNDERBIRD.SERVICE_WORKER
};

export const platformUIDecisioningManagementComponents: SolutionRoute = {
  appId: 'platformUIDecisioningManagementComponents',
  path: '/platform/components',
  redirectFrom: [{path: '/platform/v2/components'}],
  spaAppId: 'cjm-ajo-ui-decisioning-management',
  runtime: RUNTIME.CDN,
  analytics: {omegaGlobal: PDH_GLOBAL.BOTH},
  omegaSuiteId: 'platform',
  sandbox: {
    pathPrefix: {default: '/components'},
    history: {
      type: HISTORY.HASH,
      config: {addParamsToHash: true}
    },
    sources: {
      dev: 'https://localhost.corp.adobe.com:8026'
    }
  },
  autoInstantiateRuntime: true,
  thunderbird: THUNDERBIRD.SERVICE_WORKER
};

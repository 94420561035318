/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, RUNTIME, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/commerce.ts

const commerce: SolutionRoute = {
  appId: 'commerceInstanceDiscovery',
  path: '/commerce',
  spaAppId: 'Adobe-Commerce-Admin-discovery-ui',
  runtime: RUNTIME.CDN,
  sandbox: {
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  },
  access: {flag: 'commerce-enabled'}
};

export default commerce;

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, RUNTIME, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/acctriggersui.ts

const baseConfig = {
  spaAppId: 'campaign-triggers-ui',
  runtime: RUNTIME.CDN,
  sandbox: {
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  },
  access: {
    subServiceCode: ['dma_analytics:triggers']
  }
};

const TRIGGERS_FLAG = 'triggers-bypass-enabled';

export const acctriggersui: SolutionRoute = {
  ...baseConfig,
  appId: 'acctriggersui',
  alternateRoute: [{
    flag: TRIGGERS_FLAG,
    path: /\/triggers(\/.*|$)/,
    redirect: '/triggers/v2$1'
  }],
  path: '/triggers',
  access: {
    ...baseConfig.access,
    code: ['dma_campaign', 'dma_campaign_classic'],
    logicOp: 'AND'
  }
};

// This version of Triggers was created because we can't do
// (code OR flag) AND subServiceCode, and I didn't want to
// destroy how we check permissions. Maybe in the future we
// can support this case more nicely.
export const acctriggerv2: SolutionRoute = {
  ...baseConfig,
  appId: 'acctriggersuiv2',
  alternateRoute: [{
    flag: TRIGGERS_FLAG,
    signalsInactive: true,
    path: /\/triggers\/v2(\/.*|$)/,
    redirect: '/triggers$1'
  }],
  path: '/triggers/v2',
  access: {
    ...baseConfig.access,
    flag: TRIGGERS_FLAG,
    logicOp: 'AND'
  }
};

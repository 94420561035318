/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/maestroUI.ts

const maestroUI: SolutionRoute = {
  appId: 'maestroUI',
  path: '/maestro',
  sandbox: {
    history: HISTORY.HISTORY,
    sources: {
      local: 'https://localhost.corp.adobe.com:3000',
      dev: 'https://maestro-dev.workfront-dev.adobe.com',
      qa: 'https://maestro-qa.workfront-dev.adobe.com',
      stage: '',
      prod: '' // Remove this line before the production release.
    }
  },
  permissionsPolicy: ['clipboard-read', 'clipboard-write']
};

export default maestroUI;

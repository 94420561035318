/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {FetchScope} from '@adobe/exc-app/network';
import {gqlQuery} from '../index';
import type {NavigationsDataResponse, NavigationsDataVariables} from '../models/workhub';

export const WORKHUB_QUERY = `
  query workhub($locale:String, $capability:String, $workhubVersion:String) {
    getNavigationsData(reqData:{locale: $locale, capability: $capability, workhubVersion: $workhubVersion}) {
      applicationId
      category
      description
      displayName
      enabled
      icon
      id
      newTab
      nounName
      nouns
      urlTemplate
      urlRoot
      visible
      navigationSubTreeElements {
        applicationId
        category
        description
        displayName
        enabled
        icon
        id
        newTab
        nounName
        urlTemplate
        visible
      }
    }
  }
`;

export const getNavigationsData = (vars: NavigationsDataVariables): Promise<NavigationsDataResponse> =>
  gqlQuery<NavigationsDataResponse, NavigationsDataVariables>(WORKHUB_QUERY, vars, {scope: FetchScope.SANDBOX_PLUS});

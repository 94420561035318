/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2020 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, PDH_GLOBAL, RUNTIME} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/cloudManager.ts

const cloudManager = {
  appId: 'cloudManagerUi',
  spaAppId: 'experience-platform-self-service-cvui',
  path: '/cloud-manager',
  permissionsPolicy: ['clipboard-write'],
  omegaSuiteId: 'cm',
  analytics: {omegaGlobal: PDH_GLOBAL.BOTH},
  gainsight: {productKey: 'AP-KDZIABJIM4XD-2'},
  serviceCode: 'dma_aem_cloud',
  sideNav: {closeOnLoad: true},
  runtime: RUNTIME.CDN,
  access: {
    code: ['dma_aem_ams', 'dma_aem_cloud', 'dma_media_library', 'dma_commerce_cloud', 'dma_aem_onprem'],
    flag: 'aem-on-prem-enabled'
  },
  sandbox: {
    ims: {
      client_id: 'ssp_ui',
      scopes: 'openid,AdobeID,session,read_organizations,ab.manage,additional_info.projectedProductContext,additional_info.user_image_url,additional_info.roles'
    },
    hideInitialSpinner: true,
    history: HISTORY.HISTORY,
    pageTimeout: 90000,
    sources: {
      local: 'https://local.corp.adobe.com'
    }
  },
  settingsAppId: 'cloudManagerUi'
};

export default cloudManager;

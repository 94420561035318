/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {FeatureFlagsById} from '../../models/FeatureFlags';

/**
 * Cleans up and sorts a feature flags object for multiple flag requests
 * Because the response or response with access overrides could include
 * projects or clients NOT requested, we filter the list by just the requested ones
 * @param projectIds - Array of project/client ids
 * @param featureFlagsById - Response of feature flags by project/client ID (possibly from cache)
 * @returns An object of project/client ids with flattened feature flags
 */
export const sortAndFilterFlags = (
  projectIds: string[],
  featureFlagsById: FeatureFlagsById = {}
): FeatureFlagsById =>
  Object.keys(featureFlagsById)
    .sort((a, b) => a.localeCompare(b))
    .reduce((result: FeatureFlagsById, key) => projectIds.includes(key) ? {...result, [key]: featureFlagsById[key]} : result, {});

{
  "alpha": "Alpha",
  "alphaFeedback": "Commentaires sur la version alpha",
  "beta": "Bêta",
  "betaFeedback": "Commentaires sur la version bêta",
  "customReleaseTrial": "Essai",
  "releaseBadgeAlpha": "Alpha",
  "releaseBadgeBeta": "Version bêta",
  "releaseBadgeDev": "Développement",
  "releaseBadgePoc": "POC"
}

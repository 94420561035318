/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, PDH_GLOBAL, RUNTIME, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/platformAnalytics.ts

const base = {
  settingsAppId: 'analytics',
  experienceLeague: {
    communities: 'adobe-analytics',
    filter: 'Customer Journey Analytics'
  },
  mfeAppIds: ['AnalyticsUI-core'],
  serviceCode: 'dx_awp',
  omegaSuiteId: 'aa',
  analytics: {omegaGlobal: PDH_GLOBAL.AA} as const,
  gainsight: {productKey: 'AP-WVMZ1DVKRDJD-2'},
  permissionsPolicy: ['clipboard-write'],
  access: {code: ['dx_awp']}
};

export const platformAnalytics: SolutionRoute = {
  ...base,
  appId: 'platformAnalytics',
  decodeUrl: true,
  parent: 'AEP',
  path: '/platform/analytics',
  runtime: RUNTIME.CDN_LEGACY,
  sandbox: {
    hideInitialSpinner: true,
    history: HISTORY.SERVER,
    ims: {
      client_id: 'WorkspacePlatform',
      scopes: 'openid,AdobeID,session,read_organizations,additional_info.job_function,additional_info.user_image_url,additional_info.projectedProductContext,additional_info.roles'
    },
    sources: {
      dev: 'https://localhost.corp.adobe.com:8443/platformAnalytics.html',
      qa: 'https://stage.analytics.adobe.com',
      stage: 'https://stage.analytics.adobe.com',
      prod: 'https://analytics.adobe.com'
    }
  }
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, PDH_GLOBAL, RELEASE_TYPE, RUNTIME, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/exdDecisionPolicyAssist.ts

const baseConfig = {
  analytics: {omegaGlobal: PDH_GLOBAL.WEBSDK},
  releaseType: RELEASE_TYPE.POC,
  runtime: RUNTIME.CDN,
  spaAppId: 'cjm-exd-decision-policy-assist',
  urlContext: {key: 'sandbox'} as const
};

export const exdDecisionPolicyAssist: SolutionRoute = {
  ...baseConfig,
  appId: 'exdDecisionPolicyAssist',
  path: '/exd-decision-policy-assist',
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013',
      prod: '' // Remove this line before the production release.
    }
  }
};

export const exdDecisionPolicyAssistStorybook: SolutionRoute = {
  ...baseConfig,
  appId: 'exdDecisionPolicyAssistStorybook',
  path: '/exd-decision-policy-assist-storybook',
  sandbox: {
    pathPrefix: {default: '/static-assets/storybook/index.html', dev: ''},
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013',
      prod: '' // Remove this line before the production release.
    }
  }
};

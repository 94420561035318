/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {AnalyticsConfiguration, Gainsight, NestedAppConfig} from '@adobe/exc-app/RuntimeConfiguration';
import type {AppSandbox} from '../models/AppSandbox';
import type {CoreContext} from '../models/Context';
import {digitalDataUpdated, isAWSOrgRegion, sanitizeConfiguration} from '@exc/shared';
import type {GainsightTriggers, SolutionRoute} from '../models/Solution';
import {
  getAnalyticsSolution,
  getAppEnvironment,
  getDiscoverySource,
  getMetricsConfig,
  getQuietToastEnabled,
  getSourceEnvironment,
  setBaseUrl
} from '../utils/appSandboxUtils';
import {getBootstrap} from './BootstrapService';
import {getHistoryType, waitForGainsightCallback} from '../utils';
import {getPathWithoutTenant} from '@exc/url';
import {Level} from '@adobe/exc-app/metrics';
import {pdhGlobalMap} from '../utils/omega';
import type {RuntimeConfiguration} from '@adobe/exc-app';

export type OutgoingConfiguration = Omit<RuntimeConfiguration, 'changedProperties'> & {internal: boolean};
export type OutgoingGainsight = Gainsight & {user: {internal: boolean}};

export class AppConfigureService {
  private getOmegaScripts({analytics} : SolutionRoute): Record<string, string> | undefined {
    if (analytics) {
      if ('omegaGlobal' in analytics && analytics.omegaGlobal) {
        return pdhGlobalMap[analytics.omegaGlobal].scripts;
      }
      if ('scripts' in analytics) {
        return analytics.scripts;
      }
    }
  }

  /**
   * Returns Omega (Data Collection) script for the iframe to initialize Omega if one is configured.
   * @param context - Context object.
   * @param solutionConfig - Application config.
   * @returns script to load or undefined if no script is configured.
   * @private
   */

  private getAnalyticsScript(context: CoreContext, solutionConfig: SolutionRoute): AnalyticsConfiguration | undefined {
    const solution = getAnalyticsSolution(solutionConfig);
    const scripts = this.getOmegaScripts(solutionConfig);
    const env = getAppEnvironment(context, solutionConfig);

    if (solution && scripts?.[env]) {
      return {script: scripts[env], solution};
    }
  }

  /**
   * Returns FIs to trigger Gainsight if configured and available for the current user.
   * @param context - Context object
   * @param triggers - Triggers configuration
   * @returns array of trigger FIs. Empty array if none available.
   * @private
   */

  private getGainsightTriggers(context: CoreContext, triggers: GainsightTriggers): string[] {
    const {fulfillableItems: userFIs, imsOrg} = context;
    const {fulfillableItems, serviceCode} = triggers;
    if (fulfillableItems && serviceCode) {
      const availableFIs = userFIs[imsOrg]?.[serviceCode] || [];
      return availableFIs.filter(key => fulfillableItems.includes(key));
    }
    return [];
  }

  /**
   * Initializes Gainsight if configured for the active application.
   * @param solutionConfig - Application Config.
   * @param gainsight - Gainsight configuration.
   */
  public setGainsight({metrics, secondary, solutionConfig}: AppSandbox, gainsight: Gainsight): void {
    const {analytics} = solutionConfig;
    const analyticsSolution = getAnalyticsSolution(solutionConfig);
    if (secondary) {
      // Unified Shell Gainsight should only sync with the primary sandbox.
      // Secondary sandboxes (i.e. SandboxInner) should be ignored.
      return;
    }
    // Add the Gainsight configuration to the Unified Shell window as well as
    // the iframe so that it will also abide by the user permissions.
    (window as any).shellGainsight = gainsight;
    window.dispatchEvent(new CustomEvent('shellGainsight'));
    if (analyticsSolution) {
      const omegaGlobal = !!analytics && ('omegaGlobal' in analytics) && analytics.omegaGlobal;
      waitForGainsightCallback()
        .then(callback => callback(analyticsSolution, !!omegaGlobal))
        .catch(() => {
          metrics.event('Sandbox.noGainsight', {analyticsSolution, omegaGlobal}, {level: Level.ERROR});
        });
    }
  }

  private getNestedApps = ({context, solutionConfig}: AppSandbox): Record<string, NestedAppConfig> | undefined =>
    solutionConfig.nestedApps?.reduce<Record<string, NestedAppConfig>>((nestedApps, nestedApp) => {
      const {appId, gainsight, metadata: {altSpaId} = {}, spaAppId} = nestedApp;
      const analyticsConfig = this.getAnalyticsScript(context, nestedApp as SolutionRoute);
      if (analyticsConfig) {
        spaAppId && (nestedApps[spaAppId] = {appId, gainsight, ...analyticsConfig});
        altSpaId && (nestedApps[altSpaId] = {appId, gainsight, ...analyticsConfig});
      }
      return nestedApps;
    }, {});

  /**
   * Constructs the iframe configuration payload from the context, config and app sandbox state.
   * @returns configuration payload.
   * @param app
   */
  public async getConfigurePayload(app: AppSandbox): Promise<OutgoingConfiguration> {
    const {configURL, context, metricsAppId, props, solutionConfig} = app;
    const {cdn, endpoints} = getBootstrap().config;
    const {
      activeProductContext,
      apiKey,
      apiMode,
      appContainer,
      avatar,
      environment,
      featureFlags,
      fulfillableItems: contextFulfillableItems,
      gainsightRoles,
      imsClientId,
      imsEnvironment,
      imsInfo,
      imsOrg,
      imsOrgs,
      imsOrgName,
      imsProfile,
      imsToken,
      internal,
      lastRecentTs,
      locale,
      localeOriginal,
      metricsEnv,
      orgRegion,
      preferredLanguages,
      sandbox: selectedSandbox,
      shellHeight,
      shellInfo,
      shellSideNavPresent,
      shellSideNavWidth,
      sideNavOpen,
      subOrg,
      tenant,
      theme,
      userConsent
    } = context;
    const {
      appId,
      browserParamFilterList,
      fiServiceCode,
      gainsight: gainsightConfig,
      gqlEndpoint,
      metadata,
      omegaSuiteId = appId,
      parent,
      spaAppId
    } = solutionConfig;
    // eslint-disable-next-line no-unused-vars
    const {triggers, ...gainsightRest} = gainsightConfig || {};
    const analytics = this.getAnalyticsScript(context, solutionConfig);
    const baseUrl = setBaseUrl(app.basePath);
    const fulfillableItems = fiServiceCode ? contextFulfillableItems?.[imsOrg]?.[fiServiceCode] || [] : [];
    const metricsConfig = await getMetricsConfig(app);
    const nestedApps = this.getNestedApps(app);
    const quiteToastPromise = getQuietToastEnabled(app);

    // Filter this list, so we don't send down data that might cause security issues
    // (IMS related stuff, other?)

    const gainsight: OutgoingGainsight = {
      appId,
      appParent: app.secondary ? 'component' : parent,
      enabled: 'aptrinsic' in window,
      environment,
      fulfillableItems,
      omegaSuiteId,
      productKey: 'AP-4BHKD5GITH0M-2', // Default gainsight key, overridden by defined configs.
      shellPath: getPathWithoutTenant(window.location.hash.replace(/^#/, '')),
      user: {
        authId: imsProfile?.authId,
        internal,
        permissions: userConsent,
        roles: gainsightRoles,
        theme: theme.includes('light') ? 'light' : 'dark',
        triggers: triggers ? this.getGainsightTriggers(context, triggers) : []
      },
      ...gainsightRest
    };

    const runtimeConfig = {
      activeProductContext,
      analytics,
      apiGatewayUrl: endpoints.apiGateway,
      apiKey,
      apiMode,
      appContainer,
      appId,
      appMetadata: metadata,
      avatar,
      baseFrameUrl: configURL?.toString() || '',
      basePath: getPathWithoutTenant(app.basePath),
      baseUrl,
      browserParamFilterList,
      cdn,
      componentBootstrapData: app.secondary ? props.bootstrapData : undefined,
      discovery: !!getDiscoverySource(app),
      environment,
      externalQueryParams: window.location.search,
      featureFlags,
      fulfillableItems,
      gainsight,
      gqlEndpoint,
      historyType: getHistoryType(solutionConfig),
      imsClientId,
      imsEnvironment,
      imsInfo,
      imsOrg,
      imsOrgName,
      imsOrgs,
      imsProfile,
      imsToken,
      inModal: app.secondary,
      internal,
      ioGatewayUrl: endpoints.ioGateway,
      ioRegionSpecificMap: endpoints.regionGatewayIoMap,
      isAWSOrg: isAWSOrgRegion(orgRegion),
      lastRecentTs,
      locale,
      localeOriginal,
      metricsAppId,
      metricsConfig,
      metricsEnv,
      nestedApps,
      orgRegion,
      preferredLanguages,
      sandbox: selectedSandbox,
      shellHeight,
      shellInfo,
      shellSideNavCollapsed: !sideNavOpen,
      shellSideNavPresent,
      shellSideNavWidth,
      sourceEnvironment: getSourceEnvironment(context, solutionConfig),
      spaAppId,
      subOrg,
      tenant,
      theme: `spectrum--${theme}`,
      toastQuietModeEnabled: await quiteToastPromise,
      userConsentPermissions: userConsent,
      xqlGatewayUrl: endpoints.xql
    };

    // Add the runtimeConfig to the Shell's digitalData
    // - but not for inner sandbox frames as they should
    // have the digitalData from the outer sandbox
    if (!app.secondary) {
      window.digitalData = {...window.digitalData as any, unifiedShell: {config: sanitizeConfiguration(runtimeConfig)}};
      digitalDataUpdated('unifiedShell');
    }

    return runtimeConfig;
  }

  /**
   * Compares the current and previous configuration payloads.
   * @param payload - Current configuration payload
   * @param lastPayload - Previous configuration payload
   * @returns array of changed configuration keys.
   */
  public getChangedConfig(
    payload: OutgoingConfiguration,
    lastPayload?: OutgoingConfiguration
  ): (keyof OutgoingConfiguration)[] {
    // On first config event, mark all values as changed
    const keys = Object.keys(payload) as (keyof OutgoingConfiguration)[];
    if (!lastPayload) {
      return keys.filter(key => payload[key] !== undefined);
    }
    return keys.filter(key => JSON.stringify(payload[key]) !== JSON.stringify(lastPayload[key]));
  }
}

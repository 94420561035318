/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {compile, Key, pathToRegexp} from 'path-to-regexp';
import CookieError, {storageAvailable} from './CookieError';
import {history as defaultHistory, Route, Router} from '@exc/router';
import {denyBrowserParams, getPathWithoutContext, getPathWithoutTenant, getUnifiedShellUrl} from '@exc/url';
import {Events} from '@exc/shared';
import ExcCore, {AppSelectorSandbox, ErrorTypes, ErrorView, Sandbox, waitComponent} from '@exc/core';
import {getBootstrap} from '@exc/core/src/services/BootstrapService';
import {getQueryValue} from '@exc/url/query';
import {getRoutes} from './solutionConfigs';
import {isSkyline} from '@exc/url/skyline';
import type {LocalSolutions} from '@exc/core/src/models/UnifiedShellConfig';
import React from 'react';
import {Redirect, SolutionRoute, THUNDERBIRD} from '@exc/core/src/models/Solution';
import './App.scss';

function sortByPath<T>(arr: T[], key: string): T[] {
  const getValue = (obj: T): string => (key.split('.')
    .reduce((prev, subKey) => (prev as any)?.[subKey], obj) || '') as string;
  return arr.sort((a, b) => {
    const aLen = getValue(a).split('/').length;
    const bLen = getValue(b).split('/').length;
    if (aLen < bLen) {
      return 1;
    } else if (aLen > bLen) {
      return -1;
    }
    return 0;
  });
}

/*
 * Process solutions and combine with locally defined solutions.
 */
const generateSolutions = (localSolutions: LocalSolutions): SolutionRoute[] => {
  let solutions = getRoutes();
  if (localSolutions) {
    solutions = {...solutions, ...localSolutions};
  }

  const denyParams: string[] = [];

  // Get the name of the solution export added onto the solution object.
  Object.keys(solutions).forEach(name => {
    const solution = solutions[name];
    solution.exportName = name;
    if (solution.spaAppId) {
      denyParams.push(`${solution.spaAppId}_version`);
    }
    if (solution.mfeAppIds?.length) {
      solution.mfeAppIds.forEach(appId => denyParams.push(`${appId}_version`));
    }
  });

  denyBrowserParams(denyParams);

  // Sort the solutions by number of chunks in the path. This is required to
  // handle /home and /home/notifications. The latter needs to come first so
  // that it matches correctly in the router.
  return sortByPath(Object.values(solutions), 'path');
};

/*
 * Generate redirect routes for each solution.
 */
const generateRedirects = (solutions: SolutionRoute[]) => {
  const redirects: {path: string, redirect: Redirect}[] = [];
  solutions.forEach(({path, redirectFrom}) =>
    redirectFrom && redirectFrom.forEach(redirect => redirects.push({path, redirect})));
  return redirects;
};

export interface AppProps {
  cdn: string;
  env: string;
  region: string;
  scriptsPath: string;
  solutionsOverride?: SolutionRoute[];
  spaVersion: string;
}

export default function UnifiedShell({cdn, env, region, scriptsPath, solutionsOverride, spaVersion}: AppProps) {
  const {hash, origin, pathname, search} = window.location;
  env = env.toLowerCase();
  const bootstrap = getBootstrap();

  // If the Shell is loaded within a parent, we should send up a redirect to
  // this page that we're on. This supports redirects within other applications
  // that may end up in this situation. Additionally, don't do this if this
  // is running in a Cypress test because it will break!
  if (window !== window.parent && !bootstrap.inAutomation()) {
    window.parent.postMessage({
      appId: 'unified-shell',
      type: Events.QUEUE,
      unifiedShell: 1,
      value: [[Events.SHELL_REDIRECT, {discovery: true, path: getPathWithoutTenant(hash.replace(/^#/, ''))}]]
    }, origin);
    return null;
  }

  if (!storageAvailable('localStorage')) {
    return <CookieError />;
  }

  const solutions = solutionsOverride || generateSolutions(bootstrap.localSolutions);
  const skyline = isSkyline();
  const nonSkylineSolutions = Object.values(solutions).filter(solution => !solution.withinSkyline);
  const skylineSolutions = Object.values(solutions).filter(solution => solution.withinSkyline);
  const thunderbird = getQueryValue('thunderbird');

  if (['force', 'off'].includes(thunderbird)) {
    // Turn thunderbird on or off for all applicable solutions.
    solutions
      .filter(solution => !!solution.spaAppId)
      .forEach(solution => solution.thunderbird = (thunderbird === 'force') ? THUNDERBIRD.SERVICE_WORKER : THUNDERBIRD.OFF);
  }

  // If there is a path defined, it's not valid in the Unified Shell URL. It
  // replaces the history path to `/` and updates the hash history (`history`)
  // to the provided path.
  if (defaultHistory && /^(\/((?!login|unifiedshell|ui).)+)$/.test(pathname)) {
    window.history.replaceState(null, '', '/');
    // The hash property defaults to `#/` since Unified Shell uses hash history.
    // Don't want this to transfer to the final path.
    defaultHistory.replace({hash: hash === '#/' ? '' : hash, pathname, search});
  }

  // Sets up the list of redirects that will be used to generate routes
  // specifically meant to navigate to a different path.
  const redirects = generateRedirects(solutions);

  return (
    <ExcCore
      cdn={cdn || window.location.origin}
      environment={env}
      listedApps={solutions}
      region={region}
      scriptsPath={scriptsPath}
      spaVersion={spaVersion}
    >
      <Router history={defaultHistory}>
        {/* If Skyline is loaded, all non-skyline applications need to redirect to experience.adobe.com */}
        {skyline && nonSkylineSolutions.map((solution, idx) => (
          <Route key={`nonSkyline-${idx}`} path={`/:id(@[^/]+)?/:contextParam([^/]+:[^/]+)?${solution.path}`} redirect={({route}) => {
            const redirectMatcher = pathToRegexp(solution.path, undefined, {end: false});
            if (!redirectMatcher.exec(getPathWithoutContext(getPathWithoutTenant(route)))) {
              return;
            }
            window.open(getUnifiedShellUrl(env, route), '_self');
            // This is pretty clever. Sends the path to a wait spinner so that
            // there is something to show while the window.open is triggering.
            return '/wait';
          }} />
        ))}

        {/* Handle solution redirect options */}
        {sortByPath(redirects, 'redirect.path').map(({path, redirect: {options, path: redirectedPath}}, redirectIdx) => {
          redirectedPath = /:[^/]*$/.test(redirectedPath) ? `${redirectedPath}([^/#?]+)` : redirectedPath;
          return (
            <Route exact key={redirectIdx} path={`/:id(@[^/]+)?/:contextParam([^/]+:[^/]+)?${redirectedPath}(/.*)?`} redirect={({route}) => {
              const keys: Key[] = [];
              const redirectMatcher = pathToRegexp(redirectedPath, keys, options || {end: false});
              const matchResult = redirectMatcher.exec(getPathWithoutContext(getPathWithoutTenant(route)));

              if (!matchResult) {
                return;
              }

              const toNewPath = compile(path, {encode: encodeURIComponent});
              const params: Record<string, string> = {};
              for (let i = 1; i < matchResult.length; i++) {
                const key = keys[i - 1];
                const val = matchResult[i];

                if (val !== undefined) {
                  params[key.name] = val;
                }
              }
              return route.replace(matchResult[0], toNewPath(params));
            }} />
          );
        })}

        {/* Handle all solutions with optional tenant and optional sandbox */}
        {(skyline ? skylineSolutions : nonSkylineSolutions).map((solution, idx) => (
          <Route key={idx} path={`/:id(@[^/]+)?/:contextParam([^/]+:[^/]+)?${solution.path}`}>
            {'alternateRoute' in solution ? <AppSelectorSandbox config={solution} /> : <Sandbox config={solution} />}
          </Route>
        ))}

        {/* Handle URLs that only have tenant */}
        <Route exact path="/@:id" redirect={({route}) => `${route}${route.endsWith('/') ? '' : '/'}home`} />

        {/* Handle Profile */}
        <Route key="profile" path="(/@|):id?/profile" />

        {/* Wait path */}
        <Route path="(/@|):id?/wait">
          {waitComponent}
        </Route>

        {/* Handle no path */}
        <Route exact path="/" redirect={() => isSkyline() ? '/aem' : '/home'} />
        <Route>
          <ErrorView resetSolution type={ErrorTypes.NOT_FOUND} />
        </Route>
      </Router>
    </ExcCore>
  );
}

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, PDH_GLOBAL, RUNTIME, SolutionRoute, THUNDERBIRD} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/content-hub.ts

const contentHub: SolutionRoute = {
  appId: 'ContentHub',
  path: '/assets/contenthub',
  permissionsPolicy: ['clipboard-write'],
  runtime: RUNTIME.CDN,
  analytics: {omegaGlobal: PDH_GLOBAL.WEBSDK} as const,
  gainsight: {productKey: 'AP-AULLRFDZLJ9K-2'},
  spaAppId: 'CQ-contenthub',
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    ims: {
      client_id: 'aem-assets-content-hub-1',
      scopes: 'additional_info.ownerOrg,additional_info.projectedProductContext,AdobeID,openid,session,read_organizations,ab.manage'
    },
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013',
      dev443: 'https://localhost.corp.adobe.com:443'
    }
  },
  thunderbird: THUNDERBIRD.SERVICE_WORKER,
  access: {
    code: ['dma_aem_contenthub'],
    fd: [{code: 'dma_aem_cloud', property: 'aemSolutions', value: 'contenthub', exists: true}],
    flag: 'content-hub-enabled'
  }
};

export default contentHub;

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2020 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

/*
 * While onboarding another app in cjm.js, please identify
 * if there are sharable configs among apps and if yes, then
 * create a baseConfig object and move common configs there
 */
import ajoContentEditor from './ajoContentEditor';
import {ansible} from './ansible';
import {assetsBase} from './ddam';
import {experienceGenerationContentAssistant} from './experienceGenerationContentAssistant';
import {
  experiencePlatformSpa,
  mexpAccess,
  mexpBaseConfig,
  platformUIComponents,
  platformUILicenseUsage,
  platformUIOffers
} from './experiencePlatform';
import {HISTORY, PDH_GLOBAL, RELEASE_TYPE, RUNTIME, SolutionRoute, THUNDERBIRD} from '@exc/core/src/models/Solution';
import inappEditor from './inappEditor';
import journeysNextAdmin from './journeysNextAdmin';
import journeysNextAuthoring from './journeysNextAuthoring';
import permissionsUIConfig from './permissionsUI';
import personalizationEditor from './personalizationEditor';
import {platformAnalytics} from './platformAnalytics';
import {puebloBaseConfig} from './pueblo';
import {translationConfig} from './translationService';
import {useCasesConfig} from './useCases';

// This file was generated automatically from ../solutions/cjm.ts

const access = {
  code: ['acp'],
  fi: ['acp:cjm_foundation'],
  logicOp: 'AND'
};
const sideNav = {id: 'cjm', workHubId: 'CJM'};

const personalizationEditorAjo = {
  ...personalizationEditor,
  analytics: {omegaGlobal: PDH_GLOBAL.BOTH},
  omegaSuiteId: 'cjm'
};

const ansibleAjo: SolutionRoute = {...ansible, omegaSuiteId: 'cjm'};

const baseConfig = {
  appContainer: '#dp-ui',
  appRoot: 'cjm-home',
  access,
  analytics: {
    omegaGlobal: PDH_GLOBAL.BOTH
  },
  experienceLeague: {
    communities: 'adobe-journey-optimizer',
    filter: 'Journey Optimizer'
  },
  fiServiceCode: 'acp',
  gainsight: {productKey: 'AP-GLVNMB5BLV8T-2'},
  omegaSuiteId: 'cjm',
  parent: 'CJM' as const,
  sideNav,
  unifiedSearch: {
    enabled: true
  },
  urlContext: {key: 'sandbox'} as const,
  betaFeedbackConfigId: 'cjm',
  nestedApps: [ansibleAjo, experienceGenerationContentAssistant, personalizationEditorAjo]
};

export const cjmHome: SolutionRoute = {
  ...baseConfig,
  ...puebloBaseConfig,
  appContainer: '#container',
  appId: 'cjm-home',
  dataPrefetchContracts: ['recents-sandbox', 'dashboard-sandbox'],
  analytics: {omegaGlobal: PDH_GLOBAL.BOTH},
  omegaSuiteId: 'cjm',
  path: '/journey-optimizer/home',
  redirectFrom: [
    {path: '/journey-optimizer', options: {end: true}},
    {path: '/cjm/home'},
    {path: '/cjm', options: {end: true}}
  ]
};

export const cjmAssets: SolutionRoute = {
  ...baseConfig,
  ...assetsBase,
  appContainer: undefined,
  appId: 'cjm-assets',
  metricsAppId: 'cjm-assets',
  path: '/journey-optimizer/assets',
  redirectFrom: [{path: '/cjm/assets'}],
  unifiedSearch: {...baseConfig.unifiedSearch, compact: true}
};

export const cjmAdminNext: SolutionRoute = {
  ...baseConfig,
  appContainer: '#root',
  appId: 'cjm-next-admin',
  path: '/journey-optimizer/admin',
  spaAppId: 'Voyager-voyager-authoring-ui-react',
  redirectFrom: [{path: '/cjm/admin'}, {path: '/journey-optimizer/v2/admin'}],
  runtime: RUNTIME.CDN,
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    pathPrefix: {default: '/admin-app', preHash: true},
    sources: {
      dev: 'https://localhost.corp.adobe.com:8098'
    }
  },
  thunderbird: THUNDERBIRD.SERVICE_WORKER
};

export const cjmPlatform: SolutionRoute = {
  ...baseConfig,
  ...experiencePlatformSpa,
  appContainer: undefined,
  appId: 'cjm-platform',
  appRoot: 'cjm-home',
  omegaSuiteId: 'cjm',
  parent: 'CJM',
  path: '/journey-optimizer/platform',
  redirectFrom: [{path: '/cjm/platform'}],
  alternateRoute: [
    {
      flag: 'ajo-license-usage-v2-enabled',
      param: 'experience-platform-aep-ui-license-usage_version',
      path: /\/journey-optimizer\/platform\/licenseUsage(\/.*|$)/,
      redirect: '/journey-optimizer/platform/license-usage$1'
    }
  ],
  sideNav // This is necessary because the experiencePlatformSpa will override the setting in baseConfig. This will be a problem when AEP has a sideNav.
};

export const cjmPlatformUIComponents: SolutionRoute = {
  ...baseConfig,
  ...platformUIComponents,
  appContainer: undefined,
  appId: 'cjm-platformUIDecisioningManagementComponents',
  appRoot: 'cjm-home',
  analytics: {omegaGlobal: PDH_GLOBAL.BOTH},
  omegaSuiteId: 'cjm',
  parent: 'CJM',
  path: '/journey-optimizer/platform/components',
  redirectFrom: [{path: '/journey-optimizer/platform/v2/components'}],
  sideNav
};

export const cjmPlatformUILicenseUsage: SolutionRoute = {
  ...baseConfig,
  ...platformUILicenseUsage,
  appId: 'cjm-platformUILicenseUsage',
  appRoot: 'cjm-home',
  analytics: {omegaGlobal: PDH_GLOBAL.BOTH},
  omegaSuiteId: 'cjm',
  parent: 'CJM',
  path: '/journey-optimizer/platform/license-usage',
  alternateRoute: [
    {
      flag: 'ajo-license-usage-v2-enabled',
      param: 'experience-platform-aep-ui-license-usage_version',
      signalsInactive: true,
      path: /\/journey-optimizer\/platform\/license-usage(\/.*|$)/,
      redirect: '/journey-optimizer/platform/licenseUsage$1'
    }
  ],
  sideNav
};

export const cjmPlatformUIOffers: SolutionRoute = {
  ...baseConfig,
  ...platformUIOffers,
  appContainer: undefined,
  appId: 'cjm-platformUIDecisioningManagementOffers',
  appRoot: 'cjm-home',
  analytics: {omegaGlobal: PDH_GLOBAL.BOTH},
  omegaSuiteId: 'cjm',
  parent: 'CJM',
  path: '/journey-optimizer/platform/offers',
  redirectFrom: [{path: '/journey-optimizer/platform/v2/offers'}],
  sideNav
};

export const cjmLandingPagesUi: SolutionRoute = {
  ...baseConfig,
  appId: 'cjmLandingPagesUi',
  settingsAppId: 'analytics',
  path: '/journey-optimizer/landing-pages',
  runtime: RUNTIME.CDN,
  spaAppId: 'cjm-landing-pages-ui',
  sandbox: {
    pathPrefix: {default: '/landing-pages'},
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:8449'
    }
  }
};

export const ajoLandingPagesCJA: SolutionRoute = {
  ...baseConfig,
  ...platformAnalytics,
  access: cjmLandingPagesUi.access,
  appId: 'platformAnalytics-ajoLandingPagesApp', // This must start with platformAnalytics for CJA to load correctly. We could fix this later.
  omegaSuiteId: 'cjm',
  parent: 'CJM',
  path: '/journey-optimizer/landing-page/report',
  sideNav // This is necessary to override the sideNav in platformAnalytics if it ever has one.
};

export const cjmSubscriptionsUi: SolutionRoute = {
  ...baseConfig,
  appId: 'cjmSubscriptionsUi',
  settingsAppId: 'analytics',
  path: '/journey-optimizer/subscriptions',
  runtime: RUNTIME.CDN,
  spaAppId: 'cjm-landing-pages-ui',
  sandbox: {
    pathPrefix: {default: '/subscriptions'},
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:8449'
    }
  }
};

export const ajoSubscriptionsCJA: SolutionRoute = {
  ...baseConfig,
  ...platformAnalytics,
  access: cjmSubscriptionsUi.access,
  appId: 'platformAnalytics-ajoSubscriptionsApp', // This must start with platformAnalytics for CJA to load correctly. We could fix this later.
  omegaSuiteId: 'cjm',
  parent: 'CJM',
  path: '/journey-optimizer/subscription/report',
  sideNav // This is necessary to override the sideNav in platformAnalytics if it ever has one.
};

export const UnifiedDecisioning: SolutionRoute = {
  ...baseConfig,
  appId: 'UnifiedDecisioning',
  path: '/journey-optimizer/unified-decisioning',
  spaAppId: 'cjm-unified-decisioning-ui',
  releaseType: RELEASE_TYPE.GA,
  runtime: RUNTIME.CDN,
  sandbox: {
    pathPrefix: {default: '/unified-decisioning'},
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  }
};

export const cjmLiveReport: SolutionRoute = {
  ...baseConfig,
  appContainer: '#cjm-live-reporting-app',
  appId: 'cjm-live-report',
  settingsAppId: 'analytics',
  path: '/journey-optimizer/reports',
  redirectFrom: [{path: '/cjm/livereports'}, {path: '/journey-optimizer/livereports'}],
  analytics: {omegaGlobal: PDH_GLOBAL.BOTH},
  omegaSuiteId: 'cjm',
  runtime: RUNTIME.CDN,
  sandbox: {
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:3000'
    },
    ims: {
      client_id: 'cjm-live-report',
      scopes:
        'AdobeID, openid, read_organizations, ab.manage, additional_info, additional_info.projectedProductContext, additional_info.roles, read_pc.acp, read_pc, read_pc.dma_tartan'
    }
  },
  spaAppId: 'cjm-live-reporting-ui'
};

export const ajoReportsCJA: SolutionRoute = {
  ...baseConfig,
  ...platformAnalytics,
  access: cjmLiveReport.access,
  appId: 'platformAnalytics-ajoReportsApp', // This must start with platformAnalytics for CJA to load correctly. We could fix this later.
  omegaSuiteId: 'cjm',
  parent: 'CJM',
  path: '/journey-optimizer/channel/report',
  sideNav // This is necessary to override the sideNav in platformAnalytics if it ever has one.
};

export const ajoJourneysCJA: SolutionRoute = {
  ...baseConfig,
  ...platformAnalytics,
  appId: 'platformAnalytics-ajoJourneysApp', // This must start with platformAnalytics for CJA to load correctly. We could fix this later.
  omegaSuiteId: 'cjm',
  parent: 'CJM',
  path: '/journey-optimizer/journey/report',
  access: {
    ...access,
    fi: ['acp:cjm_orch', 'acp:cjm_foundation']
  },
  sideNav // This is necessary to override the sideNav in platformAnalytics if it ever has one.
};

export const cjmJourneysNextAdmin: SolutionRoute = {
  ...journeysNextAdmin,
  ...baseConfig,
  redirectFrom: [{path: '/cjm/journeys'}, {path: '/journey-optimizer/v2/journeys'}],
  appId: 'cjm-journeys-next-admin',
  settingsAppId: 'analytics',
  path: '/journey-optimizer/journeys',
  access
};

export const cjmJourneysNextAuthoring: SolutionRoute = {
  ...journeysNextAuthoring,
  ...baseConfig,
  redirectFrom: [{path: '/journey-optimizer/v2/journeys/journey'}],
  appId: 'cjm-journeys-next-authoring',
  settingsAppId: 'analytics',
  path: '/journey-optimizer/journeys/journey',
  access
};

export const cjmEmailDesigner: SolutionRoute = {
  ...baseConfig,
  appContainer: 'main',
  appId: 'cjm-email-designer',
  path: '/journey-optimizer/content/email-designer',
  redirectFrom: [{path: '/cjm/email-designer'}, {path: '/cjm/content/email-designer'}],
  analytics: {omegaGlobal: PDH_GLOBAL.BOTH},
  spaAppId: 'pixel-acrites-ui',
  runtime: RUNTIME.CDN,
  sandbox: {
    hideInitialSpinner: true,
    history: HISTORY.HASH,
    sources: {
      dev: 'https://localhost.rd.campaign.adobe.com:9000/'
    }
  },
  sideNav: {...baseConfig.sideNav, closeOnLoad: true}
};

export const cjmConfigUi: SolutionRoute = {
  ...baseConfig,
  appContainer: '#root',
  appId: 'cjm-configui',
  path: '/journey-optimizer/configui',
  redirectFrom: [{path: '/cjm/configui'}],
  runtime: RUNTIME.CDN,
  spaAppId: 'exc-cjm-config-ui',
  sandbox: {
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  }
};

export const ajoPermissionsUI: SolutionRoute = {
  ...baseConfig,
  ...permissionsUIConfig,
  appContainer: '#root',
  appId: 'ajo-permissionsUI',
  path: '/journey-optimizer/admin/permissions'
};

export const unifiedTags: SolutionRoute = {
  ...baseConfig,
  appContainer: '#root',
  appId: 'unifiedTags',
  path: '/journey-optimizer/tags',
  spaAppId: 'exc-unified-tags-ui',
  runtime: RUNTIME.CDN,
  sandbox: {
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  },
  access: {flag: 'tags-enabled'},
  analytics: {omegaGlobal: PDH_GLOBAL.BOTH}
};

export const ajofrequencyrulesui: SolutionRoute = {
  ...baseConfig,
  appContainer: '#root',
  appId: 'ajofrequencyrulesui',
  path: '/journey-optimizer/rules/frequency',
  spaAppId: 'cjm-frequency-rules-ui',
  sandbox: {
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  }
};

export const ajobusinessrulesui: SolutionRoute = {
  ...baseConfig,
  appContainer: '#root',
  appId: 'ajobusinessrulesui',
  path: '/journey-optimizer/rules/frequency/rules',
  spaAppId: 'cjm-business-rules-ui',
  releaseType: RELEASE_TYPE.BETA,
  runtime: RUNTIME.CDN,
  sandbox: {
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  }
};

export const cjmChannelHandlerUI: SolutionRoute = {
  ...baseConfig,
  appId: 'cjmChannelHandlerUI',
  path: '/journey-optimizer/channel-handler',
  spaAppId: 'cjm-channel-handler-ui',
  analytics: {omegaGlobal: PDH_GLOBAL.BOTH},
  omegaSuiteId: 'cjm',
  releaseType: RELEASE_TYPE.POC,
  runtime: RUNTIME.CDN,
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013',
      prod: '' // Remove this line before the production release.
    }
  }
};

export const ajoCampaigns: SolutionRoute = {
  ...baseConfig,
  nestedApps: [...baseConfig.nestedApps, ajoContentEditor, cjmChannelHandlerUI, inappEditor],
  appContainer: '#root',
  autoInstantiateRuntime: true,
  appId: 'ajoCampaignsApp',
  settingsAppId: 'analytics',
  path: '/journey-optimizer/campaigns',
  redirectFrom: [{path: '/ajo-campaigns'}],
  spaAppId: 'ajo-mobile-ajo-campaigns-ui',
  thunderbird: THUNDERBIRD.SERVICE_WORKER,
  analytics: {omegaGlobal: PDH_GLOBAL.BOTH},
  omegaSuiteId: 'cjm',
  runtime: RUNTIME.CDN,
  sandbox: {
    history: {
      type: HISTORY.HASH,
      config: {addParamsToHash: true}
    },
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  },
  access: {
    ...access,
    fi: ['acp:cjm_msg', 'acp:cjm_foundation']
  }
};

export const ajoCampaignsCJA: SolutionRoute = {
  ...baseConfig,
  ...platformAnalytics,
  appId: 'platformAnalytics-ajoCampaignsApp', // This must start with platformAnalytics for CJA to load correctly. We could fix this later.
  omegaSuiteId: 'cjm',
  parent: 'CJM',
  path: '/journey-optimizer/campaign/report',
  access: ajoCampaigns.access,
  sideNav // This is necessary to override the sideNav in platformAnalytics if it ever has one.
};

export const audienceOrchestrationUi: SolutionRoute = {
  ...baseConfig,
  appId: 'audienceOrchestrationUi',
  path: '/journey-optimizer/audience/compose',
  redirectFrom: [{path: '/journey-optimizer/audience/orchestration'}],
  runtime: RUNTIME.CDN,
  spaAppId: 'cjm-audience-orchestration-ui',
  analytics: {omegaGlobal: PDH_GLOBAL.BOTH},
  omegaSuiteId: 'cjm',
  sandbox: {
    history: {
      type: HISTORY.HASH,
      config: {addParamsToHash: true}
    },
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  },
  thunderbird: THUNDERBIRD.SERVICE_WORKER
};

export const cjmUseCases: SolutionRoute = {
  ...baseConfig,
  ...useCasesConfig,
  appContainer: '#root',
  appId: 'cjm-use-cases',
  appRoot: 'cjm-home',
  parent: 'CJM',
  path: '/journey-optimizer/use-cases'
};

export const cjmTranslations: SolutionRoute = {
  ...baseConfig,
  ...translationConfig,
  appId: 'cjm-translationService',
  path: '/journey-optimizer/translations',
  metadata: {
    productId: 'AJO',
    productName: 'Adobe Journey Optimizer'
  }
};

export const cjmAdminReporting: SolutionRoute = {
  ...cjmConfigUi,
  appId: 'cjm-admin-reporting',
  path: '/journey-optimizer/admin/reporting',
  redirectFrom: undefined,
  runtime: RUNTIME.CDN,
  sandbox: {
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013/userDataset/list',
      qa: 'https://experience-qa.adobe.com/solutions/exc-cjm-config-ui/userDataset/list',
      stage: 'https://experience-stage.adobe.com/solutions/exc-cjm-config-ui/userDataset/list',
      prod: 'https://experience.adobe.com/solutions/exc-cjm-config-ui/userDataset/list'
    }
  },
  spaAppId: undefined // Need to do this to pass and to override the sources effectively
};

export const cjmTemplateFragmentUI: SolutionRoute = {
  ...baseConfig,
  appId: 'cjmTemplateFragmentUI',
  path: '/journey-optimizer/content-templates',
  spaAppId: 'cjm-template-fragment-ui',
  runtime: RUNTIME.CDN,
  sandbox: {
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  }
};

export const cjmFragmentsUI: SolutionRoute = {
  ...baseConfig,
  appId: 'cjmFragmentsUI',
  path: '/journey-optimizer/fragments',
  spaAppId: 'cjm-fragments-ui',
  runtime: RUNTIME.CDN,
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  }
};

export const cjmPreviewsUI: SolutionRoute = {
  ...baseConfig,
  appId: 'cjmPreviewUI',
  path: '/journey-optimizer/preview-ui',
  thunderbird: THUNDERBIRD.SERVICE_WORKER,
  runtime: RUNTIME.CDN,
  releaseType: RELEASE_TYPE.POC,
  spaAppId: 'cjm-preview-ui',
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013',
      prod: '' // Remove this line before the production release.
    }
  }
};

export const cjmMexp: SolutionRoute = {
  ...baseConfig,
  ...mexpBaseConfig,
  appId: 'ajo-mexp',
  path: '/journey-optimizer/mexp',
  access: {
    ...mexpAccess,
    fi: ['acp:cjm_foundation']
  }
};

export const ajoloyaltychallengesui: SolutionRoute = {
  ...baseConfig,
  appId: 'ajoloyaltychallengesui',
  analytics: {omegaGlobal: PDH_GLOBAL.WEBSDK},
  path: '/journey-optimizer/loyalty/challenges',
  runtime: RUNTIME.CDN,
  spaAppId: 'cjm-challenges-ui',
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  },
  thunderbird: THUNDERBIRD.SERVICE_WORKER,
  access: {
    ...access,
    flag: 'loyalty-challenge-enabled'
  }
};

export const ajoOrion: SolutionRoute = {
  ...baseConfig,
  appId: 'ajoFirstmile',
  analytics: {omegaGlobal: PDH_GLOBAL.WEBSDK},
  path: '/journey-optimizer/firstmile',
  runtime: RUNTIME.CDN,
  spaAppId: 'dms-mobile-phoenix',
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      proxy: 'https://local.orion.adobe.com',
      dev: 'https://local.orion.adobe.com:8888'
    }
  },
  thunderbird: THUNDERBIRD.SERVICE_WORKER
};

export const cjmBrandJourneyUi: SolutionRoute = {
  ...baseConfig,
  appId: 'cjm-brand-journey-ui',
  analytics: {omegaGlobal: PDH_GLOBAL.WEBSDK},
  path: '/journey-optimizer/journeys/batch-journey',
  releaseType: RELEASE_TYPE.POC,
  runtime: RUNTIME.CDN,
  spaAppId: 'cjm-brand-journey-ui',
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013',
      prod: '' // Remove this line before the production release.
    }
  },
  thunderbird: THUNDERBIRD.SERVICE_WORKER
};

export const sampleSizeCalculator: SolutionRoute = {
  ...baseConfig,
  appId: 'sampleSizeCalculator',
  settingsAppId: 'analytics',
  path: '/journey-optimizer/sample-size-calculator',
  runtime: RUNTIME.CDN,
  releaseType: RELEASE_TYPE.ALPHA,
  spaAppId: 'experience-platform-experiment-validator',
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      dev: 'https://localhost.corp.adobe.com:8050'
    }
  },
  thunderbird: THUNDERBIRD.SERVICE_WORKER
};

{
  "alpha": "Alpha",
  "alphaFeedback": "Alpha 意見反應",
  "beta": "Beta",
  "betaFeedback": "Beta 意見回饋",
  "customReleaseTrial": "試用",
  "releaseBadgeAlpha": "Alpha",
  "releaseBadgeBeta": "Beta",
  "releaseBadgeDev": "Dev",
  "releaseBadgePoc": "POC"
}

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
export const SHELL_LD_PROJECT = 'unified-shell';
// Only certain projects can be forced to be re-fetched.
export const FORCIBLE_PROJECTS = [SHELL_LD_PROJECT];

// Launch Darkly projects need to be onboarded to the GraphQL Service
// If you need to add a project here, you'll also need to add it to
// https://git.corp.adobe.com/excgql-fed-excelerate/graphql-service
export const ORG_ONLY_PROJECTS = [
  'aem-generate-variations',
  'aep-co-pilot',
  'experience-platform',
  'marketo',
  'sapphire',
  'target',
  SHELL_LD_PROJECT
];

export const ORG_SANDBOX_PROJECTS = ['cjm', 'project-m', 'voyager', 'voyager-dev'];

export const VALID_LAUNCH_DARKLY_PROJECTS = [... ORG_SANDBOX_PROJECTS, ... ORG_ONLY_PROJECTS];

export const LD_GRAPHQL_QUERY_DATA_KEY = 'multiProjectFeatureFlags';

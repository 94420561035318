/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {experiencePlatformSpa} from './experiencePlatform';
import {HISTORY, PDH_GLOBAL, RELEASE_SCOPE, RELEASE_TYPE, RUNTIME, SolutionRoute, THUNDERBIRD} from '@exc/core/src/models/Solution';
import {puebloBaseConfig} from './pueblo';

// This file was generated automatically from ../solutions/sapphireUI.ts

const access = {
  code: ['acp', 'dma_marketo'],
  codeLogicOp: 'AND',
  flag: 'sapphire-enabled',
  logicOp: 'AND'
};
const sideNav = {id: 'sapphire', workHubId: 'SAPPHIRE'};

const baseConfig = {
  access,
  analytics: {omegaGlobal: PDH_GLOBAL.WEBSDK},
  appRoot: 'sapphireUI',
  gainsight: {productKey: 'AP-GLVNMB5BLV8T-2'},
  parent: 'SAPPHIRE' as const,
  releaseScope: RELEASE_SCOPE.PARENT,
  sideNav,
  thunderbird: THUNDERBIRD.SERVICE_WORKER,
  urlContext: {key: 'sandbox'} as const
};

const baseUrl = '/journey-optimizer-b2b';
const sapphireBaseUrl = '/sapphire';

export const sapphireUI: SolutionRoute = {
  ...baseConfig,
  appId: 'sapphireUI',
  path: baseUrl,
  redirectFrom: [{path: sapphireBaseUrl}],
  spaAppId: 'sapphire-sapphire-ui',
  runtime: RUNTIME.CDN,
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  }
};

export const sapphirePlatform: SolutionRoute = {
  ...baseConfig,
  ...experiencePlatformSpa,
  appContainer: undefined,
  appId: 'sapphire-platform',
  path: `${baseUrl}/platform`,
  redirectFrom: [{path: `${sapphireBaseUrl}/platform`}],

  // Necessary because the experiencePlatformSpa overrides baseConfig.
  appRoot: 'sapphireUI',
  parent: 'SAPPHIRE',
  sideNav,
  unifiedSearch: undefined
};

export const sapphireEmailTemplates: SolutionRoute = {
  ...baseConfig,
  appId: 'sapphireEmailTemplates',
  path: `${baseUrl}/email-templates`,
  redirectFrom: [{path: `${sapphireBaseUrl}/email-templates`}],
  runtime: RUNTIME.CDN,
  spaAppId: 'sapphire-email-templates',
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      dev: 'https://localhost.corp.adobe.com:8015'
    }
  }
};

export const sapphirePersonalizationEditor: SolutionRoute = {
  ...baseConfig,
  appId: 'sapphirePersonalizationEditor',
  path: `${baseUrl}/personalization-editor`,
  redirectFrom: [{path: `${sapphireBaseUrl}/personalization-editor`}],
  spaAppId: 'sapphire-personalization-editor',
  runtime: RUNTIME.CDN,
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      dev: 'https://localhost.corp.adobe.com:8014'
    }
  }
};

export const sapphireAssetsUI: SolutionRoute = {
  ...baseConfig,
  appId: 'sapphireAssetsUI',
  path: `${baseUrl}/assets-listing`,
  redirectFrom: [
    {path: `${baseUrl}/marketo-assets`},
    {path: `${sapphireBaseUrl}/marketo-assets`}
  ],
  runtime: RUNTIME.CDN,
  spaAppId: 'sapphire-sapphire-assets-ui',
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      dev: 'https://localhost.corp.adobe.com:8016'
    }
  }
};

export const sapphireConfigUI: SolutionRoute = {
  ...baseConfig,
  appId: 'sapphireConfigUI',
  path: `${baseUrl}/config`,
  redirectFrom: [{path: `${sapphireBaseUrl}/config`}],
  runtime: RUNTIME.CDN,
  spaAppId: 'sapphire-sapphire-config-ui',
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      dev: 'https://localhost.corp.adobe.com:8044'
    }
  }
};

export const sapphireBuyingGroups: SolutionRoute = {
  ...baseConfig,
  appId: 'sapphireBuyingGroups',
  path: `${baseUrl}/buying-groups`,
  redirectFrom: [{path: `${sapphireBaseUrl}/buying-groups`}],
  runtime: RUNTIME.CDN,
  spaAppId: 'sapphire-buying-groups',
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  }
};

export const sapphireChannelFragmentsUI: SolutionRoute = {
  ...baseConfig,
  appId: 'sapphireChannelFragmentsUI',
  path: `${baseUrl}/fragments`,
  redirectFrom: [{path: `${sapphireBaseUrl}/fragments`}],
  runtime: RUNTIME.CDN,
  spaAppId: 'sapphire-channel-fragments',
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  }
};

export const sapphireAdminConfigsUI: SolutionRoute = {
  ...baseConfig,
  appId: 'sapphireAdminConfigsUI',
  path: `${baseUrl}/admin-configs`,
  redirectFrom: [{path: `${sapphireBaseUrl}/admin-configs`}],
  runtime: RUNTIME.CDN,
  spaAppId: 'sapphire-sapphire-admin-configs-ui',
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  }
};

export const sapphireAdminChannelsUI: SolutionRoute = {
  ...baseConfig,
  appId: 'sapphireAdminChannelsUI',
  path: `${baseUrl}/channels-config`,
  redirectFrom: [{path: `${sapphireBaseUrl}/channels-config`}],
  runtime: RUNTIME.CDN,
  spaAppId: 'sapphire-sapphire-admin-channels-ui',
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  }
};

export const sapphireHome: SolutionRoute = {
  ...baseConfig,
  ...puebloBaseConfig,
  appId: 'sapphireHome',
  appContainer: '#container',
  path: `${baseUrl}/home`,
  redirectFrom: [
    {path: baseUrl, options: {end: true}},
    {path: sapphireBaseUrl, options: {end: true}}
  ]
};

export const sapphireLandingPages: SolutionRoute = {
  ...baseConfig,
  appId: 'sapphireLandingPages',
  path: `${baseUrl}/landing-pages`,
  redirectFrom: [{path: `${sapphireBaseUrl}/landing-pages`}],
  releaseType: RELEASE_TYPE.POC,
  runtime: RUNTIME.CDN,
  spaAppId: 'sapphire-landing-pages',
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013',
      prod: '' // Remove this line before the production release.
    }
  }
};

export const sapphireAccount: SolutionRoute = {
  ...baseConfig,
  appId: 'sapphireAccount',
  path: `${baseUrl}/accounts`,
  releaseType: RELEASE_TYPE.POC,
  runtime: RUNTIME.CDN,
  spaAppId: 'sapphire-sapphire-account',
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013',
      prod: '' // Remove this line before the production release.
    }
  }
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/aiq-ui.ts

const sharedConfig = {
  spaAppId: 'primetime-aiq-ui-unified-shell',
  appRoot: 'primetime',
  experienceLeague: {
    communities: 'adobe-primetime',
    filter: 'Primetime'
  },
  serviceCode: 'dma_primetime',
  sandbox: {
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:12399'
    }
  },
  urlContext: {
    key: 'sandbox',
    optional: true
  } as const
};

export const aiqUiD2c: SolutionRoute = {
  ...sharedConfig,
  appId: 'aiq-ui-d2c',
  path: '/aiq/d2c',
  sandbox: {
    pathPrefix: {default: '/d2c'},
    ...sharedConfig.sandbox
  }
};

export const aiqUiTve: SolutionRoute = {
  ...sharedConfig,
  appId: 'aiq-ui-tve',
  path: '/aiq/tve',
  sandbox: {
    pathPrefix: {default: '/tve'},
    ...sharedConfig.sandbox
  }
};

export const aiqUiHome: SolutionRoute = {
  ...sharedConfig,
  appId: 'aiq-ui-home',
  path: '/aiq/home',
  redirectFrom: [{path: '/aiq', options: {end: true}}],
  sandbox: {
    pathPrefix: {default: '/home'},
    ...sharedConfig.sandbox
  }
};

export const aiqUiCc: SolutionRoute = {
  ...sharedConfig,
  appId: 'aiq-ui-cc',
  path: '/aiq/cc',
  sandbox: {
    pathPrefix: {default: '/cc'},
    ...sharedConfig.sandbox
  }
};

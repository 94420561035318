/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {ExcModuleRuntime} from '@exc/shared';
import type {Metrics} from '@adobe/exc-app/metrics';

interface OmegaAttributes {
  attributes?: Record<string, string>;
}

/**
 * Creates list of attributes to be included in omega payload.
 */
const getAttributes = (element: HTMLElement): OmegaAttributes => {
  let currentElement = element;
  const attributes: Record<string, string> = {
    url: window.location.href // eslint-disable-line
  };

  while (currentElement) {
    const dataAttrs = Object.values(currentElement.attributes);
    Object.values(dataAttrs).forEach(({nodeName, nodeValue}: Attr) => {
      if (nodeName.startsWith('data-omega-attribute-') && nodeValue) {
        const attrName = nodeName.replace('data-omega-attribute-', '');
        attributes[attrName] = nodeValue;
      }
    });

    if (
      currentElement.hasAttribute('data-omega-feature') ||
      currentElement.hasAttribute('data-omega-feature-default')
    ) {
      break;
    }
    currentElement = currentElement.parentElement as HTMLElement;
  }
  // add default attributes
  const defaultFeature = getClosestAttribute(element, 'data-omega-feature-default');
  const defaultWidget = getClosestAttribute(element, 'data-omega-widget-default');
  const defaultWidgetType = getClosestAttribute(element, 'data-omega-widget-type-default');

  if (defaultFeature) {
    attributes.defaultFeature = defaultFeature;
  }
  if (defaultWidget) {
    attributes.defaultWidget = defaultWidget;
  }
  if (defaultWidgetType) {
    attributes.defaultWidgetType = defaultWidgetType;
  }
  return {attributes};
};

/**
 * Gets closest parent with omega props if the target of the click
 * is not the element with omega props.
 */
const getAdjustedTarget = (element: HTMLElement) => {
  const omegaElement = element.closest('[data-omega-element]');
  return omegaElement ? omegaElement : element;
};

/**
 * Gets closest parent with the specific omega attribute and returns the value
 * of that attribute if found.
 */
const getHigherAttribute = (element: HTMLElement, attr: string) => {
  const omegaElement = element.closest(`[${attr}]`);
  if (omegaElement) {
    return omegaElement.getAttribute(attr);
  }
};

/**
 * Checks for data attribute on the element. If it doesn't exist, checks up the
 * dom tree to find it.
 */
const getClosestAttribute = (element: HTMLElement, attr: string) => element.getAttribute(attr) || getHigherAttribute(element, attr);

/**
 * Checks for data attribute on current element and immediate parent only.
 */
const getAttributeFromElementOrParent = (element: HTMLElement, attribute: string) => element.getAttribute(attribute) || element.parentElement?.getAttribute(attribute);

/**
 * Sends an event to OMEGA.
 */
const processEvent = (
  targetElement: HTMLElement,
  metrics: Metrics,
  debugOn: boolean,
  win: Window & typeof globalThis,
  idAttribute?: string
) => {
  // If the element does not have an element attribute, it is not a valid omega annotation.
  const element = targetElement.getAttribute('data-omega-element');
  const elementId = targetElement.getAttribute('data-testid') || targetElement.getAttribute('data-test-id') || undefined;
  if (element) {
    // check for feature
    const feature =
      getClosestAttribute(targetElement, 'data-omega-feature') ||
      getClosestAttribute(targetElement, 'data-omega-feature-default');

    // check for widget name
    const widgetName =
      getClosestAttribute(targetElement, 'data-omega-widget') ||
      getClosestAttribute(targetElement, 'data-omega-widget-default');

    // check for widget type
    const widgetType =
      getClosestAttribute(targetElement, 'data-omega-widget-type') ||
      getClosestAttribute(targetElement, 'data-omega-widget-type-default');

    // check for search term and result count
    const searchTerm = getClosestAttribute(targetElement, 'data-omega-search-term');
    const searchResultCount = getClosestAttribute(targetElement, 'data-omega-search-resultcount');

    if (feature && widgetName) {
      // Optional Values
      const customAction = targetElement.getAttribute('data-omega-action');
      const type = targetElement.nodeName.toLowerCase();
      const attributes = getAttributes(targetElement);
      const payload = {
        action: customAction || 'click',
        element,
        elementId,
        feature,
        ...(searchTerm || searchResultCount ? {search: {resultcount: searchResultCount, term: searchTerm}} : {}),
        type,
        widget: {name: widgetName, type: widgetType || type},
        ...attributes
      };
      metrics.analytics.trackEvent(payload);
      // If the debugging query param is present, console the payload
      if (debugOn) {
        console.log(payload); // eslint-disable-line
      }
    }
  } else if (idAttribute && (win as any)._satellite) {
    const elementInstanceId = getAttributeFromElementOrParent(targetElement, 'data-instance-id');
    (win as any)._satellite?.track('dataInstanceIdScanned', {'data-instance-id': elementInstanceId});
    // If the debugging query param is present, console the payload
    if (debugOn) {
      console.log({id: idAttribute, element: elementInstanceId}); // eslint-disable-line
    }
  }
};

/**
 * Handles events of type 'omega-event', 'pointerup' and 'keydown'.
 * Ensures that Omega event is only sent once for 'omega-event' when a single action
 * triggers multiple events ('omega-event' along with 'pointerup' or 'keydown').
 */
const handleEventFn = (
  e: MouseEvent | KeyboardEvent | Event,
  metrics: Metrics,
  debugOn: boolean,
  win: Window & typeof globalThis,
  idAttribute?: string
) => {
  if (e.type === 'keydown' && (e as KeyboardEvent).key !== 'Enter') {
    return;
  }

  // instanceof must check against the correct global context.
  if (!(e.target instanceof win.HTMLElement)) {
    return;
  }
  let targetElement = e.target;
  // Sometimes the target element is a child of the omega annotated element.
  if (!targetElement.getAttribute('data-omega-element')) {
    targetElement = getAdjustedTarget(targetElement) as HTMLElement;
  }

  // Prevent duplicate event handling ('omega-event' takes precedence over other event types)
  if (e.type === 'omega-event') {
    // Add a flag attribute to target element to indicate that the 'omega-event' has been handled.
    targetElement.setAttribute('data-omega-handled', 'true');
    processEvent(targetElement, metrics, debugOn, win, idAttribute);
  } else {
    // Defer execution when event type is 'pointerup' or 'keydown'
    return win.requestAnimationFrame(() => {
      if (targetElement.getAttribute('data-omega-handled') !== 'true') {
        processEvent(targetElement, metrics, debugOn, win, idAttribute);
      } else {
        targetElement.removeAttribute('data-omega-handled');
      }
    });
  }
};

// eslint-disable-next-line no-restricted-globals
export function trackOmega(name = 'exc.omega', win = window, debugOn = false, newMetrics?: Metrics, idAttribute?: string): void {
  const {metrics: metricsApi}: ExcModuleRuntime = win['exc-module-runtime'] || {};
  if (!newMetrics && !metricsApi) {
    throw new Error('Cannot access the Metrics SDK');
  }
  const metrics = newMetrics || metricsApi.create(name);
  const handleEvent = (e: MouseEvent | KeyboardEvent | Event) => handleEventFn(e, metrics, debugOn, win, idAttribute);

  win.addEventListener('omega-event', handleEvent, {capture: true});
  win.addEventListener('pointerup', handleEvent, {capture: true});
  win.addEventListener('keydown', handleEvent, {capture: true});
}

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {ansiblePlatform, platformBase} from './experiencePlatform';
import {HISTORY, PDH_GLOBAL, RELEASE_TYPE, RUNTIME, SolutionRoute, THUNDERBIRD} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/intelligentservices.ts

const intelligentServicesBase = {
  spaAppId: 'DSNP-UI-SDK-Monorepo',
  analytics: {
    omegaGlobal: PDH_GLOBAL.BOTH
  },
  omegaSuiteId: 'platform',
  runtime: RUNTIME.CDN,
  sandbox: {
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  }
};

export const intelligentservices: SolutionRoute = {
  ...intelligentServicesBase,
  appId: 'intelligentservices',
  appRoot: 'experiencePlatformUI',
  parent: 'AEP',
  path: '/platform/intelligent-services',
  sideNav: {
    id: 'aep',
    workHubId: 'AEP',
    workHubSelectedId: 'service'
  },
  access: {
    code: ['acp']
  },
  urlContext: {
    key: 'sandbox'
  }
};

export const intelligentserviceslabs: SolutionRoute = {
  ...intelligentServicesBase,
  appId: 'intelligentserviceslabs',
  path: '/labs/intelligent-services',
  // Removing access requirement
  // access: { code: ['acp'] },
  urlContext: {
    key: 'sandbox',
    optional: true
  }
};

export const intelligentservicesumm: SolutionRoute = {
  ...intelligentServicesBase,
  appId: 'intelligentservicesumm',
  path: '/labs/umm-playground',
  // Removing the side nav, and we'll probably want a new one for the umm
  // sideNav: {
  //   workHubId: 'AEP',
  //   workHubSelectedId: 'service'
  // },
  access: {
    code: ['acp']
  },
  urlContext: {
    key: 'sandbox'
  }
};

export const labsexperienceintelligence: SolutionRoute = {
  ...intelligentServicesBase,
  appId: 'labsexperienceintelligence',
  path: '/labs/experience-intelligence',
  releaseType: RELEASE_TYPE.POC,
  spaAppId: 'XI-frontend-foundations',
  sandbox: {
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013',
      prod: '' // Remove this line before the production release.
    }
  },
  thunderbird: THUNDERBIRD.SERVICE_WORKER
};

const ummRouteBase = {
  sideNav: {
    id: 'umm',
    workHubId: 'UMM'
  },
  access: {
    code: ['acp']
  },
  urlContext: {
    key: 'sandbox'
  } as const
};

export const adobemixmodeler: SolutionRoute = {
  ...intelligentServicesBase,
  ...ummRouteBase,
  appId: 'adobemixmodeler',
  path: '/mix-modeler',
  access: {
    fi: ['acp:acp_mixmod', 'acp:acp_mixmod_models'],
    flag: 'adobemixmodeler-enabled'
  }
};

export const adobemixmodelerplatform: SolutionRoute = {
  ...intelligentServicesBase,
  ...platformBase,
  ...ummRouteBase,
  nestedApps: [ansiblePlatform],
  appContainer: undefined,
  appId: 'adobemixmodelerplatform',
  path: '/mix-modeler/platform',
  appRoot: undefined,
  parent: undefined,
  sandbox: {
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  },
  spaAppId: 'experience-platform-platform-ui-client'
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {fetch, FetchScope} from '@adobe/exc-app/network';
import {getBootstrap} from './BootstrapService';

type ResponseBody = ResponseBodySameLanguage | ResponseBodyDifferentLanguage;

type ResponseBodyDifferentLanguage = {
  [key: string]: TranslationItem[];
};

type ResponseBodySameLanguage = {
  code: number;
  msg: string;
};

type TranslationItem = {
  error?: string;
  from?: string;
  originalText?: string;
  success?: boolean;
  to?: string;
  translatedText?: string;
};

export type TranslationResponse = TranslationItem | TranslationItem[];

class TranslationService {
  private readonly baseUrl: string;

  constructor(serviceDomain: string) {
    this.baseUrl = serviceDomain;
  }

  /**
   * Calls translation service endpoint to get translated text
   * in specified language.
   * @async
   * @param {Array} arr Array of strings to translate.
   * @param {string} language Locale for language str is translated.
   * Default is locale for english (en_US).
   * @returns {Promise<Object>} Returns object that contains original text
   * and translated text, but also with locale of original and translated text.
   */
  public async translate(arr: string[], language = 'en_US'): Promise<TranslationResponse> {
    const {clientId} = getBootstrap();
    let body: ResponseBody;
    try {
      const response = await fetch(`${this.baseUrl}/api/v5/translate`, {
        body: JSON.stringify({
          contentType: 'UGC',
          domain: 'GENERIC',
          str: arr,
          to: [language]
        }),
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': clientId
        },
        method: 'POST',
        scope: FetchScope.AUTH
      });
      body = await response.json();
    } catch (error: any) {
      return {error};
    }
    // code -9 is returned if from and to language are same
    if (body.code === -9) {
      return arr.map((str: string): TranslationItem => ({from: language, translatedText: str}));
    }
    // Construct a flattened object of the translation data for
    // backwards compatibility from v2 => v5 upgrade (summer 2023)
    const {from, originalText, translations} = Object.values(body)[0][0];
    const {success, to, translatedText} = translations[0];
    // Return an object inside an array for backwards compatibility
    // Previously returned Object.values(body)[0];
    return [{
      from,
      originalText,
      success,
      to,
      translatedText
    }];
  }
}

export default TranslationService;

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {AppMessage, AppSandbox, OutgoingMessage, PongMessage} from '../models/AppSandbox';
import {Events, EventsThirdParty, MessageQueueItem, SpaInfo} from '@exc/shared';
import {getMetricsConfig, getRuntimeParam} from '../utils/appSandboxUtils';
import {getValidSourcesRegexString} from '../utils';
import {Level} from '@adobe/exc-app/metrics/Level';
import metrics from '@adobe/exc-app/metrics';

export class MessageService {
  readonly metrics = metrics.create('exc.core.services.MessageService');
  readonly validSourcesRegexString = getValidSourcesRegexString();
  private outQueue: OutgoingMessage<any>[] = [];
  /**
   * Posts a message to the iframe using the postMessage API.
   * @param frame - Iframe DOM element
   * @param origin - Iframe origin.
   * @param message - Message to send
   * @returns true if message was sent, false otherwise.
   * @private
   */
  private postMessage <T>({frame, origin}: AppSandbox, message: OutgoingMessage<T> | PongMessage): boolean {
    if (frame?.contentWindow) {
      frame?.contentWindow.postMessage({...message, unifiedShell: 1}, origin);
      return true;
    }
    return false;
  }

  /**
   * Sends a message to the iframe.
   * If connection has not yet established, the message would be queued.
   * @param message - Message to send
   * @param app - AppSandbox object
   */
  public sendMessage<T>(message: OutgoingMessage<T> | PongMessage, app: AppSandbox) {
    const {connected, frame, origin} = app;
    // Send messages when we are connected and have the iframe reference.
    // Queue otherwise.
    if (connected && frame) {
      // Enhances security and defense against HTML injections on post messages.
      // Returns early if the origin is not one of the csp allowed origins.
      if (!this.validSourcesRegexString.test(origin)) {
        this.metrics.event('Sandbox.postOriginInvalid', 'Invalid origin', {level: Level.WARN}, {origin});
        return;
      }
      if (this.postMessage(app, message)) {
        return;
      }
      app.inErrorState() || this.metrics.error('Sandbox frame has no contentWindow');
      return;
    }
    app.mounted && this.metrics.info(
      `${message.type || 'unknown'} message sent before connection - Queueing.`, {
        connected,
        hasFrame: !!app.frame
      });
    this.outQueue.push(message);
  }

  /**
   * Sends any outgoing messages waiting in the queue after the connection with the iframe
   * was established.
   * @param app - AppSandbox object.
   * @private
   */
  private processMessageQueue(app: AppSandbox) {
    const {connected, frame} = app;
    if (this.outQueue.length && connected && frame) {
      // Flush all outgoing messages now that we got a Ping.
      this.metrics.event('Sandbox.onMessage.queue.flush', {length: this.outQueue.length});
      this.outQueue.forEach(message => this.sendMessage(message, app));
      this.outQueue = [];
    }
  }

  /**
   * Establish a connection with the iframe following an incoming Ping message.
   * @param message - Incoming ping message.
   * @param app - AppSandbox object.
   * @private
   */
  private async onPing(message: AppMessage<SpaInfo>, app: AppSandbox) {
    const {appId, autoInstantiateRuntime} = app.solutionConfig;
    const {data: {value = {}}, origin} = message;
    // Second ping means iframe reloaded, need to restart timeout.
    app.connected && this.metrics.event('Sandbox.secondPing', {appId});
    app.connected = true;
    app.origin = origin;
    this.metrics.event('Sandbox.onMessage.ping', {appId, hasFrame: !!app.frame, ...value});
    // Reset timeout as we know the frame is alive.
    app.setPageTimeout();
    // Return empty message to initiate origin
    const metricsConfig = await getMetricsConfig(app);
    // Many SPAs load Runtime.js early but instantiate the Runtime object later.
    // For these scenarios, we send some useful information to the iframe via the PONG
    // event so that the iframe can do a few things ahead of Runtime instantiation such as
    // 1. Use EIM
    // PONG event is only dependent on the iframe loading Runtime.js
    this.sendMessage({appId, type: Events.PONG, value: {autoInstantiateRuntime, metricsConfig}}, app);
    this.processMessageQueue(app);
  }

  /**
   * Processes messages batch incoming from the iframe.
   * @param value - Incoming messages array.
   * @param app - AppSandbox object.
   * @private
   */
  private processIncomingQueue(value: MessageQueueItem[], app: AppSandbox): void {
    const historyEvents: MessageQueueItem[] = [];
    let queue = value.filter(v => {
      if (v[0] === Events.HISTORY) {
        historyEvents.push(v);
        return false;
      }
      return true;
    });

    if (historyEvents.length) {
      queue.push(historyEvents[historyEvents.length - 1]);
    }

    // Ignore CLICK event if this is an OPEN_HELP_CENTER event
    if (queue.find(v => v[0] === Events.OPEN_HELP_CENTER)) {
      queue = queue.filter(v => v[0] !== Events.CLICK);
    }

    const {sandbox: {thirdParty}} = app.solutionConfig;
    queue.forEach(([type, val]) => {
      // If loading a third party solution, check if a supported event.
      if (thirdParty && !(type in EventsThirdParty)) {
        this.metrics.event('Sandbox.3rdPartyForbbiden', type, {level: Level.ERROR});
        return;
      }
      app.handlePostMessage(type, val);
    });
  }

  /**
   * In rare scenarios a Ping message can be processed before the iframe element has mounted.
   * When this happens, process the outgoing message queue.
   * @param app - AppSandbox object.
   */
  public processMessageAfterMount(app: AppSandbox): void {
    this.metrics.event('Sandbox.mountAfterConnect');
    this.processMessageQueue(app);
  }

  /**
   * Process an incoming message from the iframe.
   * This only handles a handful of message types, as most messages would come inside a batch.
   * @param message - Incoming message.
   * @param app - AppSandbox object.
   */
  public processMessage(message: AppMessage<any>, app: AppSandbox): void {
    if (message.data?.unifiedShell !== 1) {
      return;
    }

    const {solutionConfig: {sandbox: {thirdParty}}} = app;
    const {appId, type, value} = message.data;

    // If loading a third party solution, check if a supported event.
    if (thirdParty && !(type in EventsThirdParty)) {
      this.metrics.event('Sandbox.3rdPartyForbbiden', type, {level: Level.ERROR});
      return;
    }

    switch (type) {
      case Events.RUNTIME_REQ: {
        const runtimeUrl = getRuntimeParam(app);
        this.postMessage(app, {type: Events.RUNTIME_RESP, value: runtimeUrl});
        return;
      }
      case Events.NOT_INITIALIZED:
        app.waitForRuntime = true;
        this.metrics.event('Sandbox.waitingForRuntimeInit');
        return;
      case Events.PING:
        app.mounted && this.onPing(message, app);
        return;
    }

    // Ignore events that come from a different iframe than what is currently loaded
    if (appId !== app.appId && appId !== 'unified-shell') {
      if (appId && app.lastAppId === appId) {
        this.metrics.info(
          `Post message received from previous app (${appId}). Current appId ${app.appId} - Ignoring.`,
          {message: message.data, origin: message.origin}
        );
        return;
      }
      this.metrics.info(
        `Post message appId ${appId} does not match current appId ${app.appId}`,
        {message: message.data, origin: message.origin}
      );
    }

    if (type === Events.QUEUE) {
      this.processIncomingQueue(value, app);
      return;
    }

    this.metrics.warn(`${type} event sent outside of a queue`);
  }
}

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, PDH_GLOBAL, RUNTIME, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/ansible.ts

export const ansibleStorybook: SolutionRoute = {
  appId: 'ansible-storybook',
  path: '/segment-builder/storybook',
  spaAppId: 'Ansible-ansible-sdk',
  runtime: RUNTIME.CDN,
  sandbox: {
    pathPrefix: {default: '/storybook', dev: ''},
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://ansible.local.cloud.adobe.io:6006',
      prod: ''
    }
  },
  urlContext: {key: 'sandbox', optional: true}
};

export const ansible: SolutionRoute = {
  appId: 'ansible',
  analytics: {omegaGlobal: PDH_GLOBAL.BOTH},
  path: '/segment-builder',
  spaAppId: 'Ansible-ansible-sdk',
  runtime: RUNTIME.CDN,
  sandbox: {
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://ansible.local.cloud.adobe.io:6006',
      prod: ''
    }
  },
  urlContext: {key: 'sandbox', optional: true}
};

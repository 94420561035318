/*************************************************************************
 * Copyright 2020 Adobe
 * All Rights Reserved.
 *
 * NOTICE: Adobe permits you to use, modify, and distribute this file in
 * accordance with the terms of the Adobe license agreement accompanying
 * it. If you have received this file from a source other than Adobe,
 * then your use, modification, or distribution of it requires the prior
 * written permission of Adobe.
 **************************************************************************/

/**
 * @packageDocumentation
 * @module "index"
 */
import {AgreementsApi} from '../agreements';
import {AIApi} from '../ai';
import {AppApi} from '../appapi';
import {CacheApi} from '../cache';
import {ComponentApi} from '../component';
import {ConsentApi} from '../consent';
import {FeatureFlagsApi} from '../featureflags';
import {HelpCenterApi} from '../helpcenter';
import {InternalApi} from '../internal';
import {MetricsApi} from '../metrics';
import {ModulesApi} from '../modules';
import {NetworkApi} from '../network';
import {Nps} from '../nps';
import {OrgSwitcherApi} from '../orgswitcher';
import {PageApi} from '../page';
import {PermissionsApi} from '../permissions';
import {PulseApi} from '../pulse';
import Runtime from './Runtime';
import {SessionApi} from '../session';
import {SettingsApi} from '../settings';
import {ShellApi} from '../shell';
import {SidebarApi} from '../sidebar';
import {SidenavApi} from '../sidenav';
import {TopbarApi} from '../topbar';
import {UserApi} from '../user';
import {UserProfileApi} from '../userprofile';

export interface CommonModules {
  readonly internal: InternalApi;
  readonly metrics: MetricsApi;
  readonly network: NetworkApi;
}

export interface Modules extends CommonModules {
  readonly agreements: () => AgreementsApi;
  readonly ai: () => AIApi;
  readonly appApi: () => AppApi;
  readonly cache: () => CacheApi;
  readonly component: () => ComponentApi;
  readonly consent: () => ConsentApi;
  readonly default: (options?: any) => Runtime;
  readonly featureFlags: () => FeatureFlagsApi;
  readonly helpCenter: HelpCenterApi;
  readonly modules: () => ModulesApi;
  readonly nps: Nps;
  readonly orgSwitcher: OrgSwitcherApi;
  readonly page: PageApi;
  readonly permissions: () => PermissionsApi;
  readonly pulse: () => PulseApi;
  readonly Runtime: new (options?: any) => Runtime;
  readonly session: () => SessionApi;
  readonly settings: () => SettingsApi;
  readonly shell: () => ShellApi;
  readonly sidebar: SidebarApi;
  readonly sidenav: () => SidenavApi;
  readonly topbar: TopbarApi;
  readonly user: () => UserApi;
  readonly userProfile: UserProfileApi;
}

type Global = typeof window & {
  readonly 'exc-module-runtime': Modules & {
    'exc-app-version'?: string;
  };
  EXC_MR_READY?: (() => void) & {autoDelete?: boolean};
};

/**
 * Gets the implementation for the module.
 * @ignore
 * @param moduleName The name of the module.
 * @returns The implementation.
 */
export function getImpl<T extends keyof Modules>(moduleName: T): Modules[T] {
  const emr = (window as Global)['exc-module-runtime'];
  if (!emr) {
    throw new Error(`module-runtime APIs not available (Fetching ${moduleName}).`);
  }
  return emr[moduleName];
}

/**
 * Connects attributes of the specified API.
 * @ignore
 * @param apiName Name of the API.
 * @param attributes The attributes to connect.
 * @returns The API instance.
 */
export function connect<T extends keyof Modules>(apiName: T, attributes: [keyof Modules[T], boolean?][]): Modules[T] {
  const api = {} as Modules[T];
  attributes.forEach(feature => {
    const featureName = feature[0];
    if (feature[1]) {
      api[featureName] = ((...args: any[]) => {
        return (getImpl(apiName)[featureName] as any)(...args);
      }) as any;
    } else {
      Object.defineProperty(api, featureName, {
        get: () => {
          return getImpl(apiName)[featureName];
        },
        set: value => {
          getImpl(apiName)[featureName] = value;
        }
      });
    }
  });
  return api;
}

export default (window as Global);

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2020 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/aem-pages.ts

export const aemPages: SolutionRoute = {
  appId: 'aem-pages',
  path: '/aem-pages',
  sandbox: {
    history: HISTORY.SERVER,
    sources: {
      dev: 'https://localhost.corp.adobe.com:12399/',
      qa: 'https://dev--helix-organizer--adobe.hlx.live/',
      stage: 'https://stage--helix-organizer--adobe.hlx.live/',
      prod: 'https://main--helix-organizer--adobe.hlx.live/'
    }
  },
  access: {flag: 'aem-pages-enabled'}
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, PDH_GLOBAL, RELEASE_TYPE, RUNTIME, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/genStudioStorybooks.ts

const getGenStudioStorybook = (name: string, appId: string): SolutionRoute => ({
  appId,
  spaAppId: appId,
  analytics: {omegaGlobal: PDH_GLOBAL.WEBSDK},
  path: `/${appId.toLowerCase()}`,
  releaseType: RELEASE_TYPE.POC,
  runtime: RUNTIME.CDN,
  sandbox: {
    pathPrefix: {default: '/static-assets/storybook/index.html', dev: '/'},
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013',
      prod: '' // Do not remove line, storybook should not go to production.
    }
  }
});

export const genstudioContentSharedUi: SolutionRoute = getGenStudioStorybook(
  'Gen Studio Content Shared UI storybook',
  'GenStudio-content-shared-ui'
);

export const genstudioContentSdk: SolutionRoute = getGenStudioStorybook(
  'Gen Studio Content SDK storybook',
  'GenStudio-content-sdk'
);

export const genstudioSharedUi: SolutionRoute = getGenStudioStorybook(
  'Gen Studio Shared UI storybook',
  'GenStudio-shared-ui'
);

{
  "alpha": "Alpha",
  "alphaFeedback": "Alpha Feedback",
  "beta": "Beta",
  "betaFeedback": "Beta Feedback",
  "customReleaseTrial": "Trial",
  "releaseBadgeAlpha": "Alpha",
  "releaseBadgeBeta": "Beta",
  "releaseBadgeDev": "Dev",
  "releaseBadgePoc": "POC"
}

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2020 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, PDH_GLOBAL, RUNTIME, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/creativeDesigner.ts

const creativeDesigner: SolutionRoute = {
  appId: 'creative-designer',
  path: '/creative-designer',
  permissionsPolicy: ['clipboard-write'],
  serviceCode: 'dma_campaign',
  spaAppId: 'pixel-acrites-ui',
  omegaSuiteId: 'cc',
  analytics: {omegaGlobal: PDH_GLOBAL.BOTH},
  runtime: RUNTIME.CDN,
  sandbox: {
    hideInitialSpinner: true,
    history: HISTORY.HASH,
    sources: {
      dev: 'https://localhost.rd.campaign.adobe.com:9000/'
    }
  }
};

export default creativeDesigner;

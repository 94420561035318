/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
export enum ErrorTypes {
  COOKIE_NEEDED = 'COOKIE_NEEDED',
  HTML_FAIL = 'HTML_FAIL',
  LOCAL_TIMEOUT = 'LOCAL_TIMEOUT',
  NO_SANDBOXES = 'NO_SANDBOXES',
  NOT_FOUND = 'NOT_FOUND',
  OFFLINE = 'OFFLINE',
  RATE_LIMIT = 'RATE_LIMIT',
  SANDBOX_FAIL = 'SANDBOX_FAIL',
  SUBORG_FAIL = 'SUBORG_FAIL',
  TIMEOUT = 'TIMEOUT',
  UNKNOWN = 'UNKNOWN'
}

export enum AuthMessages {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT'
}

export enum PingResult {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS'
}

export enum HTMLStatus {
  CORRUPT = 'CORRUPT',
  HEADERLIMIT = 'HEADER_LIMIT',
  OK = 'OK',
  OUTDATED = 'OUTDATED',
  PENDING = 'PENDING',
  TIMEOUT = 'TIMEOUT',
  UNREACHABLE = 'UNREACHABLE'
}

export enum RecentStatus {
  ERROR = 'ERROR',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS'
}

export enum LoadState {
  INIT,
  LOAD_START,
  LOADED,
  TIMEOUT
}

export enum AuthType {
  TYPE1 = 'ind',
  TYPE2E = 'eoa',
  TYPE3 = 'fed'
}

export enum AppEvents {
  SLOW = 'SLOW',
  TIMEOUT = 'TIMEOUT'
}

export enum ContextWaitingState {
  WAIT_FOR_DISCOVERY = 'DISCOVERY',
  WAIT_FOR_PERMISSION = 'PERMISSION',
  WAIT_FOR_PROFILE = 'PROFILE',
  WAIT_FOR_SUBORG = 'SUBORG',
  WAIT_FOR_FLAGS = 'FLAGS'
}

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, PDH_GLOBAL, RELEASE_TYPE, RUNTIME, SolutionRoute, THUNDERBIRD} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/experienceManager.ts

const aemHomeUi: SolutionRoute = {
  appId: 'aem-home-ui',
  analytics: {omegaGlobal: PDH_GLOBAL.WEBSDK},
  path: '/experiencemanager',
  sideNav: {closeOnLoad: false},
  releaseType: RELEASE_TYPE.POC,
  runtime: RUNTIME.CDN,
  spaAppId: 'experience-platform-aem-home-ui',
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013',
      prod: '' // Remove this line before the production release.
    }
  },
  thunderbird: THUNDERBIRD.SERVICE_WORKER
};

const experienceManager: SolutionRoute = {
  appId: 'experienceManagerUI',
  appContainer: '#landing-container',
  experienceLeague: {
    communities: 'adobe-experience-manager',
    filter: 'Experience Manager'
  },
  path: '/experiencemanager',
  spaAppId: 'experience-platform-self-service-ui-landing',
  gainsight: {productKey: 'AP-AULLRFDZLJ9K-2'},
  omegaSuiteId: 'aem',
  analytics: {omegaGlobal: PDH_GLOBAL.AA},
  alternateRoute: [{
    config: aemHomeUi,
    flag: 'experience-manager-home-enabled',
    path: /\/experiencemanager(\/.*|$)/
  }],
  sandbox: {
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:8089'
    }
  },
  access: {
    code: ['dma_aem_ams', 'dma_scene7', 'dma_mpod', 'dma_aem_cloud', 'dma_aem_onprem'],
    flag: 'aem-on-prem-enabled'
  }
};

export default experienceManager;

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import BaseService from './BaseService';
import {getValueFromPath} from '@exc/url';
import hashString from 'string-hash';
import {history} from '@exc/router';
import metrics from '@adobe/exc-app/metrics';
import unifiedShellSession from '../UnifiedShellSessionService';
import {updateValueInPath} from '@exc/url/pathUpdate';

const compare = (a, b) => a.name.localeCompare(b.name);

/**
 * Get the pathname of the suborg by key
 * @param {Object} selectedSubOrg Required: current selected sub org
 * @param {String} pathnameKey Required: the key to use to get the pathname from the suborg object
 *
 * @returns {String} Sub org pathname
 */
const getSubOrgPathname = (selectedSubOrg, pathnameKey) => selectedSubOrg && selectedSubOrg[pathnameKey]?.split(' ').join('');

/**
 * Given a product context and the relevant url config, constructs the suborg
 * @param {Object} prodCtx Required: a product context object
 * @param {Object} contextConfig Required: the url config of the given app
 *
 * @returns {Object} The constructed suborg
 */
const getSubOrgFromProductContext = (prodCtx, contextConfig) => {
  const {ident, owningEntity} = prodCtx;
  const {id, name, orgIdName, preferred} = contextConfig;
  const subOrg = {
    id: ident,
    name: typeof name === 'string' ? prodCtx[name] : name(prodCtx),
    orgIdName: typeof orgIdName === 'function' ? orgIdName(prodCtx) : prodCtx[id],
    owningEntity,
    preferred: typeof preferred === 'string' || !preferred ? prodCtx[preferred] : preferred(prodCtx)
  };
  subOrg.path = getSubOrgPathname(subOrg, contextConfig?.customPathKey || 'orgIdName');
  return subOrg;
};

/**
 * Given an app url config, searches through all product contexts to find the suborgs for that app.
 * This function also finds the last used suborg for that app, if possible.
 * @param {Object} profile Required: the user profile object
 * @param {Object} appUrlConfig Required: the url config from the app configuration
 * @param {String} currentImsOrg Optional: the currectly active org
 * @param {Object} localSession Optional: the unified shell session data
 * @param {Object} lastSelectedSubOrgs Optional: an object containing the stored last used suborgs, if any
 *
 * @returns {Array} An alphabetical array of suborgs for the given app and org. If the last used suborg is
 * known it is noted with the prop `lastUsed`.
 */
export const getAllSubOrgsForApp = (profile, appUrlConfig, currentImsOrg, localSession, lastSelectedSubOrgs) => {
  const productContext = profile.projectedProductContext;
  const subOrgsForOrg = localSession?.[`shellSubOrgs-${appUrlConfig.serviceCode}`]?.[currentImsOrg] || {};
  const subOrgArray = [];
  const {id, serviceCode} = appUrlConfig;

  productContext.forEach(({prodCtx}) => {
    const {owningEntity} = prodCtx;
    if (prodCtx[id] && prodCtx.serviceCode === serviceCode && (!currentImsOrg || owningEntity === currentImsOrg)) {
      const formattedSubOrg = getSubOrgFromProductContext(prodCtx, appUrlConfig);
      subOrgArray.find(subOrg => subOrg.id === formattedSubOrg.id) || subOrgArray.push(formattedSubOrg);
    }
  });
  // If no suborgs, return
  if (subOrgArray.length === 0) {
    return;
  }
  // Add lastUsed
  const lastSelected = subOrgsForOrg.lastSelectedSubOrg || lastSelectedSubOrgs?.[currentImsOrg]?.[appUrlConfig.serviceCode];
  const isLastUsed = orgName => subOrgArray.length === 1 || lastSelected === orgName;
  const subOrgs = subOrgArray.map(org => ({...org, lastUsed: isLastUsed(org.name)}));
  // Alphabetically sort
  return subOrgs.sort(compare);
};

export default class SubOrgService extends BaseService {
  isSubOrg = true;

  constructor(solutionConfig, callback, settingsService) {
    super(solutionConfig, callback, 'so', `shellSubOrgs-${solutionConfig.urlContext.config.serviceCode}`, settingsService);
    this.metrics = metrics.create('exc.core.SubOrgService');

    // Use either custom value from config or orgIdName in path.
    const {customPathKey} = this.contextConfig;
    this.pathnameKey = customPathKey || 'orgIdName';
  }

  getSubOrgPathname = selectedSubOrg => getSubOrgPathname(selectedSubOrg, this.pathnameKey);

  getSubOrgFromProductContext = prodCtx => getSubOrgFromProductContext(prodCtx, this.contextConfig);

  getHashedUserId = profile => {
    const userId = this.auth.getHashedUserId() || (profile.userId && hashString(profile.userId).toString());
    userId || this.metrics.warn('No user id for fetching suborgs');
    return userId;
  };

  async fetch(state) {
    await super.fetch(state);
    if (!Object.keys(state.orgToSubOrgsMap).length) {
      await this.getOrgsToSubOrgsMap(state.profile);
    }

    const {profile, selectedImsOrg, shellResponse = {}} = state;
    const userId = this.getHashedUserId(profile);
    const {subOrgs, lastSelectedSubOrg} = await unifiedShellSession.get(this.storageKey, userId, selectedImsOrg) || {};
    let selectedSubOrg;

    if (subOrgs) {
      let pathSubOrg = getValueFromPath(history.location.pathname, this.urlKey);
      pathSubOrg = pathSubOrg && subOrgs.find(({path}) => path === pathSubOrg);

      if (pathSubOrg) {
        selectedSubOrg = pathSubOrg;
      } else {
        // Otherwise, select the last selected sub org from local storage, the default sub org, or the first in the list.
        selectedSubOrg = lastSelectedSubOrg && subOrgs.find(so => so.name === lastSelectedSubOrg);
        selectedSubOrg = selectedSubOrg || subOrgs.find(so => so.preferred) || subOrgs.sort(compare)[0];
      }
    }

    if (selectedSubOrg && shellResponse.services) {
      const allApps = shellResponse.services.concat(shellResponse.solutions);
      const app = allApps.find(a => a.subOrgs?.some(subOrg => subOrg.name === selectedSubOrg.name));
      if (app && !app.subOrgs.find(subOrg => subOrg.name === selectedSubOrg.name).lastUsed) {
        const updatedSubOrgs = app.subOrgs.map(subOrg => ({...subOrg, lastUsed: subOrg.name === selectedSubOrg.name}));
        let appType, key;
        if (shellResponse.services.some(a => a.appId === app.appId)) {
          appType = shellResponse.services;
          key = 'services';
        } else {
          appType = shellResponse.solutions;
          key = 'solutions';
        }
        const updatedApps = appType.map(s => s.appId === app.appId ? {...s, subOrgs: updatedSubOrgs} : s);
        const updatedShellResponse = {...shellResponse, [key]: updatedApps};
        this.updateState({shellResponse: updatedShellResponse});
      }
    }

    if (!selectedSubOrg) {
      this.metrics.warn('No selected sub org', {selectedImsOrg, subOrgHashedUserId: userId});
      const orgAccountMap = await unifiedShellSession.get('shellOrgAccountMap') || {};
      // If the IMS org of the suborg fetch is different from the account of the user, it might be a
      // type2e switch so we should not show the error page yet.
      if (!userId || userId === orgAccountMap[selectedImsOrg]) {
        this.set({selectedImsOrg, selectedSubOrg: null});
        unifiedShellSession.remove(this.storageKey, userId, selectedImsOrg);
        const errorMessage = `User ${userId} doesn't have access to suborgs in org ${selectedImsOrg}.`;
        this.metrics.warn(errorMessage);
        updateValueInPath(this.urlKey, undefined, this.hasTenantInUrl);
        throw new Error(errorMessage);
      }
    }

    this.store(selectedSubOrg, selectedImsOrg);
    this.set({selectedImsOrg, selectedSubOrg});
    return {selectedSubOrg};
  }

  async getOrgsToSubOrgsMap(profile) {
    const session = await unifiedShellSession.get();
    const userId = this.getHashedUserId(profile);

    const localSession = session[userId] || {};
    const subOrgs = getAllSubOrgsForApp(profile, this.contextConfig, undefined, localSession, undefined);
    if (!subOrgs) {
      updateValueInPath(this.urlKey, undefined, this.hasTenantInUrl);
      return;
    }

    const orgToSubOrgsMap = {};
    subOrgs.forEach(suborg => {
      if (localSession[this.storageKey] && localSession[this.storageKey][suborg.owningEntity]) {
        // Add to storage entry unless it already exists
        localSession[this.storageKey][suborg.owningEntity].subOrgs.find(so => so.id === suborg.id) ||
        localSession[this.storageKey][suborg.owningEntity].subOrgs.push(suborg);
        // Form orgs to subOrgs map
        if (!orgToSubOrgsMap[suborg.owningEntity]) {
          orgToSubOrgsMap[suborg.owningEntity] = [suborg];
        } else {
          orgToSubOrgsMap[suborg.owningEntity].find(so => so.id === suborg.id) ||
          orgToSubOrgsMap[suborg.owningEntity].push(suborg);
        }
      } else {
        localSession[this.storageKey] = {
          ...localSession[this.storageKey],
          [suborg.owningEntity]: {
            subOrgs: [suborg]
          }
        };
        orgToSubOrgsMap[suborg.owningEntity] = [suborg];
      }
    });

    this.updateState({orgToSubOrgsMap});
    session[userId] = localSession;
    return unifiedShellSession.set(session);
  }

  async store(selectedSubOrg, selectedImsOrg) {
    if (selectedSubOrg) {
      await super.store({lastSelectedSubOrg: selectedSubOrg.name}, selectedImsOrg);
      const settings = await this.settingsService?.getSetting({lastSelectedSubOrgs: null});
      const newOrgLastSelected = settings?.settings.lastSelectedSubOrgs?.[selectedImsOrg] ? ({
        ...settings?.settings.lastSelectedSubOrgs[selectedImsOrg],
        [this.contextConfig.serviceCode]: selectedSubOrg.name
      }) : ({
        [this.contextConfig.serviceCode]: selectedSubOrg.name
      });
      this.settingsService?.setSetting({
        lastSelectedSubOrgs: {
          ...settings?.settings.lastSelectedSubOrgs,
          [selectedImsOrg]: newOrgLastSelected
        }
      });
    }
  }

  set({selectedSubOrg, selectedImsOrg, setState = true}) {
    let activeProductContext;
    const {customIdName = 'global_company_id'} = this.contextConfig;
    if (selectedSubOrg) {
      const {orgIdName: global_company_id, id: ident, owningEntity} = selectedSubOrg;
      activeProductContext = {
        [this.serviceCode]: {
          [customIdName]: global_company_id,
          ident,
          owningEntity,
          serviceCode: this.serviceCode
        }
      };
    }

    const {orgIdName} = selectedSubOrg || {};
    this.select(orgIdName);
    setState && this.updateState({
      activeProductContext,
      selectedImsOrg,
      selectedSubOrg
    });

    const {path: subOrgPathname} = selectedSubOrg || {};

    if (subOrgPathname && subOrgPathname !== getValueFromPath(history.location.pathname, this.urlKey)) {
      updateValueInPath(this.urlKey, subOrgPathname, this.hasTenantInUrl);
    }
    return {activeProductContext};
  }

  change(selectedImsOrg, selectedSubOrg, setState = true) {
    this.store(selectedSubOrg, selectedImsOrg);
    return this.set({selectedImsOrg, selectedSubOrg, setState});
  }

  onHistoryChange(location, state) {
    const {selectedSubOrg, orgToSubOrgsMap, selectedImsOrg} = state;
    const {path: subOrgPathname} = selectedSubOrg || {};
    const pathSubOrg = getValueFromPath(location.pathname, this.urlKey);

    // If the suborg is manually changed in the URL, set the new suborg.
    if (pathSubOrg && subOrgPathname && pathSubOrg !== subOrgPathname) {
      const updatedSubOrg = (orgToSubOrgsMap[selectedImsOrg] || []).find(({path}) => path === pathSubOrg);
      updatedSubOrg && this.change(selectedImsOrg, updatedSubOrg);
    }

    if (subOrgPathname && !getValueFromPath(location.pathname, this.urlKey)) {
      updateValueInPath(this.urlKey, subOrgPathname, this.hasTenantInUrl);
    }
  }
}

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {DiscoverySource} from '@exc/core/src/models/Discovery';
import {EnvironmentMap, HISTORY, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/campaign.ts

const common = {
  access: {code: ['dma_campaign']},
  autoInstantiateRuntime: true,
  analytics: {code: 'ac'},
  appId: 'acs',
  experienceLeague: {
    communities: 'adobe-campaign-standard',
    filter: 'Campaign Standard'
  },
  serviceCode: 'dma_campaign'
};

// Campaign Launch Page Next
export const campaign: SolutionRoute = {
  ...common,
  appId: 'campaignLaunch',
  access: {code: ['dma_campaign', 'dma_campaign_classic']},
  path: '/campaign',
  spaAppId: 'Campaign-unified-campaign-launch-page',
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      dev: 'https://localhost.corp.adobe.com:12399'
    }
  }
};

const getSources = (domain = 'campaign.adobe.com'): EnvironmentMap<string | DiscoverySource> => ({
  dev: {
    discovery: 'https://localhost.corp.adobe.com/rest/head/imsSession/',
    payload: ['imsOrg'],
    withCredentials: true,
    url: 'https://localhost.corp.adobe.com/{1}',
    defaults: {1: 'view/home'}
  },
  qa: {
    discovery: `https://{mySubOrg}.qa.${domain}/rest/head/imsSession/`,
    payload: ['imsOrg'],
    withCredentials: true,
    url: `https://{mySubOrg}.qa.${domain}/{1}`,
    defaults: {1: 'view/home'}
  },
  stage: {
    discovery: `https://{mySubOrg}.qa.${domain}/rest/head/imsSession/`,
    payload: ['imsOrg'],
    withCredentials: true,
    url: `https://{mySubOrg}.qa.${domain}/{1}`,
    defaults: {1: 'view/home'}
  },
  // All production IMS environment
  prod: {
    discovery: `https://{mySubOrg}.${domain}/rest/head/imsSession/`,
    payload: ['imsOrg'],
    withCredentials: true,
    url: `https://{mySubOrg}.${domain}/{1}`,
    defaults: {1: 'view/home'}
  },
  qe: '', // TO FILL when production (apparently AZURE URLs)
  rd: {
    discovery: `https://{mySubOrg}.rd.${domain}/rest/head/imsSession/`,
    payload: ['imsOrg'],
    withCredentials: true,
    url: `https://{mySubOrg}.rd.${domain}/{1}`,
    defaults: {1: 'view/home'}
  }
});

const commonSandbox = {
  history: {
    type: HISTORY.SERVER,
    config: {spinnerOnChange: true, absolutePaths: true}
  },
  pageTimeout: 90000
};

// Campaign Instances
export const campaignSub: SolutionRoute = {
  ...common,
  path: '/campaign/:mySubOrg',
  sandbox: {...commonSandbox, sources: getSources()}
};

// Campaign Demo Instances
export const campaignDemoSub: SolutionRoute = {
  ...common,
  path: '/campaign/demo/:mySubOrg',
  sandbox: {...commonSandbox, sources: getSources('campaign-demo.adobe.com')}
};

// Campaign Partner Instances
export const campaignPartnerSub: SolutionRoute = {
  ...common,
  path: '/campaign/partner/:mySubOrg',
  sandbox: {...commonSandbox, sources: getSources('campaign-sandbox.adobe.com')}
};

// Campaign Training Instances
export const campaignTrainingSub: SolutionRoute = {
  ...common,
  path: '/campaign/training/:mySubOrg',
  sandbox: {...commonSandbox, sources: getSources('adobevlab.com')}
};

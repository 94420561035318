/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/accp.ts

const accp: SolutionRoute = {
  appId: 'accp',
  path: '/controlpanel',
  serviceCode: 'dma_campaign',
  sandbox: {
    hideInitialSpinner: true,
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:1234',
      qa: 'https://accp-dev.campaign.adobe.com',
      snapshot: 'https://accp-snapshot.campaign.adobe.com/{base}',
      stage: 'https://accp-stage.campaign.adobe.com',
      prod: 'https://accp.campaign.adobe.com'
    },
    ims: {
      client_id: 'accp',
      scopes:
        'AdobeID,openid,read_organizations,creative_cloud,additional_info.company,additional_info.projectedProductContext,session,acss_cpanelapi,additional_info.roles'
    }
  }
};

export default accp;

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {cleanQueryParams, getPathWithoutTenant, getTenantFromPath, getUnifiedShellUrl, getValueFromPath, isExperienceOrigin} from './index';
import {history} from '@exc/router';

interface UrlInfo {
  path?: string;
  sandbox?: string;
  subOrg?: string;
  tenant?: string;
}

/**
 * Cleans any unnecessary query params from the provided path.
 * @param path - The path string to clean.
 * @param filterParams - additional params to filter (optional)
 * @returns Cleaned path.
 */
export const cleanPath = (path: string, filterParams?: Set<string>): string => {
  const url = new URL(`${window.location.origin}${path}`);
  cleanQueryParams(url.searchParams, filterParams);
  return `${url.pathname}${url.search}${url.hash}`;
};

/**
 * Updates path with or without tenant.
 * @param url New url information such as path, tenant, sandbox, or sub org.
 * @param replace Whether or not to replace vs push to history.
 * @param hasTenant Whether or not the updated path should have a tenant.
 * @param contextParam Url context parameter, e.g. so or sname.
 * @param assign If true, use location assign, if false use replace/psuh.
 * @param filterParams additional params to filter (optional)
 */
export const updatePath = (
  url: UrlInfo,
  replace = false,
  hasTenant = true,
  contextParam?: string,
  assign = false,
  filterParams?: Set<string>
): void => {
  let {path, sandbox, subOrg, tenant} = url;
  let {hash, pathname, search} = history.location;
  pathname = pathname || window.location.pathname;

  let fullPath = path ? cleanPath(getPathWithoutTenant(path), filterParams) : `${getPathWithoutTenant(pathname)}${search}${hash}`;
  // If a particular sandbox/suborg has been supplied in URL, we must replace the current one.
  let updatedContextParamValue = sandbox || subOrg;
  if (contextParam && updatedContextParamValue) {
    fullPath = `/${contextParam}:${updatedContextParamValue}${fullPath}`;
  } else {
    let currentUrlContextParam = getValueFromPath(pathname, contextParam || '');
    fullPath = currentUrlContextParam && contextParam ? `/${contextParam}:${currentUrlContextParam}${fullPath}` : fullPath;
  }
  // Clean up tenant
  if (tenant) {
    tenant = tenant.startsWith('/@') ? tenant : `/@${tenant}`;
  }
  let pathTenant = hasTenant ? (tenant || getTenantFromPath(pathname)) : '';

  if (assign) {
    window.location.assign(pathTenant + fullPath);
  } else {
    history[replace ? 'replace' : 'push'](pathTenant + fullPath);
  }
};

export const setUpAppRouter = (env: string) => (
  (path: string) => {
    if (isExperienceOrigin()) {
      const pathTenant = getTenantFromPath(history.location.pathname || window.location.pathname);
      const hash = path.split('#')[1];
      history['push'](pathTenant + hash);
    } else {
      window.open(getUnifiedShellUrl(env, path), '_self');
    }
  }
);

/**
 * Updates sandbox in the URL
 * @param key - context url key, e.g. sname or so.
 * @param name - New value.
 * @param hasTenant - Whether or not the updated path should have a tenant.
 * @param replace - Whether or not the history event should be a replace.
 */
export const updateValueInPath = (key: string, name?: string, hasTenant = true, replace = true): void => {
  let {hash, pathname, search} = history.location;
  name = name ? `/${key}:${name}` : '';
  const fullPath = `${name}${getPathWithoutTenant(pathname)}${search}${hash}`;
  const pathTenant = hasTenant ? getTenantFromPath(pathname) : '';
  history[replace ? 'replace' : 'push'](pathTenant + fullPath);
};

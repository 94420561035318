/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, PDH_GLOBAL, RELEASE_TYPE, RUNTIME, SolutionRoute, THUNDERBIRD} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/experienceGenerationContentAssistant.ts

const baseConfig = {
  analytics: {omegaGlobal: PDH_GLOBAL.WEBSDK},
  releaseType: RELEASE_TYPE.POC,
  runtime: RUNTIME.CDN,
  spaAppId: 'cjm-genai-content-assistant',
  thunderbird: THUNDERBIRD.SERVICE_WORKER,
  urlContext: {key: 'sandbox'} as const,
  appId: 'experienceGenerationContentAssistant',
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  }
};

export const experienceGenerationContentAssistant: SolutionRoute = {
  ...baseConfig,
  path: '/genai-content-assistant',
  nestedApps: [baseConfig]
};

export const experienceGenerationContentAssistantStorybook: SolutionRoute = {
  ...experienceGenerationContentAssistant,
  appId: 'experienceGenerationContentAssistantStorybook',
  path: '/genai-content-assistant/storybook',
  sandbox: {
    pathPrefix: {default: '/static-assets/storybook/index.html', dev: ''},
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013',
      prod: '' // Storybook should not be enabled in production.
    }
  },
  thunderbird: THUNDERBIRD.OFF
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {AGREEMENT_TYPE} from '@adobe/exc-app/agreements';
import classNames from 'classnames';
import {CustomRelease} from '@adobe/exc-app/topbar';
import {getQueryValue} from '@exc/url/query';
import {injectIntl} from 'react-intl';
import metrics from '@adobe/exc-app/metrics';
import {NOOP} from '@exc/shared';
import {PAGE_SIZES} from './utils/headerResponsiveness';
import PropTypes from 'prop-types';
import React, {Suspense} from 'react';
import {RELEASE_TYPE} from '@exc/core/src/models/Solution';
import {segmentCustomLabels} from './utils/utils';
import ShellWait from './ShellWait';
import {storage} from '@exc/storage';
import {trackOmega} from '@exc/events';
import './Shell.scss';

const ShellBanner = React.lazy(() => import('./ShellBanner'));
const ShellNetworkError = React.lazy(() => import('./ShellNetworkError'));
const ShellSidebar = React.lazy(() => import('./ShellSidebar'));
const AIAssistantNoPermissionsWarning = React.lazy(() => import('./ai/NoPermissionsWarning'));
const AIAssistantNotProvisionedWarning = React.lazy(() => import('./ai/NotProvisionedWarning'));
// For local testing of ShellNetworkError, uncomment below
// setTimeout(() => import('./ShellNetworkError'), 5000);

const MEDIUM_MAX_WIDTH = 950;
const SMALL_MAX_WIDTH = 700;
const X_SMALL_MAX_WIDTH = 575;
const MONTH = 1000 * 60 * 60 * 24 * 30;

const AI_POC_LIST = ['cloudManagerUi'];
const appSupportsAlwaysOn = (parentId, appId) => {
  const supportedParents = ['AEP', 'CJM'];
  const ignoredApps = ['platformAnalytics'];
  return supportedParents.includes(parentId) && !ignoredApps.includes(appId);
};
const GEN_AI_AGREEMENT_KEY = 'gen-ai-ga-agreement';

function getBusinessApp(parentId, appId) {
  if (appId === 'shell-testapp') {
    return 'AEP';
  }

  // TODO(rrp): this is a special case, but we should clean up the logic here.
  // The problem is that CJA has "AEP" as the parent app.
  if (appId === 'platformAnalytics') {
    return 'CJA';
  }

  return parentId || appId;
}

/**
 * Shell Super component for Experience Cloud UI.
 */

const shellMetrics = metrics.create('exc.shell.container');

class Shell extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // The permissions to use the AI Assistant is based on org and feature flags.
      hasAIPermission: undefined,
      hasDeferredComponents: false,
      hideAjoBanner: true,
      // "Always on" means the AI Assistant button is visible in the ShellHeader even when
      // the user doens't have permission or the AI Assistant isn't provisioned.
      isAIAlwaysOn: undefined,
      // Blue badge on the AI Assistant icon in the ShellHeader that appears when
      // the user has access to the AI Assistant but hasn't accepted the beta agreement.
      isAIBadgeVisible: false,
      // "Visible" means the Assistant's ShellHeaderAction is visible, but doesn't guarantee
      // org permissions or legal provisioning.
      isAIButtonVisible: false,
      // "Provisioned" means launching the AI Assistant will open the chat sidebar.
      // "Not provisioned" means launching the AI Assistant will trigger a dialog
      // that explains the user doesn't have access because additional legal terms
      // must be agreed to.
      isAIProvisioned: undefined,
      // Is warning dialog open for missing permissions or legal provisioning.
      isAIWarningOpen: false,
      isLoadingAIState: false,
      isNotOutlined: true,
      isSidebarOpen: false,
      segmentedCustomLabels: segmentCustomLabels(props.customEnvLabel),
      size: PAGE_SIZES.LARGE
    };
    this.aiToggleRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    trackOmega('shell.omega', window, Boolean(getQueryValue('debugOmega')));

    this.onResize();
    this.loadDeferred();
  }

  componentDidUpdate(prevProps) {
    const {appId, customEnvLabel, featureFlags, sandbox, selectedImsOrg, workspaces} = this.props;
    const stateConfig = {};

    // Workspaces update late sometimes, so need to run again when updated.
    if (prevProps.workspaces && workspaces && prevProps.workspaces.length !== workspaces.length) {
      this.onResize();
    }

    if (!prevProps.deferredComponentsPromise) {
      this.loadDeferred();
    }

    if (JSON.stringify(customEnvLabel) !== JSON.stringify(prevProps.customEnvLabel)) {
      stateConfig.segmentedCustomLabels = segmentCustomLabels(customEnvLabel);
    }

    const aiPropsChanged = (
      this.hasPropChanged(prevProps, props => props?.sandbox?.selected?.name) ||
      this.hasPropChanged(prevProps, props => props?.selectedImsOrg) ||
      this.hasPropChanged(prevProps, props => props?.appId) ||
      (Object.keys(prevProps?.featureFlags || {}).length !== Object.keys(featureFlags || {}).length)
    );
    // Only try to load assistant after all props are populated to prevent multiple feature flag calls
    if (
      aiPropsChanged &&
      (!sandbox?.enabled || sandbox?.selected?.name) &&
      selectedImsOrg &&
      appId &&
      Object.keys(featureFlags || {}).length
    ) {
      this.tryLoadingAIState();
    }

    Object.keys(stateConfig).length && this.setState(stateConfig);
  }

  hasPropChanged = (prevProps, fn) => fn(prevProps) !== fn(this.props);

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  loadDeferred() {
    this.props.deferredComponentsPromise?.then(deferredComponents => {
      this.deferredComponents = deferredComponents;
      this.setState({hasDeferredComponents: true});
    });
  }

  async tryLoadingAIState() {
    const {appId, featureFlags, getLDFeatureFlags, sandbox, selectedImsOrg} = this.props;
    const flags = selectedImsOrg ? await getLDFeatureFlags(['aep-co-pilot', 'unified-shell']) : {}; // Legal provisioing and featureFlags
    const isAIAlwaysOn = flags['aep-co-pilot']['always-on-icon'] === 'true'; // TODO: update with real FF, this is a placeholder
    const shouldLoad =
      this.isAIOverridden(appId, featureFlags) || // If overridden like with the query param, always load.
      isAIAlwaysOn || // If always on, always load.
      (appId === 'platformAnalytics' || sandbox?.selected?.name) && // Ensure app is CJA or a sandbox is selected for later checks.
      selectedImsOrg; // Ensure an org is selected for later checks.
    const payload = {
      isAIAlwaysOn,
      isAIProvisioned: flags['aep-co-pilot']['assistant-permissions-enabled'] === 'true'
    };
    if (shouldLoad) {
      this.setState({...payload, isLoadingAIState: true}, this.loadAIState);
    } else {
      this.setState({...payload, isLoadingAIState: false});
    }
  }

  async loadAIState() {
    // Permissions: needs sandbox (default), appId (CJA), selectedImsOrg
    // Always on:   needs featureFlags
    // Legal:       needs featureFlags
    // Visibility:  needs appId, permissions, featureFlags, always on
    // Beta:        needs agreements
    // Badge:       needs permissions, beta

    // Query param overrides permissions & visibility: appId, featureFlags, sandbox, selectedImsOrg

    // agreements.get()           will return empty object until it resolves in Core.js
    // featureFlags               will be empty object until it resolves in Core.js
    // hasAssistantPermissions()  will return undefined if sandbox or selectedImsOrg are missing

    const {agreements, appId, featureFlags, hasAssistantPermission} = this.props;
    const {isAIAlwaysOn} = this.state;

    // AI POC apps don't need to adhere to the assistant permissions or provisioning checks.
    // Turn on "always on" too.
    if (this.isAIPOC(appId)) {
      this.setState({hasAIPermission: true, isAIAlwaysOn: true, isAIBadgeVisible: false, isAIButtonVisible: true, isAIProvisioned: true});
      return;
    }

    const [perms, beta] = await Promise.all([
      hasAssistantPermission(), // Org permissions
      agreements.get(GEN_AI_AGREEMENT_KEY) // Beta agreement
    ]);
    const isOverridden = this.isAIOverridden(appId, featureFlags);
    const hasAIPermission = isOverridden ?? perms;
    const isAIBadgeVisible = typeof isOverridden === 'undefined' && hasAIPermission && !beta?.accepted;
    const isAIButtonVisible = this.isAIButtonVisible(appId, hasAIPermission, featureFlags, isAIAlwaysOn);

    this.setState({
      hasAIPermission,
      isAIBadgeVisible,
      isAIButtonVisible,
      isLoadingAIState: false,
      // If AI Assistant is open and the user changes orgs to one without the
      // beta agreement accepted, close the AI Assistant.
      ...((!beta?.accepted || !hasAIPermission) && {isSidebarOpen: false})
    });
  }

  isAIButtonVisible(appId, hasAIPermission, featureFlags, isAIAlwaysOn) {
    const trulyOn = isAIAlwaysOn && appSupportsAlwaysOn(this.props.parentId, appId);
    return this.isAIOverridden(appId, featureFlags) ?? (trulyOn || !!hasAIPermission);
  }

  isAIOverridden(appId, featureFlags) {
    if (appId === 'analytics') {
      return false;
    }

    if (appId === 'cloudManagerUi' && featureFlags['shell-ai-chat-aem-lh'] === 'true') {
      return true;
    }

    if (getQueryValue('aiChatEnabled') === 'true') {
      return true;
    }
  }

  isAIPOC = appId => AI_POC_LIST.includes(appId);

  onToggleSidebar = async ({force = false, forceState = false, query} = {}) => {
    const {isAIProvisioned, hasAIPermission, isAIAlwaysOn, isAIBadgeVisible, isSidebarOpen: isOpen} = this.state;
    const isSidebarOpen = force ? forceState : !isOpen;

    if (isAIProvisioned === false && isSidebarOpen) {
      this.setState({isAIWarningOpen: true});
      shellMetrics.event('ai.chat.sidebar.show-provisioning-warning');
      return;
    }

    if (!hasAIPermission && isAIAlwaysOn && isSidebarOpen) {
      this.setState({isAIWarningOpen: true});
      shellMetrics.event('ai.chat.sidebar.show-permission-warning');
      return;
    }

    if (isSidebarOpen) {
      const {accepted} = await this.props.agreements.show(GEN_AI_AGREEMENT_KEY, {type: AGREEMENT_TYPE.AI});
      if (!accepted) {
        return;
      }
      isAIBadgeVisible && this.setState({isAIBadgeVisible: false});
    }

    this.setState({
      initialAIQuery: query,
      isSidebarOpen
    });
    isSidebarOpen || setTimeout(() => this.aiToggleRef.current?.focus(), 0); // Timeout handles dismissing expanded mode
    shellMetrics.event('ai.chat.sidebar.open', {force, isSidebarOpen});
  };

  dismissAIWarning = () => this.setState({isAIWarningOpen: false});

  /**
   * Handler for browser resize event.
   * @listens Event window's resize event.
   */
  onResize = () => {
    const width = window.innerWidth;
    let newSize;

    if (width > MEDIUM_MAX_WIDTH) {
      newSize = PAGE_SIZES.LARGE;
    } else if (width <= X_SMALL_MAX_WIDTH) {
      newSize = PAGE_SIZES.EXTRA_SMALL;
    } else if (width <= SMALL_MAX_WIDTH) {
      newSize = PAGE_SIZES.SMALL;
    } else {
      newSize = PAGE_SIZES.MEDIUM;
    }

    newSize !== this.state.size && this.setState({size: newSize});
  };

  /**
   * Determines if the AJO banner should be shown. Requirements:
   * 1. User is in AJO
   * 3. User has not dismissed the banner this login
   */
  canShowAjoBanner = () => this.props.parentId === 'CJM' && !this.state.hideAjoBanner;

  /**
   * When the AJO banner is dismissed via the close (x) button, does the following:
   * 1. Increments the number of times the banner has been dismissed
   * 2. Sets a flag to prevent the banner from being shown again this login
   * 3. Hides the banner via state
   */
  onAjoBannerDismiss = () => {
    storage.local.set('ajoBannerDismissedPhase2', true, MONTH);
    this.setState({hideAjoBanner: true});
  };

  render() {
    const {
      accessOverridesEnabled,
      appContainer,
      appId,
      appRoot,
      appTheme,
      authId,
      backgroundReady,
      basePath,
      children,
      closeLanguagePicker,
      coachMark,
      currentActiveTenantId,
      customHeroClick,
      customProfileButtons,
      customSearch,
      devMode,
      disableShellFeedbackButton,
      environment,
      featureFlags,
      feedback,
      getCapabilityWithIconUrl,
      globalSearch,
      hasAccountClusterData,
      helpCenterConfig,
      imsOrgs,
      internal,
      intl,
      isImpersonating,
      landingpage,
      locale,
      localeData,
      modal,
      networkErrorProps,
      noAccess,
      onCustomProfileButtonClick,
      onGlobalDialogOpen,
      onImsOrgChange,
      onLanguageChange,
      onSetUserTheme,
      onSignOutClick,
      onSolutionClick,
      orgToSubOrgsMap,
      parentId,
      profile,
      pulse,
      preferredLanguages,
      recentSandboxes,
      registerKeyHandler,
      releaseType,
      sandbox,
      selectedImsOrg,
      selectedSideNavItemId,
      selectedSubOrg,
      selectedWorkspaceId,
      serviceEnvironment,
      services,
      settingsData,
      settingsService,
      showDebugModal,
      showLanguagePicker,
      showToast,
      sideNav = {},
      solutionIcon,
      solutionReleaseType,
      solutions,
      solutionShortTitle,
      solutionTitle,
      subOrgAccessOnlyOnCurrentOrg,
      theme,
      toastPlacement,
      translationService,
      userAvatar,
      userRecentSandboxesHandler,
      userToken,
      workHubResponse,
      workspaces
    } = this.props;
    const {
      hasAIPermission,
      hasDeferredComponents,
      initialAIQuery,
      isAIProvisioned,
      isAIBadgeVisible,
      isAIButtonVisible,
      isAIWarningOpen,
      isSidebarOpen,
      segmentedCustomLabels,
      size
    } = this.state;
    const ShellHeader = hasDeferredComponents ? this.deferredComponents.ShellHeader : 'header';
    const releaseTypeMessages = {
      [CustomRelease.TRIAL]: intl.formatMessage({
        defaultMessage: 'Trial',
        description: 'Label for Trial software access',
        id: 'customReleaseTrial'
      }),
      [RELEASE_TYPE.ALPHA]: intl.formatMessage({
        defaultMessage: 'Alpha',
        description: 'Label for ALPHA service lifecycle',
        id: 'releaseBadgeAlpha'
      }),
      [RELEASE_TYPE.BETA]: intl.formatMessage({
        defaultMessage: 'Beta',
        description: 'Label for BETA service lifecycle',
        id: 'releaseBadgeBeta'
      }),
      [RELEASE_TYPE.DEV]: intl.formatMessage({
        defaultMessage: 'Dev',
        description: 'Label for DEV service lifecycle',
        id: 'releaseBadgeDev'
      }),
      [RELEASE_TYPE.POC]: intl.formatMessage({
        defaultMessage: 'POC',
        description: 'Label for POC service lifecycle',
        id: 'releaseBadgePoc'
      })
    };
    const ShellHeaderProps = hasDeferredComponents ? {
      accessOverridesEnabled,
      aiSidebarEnabled: isAIButtonVisible,
      aiToggleRef: this.aiToggleRef,
      appContainer,
      appId,
      appRoot,
      appTheme,
      backgroundReady,
      basePath,
      coachMark,
      currentActiveTenantId,
      customHeroClick,
      customProfileButtons,
      customSearch,
      devMode,
      disableShellFeedbackButton,
      environment,
      featureFlags,
      feedback,
      globalSearch: {
        ...globalSearch,
        SearchWidget: this.deferredComponents.SearchWidget
      },
      hasAccountClusterData,
      helpCenterConfig,
      imsOrgs,
      internal,
      isAIBadgeVisible,
      isImpersonating,
      landingpage,
      locale,
      localeData,
      modal,
      noAccess,
      onCustomProfileButtonClick,
      onGlobalDialogOpen,
      onImsOrgChange,
      onLanguageChange,
      onSetUserTheme,
      onSignOutClick,
      onSolutionClick,
      onToggleSidebar: this.onToggleSidebar,
      orgToSubOrgsMap,
      parentId,
      preferredLanguages,
      pulse,
      recentSandboxes,
      registerKeyHandler,
      releaseTypeMessages,
      sandbox,
      segmentedCustomLabels,
      selectedImsOrg,
      selectedSubOrg,
      selectedWorkspaceId,
      serviceEnvironment,
      services,
      settingsData,
      settingsService,
      showDebugModal,
      sidebarOpen: isSidebarOpen,
      sideNav,
      size,
      solutionIcon,
      solutionReleaseType,
      solutions,
      solutionShortTitle,
      solutionTitle,
      subOrgAccessOnlyOnCurrentOrg,
      theme,
      toastPlacement,
      translationService,
      userAvatar,
      userEmail: profile.email,
      userFirstName: profile.first_name,
      userLastName: profile.last_name,
      userName: profile.name,
      userRecentSandboxesHandler,
      userTitle: profile.job_function,
      userToken,
      workHubResponse,
      workspaces
    } : {
      className: `spectrum-Shell spectrum--${theme || 'lightest'} spectrum-Shell-headingContainer`
    };

    let AlertsContainer = null;
    if (hasDeferredComponents) {
      AlertsContainer = this.deferredComponents.AlertsContainer;
    }

    const ShellSideNav = hasDeferredComponents ? this.deferredComponents.ShellSideNav : () => null;
    if (!disableShellFeedbackButton) {
      const feedbackReleaseTypes = [RELEASE_TYPE.ALPHA, RELEASE_TYPE.BETA];
      const releaseTypes = [releaseType, solutionReleaseType];
      const isAlphaOrBeta = !!feedbackReleaseTypes.filter(value => releaseTypes.includes(value)).length;

      // Only modify the feedback property if the releaseType or
      // solutionReleaseType is Alpha or Beta. In the other scenarios, the
      // feedback property should not be overwritten, as it will have come from
      // an API call from the application.
      if (isAlphaOrBeta) {
        // If the feedback button hasn't been explicitly disabled,
        // check if the pre-release values are Alpha or Beta.
        // The order of the release types is important here.
        ShellHeaderProps.feedback = [releaseType, solutionReleaseType].reduce((button, release) => {
          if (button) {
            // If we already have a button, then we're done
            return button;
          }

          if (feedbackReleaseTypes.includes(release)) {
            // If the pre-release value is Alpha or Beta, then we need to show the button
            return release === RELEASE_TYPE.ALPHA ? {
              buttonLabel: intl.formatMessage({defaultMessage: 'Alpha Feedback', description: 'Alpha feedback button label', id: 'alphaFeedback'}),
              enabled: true,
              type: 'openFeedback'
            } : {
              buttonLabel: intl.formatMessage({defaultMessage: 'Beta Feedback', description: 'Beta feedback button label', id: 'betaFeedback'}),
              enabled: true,
              type: 'openFeedback'
            };
          }
        }, undefined);
      }
    }

    // Add magenta border
    isImpersonating ? document.body.classList.add('impersonation') : document.body.classList.remove('impersonation');

    const businessApp = getBusinessApp(parentId, appId);
    return (
      <div
        className="spectrum-ShellContainer"
        data-omega-feature="shell header"
        data-omega-widget="shell header buttons"
        data-omega-widget-type="button">
        {networkErrorProps?.showNetworkDisconnected &&
          <Suspense fallback={null}>
            <ShellNetworkError {...networkErrorProps} />
          </Suspense>
        }
        <ShellHeader {...ShellHeaderProps} />
        <div className={classNames('spectrum-ShellContainer-content', {
          'can-takeover': appContainer,
          'has-appContainer': appContainer,
          'modal-open': modal
        })}>
          {hasDeferredComponents && showLanguagePicker &&
            <Suspense fallback={<ShellWait />}>
              <this.deferredComponents.ShellLanguagePicker
                authId={authId}
                closeLanguagePicker={closeLanguagePicker}
                locale={locale}
                onLanguageChange={onLanguageChange}
                serviceEnvironment={serviceEnvironment}
                showLanguagePicker={showLanguagePicker}
                userToken={userToken}
              />
            </Suspense>
          }
          {sideNav.config &&
            <Suspense fallback={null}>
              <ShellSideNav
                appContainer={appContainer}
                appId={appId}
                env={environment}
                featureFlags={featureFlags}
                getCapabilityWithIconUrl={getCapabilityWithIconUrl}
                isOpen={sideNav.enabled && sideNav.open}
                locale={locale}
                modal={modal}
                orgId={selectedImsOrg}
                releaseTypeMessages={releaseTypeMessages}
                selectedSideNavItemId={selectedSideNavItemId}
                settingsService={settingsService}
                sideNavConfig={sideNav.config}
                sideNavOpenByHover={sideNav.openByHover}
                theme={theme}
                toggleSideNav={sideNav.toggle}
                userId={profile.authId}
                workHubResponse={workHubResponse}
              />
            </Suspense>
          }
          {this.canShowAjoBanner() && (
            <Suspense fallback={null}>
              <ShellBanner
                locale={locale}
                onDismiss={this.onAjoBannerDismiss}
                sideNavOpen={sideNav.enabled && sideNav.open} />
            </Suspense>
          )}
          {children}
          {AlertsContainer &&
            <Suspense fallback={null}>
              <AlertsContainer theme={theme} />
            </Suspense>}
          {isAIButtonVisible && (hasAIPermission && isAIProvisioned ? (
            <Suspense fallback={null}>
              <ShellSidebar
                appContainer={appContainer}
                appId={appId}
                appRoot={appRoot}
                authId={profile.authId}
                avatar={profile.avatar}
                businessApp={businessApp}
                debugEnabled={featureFlags?.['co-pilot-review-enabled'] === 'true'}
                email={profile.email || ''}
                env={environment}
                featureFlags={featureFlags}
                initialQuery={initialAIQuery}
                isPOC={this.isAIPOC(appId)}
                onClose={this.onToggleSidebar}
                open={isSidebarOpen}
                orgId={selectedImsOrg}
                sandboxName={sandbox.selected?.name}
                services={services}
                settingsService={settingsService}
                showToast={showToast}
              />
            </Suspense>
          ) : (
            <Suspense fallback={null}>
              {!hasAIPermission ? (
                <AIAssistantNoPermissionsWarning isOpen={isAIWarningOpen} onDismiss={this.dismissAIWarning} />
              ) : (
                <AIAssistantNotProvisionedWarning isOpen={isAIWarningOpen} onDismiss={this.dismissAIWarning} />
              )}
            </Suspense>
          ))}
        </div>
      </div>
    );
  }
}

Shell.propTypes = {
  appContainer: PropTypes.string,
  backgroundReady: PropTypes.bool,
  basePath: PropTypes.string,
  currentActiveTenantId: PropTypes.string,
  customButtons: PropTypes.object,
  customEnvLabel: PropTypes.array,
  customHeroClick: PropTypes.func,
  customSearch: PropTypes.shape({
    enabled: PropTypes.bool,
    onClick: PropTypes.func,
    open: PropTypes.bool
  }),
  devMode: PropTypes.bool,
  disableShellFeedbackButton: PropTypes.bool,
  featureFlags: PropTypes.object,
  getCapabilityWithIconUrl: PropTypes.func,
  getLDFeatureFlags: PropTypes.func,
  hasAccountClusterData: PropTypes.bool,
  helpCenterConfig: PropTypes.shape({
    resources: PropTypes.arrayOf(
      PropTypes.shape({
        href: PropTypes.string,
        icon: PropTypes.string,
        label: PropTypes.string
      })
    )
  }),
  imsOrgs: PropTypes.arrayOf(
    PropTypes.shape({
      imsOrgId: PropTypes.string,
      imsOrgName: PropTypes.string,
      tenantId: PropTypes.string
    })
  ).isRequired,
  landingpage: PropTypes.shape({
    description: PropTypes.string,
    href: PropTypes.string,
    longname: PropTypes.string,
    name: PropTypes.string,
    visible: PropTypes.bool
  }),
  locale: PropTypes.string,
  networkErrorProps: PropTypes.object,
  noAccess: PropTypes.bool,
  onCustomButtonClick: PropTypes.func,
  onImsOrgChange: PropTypes.func,
  onSignOutClick: PropTypes.func,
  onSolutionClick: PropTypes.func,
  recentSandboxes: PropTypes.array,
  releaseType: PropTypes.oneOf(Object.values(RELEASE_TYPE)),
  sandbox: PropTypes.shape({
    enabled: PropTypes.bool,
    onChange: PropTypes.func,
    sandboxes: PropTypes.array,
    selected: PropTypes.object
  }),
  selectedImsOrg: PropTypes.string,
  selectedSubOrg: PropTypes.object,
  selectedWorkspaceId: PropTypes.string,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      enabled: PropTypes.bool,
      href: PropTypes.string,
      learnMoreLink: PropTypes.string,
      longname: PropTypes.string,
      name: PropTypes.string,
      visible: PropTypes.bool
    })
  ),
  settingsData: PropTypes.object,
  settingsService: PropTypes.object,
  showDebugModal: PropTypes.func,
  sideNav: PropTypes.shape({
    buttonEnabled: PropTypes.bool,
    config: PropTypes.object,
    enabled: PropTypes.bool,
    open: PropTypes.bool,
    openByHover: PropTypes.bool,
    toggle: PropTypes.func
  }),
  solutionIcon: PropTypes.string,
  solutionReleaseType: PropTypes.oneOf(Object.values(RELEASE_TYPE)),
  solutions: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      enabled: PropTypes.bool,
      href: PropTypes.string,
      learnMoreLink: PropTypes.string,
      longname: PropTypes.string,
      name: PropTypes.string,
      visible: PropTypes.bool
    })
  ),
  solutionShortTitle: PropTypes.string,
  solutionTitle: PropTypes.string,
  userAvatar: PropTypes.string,
  userFirstName: PropTypes.string,
  userLastName: PropTypes.string,
  userName: PropTypes.string,
  userRecentSandboxesHandler: PropTypes.func,
  userTitle: PropTypes.string,
  workspaces: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string
    })
  )
};

Shell.defaultProps = {
  appContainer: null,
  backgroundReady: false,
  currentActiveTenantId: '',
  customButtons: [],
  customSearch: {
    enabled: false,
    onClick: () => {},
    open: false
  },
  hasAccountClusterData: false,
  helpCenterConfig: {},
  landingpage: {},
  locale: 'en-US',
  noAccess: false,
  onCustomButtonClick: NOOP,
  onImsOrgChange: NOOP,
  onSignOutClick: NOOP,
  onSolutionClick: NOOP,
  releaseType: RELEASE_TYPE.GA,
  sandbox: {
    enabled: false,
    onChange: NOOP,
    sandboxes: [],
    selected: null
  },
  selectedWorkspaceId: '',
  services: [],
  showDebugModal: null,
  solutionReleaseType: RELEASE_TYPE.GA,
  solutions: [],
  solutionShortTitle: 'AEC',
  solutionTitle: 'Adobe Experience Cloud',
  userAvatar: undefined,
  userName: 'Adobe User',
  userTitle: undefined,
  workspaces: []
};

export default injectIntl(Shell);

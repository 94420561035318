/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {
  CACHE_SCOPE,
  DiscoveryCacheConfig,
  DiscoveryPayloadKeys,
  DiscoverySource,
  PATH_CACHE_METHOD
} from '@exc/core/src/models/Discovery';
import {ErrorInfo} from '@exc/core/src/models/Common';
import {HISTORY, RUNTIME, SessionScope, SolutionRoute} from '@exc/core/src/models/Solution';
import type {Location} from 'history';

// This file was generated automatically from ../solutions/analytics.ts

const cache: DiscoveryCacheConfig = {
  keepParams: [
    'company',
    'current_org'
  ],
  pathCacheMethod: PATH_CACHE_METHOD.CACHE_PARAMS,
  removeParams: [
    'jpj',
    'redirectedFromUnifiedShellHtml',
    'redirected',
    'ssSession',
    'unifiedShellImsRedirectForPhp'
  ],
  scope: CACHE_SCOPE.LOCAL,
  ttl: 31 * 86400 // One month
};

const payload: DiscoveryPayloadKeys = ['activeProductContext', 'imsOrg', 'path'];

const getErrorInfo = (res: Record<string, string>): ErrorInfo => {
  // Special case for the discovery services.
  if (res.errorCode === '400' && res.errorDescription?.startsWith('Could not find Analytics Company')) {
    return {id: 'company_notfound'};
  }
  return {
    description: res.errorDescription,
    heading: res.errorHeading,
    id: res.errorCode
  };
};

const sessionDiscovery: DiscoverySource = {
  cache: {
    pathCacheMethod: PATH_CACHE_METHOD.CACHE_DEFAULT_PATH,
    removeParams: [
      'jpj',
      'ssSession'
    ],
    scope: CACHE_SCOPE.LOCAL,
    ttl: 31 * 86400
  },
  discovery: '{lastOrigin}/sc15/json/generatePhpSession.php',
  getErrorInfo,
  includesSession: true,
  payload: ['path', 'activeProductContext', 'company', 'tenant', 'imsOrg', 'imsProfile'],
  reduceProfile: true,
  simpleRequest: true,
  withCredentials: true
};

const getDiscoveryUrl = (url: string) => ({
  cache,
  discovery: url,
  getErrorInfo,
  payload,
  next: sessionDiscovery
});

const analytics: SolutionRoute = {
  appId: 'analytics',
  settingsAppId: 'analytics',
  decodeUrl: true,
  experienceLeague: {
    communities: 'adobe-analytics',
    filter: 'Analytics'
  },
  path: '/analytics',
  serviceCode: 'dma_analytics',
  analytics: {code: 'aa'},
  mfeAppIds: ['AnalyticsUI-core'],
  gainsight: {productKey: 'AP-WVMZ1DVKRDJD-2'},
  permissionsPolicy: ['clipboard-write'],
  urlContext: {
    key: 'subOrg',
    config: {
      id: 'global_company_id',
      name: 'login_company',
      preferred: 'is_preferred',
      serviceCode: 'dma_analytics'
    }
  },
  sections: [
    {
      name: 'Analytics SPA',
      route: '/spa/',
      metricsAppId: 'analytics'
    },
    {
      name: 'Analytics PHP',
      route: '/p/',
      sessionParameter: 'ssSession',
      metricsAppId: 'analytics-php'
    },
    {
      name: 'Analytics PHP',
      route: '/sc15/',
      sessionParameter: 'ssSession',
      metricsAppId: 'analytics-php'
    }
  ],
  runtime: RUNTIME.CDN_LEGACY,
  sandbox: {
    hideInitialSpinner: true,
    history: {
      type: HISTORY.SERVER,
      config: {absolutePaths: true}
    },
    ims: {
      client_id: 'SiteCatalyst2',
      scopes: 'openid,AdobeID,session,read_organizations,additional_info.job_function,additional_info.user_image_url,' +
        'additional_info.projectedProductContext,additional_info.roles'
    },
    sources: {
      dev: 'https://localhost.corp.adobe.com:8443/analytics.html',
      qa: getDiscoveryUrl('https://appservice-discovery-stage.int.ethos11-stage-ut1.ethos.adobe.net/1.0/unifiedShell'),
      stage: getDiscoveryUrl('https://appservice-discovery-stage.int.ethos11-stage-ut1.ethos.adobe.net/1.0/unifiedShell'),
      prod: getDiscoveryUrl('https://analytics.discovery.adobe.net/1.0/unifiedShell')
    },
    pageTimeout: 300000
  },
  session: {
    defaultTTL: 7200, // 2 hours, can be extended by Analytics.
    getFromDiscovery: true,
    multiSessions: true,
    scope: SessionScope.CONTEXT
  },
  access: {code: ['dma_analytics']},
  getPathOrgFromContext: (location: Location): string => {
    const url = new URL(`https://test.url/${location.search}`);
    return decodeURIComponent(url.searchParams.get('current_org') || '');
  }
};

export default analytics;

/*************************************************************************
 * Copyright 2020 Adobe
 * All Rights Reserved.
 *
 * NOTICE: Adobe permits you to use, modify, and distribute this file in
 * accordance with the terms of the Adobe license agreement accompanying
 * it. If you have received this file from a source other than Adobe,
 * then your use, modification, or distribution of it requires the prior
 * written permission of Adobe.
 **************************************************************************/

/**
 * APIs that let solutions interact with the top bar and personalize it, e.g. configuring the
 * solution area on the left, setting up workspaces, custom search, etc.
 *
 * ***Import:***
 *
 * ```typescript
 * import topbar from '@adobe/exc-app/topbar';
 * ```
 *
 * ***Default export:***
 *
 * [TopbarApi](../interfaces/topbar.topbarapi.md#interface-topbarapi)
 *
 * ***Usage:***
 *
 * ```typescript
 * import topbar from '@adobe/exc-app/topbar';
 *
 * topbar.customEnvLabel = 'Beta';
 * ```
 * @packageDocumentation
 * @module topbar
 */

import {connect} from './src/Global';

/**
 * Defines the attributes of the solution to display on the left-side of the top bar.
 */
export interface Solution {
  /**
   * The icon to display in the solution area. If this isn't set, then the default experience cloud
   * icon will be used.
   */
  icon: string;

  /**
   * The title to display on smaller browser resolutions where the horizonal space is constrained.
   */
  shortTitle: string;

  /**
   * The title to display. Defaults to 'Adobe Experience Cloud'.
   */
  title: string;
}

export interface CustomSearchConfig {
  /**
   * Used to control whether or not the search icon in the top bar is enabled and clickable.
   */
  enabled?: boolean;

  /**
   * Flag used to notify shell whether the custom search dialog has been opened/closed.
   */
  open?: boolean;

  /**
   * The callback to execute when the search icon is clicked.
   */
  callback?: Callback;
}

/**
 * @ignore
 */
export interface CoachMarkConfig {
  /**
   * The callback to execute when the position of the element Coach Marks is pointing to is updated.
   * The position, which specifies the horizontal position of the element in pixels,
   * will be passed as an argument to the callback.
   */
  callback?: CoachMarkCallback;

  /**
   * Valid CSS selector string indicating which element Coach Marks should be pointing to.
   */
  element: string;

  /**
   * Whether or not Coach Marks is currently enabled in the Shell.
   */
  enabled: boolean;
}

export interface CustomFeedbackConfig {
  buttonLabel: string;
  buttonVariant?: 'cta' | 'secondary';
  callback: Callback;
  enabled: boolean;
  type: 'custom';
}

export interface ExternalFeedbackConfig {
  buttonLabel: string;
  buttonVariant?: 'cta' | 'secondary';
  enabled: boolean;
  type: 'external';
  url: string;
}

export interface HelpCenterFeedbackConfig {
  buttonLabel: string;
  buttonVariant?: 'cta' | 'secondary';
  enabled: boolean;
  subject?: string;
  type: 'openFeedback';
}

interface WorkspaceMenu {
  /**
   * Workspace name shown on the tab (unique).
   */
  name: string;

  /**
   * Optional when menu is used. Path when workspace is clicked. This can alternatively be a full
   * url. This must be a path if it needs to stay within the Unified Shell.
   */
  url?: string;

  /**
   * Optionally nest related links within an n-level menu underneath the workspace.
   */
  menu?: Array<WorkspaceMenu>;

  /**
   * If the workspace should open in a new tab.
   */
  newtab?: boolean;

  /**
   * Optional array of regexes that allow the solution application to decide when a workspace should
   * be selected. This is useful when there is a workspace like items and diving into a single item
   * nets a URL like item/<id>. The same workspace should probably still be selected in this case,
   * so the value of this option might be [/item\/\d*\/]
   */
  selectOn?: RegExp[];
}

export interface Callback {(value?: any): void}

/**
 * @ignore
 */
export interface CoachMarkCallback {(value?: number): void}

/**
 * This descriptor defines the custom environment labels where the type must be
 * CustomLabelType.ENVIRONMENT and the value will be whatever string you want to
 * display. This is exclusively used with the customEnvLabel api.
 */
interface CustomEnvironmentDesc {
  type: CustomLabelType.ENVIRONMENT;
  value: string;
}

/**
 * This descriptor defines a custom release label where the type must be
 * CustomLabelType.RELEASE and the value must be one of the predetermined enum
 * values. This is exclusively used with the customEnvLabel api.
 */
interface CustomReleaseDesc {
  type: CustomLabelType.RELEASE;
  value: CustomRelease;
}

export type CustomLabelDesc = CustomEnvironmentDesc | CustomReleaseDesc;

export enum CustomLabelType {
  /**
   * The default label type.
   */
  ENVIRONMENT = 'ENVIRONMENT',

  /**
   * Renders as a release badge. Can only render one badge and only for solutions in GA release.
   */
  RELEASE = 'RELEASE'
}

export enum CustomRelease {
  /**
   * "Trial" release badge. Can only be shown if your solution is already in GA.
   */
  TRIAL = 'TRIAL'
}

export type CustomLabelCollection = string | Array<string | CustomLabelDesc>| null;

export interface TopbarApiProperties {
  /**
   * Gets or sets a custom environment label in the shell. Values can be a simple string or
   * a `CustomLabelDesc` with `type` and `value` keys. The default label `type` is 'ENVIRONMENT'.
   * Specifying a `CustomRelease` option as the `value` is required when `type` is
   * `CustomLabelType.RELEASE`. Only one CustomLabelType.RELEASE type can be rendered in the header
   * and only for solutions that are in GA release.
   *
   * ***Example:***
   * import {CustomLabelType, CustomRelease} from '@adobe/exc-app/topbar';
   *
   * ```typescript
   * topbar.customEnvLabel = 'Beta';
   * topbar.customEnvLabel = ['Beta', 'Test'];
   * topbar.custonEnvLabel = [{type: CustomLabelType.ENVIRONMENT, value: 'Test'}];
   * topbar.customEnvLabel = ['Beta', {type: CustomLabelType.RELEASE, value: CustomRelease.TRIAL}];
   * ```
   */
  customEnvLabel: CustomLabelCollection;

  /**
   * Configuration for solution name and hero. All values aside from
   * path can only be used by third party applications. All other solutions will use
   * their default product set branding as of the 2020 branding changes.
   *
   * ***Example:***
   *
   * ```typescript
   * topbar.solution = {
   *   icon: 'AdobeExperienceCloud',
   *   shortTitle: 'AEC',
   *   title: 'Adobe Experience Cloud22'
   * };
   * ```
   */
  solution: Solution;

  /**
   * Configuration for the Shell workspaces. Workspace names should be unique, and should be
   * localized using the unified shell locale prior to setting runtime.workspaces.
   *
   * ```typescript
   * topbar.workspaces = [
   *   {name: 'Home', url: '/'},
   *   {name: 'ABC', url: '/abc'},
   *   {name: 'DEF', url: '/def'}
   * ];
   * ```
   *
   * Unified shell also supports workspace flyout menus, where clicking on a workspace displays a
   * dropdown menu. The top-level workspace is present in the dropdown menu as the first menu item.
   * Menus may be nested multiple times, and used in combination with normal workspaces. By default,
   * parent menu items will be automatically added to the sub-menu due to how the user interaction
   * works. To prevent this functionality, simply remove the url property on the parent item and it
   * will not be injected into the sub-menu.
   *
   * ```typescript
   * topbar.workspaces = [
   *   {name: 'Home', url: '/'},
   *   {name: 'ABC', url: '/abc'},
   *   {
   *     name: 'DEF',
   *     url: '/def',
   *     menu: [
   *       {
   *         name: 'GHI',
   *         url: '/def/ghi',
   *         menu: [
   *           {name: 'JKL', url: '/def/ghi/jkl'}
   *         ]
   *       }
   *     ]
   *   }
   * ];
   * ```
   */
  workspaces: WorkspaceMenu[];
}

/**
 * Defines page-level APIs available to solutions.
 */
export interface TopbarApi extends TopbarApiProperties {
  /**
   * Registers a callback to execute when the hero (solution) icon in the upper lefthand corner of
   * the Shell is clicked.
   * @param callback The callback to execute.
   */
  onHeroClick(callback: Callback): void;

  /**
   * Determines whether or not to enable the custom search property and enacts a callback
   * when the custom search icon is clicked.
   */
  setCustomSearch(config: CustomSearchConfig | null): void;

  /**
   * Determines whether or not Coach Mark is enabled within the Shell and which element
   * should currently be pointed to based on the CSS selector string. If no position is available,
   * the selector is either not valid or the element was hidden due to the screen width being too small.
   *
   * ```typescript
   * topbar.setCoachMark({
   *   callback: (position) => {
   *     if (!position) {
   *       return this.setState({coachMark {enabled: false, position}});
   *     }
   *     this.setState({coachMark: {enabled: true, position}});
   *   },
   *   element: '#hero',
   *   enabled: true
   * })
   * ```
   * @param config The Coach Mark configuration with valid CSS selector and enabled boolean.
   * Enacts a callback when the Coach Mark is enabled and positioned on an element.
   */
  /**
   * @ignore
   */
  setCoachMark(config: CoachMarkConfig | null): void;

  /**
   * Determines whether or not to enable a feedback button.
   */
  setFeedbackButton(config: CustomFeedbackConfig | HelpCenterFeedbackConfig | ExternalFeedbackConfig | null): void;
}

const topbar = connect('topbar', [
  ['customEnvLabel'],
  ['onHeroClick', true],
  ['setCustomSearch', true],
  ['setCoachMark', true],
  ['setFeedbackButton', true],
  ['solution'],
  ['workspaces']
]);

export default topbar;

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, PDH_GLOBAL, RELEASE_TYPE, RUNTIME, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/dataComposer.ts

export const dataComposer: SolutionRoute = {
  appContainer: '#root',
  appId: 'federatedData',
  path: '/federated-data',
  releaseType: RELEASE_TYPE.POC,
  analytics: {omegaGlobal: PDH_GLOBAL.WEBSDK},
  spaAppId: 'experience-platform-data-composer-ui',
  runtime: RUNTIME.CDN,
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  },
  urlContext: {
    key: 'sandbox',
    optional: false
  }
};

export const dataComposerStorybook: SolutionRoute = {
  appId: 'federatedDataStorybook',
  path: '/federated-data/storybook',
  releaseType: RELEASE_TYPE.POC,
  spaAppId: 'experience-platform-data-composer-ui',
  runtime: RUNTIME.CDN,
  sandbox: {
    pathPrefix: {default: '/static-assets/storybook/index.html', dev: ''},
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:8014',
      prod: ''
    }
  }
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {getAppController} from '../utils/appSandboxUtils';

export interface LoadStateResponse {
  lastAppId?: string;
  sandboxId: string;
}

class AppLoadState {
  private firstRender = true;
  private lastAppId = '';

  /**
   * Records state when app loads.
   * @param appId - Application ID
   * @returns Previous app ID, or nothing if this is the first loaded application.
   */
  public onSandboxLoad(appId: string): LoadStateResponse {
    const sandboxId = `${appId}-${Date.now()}`;
    const state: LoadStateResponse = {sandboxId};
    if (this.lastAppId && this.lastAppId !== appId) {
      this.firstRender = false;
      state.lastAppId = this.lastAppId;
    } else {
      this.lastAppId = appId;
    }
    getAppController().registerApp(sandboxId, {appId});
    return state;
  }

  /**
   * Records state when application renders
   * @returns true if this is the first application rendered, false otherwise.
   */
  public updateFirstRender(): boolean {
    const {firstRender} = this;
    this.firstRender = false;
    return firstRender;
  }

  public unregisterSandbox = (sandboxId: string) => getAppController().unregisterApp(sandboxId);
}

export default new AppLoadState();

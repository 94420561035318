/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
export {trackOmega} from './trackOmega';
export {sendOmegaEvent} from './sendOmegaEvent';
interface EventData {
  action?: string;
  attributes?: Record<string, any>;
  element: string;
  event?: EventDataEvent;
  feature?: string;
  search?: Record<string, any>;
  type?: string;
  widget?: Record<string, any>;
}

export interface EventDataEvent {
  target: Record<string, any>;
  type: string;
}

export interface OmegaProps {
  'data-omega-feature'?: string;
  'data-omega-widget'?: string;
  'data-omega-widget-type'?: string;
  [key: string]: any; // This line allows for any attribute starting with "data-omega-attribute-"
}

/**
 * Generates an object of omega event data.
 * @param data Omega event data.
 * @param data.attributes Optional list of attributes.
 * @param data.element The name or label of the element acted upon.
 * @param data.event The event.
 * @param data.feature The feature.
 * @param data.search Optional search.
 * @param data.type The type.
 * @param data.widget The widget, a subset of the feature.
 * @returns Correctly formatted object of event information.
 */
export function getOmegaEvent({action, attributes, element, event, feature, search, type, widget}: EventData): Record<any, any> {
  action = action || event && event.type;
  type = type || event && event.target && event.target.nodeName.toLowerCase();
  feature = feature || 'shell header';
  widget = widget || {name: element, type};

  const eventData = {action, attributes, element, feature, search, type, widget};
  if (!eventData.attributes) {
    delete eventData.attributes;
  }
  if (!eventData.search) {
    delete eventData.search;
  }
  return eventData;
}

/**
 * Filter props to only include specific Omega properties. This function is only used
 * in cases where users want to pass data-omega-feature, data-omega-widget and
 * data-omega-widget-type to override the default omega attributes added by Quarry or
 * other components having default omega attributes. It can also be used to filter
 * default omega props passed to components, or to pass other attributes such as
 * data-omega-attribute-* and data-omega-action. This function does not retain
 * the element and action omega attributes as they do not support default values.
 * @param props - An object containing any props.
 * @returns An object containing only the props that have Omega properties.
 */
export const filterOmegaProps = (props: {[key: string]: any}): OmegaProps => {
  const omegaProps: OmegaProps = {};

  Object.entries(props).forEach(([key, value]) => {
    if (
      [
        'data-omega-feature',
        'data-omega-feature-default',
        'data-omega-widget',
        'data-omega-widget-default',
        'data-omega-widget-type',
        'data-omega-widget-type-default',
        'data-omega-action',
        'data-omega-search-term',
        'data-omega-search-resultcount'
      ].includes(key) ||
      key.startsWith('data-omega-attribute-')
    ) {
      omegaProps[key] = value;
    }
  });
  return omegaProps;
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {AuthInstance} from '@exc/auth';
import {getBootstrap} from '../BootstrapService';
import unifiedShellSession from '../UnifiedShellSessionService';

/* eslint-disable no-unused-vars */
export default class BaseService {
  constructor(solutionConfig, callback, urlKey, storageKey, settingsService) {
    this.contextConfig = solutionConfig.urlContext.config;
    this.hasTenantInUrl = !solutionConfig.hideTenant;
    this.initialized = false;
    this.serviceCode = solutionConfig.urlContext.config?.serviceCode || solutionConfig.serviceCode;
    this.storageKey = storageKey;
    this.updateState = callback;
    this.urlKey = urlKey;
    this.settingsService = settingsService;
  }

  /**
   * Initializes the service with values that won't change.
   */
  async initialize() {
    const bootstrap = getBootstrap();
    bootstrap.authInformation || await bootstrap.getAuthInformation();
    this.auth = AuthInstance();
    this.initialized = true;
  }

  /**
   * Get method to fetch refreshed value of current context from URL.
   * @param {Object} state - State values needed for fetching.
   */
  async fetch(state) {
    this.initialized || await this.initialize();
  }

  /**
   * Returns the context parameter used in the URL.
   * @returns {string} The parameter in the URL, e.g. sname or so
   */
  getUrlKey = () => {
    if (this.solutionConfig?.urlContext?.incognito) {
      return undefined;
    }
    return this.urlKey;
  };

  /**
   * Gets the unique ID of the active context.
   */
  getId = () => this.selectedId && `${this.urlKey}-${this.selectedId}`;

  /**
   * Sets the current selected context
   * @param {string} selectedId
   */
  select(selectedId) {
    this.selectedId = selectedId;
  }

  /**
   * Set method to set new value of current context.
   * @abstract
   * @param {Object|string} newValue - New context value.
   * @param {Object=} oldValue - If new value is an object, provide the old value so the two can be merged in the update.
   */
  set(newValue, oldValue) {
    // Intentional no return
  }

  /**
   * Stores url context values in local storage.
   * @param {Object|string} newValue - Value to be stored in local storage.
   * @param {string} selectedImsOrg - Org that the value should be stored under.
   */
  async store(newValue, selectedImsOrg) {
    this.initialized || await this.initialize();
    unifiedShellSession.update(this.storageKey, newValue, this.auth.getHashedUserId(), selectedImsOrg);
  }

  /**
   * Function to be called when context value is changed.
   * @abstract
   * @param {string} selectedImsOrg - Current ims org.
   * @param {string} newValue - New context value.
   * @param {Object=} oldValue - If new value is an object, provide the old value so the two can be merged in the update.
   */
  change(selectedImsOrg, newValue, oldValue) {
    // Intentional no return
  }

  /**
   * Function to be called when a history update is fired.
   * @abstract
   * @param {Object=} location - New location.
   * @param {Object} state - State values.
   */
  onHistoryChange(location, state) {
    // Intentional no return
  }
}

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {AccessItem, AccessOverridesStorageObject, Override} from '../../../models/AccessOverrides';
import type {FeatureFlagsById} from '../../../models/FeatureFlags';
import type {FFOverride, FlagValue, FlattenedFeatureFlag} from '../../../models/LaunchDarkly';
import type {FulfillableItems} from '../../../models/Context';
import {sortAndFilterFlags} from '../../../services/FeatureFlagServices/utils';
import {storage} from '@exc/storage';

export enum ACCESS_TYPES {
  FEATURE_FLAGS = 'featureFlags',
  FLOODGATE = 'floodgate',
  FULFILLABLE_ITEMS = 'fulfillableItems'
}

export enum OVERRIDE_TYPES {
  BOOLEAN = 'Boolean',
  STRING = 'String'
}

/**
 * Handles adding new items into a list
 * Supports bulk adding through comma separated strings
 * Replaces space inside the string with an underscore
 * @param {string} inputString - The text string input
 * @param {string || boolean || number} defaultValue - The default value for reset items
 * @param {array} overrides - The list of overrides
 * Returns a formatted item for Quarry Inventory
 */
export const getNewOverrides = <V>(inputString: string, defaultValue: V, overrides: Override<V>[] = []): Override<V>[] | undefined => {
  // Early return for empty strings;
  if (!inputString.length) {
    return;
  }
  // Support comma separated list of items entered by creating an array without duplicates
  let inputs = [...new Set(inputString.split(/[,]+/))];
  // Strip spaces from the start/end and replace spaces with underscores
  inputs = inputs.map(i => i.trim().replace(/[ ]+/g, '_'));
  // Filter and format the added values
  return inputs
    .filter(input => !overrides.find(override => override.key === input))
    .map(input => ({isOverride: true, key: input, value: defaultValue}));
};

/**
 * Merges the overrides and a list of access items in Quarry Inventory format
 * @param {array} list - The list of items for a project
 * @param {array} overrides - The list of a project's overrides
 */
export const mergeOverridesAndList = <V>(list: AccessItem<V>[] = [], overrides: Override<V>[] = []) => {
  const mergedList = [...list];
  // We don't mutate the list or overrides data, so we can
  // support action buttons to "reset" rendered items etc
  overrides.forEach(override => {
    const index = mergedList.findIndex(({key}) => key === override.key);
    // If the override is new, add it (added override use case)
    // Otherwise if it has a match, update it (override existing flag use case)
    if (index === -1) {
      mergedList.push(override);
    } else {
      mergedList.splice(index, 1, override);
    }
  });
  // Return an alpha sorted list
  return mergedList.length > 1 ? mergedList.sort((fi1, fi2) => fi1.key.localeCompare(fi2.key)) : mergedList;
};

/**
 * Merges feature flags object and flag overrides for use in Unified Shell
 * Is scoped to one project/client's feature flags
 * @param {array} overrides - The list of a project's overrides
 * @param {Object} flags - Feature flags by project object
 */
export const mergeFeatureFlagsForShell = (overrides: FFOverride[] = [], flags: Record<string, string> = {}): Record<string, FlagValue> => {
  if (!overrides.length) {
    return flags;
  }
  // Convert overrides to an object for easy merging
  const overridesObject = overrides.reduce<Record<string, FlagValue>>((obj, {key, value}) => {
    obj[key] = value;
    return obj;
  }, {});
  return {...flags, ...overridesObject};
};

/**
 * Merges overrides and a list of fulfillable items for Unified Shell (Core.js)
 * @param {string} selectedImsOrg - Current IMS org id
 * @param {string} fiServiceCode - Current service code for fulfillable items
 * @param {Object} fis - Fulfillable items object, keyed by org then code
 * @param {array} overrides - Overrides from storage, an array of objects
 */
export const mergeFulfillableItemsForShell = (
  selectedImsOrg: string,
  fiServiceCode: string,
  fis: FulfillableItems = {},
  overrides: AccessItem<string | boolean>[] = []
): FulfillableItems => {
  // Early return if there are no overrides to merge
  if (!overrides.length) {
    return fis;
  }
  // Save the whole userFulfillableItems object or construct one if it's missing
  const mergedFullData = !Object.keys(fis).length ? {[selectedImsOrg]: {[fiServiceCode]: []}} : {...fis};
  // Then update the current scope (IMS org, service code)
  const mergedList = [...mergedFullData[selectedImsOrg][fiServiceCode]];
  overrides.forEach(({key, value}) => {
    // If there is an override already in the list:
    // If the override value is true, and it's already in the list, no action is needed
    // If the override is false, remove from the array
    if (mergedList.includes(key) && !value) {
      const index = mergedList.findIndex(fi => fi === key);
      mergedList.splice(index, 1);
    } else {
      // If the override is NOT in the list AND the value is true, add it
      // False values should NOT be included in the override list
      value && mergedList.push(key);
    }
  });
  return {...mergedFullData, [selectedImsOrg]: {[fiServiceCode]: mergedList}};
};

/**
 * Reduces a list to the original state with only new overrides included
 * @param {array} list - The list of items
 * @param {string || boolean || number} defaultValue - The default value for reset items
 * @param {array} overrides - The list of overrides
 */
export const reduceOverridesToAdditions = <V>(
  list: AccessItem<V>[],
  defaultValue: V,
  overrides: Override<V>[] = []
): Override<V>[] => {
  const listKeys = new Set(list.map(({key}) => key));
  return overrides.reduce<Override<V>[]>((filteredList, {key}) => {
    if (!listKeys.has(key)) {
      filteredList.push({isOverride: true, key, value: defaultValue});
    }
    return filteredList;
  }, []);
};

/**
 * Merges feature flag response data with overrides for development and testing
 * Wrapper scoped to object with multiple project/client ids
 * @param {FeatureFlagsById} projectFeatureFlags - Response of feature flags by project/client id
 * @param {string} hashedAuthId - User hash string used in storage of override values
 * @param {string} overrideKey - The key used to access the overrides in storage
 * @returns {FeatureFlagsById} - A flattened object of projects with feature flag properties
 */
export const overrideFeatureFlags = (
  projectFeatureFlags: FeatureFlagsById,
  hashedAuthId: string,
  overrideKey = ACCESS_TYPES.FEATURE_FLAGS
): FeatureFlagsById => {
  const storedData = storage.local.getSync<AccessOverridesStorageObject>('accessOverrides');
  const featureFlags = storedData?.[hashedAuthId]?.[overrideKey] || {};
  const mergedFlags = {...projectFeatureFlags};
  for (const [key, value] of Object.entries(featureFlags)) {
    mergedFlags[key] = mergeFeatureFlagsForShell(value, projectFeatureFlags[key]) as FlattenedFeatureFlag;
  }
  return mergedFlags;
};

/**
 * Helper wrapper function that merges feature flag response data with overrides, filters, and sorts them for return
 * @param {FeatureFlagsById} response - Response of feature flags by project/client id
 * @param {array} projectIds - List of project/client ids
 * @param {string} hashedAuthId - User hash string used in storage of override values
 * @param {string} overrideKey - The key used to access the overrides in storage
 * @returns {FeatureFlagsById} - A flattened object of projects with feature flag properties
 */
export const overrideApiFeatureFlags = (
  response: FeatureFlagsById,
  projectIds: string[],
  hashedAuthId: string,
  overrideKey = ACCESS_TYPES.FEATURE_FLAGS
): FeatureFlagsById => {
  const flagData = overrideFeatureFlags(response, hashedAuthId, overrideKey);
  return sortAndFilterFlags(projectIds, flagData);
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2020 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, PDH_GLOBAL, SessionScope, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/screens.ts

const screens: SolutionRoute = {
  appId: 'screens',
  analytics: {
    omegaGlobal: PDH_GLOBAL.WEBSDK
  },
  path: '/screens',
  sandbox: {
    history: HISTORY.HASH,
    sources: {
      dev: 'https://localhost.corp.adobe.com:9000',
      qa: 'https://localhost.corp.adobe.com:9000',
      stage: 'https://screens-stage.adobeio-static.net',
      prod: 'https://screens.adobeio-static.net'
    }
  },
  session: {
    defaultTTL: 86400, // 1 day max, actual ttl controlled by screens
    scope: SessionScope.USER
  },
  access: {code: ['dma_aem_ams', 'dma_aem_cloud']}
};

export default screens;

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, PDH_GLOBAL, RUNTIME, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/griffon.ts

const griffon: SolutionRoute = {
  appId: 'griffon',
  path: '/assurance',
  analytics: {omegaGlobal: PDH_GLOBAL.AA} as const,
  omegaSuiteId: 'griffon',
  gainsight: {productKey: 'AP-GLVNMB5BLV8T-2'},
  redirectFrom: [{path: '/griffon'}],
  access: {
    fi: ['acp:acp_assurance'],
    flag: 'assurance-root-enabled'
  },
  runtime: RUNTIME.CDN,
  sandbox: {
    history: HISTORY.HISTORY,
    sources: {
      proxy: 'https://local.griffon.adobe.com',
      dev: 'https://local.griffon.adobe.com:8888/'
    }
  },
  spaAppId: 'dms-mobile-gemini'
};

export default griffon;

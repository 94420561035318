/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {extractTokenMetadata} from '@exc/shared';
import {getTokenKey} from './IMSUtils';
import type {ImsProfile} from '@adobe/exc-app/ims/ImsProfile';
import type {IMSUserData} from '../models';
import Metrics from '@adobe/exc-app/metrics';
import type {TokenExchangeResponse} from '@exc/graphql/src/models/auth';
import type {UserActivityMonitor} from '@adobe/exc-app/internal';

export interface RefreshEmitPayload extends IMSUserData, TokenConfigProps {
  isExcClient: boolean;
}

interface Expiration extends TokenConfigProps {
  eventFn: (event: string, payload: RefreshEmitPayload) => void;
  isExcClient: boolean;
  refreshAt: number;
  refreshFn: (args?: TokenConfigProps) => Promise<IMSUserData>;
}

interface TokenConfigProps {
  clientId: string;
  scope: string;
  userId: string;
}

type TrackedMetrics = Record<string, Pick<Expiration, 'clientId' | 'refreshAt'>>;

const EXPIRATION_PERC = .20;
const MAX_TIME_REMAINING = 1000 * 60 * 60 * 2;
const SCHEDULE_FREQ = 1000 * 60;

export class Refresher {
  private _activityMonitor: UserActivityMonitor | null = null;
  private readonly _expirationTracker: Record<string, Expiration> = {};
  private _lastRefresh = 0;
  private readonly _metrics = Metrics.create('exc.auth.Refresher');
  private _tokenPoller: number | null = null;

  constructor(activityMonitorPromise: Promise<UserActivityMonitor>) {
    // Grab the activity monitor so that we have access to the last active
    // prpoerty to determine if the user was active since their last token
    // refresh.
    activityMonitorPromise.then((activityMonitor: UserActivityMonitor) => {
      this._activityMonitor = activityMonitor;
    });
  }

  get expirationTracker() {
    return this._expirationTracker;
  }

  private parseTrackedTokens(): TrackedMetrics {
    const tracked: TrackedMetrics = {};
    Object.keys(this._expirationTracker).forEach(key => {
      const {clientId, refreshAt} = this._expirationTracker[key];
      tracked[key] = {clientId, refreshAt};
    });
    return tracked;
  }

  /**
   * Checks tracked tokens for expiration and refreshes. If the refresh is a
   * success, it emits an event. If the refresh fails, it logs it. Only checks
   * if the user has been active since the last refresh.
   */
  public async checkForExpirations(): Promise<void> {
    // If the user hasn't been active since the last token refresh, there is no
    // need to check for expirations. We should only be refreshing tokens if
    // the user has been active.
    if (!this._activityMonitor || this._activityMonitor.lastActive < this._lastRefresh) {
      this._metrics.event('checkForExpiration.skipped', {
        hasActivityMonitor: !!this._activityMonitor,
        lastActive: this._activityMonitor?.lastActive,
        lastRefresh: this._lastRefresh,
        tracked: this.parseTrackedTokens()
      });
      return;
    }
    const date = Date.now();
    let hasRefreshed = false;
    const promises: Promise<void>[] = [];
    Object.values(this._expirationTracker).forEach(({clientId, eventFn, isExcClient, refreshAt, refreshFn, scope, userId}: Expiration) => {
      if (date >= refreshAt) {
        const promise = refreshFn({clientId, scope, userId})
          .then(({profile, token}: {profile: ImsProfile, token: string}) => {
            this._metrics.event('Token.refresh', {clientId, isExcClient, userId});
            eventFn('auth.token.change', {clientId, isExcClient, profile, scope, token, userId});
            hasRefreshed = true;
          })
          .catch((err: Error) => this._metrics.error(`Unable to refresh token for client: ${clientId}`, {
            error: err?.message || 'unknown error'
          }));
        promises.push(promise);
      }
    });
    await Promise.all(promises);
    // If there was a refresh, set the epoch of the refresh so that it can be
    // compared to the last active epoch on subsequent calls of this method.
    hasRefreshed && (this._lastRefresh = Date.now());
  }

  /**
   * Gets the epoch time in which this token should be refreshed.
   * @param {string} token - The token to get time to refresh for.
   * @returns {number} - The epoch time to refresh the token.
   */
  public getRefreshAt(token: string): number {
    const {created_at = Date.now(), expires_in = 3600000} = extractTokenMetadata(token) || {};
    return (created_at + expires_in - Math.min(expires_in * EXPIRATION_PERC, MAX_TIME_REMAINING));
  }

  /**
   * Remove the provided list of keys from the tracking map.
   * @param {Array.<string>} keys - List of keys to remove.
   */
  public removeExpirationKeys(keys: string[]): void {
    keys.forEach(key => delete this._expirationTracker[key]);
  }

  /**
   * Tracks a new token expiration object for refreshing. Adds the entry to the
   * map and kicks off the tracking interval if it's not already active.
   * @param {Object} expiration - The token expiration object to track.
   */
  public trackRefresh(expiration: Expiration): void {
    const {clientId, scope, userId} = expiration;
    const key = getTokenKey(clientId, scope, userId);
    this._expirationTracker[key] = expiration;

    // Only create a new token poller if it's not active already.
    if (this._tokenPoller) {
      return;
    }
    this._tokenPoller = window.setInterval(() => this.checkForExpirations(), SCHEDULE_FREQ);
  }

  public canUseTokenForUser({token: {access_token: token}}: TokenExchangeResponse, userId: string) {
    const refreshAt = this.getRefreshAt(token);
    const {user_id} = extractTokenMetadata(token) || {};
    return user_id === userId && refreshAt > Date.now();
  }
}

let refresher: Refresher | undefined;

export const getRefresher = (activityMonitorPromise: Promise<UserActivityMonitor>): Refresher => {
  if (!refresher) {
    refresher = new Refresher(activityMonitorPromise);
  }
  return refresher;
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, PDH_GLOBAL, RUNTIME, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/personalizationEditor.ts

const personalizationEditor: SolutionRoute = {
  appId: 'personalizationEditor',
  analytics: {omegaGlobal: PDH_GLOBAL.WEBSDK},
  path: '/personalization-editor',
  spaAppId: 'cjm-personalization-editor',
  runtime: RUNTIME.CDN,
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013',
      prod: '' // Remove this line before the production release.
    }
  }
};

export default personalizationEditor;

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
export const ONBOARDED_CLIENT_IDS = [
  'acp_ui_platform',
  'genstudio',
  'rtcdp-collaboration',
  'unified-shell'
];

export const FG_GRAPHQL_QUERY_ALL_DATA_KEY = 'allFgFeatureFlags';
export const FG_GRAPHQL_QUERY_DATA_KEY = 'fgFeatureFlags';

export const SHELL_FG_PROJECT = 'unified-shell';
export const SHELL_FLAG_PREFIX = 'fg:';

// Context value mapping for Floodgate
// Some are mandatory/common context variables (like 'region' and 'sandboxType')
// whereas others were created for Unified Shell (with the `dx` prefix)

export const CONTEXT_VARIABLES_MAP = {
  authId: 'dxAuthId',
  email: 'dxEmail',
  imsOrg: 'imsOrg',
  internal: 'dxInternal',
  locale: 'dxLocale',
  name: 'dxName',
  userHash: 'dxUserHash',
  userId: 'dxUserId'
};

export const SANDBOX_VARIABLES_MAP = {
  name: 'dxSandboxName',
  region: 'region',
  type: 'sandboxType'
};

export const VALID_CLIENT_IDS = [];

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HASH_TYPE, HISTORY, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/macui.ts

const landingPath = '/apps/mac/landing.html';

const macuiRoutes = (destination: string) => ({
  devs: `https://localhost.corp.adobe.com${landingPath}?dest=${destination}`,
  qa: `https://{tenant}.experience-qa.corp.adobe.com${landingPath}?dest=${destination}`,
  stage: `https://{tenant}.experiencecloud-stage.adobe.com${landingPath}?dest=${destination}`,
  prod: `https://{tenant}.experiencecloud.adobe.com${landingPath}?dest=${destination}`
});

const baseConfigs = {
  base: '/core-services',
  experienceLeague: {
    communities: 'adobe-macui',
    filter: 'macui'
  },
  refreshOnImsChange: true,
  analytics: {code: 'macui'},
  gainsight: {productKey: 'AP-4CEBSL6PUX23-2'}
};

const getConfig = (destination: string, appId: string, overrides = {}) => ({
  ...baseConfigs,
  appId: `${appId}`,
  path: `/core-services/${destination}`,
  sandbox: {
    history: {
      type: HISTORY.HASH,
      config: {hashType: HASH_TYPE.NOSLASH}
    },
    sources: macuiRoutes(destination)
  },
  ...overrides
});

export const macuiAssets: SolutionRoute = getConfig('assets', 'macuiAssets', {
  name: 'Adobe Assets Core Services',
  serviceCode: 'dma_tartan',
  access: {},
  redirectFrom: [{path: '/core-services', options: {end: true}}]
});

export const macuiCustomerAttributes: SolutionRoute = getConfig('customer-attributes', 'macuiCustomerAttributes', {
  name: 'Adobe Customer Attributes Core Services',
  serviceCode: 'dma_crs',
  access: {code: ['dma_tartan']}
});

export const macuiAudiences: SolutionRoute = getConfig('audiences', 'macuiAudiences', {
  name: 'Adobe Audiences Library Core Services',
  serviceCode: 'dma_audience_library',
  access: {code: ['dma_tartan']}
});


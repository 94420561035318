/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
/* eslint-disable sort-keys */
import {HISTORY, RUNTIME, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/bizible.ts

const marketoMeasureUi: SolutionRoute = {
  appId: 'marketoMeasure',
  path: '/marketo-measure',
  spaAppId: 'bizible-marketo-measure-ui',
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      dev: 'http://localhost:3000',
      prod: '' // Remove this line before the production release
    }
  },
  sideNav: {},
  access: {
    code: ['dma_bizible'],
    flag: 'bizible-enabled'
  },
  isAlternate: true
} as SolutionRoute;

export const bizible: SolutionRoute = {
  appId: 'bizible',
  path: '/marketo-measure',
  redirectFrom: [{path: '/bizible'}],
  serviceCode: 'dma_bizible',
  analytics: {code: 'biz'},
  gainsight: {productKey: 'AP-VHKUZCDZTYNN-2'},
  permissionsPolicy: ['clipboard-write'],
  refreshOnImsChange: true,
  sandbox: {
    hideInitialSpinner: false,
    history: {
      type: HISTORY.SERVER,
      config: {
        absolutePaths: true,
        spinnerOnChange: false
      }
    },
    sources: {
      dev: {
        discovery: 'https://localhost:44301/api/UnifiedShell/ImsLogin',
        withCredentials: true,
        payload: ['imsOrg', 'imsProfile', 'locale', 'path'],
        reduceProfile: true
      },
      qa: {
        discovery: 'https://bizible-int.adobe.com/api/UnifiedShell/ImsLogin',
        withCredentials: true,
        payload: ['imsOrg', 'imsProfile', 'locale', 'path'],
        reduceProfile: true
      },
      stage: {
        discovery: 'https://bizible-stage.adobe.com/api/UnifiedShell/ImsLogin',
        withCredentials: true,
        payload: ['imsOrg', 'imsProfile', 'locale', 'path'],
        reduceProfile: true
      },
      prod: {
        discovery: 'https://bizible.adobe.com/api/UnifiedShell/ImsLogin',
        withCredentials: true,
        payload: ['imsOrg', 'imsProfile', 'locale', 'path'],
        reduceProfile: true
      }
    }
  },
  access: {
    code: ['dma_bizible'],
    flag: 'bizible-enabled'
  },
  alternateRoute: [
    {
      config: marketoMeasureUi,
      flag: 'marketo-measure-v2-enabled',
      path: /^\/marketo-measure\/discover\/(.*)$/i
    }
  ]
};

export const marketoMeasureInternal: SolutionRoute = {
  appId: 'marketoMeasureInternal',
  path: '/marketo-measure-internal',
  spaAppId: 'bizible-marketo-measure-internal-ui',
  runtime: RUNTIME.CDN,
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      dev: 'http://localhost:3000'
    }
  },
  access: {
    code: ['dma_bizible'],
    flag: 'bizible-internal-enabled',
    logicOp: 'AND'
  }
};

/*************************************************************************
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: Adobe permits you to use, modify, and distribute this file in
 * accordance with the terms of the Adobe license agreement accompanying
 * it. If you have received this file from a source other than Adobe,
 * then your use, modification, or distribution of it requires the prior
 * written permission of Adobe.
 **************************************************************************/

/**
 * The Agreements API allows an application to display an agreement modal which will block the user from using the feature until accepted.
 *
 * To consume this API, add the following import to your code.
 *
 * ```typescript
 * import agreements from '@adobe/exc-app/agreements';
 * ```
 *
 * API reference: [scroll down](#index)
 *
 * ### Sample code
 *
 * To use a beta agreement dialog, all you need is the showAgreement function.
 * This function will show the dialog in the event that the user has not accepted yet, and get the users response:
 * ```typescript
 * import agreements from '@adobe/exc-app/agreements';
 *
 * const {accepted, date} = await agreements.showAgreement('my-beta-agreement-id', {type: AGREEMENT_TYPE.BETA});
 * ```
 *
 * If you need to get the response without showing the dialog, an additional get function is available:
 * ```typescript
 * import agreements from '@adobe/exc-app/agreements';
 *
 * const {accepted, date} = await agreements.getResponse('my-beta-agreement-id');
 *
 * ```
 * To clear the value in settings that the user has previously submitted:
 * ```typescript
 * import agreements from '@adobe/exc-app/agreements';
 *
 * const {accepted, date} = await agreements.clearResponse('my-beta-agreement-id');
 * ```
 *
 * API for fetching app data.
 * @packageDocumentation
 * @module agreements
 */
import {getImpl} from './src/Global';

export enum AGREEMENT_TYPE {
  AI = 'AI',
  BETA = 'BETA'
}

export interface AgreementKeyOptions {
  /**
   * True if the same key will be used in multiple places to show the same agreement.
   * False if the agreement will only be used in one place.
   */
  shared?: boolean;
}

export interface AgreementOptions extends AgreementKeyOptions {
  /**
   * Feature name of the agreement that will be used in the default dialog text.
   */
  feature: string;
  /**
   * Link to learn more about the feature.
   */
  learnMoreLink?: string;
  /**
   * Type defines what non-configurable text displays in the dialog.
   */
  type: AGREEMENT_TYPE;
}

export interface AgreementResponse {
  /**
   * Boolean that is true if the user has accepted the beta agreement
   */
  accepted: boolean;
  /**
   * Date of last action (accept or reject)
   */
  date: string;
}

export enum OPERATIONS {
  CLEAR = 'clear',
  GET = 'get',
  SHOW = 'show'
}

/**
 * APIs to get beta agreements.
 */
export interface AgreementsApi {
  /**
   * Clears the users agreement response from settings.
   * @param key Application defined key to map results in settings
   * @returns A promise containing true if the setting was cleared and false if an error occurred.
   */
  clearResponse(key: string, options?: AgreementKeyOptions): Promise<boolean>;

  /**
   * Returns the users agreement response from settings, but does not show the modal in either response case.
   * @param key Application defined key to map results in settings
   * @returns A promise containing the users response if one exists in settings
   */
  getResponse(key: string, options?: AgreementKeyOptions): Promise<AgreementResponse>;

  /**
   * Shows a beta agreement dialog to the user. Using the key parameter, checks in settings if the user has accepted the agreement and
   * shows the dialog to the user if they have.
   * @param key Application defined key to map results in settings
   * @param options List of configurable options such as agreement type and feature name
   * @returns A promise that is fulfilled once the user takes action from within the dialog (or immediately with the settings response if the user has already accepted the agreement)
   */
  showAgreement(key: string, options: AgreementOptions): Promise<AgreementResponse>;
}

const agreements: AgreementsApi = {
  clearResponse: (key: string, options?: AgreementKeyOptions) => getImpl('agreements')().clearResponse(key, options),
  getResponse: (key: string, options?: AgreementKeyOptions) => getImpl('agreements')().getResponse(key, options),
  showAgreement: (key: string, options: AgreementOptions) => getImpl('agreements')().showAgreement(key, options)
};

export default agreements;

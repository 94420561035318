/*************************************************************************
 * Copyright 2020 Adobe
 * All Rights Reserved.
 *
 * NOTICE: Adobe permits you to use, modify, and distribute this file in
 * accordance with the terms of the Adobe license agreement accompanying
 * it. If you have received this file from a source other than Adobe,
 * then your use, modification, or distribution of it requires the prior
 * written permission of Adobe.
 **************************************************************************/

/**
 * Internal APIs, meant to be used by Unified Shell only.
 * @ignore
 */
import {Application} from './metrics/Application';
import EventEmitter from './src/EventEmitter';
import {getImpl, Modules} from './src/Global';
import Metric from './metrics/Metric';
import MetricsConfiguration from './metrics/Configuration';
import {Configuration as NetworkConfiguration} from './network';
import User from './metrics/User';

export type {Modules};

export interface Poller {
  activeFrequency: number;
  hiddenFrequency?: number;
  name: string;
  inactiveFrequency: number;
  immediate: boolean;
  pollFn: () => Promise<void>;
}

export interface PollerHandle {
  handle: string;
}

export interface UserActivityEmitter extends EventEmitter {
  activityEvents: string[];
}

export interface UserActivityMonitor extends EventEmitter {
  active: boolean;
  configure: (config: PollingConfiguration) => void;
  hidden: boolean;
  lastActive: number;
  registerActivityEmitter(emitter: UserActivityEmitter): void;
}

export interface PollingConfiguration {
  inactivityTimeoutSec?: number;
  pollWhileInactive?: boolean;
}

/**
 * @ignore
 */
export interface InternalApi {
  addPoller(poller: Poller): Promise<PollerHandle>
  clearUser(version?: string): void
  configureMetrics(config?: MetricsConfiguration): Promise<MetricsConfiguration>;
  configureNetwork(config: NetworkConfiguration): void;
  configurePolling(config: PollingConfiguration): Promise<void>;
  flush(): Promise<number>;
  getActivityMonitor(): Promise<UserActivityMonitor>;
  preloadEngine(): void;
  registerActivityEmitter(emitter: UserActivityEmitter): Promise<void>;
  removePoller(handle: PollerHandle): Promise<void>;
  setApplication(app: Application): void;
  setFilter(filterFunction: <T extends Metric>(record: T) => Promise<T>): void;
  setUser(user: User, version?: string): void;
  waitForGainsight(): Promise<void>;
}

/**
 * @ignore
 */
export class Internal {
  public static addPoller(poller: Poller): Promise<PollerHandle> {
    return getImpl('internal').addPoller(poller);
  }
  public static configureMetrics(config?: MetricsConfiguration): Promise<MetricsConfiguration> {
    return getImpl('internal').configureMetrics(config);
  }
  public static configureNetwork(config: NetworkConfiguration): void {
    return getImpl('internal').configureNetwork(config);
  }
  public static configurePolling(config: PollingConfiguration): Promise<void> {
    return getImpl('internal').configurePolling(config);
  }
  public static flush(): Promise<number> {
    return getImpl('internal').flush();
  }
  public static getActivityMonitor(): Promise<UserActivityMonitor> {
    return getImpl('internal').getActivityMonitor();
  }
  public static preloadEngine(): void {
    return getImpl('internal').preloadEngine();
  }
  public static registerActivityEmitter(emitter: UserActivityEmitter): Promise<void> {
    return getImpl('internal').registerActivityEmitter(emitter);
  }
  public static removePoller(handle: PollerHandle): Promise<void> {
    return getImpl('internal').removePoller(handle);
  }
  public static setApplication(app: Application): void {
    return getImpl('internal').setApplication(app);
  }
  public static setFilter(filterFunction: <T>(record: T) => Promise<T>): void {
    return getImpl('internal').setFilter(filterFunction);
  }
  public static setUser(user: User, version?: string): void {
    return getImpl('internal').setUser(user, version);
  }
  public static clearUser(version?: string): void {
    return getImpl('internal').clearUser(version);
  }
  public static waitForGainsight(): Promise<void> {
    return getImpl('internal').waitForGainsight();
  }
}


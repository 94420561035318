/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import ajoContentEditor from './ajoContentEditor';
import {ansible} from './ansible';
import {cjmChannelHandlerUI} from './cjm';
import {HISTORY, PDH_GLOBAL, RELEASE_TYPE, RUNTIME, SolutionRoute, THUNDERBIRD} from '@exc/core/src/models/Solution';
import personalizationEditor from './personalizationEditor';

// This file was generated automatically from ../solutions/quarry.ts

export const quarryStorybookConnected: SolutionRoute = {
  appId: 'quarryStorybookConnected',
  path: '/quarry/storybook-connected',
  permissionsPolicy: ['clipboard-write'],
  redirectFrom: [{path: '/quarry', options: {end: true}}],
  runtime: RUNTIME.CDN,
  sandbox: {
    pathPrefix: {default: '/storybook-connected', dev: ''},
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:2019/',
      dev443: 'https://localhost.corp.adobe.com:443/',
      prod: ''
    }
  },
  spaAppId: 'frontend-quarry',
  urlContext: {
    key: 'sandbox',
    optional: true
  },
  nestedApps: [ansible, ajoContentEditor, personalizationEditor]
};

export const quarryStorybookAjo: SolutionRoute = {
  appId: 'quarryStorybookAjo',
  path: '/quarry/storybook-ajo',
  redirectFrom: [{path: '/quarry/ajo'}],
  runtime: RUNTIME.CDN,
  sandbox: {
    pathPrefix: {default: '/storybook-ajo', dev: ''},
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:2019/',
      dev443: 'https://localhost.corp.adobe.com:443/',
      prod: ''
    }
  },
  spaAppId: 'frontend-quarry',
  urlContext: {
    key: 'sandbox',
    optional: true
  },
  nestedApps: [ansible, ajoContentEditor, personalizationEditor, cjmChannelHandlerUI, ajoContentEditor]
};

export const quarrySampleApp: SolutionRoute = {
  appId: 'quarry-sample-app',
  analytics: {omegaGlobal: PDH_GLOBAL.WEBSDK},
  path: '/quarry/sample',
  releaseType: RELEASE_TYPE.POC,
  runtime: RUNTIME.CDN,
  spaAppId: 'frontend-quarry-sample-app',
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013',
      prod: '' // Remove this line before the production release.
    }
  },
  thunderbird: THUNDERBIRD.SERVICE_WORKER
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, RUNTIME, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/target.ts

const baseConfigs = {
  appId: 'target',
  base: '/target',
  experienceLeague: {
    communities: 'adobe-target',
    filter: 'Target'
  },
  refreshOnImsChange: true,
  serviceCode: 'dma_bullseye',
  access: {code: ['dma_tnt', 'dma_bullseye']},
  analytics: {code: 'at'},
  gainsight: {productKey: 'AP-4CEBSL6PUX23-2'}
};

const landingPath = '/apps/mac/target/pages/landing.html';

enum AppTypes {
  MAC_UI = 'MAC_UI',
  SPA_PIPELINE = 'SPA_PIPELINE'
}

const spaRoutes = (destination: string) => ({
  dev: `http://localhost.corp.adobe.com:4200/${destination}`,
  devs: `https://localhost.corp.adobe.com:4200/${destination}`,
  qa: `https://experience-qa.adobe.com/solutions/tnt-bullet#/${destination}`,
  stage: `https://experience-stage.adobe.com/solutions/tnt-bullet#/${destination}`,
  prod: `https://experience.adobe.com/solutions/tnt-bullet#/${destination}`
});

const macuiRoutes = (destination: string) => ({
  dev: `http://localhost.corp.adobe.com:4502${landingPath}?dest=${destination}`,
  dev_old: `http://localhost.corp.adobe.com:4502${landingPath}?dest=${destination}`,
  dev_next: `http://localhost.corp.adobe.com:4502${landingPath}?dest=${destination}`,
  devs: `https://localhost.corp.adobe.com${landingPath}?dest=${destination}`,
  devs_old: `https://localhost.corp.adobe.com${landingPath}?dest=${destination}`,
  devs_next: `https://localhost.corp.adobe.com${landingPath}?dest=${destination}`,
  qa12: `https://{tenant}.marketing-qa12.corp.adobe.com${landingPath}?dest=${destination}`,
  qa12_old: `https://{tenant}.marketing-qa12.corp.adobe.com${landingPath}?dest=${destination}`,
  qa12_next: `https://{tenant}.marketing-qa12.corp.adobe.com${landingPath}?dest=${destination}`,
  qa: `https://{tenant}.experience-qa.corp.adobe.com${landingPath}?dest=${destination}`,
  qa_old: `https://{tenant}.experience-qa.corp.adobe.com${landingPath}?dest=${destination}`,
  qa_next: `https://{tenant}.experience-qa.corp.adobe.com${landingPath}?dest=${destination}`,
  beta: `https://{tenant}.experiencecloud-beta.adobe.com${landingPath}?dest=${destination}`,
  stage: `https://{tenant}.experiencecloud-stage.adobe.com${landingPath}?dest=${destination}`,
  stage_old: `https://{tenant}.experiencecloud-stage.adobe.com${landingPath}?dest=${destination}`,
  stage_next: `https://{tenant}.experiencecloud-stage.adobe.com${landingPath}?dest=${destination}`,
  prod: `https://{tenant}.experiencecloud.adobe.com${landingPath}?dest=${destination}`,
  prod_old: `https://{tenant}.experiencecloud.adobe.com${landingPath}?dest=${destination}`,
  prod_next: `https://{tenant}.experiencecloud.adobe.com${landingPath}?dest=${destination}`
});

const getConfig = (destination: string, type = AppTypes.MAC_UI, overrides = {}): SolutionRoute => ({
  ...baseConfigs,
  appId: `${baseConfigs.appId}-${destination}`,
  path: `/target/${destination}`,
  sandbox: {
    history: {
      type: HISTORY.HASH,
      config: {addParamsToHash: true}
    },
    sources: type === AppTypes.SPA_PIPELINE ? spaRoutes(destination) : macuiRoutes(destination)
  },
  spaAppId: type === AppTypes.SPA_PIPELINE ? 'tnt-bullet' : undefined,
  runtime: type === AppTypes.SPA_PIPELINE ? RUNTIME.CDN : undefined,
  ...overrides
});

export const targetActivities: SolutionRoute = getConfig('activities', AppTypes.MAC_UI, {
  appId: 'target',
  alternateRoute: [
    {config: getConfig('activities', AppTypes.SPA_PIPELINE, {appId: 'target'}), flag: 'target-activities-enabled', regex: true}
  ],
  redirectFrom: [{path: '/target', options: {end: true}}]
});

export const targetAudiences: SolutionRoute = getConfig('audiences', AppTypes.SPA_PIPELINE, {
  alternateRoute: [
    {config: getConfig('audiences', AppTypes.MAC_UI), flag: 'target-audiences-spa-enabled', regex: true}
  ]
});

export const targetSetup: SolutionRoute = getConfig('setup', AppTypes.SPA_PIPELINE, {
  alternateRoute: [
    {config: getConfig('setup', AppTypes.MAC_UI), flag: 'target-setup-enabled', regex: true}
  ]
});

export const targetOffers: SolutionRoute = getConfig('offers', AppTypes.MAC_UI, {
  alternateRoute: [
    {config: getConfig('offers', AppTypes.SPA_PIPELINE), flag: 'target-offers-enabled', regex: true}
  ]
});

export const targetProducts: SolutionRoute = getConfig('products', AppTypes.MAC_UI, {
  alternateRoute: [
    {config: getConfig('products', AppTypes.SPA_PIPELINE), flag: 'target-products-enabled', regex: true}
  ]
});

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, NestedRoute, PDH_GLOBAL, RUNTIME, SolutionRoute} from '@exc/core/src/models/Solution';
import {THUNDERBIRD} from '@adobe/exc-app/page';

// This file was generated automatically from ../solutions/ddam.ts

export const assetsBase = {
  metadata: {idAttribute: 'data-instance-id'},
  analytics: {omegaGlobal: PDH_GLOBAL.BOTH} as const,
  gainsight: {productKey: 'AP-GGNFMVCRYIQI-2'},
  runtime: RUNTIME.CDN,
  sandbox: {
    hideInitialSpinner: true,
    history: {
      type: HISTORY.HASH, config: {addParamsToHash: true}
    },
    ims: {
      client_id: 'aem-assets-frontend-1',
      scopes: 'AdobeID,openid,read_organizations,ab.manage,additional_info.projectedProductContext,firefly_api'
    },
    sources: {
      // TODO: leave for now but this should be removed after people have time to switch to dev443
      devcjm: 'https://localhost.corp.adobe.com:443/',
      dev443: 'https://localhost.corp.adobe.com:443/',
      dev: 'https://localhost.corp.adobe.com:8443/'
    }
  },
  spaAppId: 'CQ-assets-app',
  thunderbird: THUNDERBIRD.SERVICE_WORKER
};

const assetSelectors: NestedRoute = {
  ...assetsBase,
  appId: 'assets-selectors',
  gainsight: {productKey: 'AP-GI70AEBJW3LJ-2'},
  spaAppId: 'CQ-assets-selectors'
};

const ddam: SolutionRoute = {
  ...assetsBase,
  appId: 'ddam',
  experienceLeague: {
    communities: 'adobe-experience-manager',
    filter: 'Experience Manager'
  },
  metricsAppId: 'assets',
  nestedApps: [assetSelectors],
  path: '/assets',
  permissionsPolicy: ['clipboard-write'],
  access: {
    code: ['dma_media_library', 'dma_ddam'],
    fd: [{code: 'dma_aem_cloud', property: 'aemSolutions', propertyDefault: [], value: 'contenthub', exists: false}],
    flag: 'assets-essentials-enabled',
    visibleName: [{code: 'dma_aem_contenthub', name: /^Default Adobe GenStudio administrators$/}]
  }
};

export default ddam;

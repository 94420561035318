/*************************************************************************
 * Copyright 2020 Adobe
 * All Rights Reserved.
 *
 * NOTICE: Adobe permits you to use, modify, and distribute this file in
 * accordance with the terms of the Adobe license agreement accompanying
 * it. If you have received this file from a source other than Adobe,
 * then your use, modification, or distribution of it requires the prior
 * written permission of Adobe.
 **************************************************************************/

/**
 * API used to log messages, errors, events, metrics and analytics that get pushed to our telemetry
 * system.
 *
 * The main goal of this project is to provide solutions that are integrating with Unified-Shell and
 * running on Adobe Experience Cloud as much telemetry information as possible "for free" and a
 * convenient integration option to collect custom, application specific metrics.
 *
 * ***Import:***
 *
 * ```typescript
 * import metrics from '@adobe/exc-app/metrics';
 * ```
 *
 * ***Type:***
 *
 * [MetricsApi](../interfaces/metrics.metricsapi.md#interface-metricsapi)
 *
 * ***Usage:***
 *
 * ```typescript
 * import metrics from '@adobe/exc-app/metrics';
 *
 * class MyClass {
 *   constructor() {
 *     this.metrics = metrics.create('exc.landing-page.recents');
 *   }
 *
 *   async someOperation() {
 *     const timer = this.metrics.start('MyClass.someOperation');
 *     try {
 *       await performOperation();
 *       timer.time('done');
 *     } catch(err) {
 *       this.metrics.error(error);
 *       throw err;
 *     }
 *   }
 * }
 * ```
 * @packageDocumentation
 * @module metrics
 * @preferred
 */

import {connect} from './src/Global';
import {Events} from './metrics/Events';
import {Level} from './metrics/Level';
import Metric from './metrics/Metric';
import Metrics from './metrics/Metrics';

/**
 * Defines the metrics API.
 */
export interface MetricsApi {
  /**
   * Creates a metrics instance for the specified component to log entries and events.
   * @param name The name of the component.
   * @param {any} args Optional arguments that will be written into the
   * metricsState context bag on the recorded metrics.
   */
  create(name: string, ...args: any): Readonly<Metrics>;
}

const api = connect('metrics', [
  ['create', true]
]) as MetricsApi;

export default api;

/**
 * @ignore
 */
export {
  Events,
  Level
};

/**
 * @ignore
 */
export type {
  Metric,
  Metrics
};

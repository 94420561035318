/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
/* istanbul ignore file */
/* eslint camelcase: 0 */
export { default as en_US } from './en_us.json';
export { default as de_DE } from './de_de.json';
export { default as es_ES } from './es_es.json';
export { default as fr_FR } from './fr_fr.json';
export { default as it_IT } from './it_it.json';
export { default as ja_JP } from './ja_jp.json';
export { default as ko_KR } from './ko_kr.json';
export { default as pt_BR } from './pt_br.json';
export { default as zh_CN } from './zh_cn.json';
export { default as zh_TW } from './zh_tw.json';

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {experiencePlatformSpa} from './experiencePlatform';
import griffonConfig from './griffon';
import {HISTORY, RUNTIME, SolutionRoute} from '@exc/core/src/models/Solution';
import {puebloBaseConfig} from './pueblo';

// This file was generated automatically from ../solutions/launch.ts

const sideNav = {id: 'launch', workHubId: 'LAUNCH'};

const dataCollectionCommon = {
  appRoot: 'launchDataCollection',
  experienceLeague: {
    communities: 'adobe-launch-community',
    filter: 'Data Collection'
  },
  parent: 'DC' as const,
  analytics: {code: 'launch'},
  gainsight: {productKey: 'AP-GLVNMB5BLV8T-2'},
  runtime: RUNTIME.CDN_LEGACY,
  browserParamFilterList: ['lens_env'],
  sandbox: {
    history: HISTORY.HISTORY,
    ims: {
      client_id: 'Activation-DTM',
      scopes: 'openid,AdobeID,session,read_organizations,additional_info.projectedProductContext,additional_info.job_function,additional_info.roles,audiencemanager_api'
    },
    overrideParams: true
  },
  sideNav,
  urlContext: {key: 'sandbox', optional: true} as const
};

export const dataCollectionHome: SolutionRoute = {
  ...dataCollectionCommon,
  ...puebloBaseConfig,
  appId: 'data-collection-home',
  appRoot: 'launchDataCollection',
  omegaSuiteId: 'al',
  redirectFrom: [
    {path: '/data-collection', options: {end: true}},
    {path: '/launch'}
  ],
  path: '/data-collection/home',
  parent: 'DC' as const,
  dataPrefetchContracts: ['dashboard-org'],

  // Overrides
  browserParamFilterList: undefined,
  urlContext: undefined
};

export const launchDataCollection: SolutionRoute = {
  ...dataCollectionCommon,
  path: '/data-collection',
  appId: 'launchDataCollection',
  runtime: RUNTIME.CDN,
  sandbox: {
    ...dataCollectionCommon.sandbox,
    sources: {
      // ims stage
      dev: 'https://localhost:9002/solutions/reactor-lens?lens_env=dev',
      dev1: 'https://experience-qa.adobe.com/solutions/reactor-lens?lens_env=dev',
      engd: 'https://experience-qa.adobe.com/solutions/reactor-lens?lens_env=engd',
      qa: 'https://experience-qa.adobe.com/solutions/reactor-lens?lens_env=qa',
      stage: 'https://experience-stage.adobe.com/solutions/reactor-lens?lens_env=stage',
      // ims prod
      demo: 'https://experience.adobe.com/solutions/reactor-lens?lens_env=demo',
      prod: 'https://experience.adobe.com/solutions/reactor-lens?lens_env=prod'
    }
  }
};

export const launchDataCollectionDatastreams: SolutionRoute = {
  ...dataCollectionCommon,
  appId: 'launchDataCollectionDatastreams',
  path: '/data-collection/scramjet',
  sandbox: {
    ...dataCollectionCommon.sandbox,
    sources: {
      dev: 'https://localhost.corp.adobe.com:9002'
    }
  },
  spaAppId: 'Activation-scramjet',
  runtime: RUNTIME.CDN
};

export const launchPlaces: SolutionRoute = {
  ...dataCollectionCommon,
  appId: 'launch-places',
  path: '/data-collection/places',
  redirectFrom: [{path: '/places'}],
  sandbox: {
    hideInitialSpinner: true,
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://ui-dev.places.adobe.com/unified/index.html',
      qa: 'https://ui-qa.places.adobe.com/unified/index.html',
      stage: 'https://ui-preprod.places.adobe.com/unified/index.html',
      prod: 'https://ui.places.adobe.com/unified/index.html'
    }
  },
  access: {fi: ['acp:acp_places']}
};

export const launchPlatform: SolutionRoute = {
  ...experiencePlatformSpa,
  appId: 'launch-platform',
  appRoot: 'launchDataCollection',
  omegaSuiteId: 'al',
  gainsight: {productKey: 'AP-GLVNMB5BLV8T-2'},
  path: '/data-collection/platform',
  parent: 'DC' as const,
  sideNav
};

export const launchGriffon: SolutionRoute = {
  ...dataCollectionCommon,
  ...griffonConfig,
  appId: 'launch-griffon',
  path: '/data-collection/assurance',
  redirectFrom: [{path: '/data-collection/griffon'}],
  access: {
    ...griffonConfig.access,
    flag: 'assurance-capability-enabled'
  },
  urlContext: undefined
};

/**
 * THESE ARE PLATFORM BUT WOULD HAVE CIRCULAR DEPENDENCY SO THEY LIVE HERE :(
 */

export const platformLaunchDataCollection: SolutionRoute = {
  ...launchDataCollection,
  appId: 'platformLaunchDataCollection',
  appRoot: 'experiencePlatformUI',
  parent: 'AEP',
  path: '/platform/data-collection',
  sideNav: {
    id: 'aep',
    workHubId: 'AEP'
  }
};

export const platformLaunchDataCollectionDatastreams: SolutionRoute = {
  ...launchDataCollectionDatastreams,
  appId: 'platformLaunchDataCollectionDatastreams',
  appRoot: 'experiencePlatformUI',
  parent: 'AEP',
  path: '/platform/data-collection/scramjet',
  sideNav: {
    id: 'aep',
    workHubId: 'AEP'
  }
};

export const platformLaunchPlaces: SolutionRoute = {
  ...launchPlaces,
  appId: 'platform-launch-places',
  appRoot: 'experiencePlatformUI',
  parent: 'AEP',
  path: '/platform/data-collection/places',
  redirectFrom: undefined,
  sideNav: {
    id: 'aep',
    workHubId: 'AEP'
  }
};

export const platformLaunchGriffon: SolutionRoute = {
  ...launchGriffon,
  appId: 'platform-launch-griffon',
  appRoot: 'experiencePlatformUI',
  parent: 'AEP',
  path: '/platform/data-collection/assurance',
  redirectFrom: [{path: '/platform/data-collection/griffon'}],
  sideNav: {
    id: 'aep',
    workHubId: 'AEP'
  }
};

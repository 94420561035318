/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
// Harvester picks up the intlMessages variable name
/* eslint sort-keys: 0 */
const intlMessages = {
  iframeWaitingMessage: {
    defaultMessage: 'Thank you for waiting. Just a few more moments...',
    description: 'Message displayed to users when the application is taking a long time to load',
    id: 'iframeWaitingMessage'
  },
  solutionIframe: {
    defaultMessage: 'Main Content',
    description: 'Name of the iframe element',
    id: 'solutionIframe'
  }
};

export default intlMessages;

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import Metrics, {Level} from '@adobe/exc-app/metrics';
import {storage} from '@exc/storage';

export const DAY = 1000 * 60 * 60 * 24;
export const STORAGE_DURATION = DAY * 30; // 30 days
const STORAGE_KEY = 'queuedMetrics';

export interface QueuedMetrics {
  data?: Record<string, any>;
  description?: string;
  error?: Error;
  instanceId?: string;
  timestamp?: string;
}

type QueuedMetricItem = [string, QueuedMetrics];

interface StoredQueuedMetrics {
  items: QueuedMetricItem[];
}

const metrics = Metrics.create('exc.auth.queuedMetrics');
/**
 * Push all of the metrics stored locally out through the current metrics instance
 */
export const flushQueuedMetrics = () => {
  const {items} = storage.local.getSync(STORAGE_KEY) as StoredQueuedMetrics || {};
  (items || []).forEach(item => {
    // item is a tuple of [msg, queuedMetrics]
    metrics.event('QueuedMetrics.Flush', item[0], item[1], {level: Level.INFO});
  });
  // Clear the storage so we don't log the same thing multiple times
  storage.local.remove(STORAGE_KEY);
};

/**
 * Queue a metric to be saved and send on login.
 */
export const queueMetrics = (msg: string, queueData?: Partial<QueuedMetrics>): void => {
  const {instanceId} = (window as any).adobeMetrics.metricsState;
  const timestamp = new Date().toISOString(); // '2023-12-18T00:00:00.0000000Z'
  const {items = []} = storage.local.getSync(STORAGE_KEY) as StoredQueuedMetrics || {};
  items.push([msg, {instanceId, timestamp, ...queueData}]);
  storage.local.set(STORAGE_KEY, {items}, STORAGE_DURATION);
};

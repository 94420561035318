/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2020 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import aemCfModelEditor from './aemCfModelEditor';
import {CACHE_SCOPE, DiscoveryPayloadKeys} from '@exc/core/src/models/Discovery';
import {HISTORY, NestedRoute, PDH_GLOBAL, RELEASE_TYPE, RUNTIME, SolutionRoute, THUNDERBIRD} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/aem.ts

const payload: DiscoveryPayloadKeys = ['imsOrg', 'imsProfile', 'path'];
const requiredPayload: DiscoveryPayloadKeys = [];

export const aemSkyline: SolutionRoute = {
  appId: 'aemshell',
  appRoot: 'experienceManagerUI',
  analytics: {code: 'aem'},
  gainsight: {productKey: 'AP-AULLRFDZLJ9K-2'},
  hideTenant: true,
  path: '/aem',
  runtime: RUNTIME.CDN_LEGACY,
  sandbox: {
    hideInitialSpinner: true,
    pageTimeout: 0,
    history: {
      type: HISTORY.SERVER,
      config: {
        absolutePaths: true
      }
    },
    sources: {
      dev: {
        discovery: '/bin/unifiedshell/discovery?configid=ims',
        payload,
        reduceProfile: true,
        requiredPayload,
        redirectOnFailure: {
          code: [403],
          path: '/experiencemanager'
        },
        withCredentials: true
      },
      qa: {
        discovery: '/bin/unifiedshell/discovery?configid=ims',
        payload,
        reduceProfile: true,
        requiredPayload,
        redirectOnFailure: {
          code: [403],
          path: '/experiencemanager'
        },
        withCredentials: true
      },
      stage: {
        cache: {
          scope: CACHE_SCOPE.LOCAL,
          ttl: 43200 // 12 hours
        },
        discovery: '/bin/unifiedshell/discovery?configid=ims',
        payload,
        reduceProfile: true,
        requiredPayload,
        redirectOnFailure: {
          code: [403],
          path: '/experiencemanager'
        },
        url: '/',
        withCredentials: true
      },
      prod: {
        cache: {
          scope: CACHE_SCOPE.LOCAL,
          ttl: 43200 // 12 hours
        },
        discovery: '/bin/unifiedshell/discovery?configid=ims',
        payload,
        reduceProfile: true,
        requiredPayload,
        redirectOnFailure: {
          code: [403],
          path: '/experiencemanager'
        },
        url: '/',
        withCredentials: true
      }
    }
  },
  withinSkyline: true,
  access: {code: ['dma_aem_cloud', 'dma_media_library']}
};

export const aemCfAdmin: SolutionRoute = {
  appId: 'aem-cf-admin',
  sharedCaches: ['aem-headless'],
  spaAppId: 'cq-aem-headless-ui-admin',
  path: '/aem/cf/admin',
  thunderbird: THUNDERBIRD.SERVICE_WORKER,
  gainsight: {productKey: 'AP-AULLRFDZLJ9K-2'},
  analytics: {
    code: 'aem-cf-admin',
    scripts: {
      dev: '//assets.adobedtm.com/a7d65461e54e/72cd1ca12e34/launch-38d64c9ec532-development.min.js',
      qa: '//assets.adobedtm.com/a7d65461e54e/72cd1ca12e34/launch-38d64c9ec532-development.min.js',
      stage: '//assets.adobedtm.com/a7d65461e54e/72cd1ca12e34/launch-2de795b312e7-staging.min.js',
      prod: '//assets.adobedtm.com/a7d65461e54e/72cd1ca12e34/launch-cbc5f9ab5f51.min.js'
    }
  },
  runtime: RUNTIME.CDN,
  sandbox: {
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:12399'
    }
  },
  access: {
    code: [ 'dma_aem_ams', 'dma_aem_onprem', 'dma_aem_cloud' ]
  },
  betaFeedbackConfigId: 'aem-cf-admin'
};

const aemCfEditorBaseConfig = {
  analytics: {omegaGlobal: PDH_GLOBAL.AA},
  runtime: RUNTIME.CDN,
  gainsight: {productKey: 'AP-AULLRFDZLJ9K-2'},
  sandbox: {
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:12398'
    }
  },
  spaAppId: 'cq-aem-headless-ui-cf-editor',
  thunderbird: THUNDERBIRD.SERVICE_WORKER
};

const assetSelectorsAemCfEditor: NestedRoute = {
  ...aemCfEditorBaseConfig,
  appId: 'assets-selectors',
  gainsight: {productKey: 'AP-GI70AEBJW3LJ-2'},
  spaAppId: 'CQ-assets-selectors'
};

export const aemCfEditor: SolutionRoute = {
  ...aemCfEditorBaseConfig,
  appId: 'aem-cf-editor',
  nestedApps: [assetSelectorsAemCfEditor],
  path: '/aem/cf/editor',
  permissionsPolicy: ['clipboard-write'],
  access: {
    code: [ 'dma_aem_ams', 'dma_aem_onprem', 'dma_aem_cloud' ]
  },
  sharedCaches: ['aem-headless'],
  omegaSuiteId: 'aem'
};

export const aemCfEditorStorybook: SolutionRoute = {
  appId: 'aem-cf-editor-storybook',
  path: '/aem/cf/editor-storybook',
  sharedCaches: ['aem-headless'],
  spaAppId: 'cq-aem-headless-ui-cf-editor',
  runtime: RUNTIME.CDN,
  sandbox: {
    pathPrefix: {
      default: '/static-assets/storybook/index.html'
    },
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:12391',
      prod: ''
    }
  }
};

export const aemCommonsStorybook: SolutionRoute = {
  appId: 'aem-commons-storybook',
  path: '/aem/commons-storybook',
  sharedCaches: ['aem-headless'],
  spaAppId: 'cq-aem-headless-ui-commons',
  runtime: RUNTIME.CDN,
  sandbox: {
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:12395',
      prod: ''
    }
  }
};

export const universalEditor: SolutionRoute = {
  appId: 'universalEditor',
  path: '/aem/editor',
  omegaSuiteId: 'aem',
  analytics: {omegaGlobal: PDH_GLOBAL.AA},
  spaAppId: 'cq-universal-editor',
  nestedApps: [aemCfModelEditor],
  permissionsPolicy: ['clipboard-read', 'clipboard-write'],
  runtime: RUNTIME.CDN,
  sandbox: {
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  },
  thunderbird: THUNDERBIRD.SERVICE_WORKER
};

/**
 * Local development may not work for this application as it is part of AEM and
 * is required to load on adobeaemcloud.com. The application can be developed,
 * however at it's original location.
 */
export const skylineUniversalEditor: SolutionRoute = {
  ...universalEditor,
  appId: 'skylineUniversalEditor',
  path: '/aem/universal-editor',
  withinSkyline: true
};

export const customerAlertingCenter: SolutionRoute = {
  appId: 'customerAlertingCenter',
  path: '/aem/actions-center',
  omegaSuiteId: 'aem',
  analytics: {omegaGlobal: PDH_GLOBAL.AA},
  spaAppId: 'experience-platform-customer-alerting-center',
  sandbox: {
    history: HISTORY.HASH,
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  }
};

export const aemGenerateVariations: SolutionRoute = {
  appId: 'aemGenerateVariations',
  path: '/aem/generate-variations',
  analytics: {omegaGlobal: PDH_GLOBAL.AA},
  omegaSuiteId: 'aem',
  permissionsPolicy: ['clipboard-read', 'clipboard-write'],
  refreshOnImsChange: true,
  runtime: RUNTIME.CDN_LEGACY,
  sandbox: {
    history: HISTORY.SERVER,
    overrideParams: true,
    sources: {
      local: 'https://localhost:9080',
      workspace: 'https://245265-879aquaant-{workspace}.adobeio-static.net/index.html',
      dev: 'https://245265-879aquaant-development.adobeio-static.net/index.html',
      qa: 'https://245265-879aquaant-qa.adobeio-static.net/index.html',
      stage: 'https://245265-879aquaant-stage.adobeio-static.net/index.html',
      prod: 'https://245265-879aquaant.adobeio-static.net/index.html'
    }
  }
};

export const aemExperienceSuccessStudio: SolutionRoute = {
  appId: 'aemExperienceSuccessStudio',
  path: '/aem/experience-success-studio',
  releaseType: RELEASE_TYPE.ALPHA,
  analytics: {omegaGlobal: PDH_GLOBAL.WEBSDK},
  omegaSuiteId: 'aem',
  permissionsPolicy: ['clipboard-read', 'clipboard-write'],
  refreshOnImsChange: true,
  runtime: RUNTIME.CDN,
  sandbox: {
    history: HISTORY.SERVER,
    overrideParams: true,
    sources: {
      local: 'https://localhost:9080',
      workspace: 'https://245265-aemsuccessstudio-{workspace}.adobeio-static.net/index.html',
      dev: 'https://245265-aemsuccessstudio-development.adobeio-static.net/index.html',
      qa: 'https://245265-aemsuccessstudio-qa.adobeio-static.net/index.html',
      stage: 'https://245265-aemsuccessstudio-stage.adobeio-static.net/index.html',
      prod: 'https://245265-aemsuccessstudio.adobeio-static.net/index.html'
    }
  },
  access: {
    flag: 'aem-exp-success-studio-enabled'
  }
};

export const releaseToggles: SolutionRoute = {
  appId: 'releaseToggles',
  path: '/aem/toggles',
  betaFeedbackConfigId: 'aem-release-toggles-ui',
  spaAppId: 'experience-platform-skyline-release-toggles-ui-frontend',
  runtime: RUNTIME.CDN,
  sandbox: {
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  },
  access: {flag: 'release-toggles-enabled'}
};

export const aemxmngr: SolutionRoute = {
  appId: 'aemxmngr',
  appRoot: 'experienceManagerUI',
  path: '/aem/extension-manager',
  omegaSuiteId: 'aemxmngr',
  analytics: {omegaGlobal: PDH_GLOBAL.AA},
  spaAppId: 'devx-services-aemx-mngr-spa',
  runtime: RUNTIME.CDN,
  permissionsPolicy: ['clipboard-write'],
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      dev: 'https://localhost.corp.adobe.com:12399'
    }
  }
};

export const aemtrustcenter: SolutionRoute = {
  appId: 'aemtrustcenter',
  analytics: {omegaGlobal: PDH_GLOBAL.WEBSDK},
  path: '/aem/trust-center',
  releaseType: RELEASE_TYPE.POC,
  runtime: RUNTIME.CDN,
  permissionsPolicy: ['clipboard-write'],
  spaAppId: 'experience-platform-aemtrustcenter',
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  },
  thunderbird: THUNDERBIRD.SERVICE_WORKER
};

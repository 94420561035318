/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {checkCDN} from '../../cdnConnect';
import {getErrorMessage, runWithTimeout, wait} from '@exc/shared';
import {getHtmlFromWorker, getWindowConfig, isWorkerActivated} from '../../utils/workerUtils';
import {HTMLStatus} from '../../enums';
import Metrics, {Level} from '@adobe/exc-app/metrics';
import {reload} from '@exc/auth/src/utils/reloader';
import {runInBackground} from '../../utils';
import {Solution} from '../../models/Solution';
import {SPAConfig, UnifiedShellConfig} from '../../models/UnifiedShellConfig';

/** Timeout for service worker to fetch and check HTML */
const ONE_MINUTE = 60 * 1000;
const ONE_HOUR = 60 * ONE_MINUTE;
const HTML_LONG_TIMEOUT = 20 * 1000; // 20 seconds
const HTML_SHORT_TIMEOUT = HTML_LONG_TIMEOUT / 4; // 5 seconds
const HTML_VERY_RECENT_THRESHOLD = 5 * ONE_MINUTE; // 5 minutes
const HTML_RECENT_THRESHOLD = 24 * ONE_HOUR; // 1 day
const CHECK_WAIT = 2 * ONE_MINUTE; // 2 minutes

interface UpdateResponse {
  assetsCached: number;
  spaVersion: string;
}

export default class ServiceWorkerControllerService {
  private readonly metrics = Metrics.create('exc.core.services.ServiceWorkerControllerService');

  constructor() {
    this.init();
  }

  private async init() {
    await wait(CHECK_WAIT);
    await runInBackground();
    const {scheduleUpdates} = await import('./ServiceWorkerUpdateService');
    scheduleUpdates();
  }

  /**
   * Checks if an SPA version has updated in the new config
   * @param {string} appId - SPA app ID
   * @param {UnifiedShellConfig} config - Incoming config object
   * @param {UnifiedShellConfig} windowConfig - Current config object (window.config)
   * @returns true if version changed, false otherwise.
   */
  private hasAppVersionChanged(
    appId: string,
    config: UnifiedShellConfig,
    windowConfig: UnifiedShellConfig
  ): Record<string, string>|undefined {
    const liveSolution: SPAConfig = windowConfig.solutions[appId];
    const newSolution: SPAConfig = config.solutions[appId];

    const changed = (!liveSolution && newSolution) ||
      (liveSolution && !newSolution) ||
      (liveSolution.liveVersion !== newSolution.liveVersion);

    if (changed) {
      return {
        appId,
        newVersion: newSolution?.liveVersion,
        oldVersion: liveSolution?.liveVersion
      };
    }
  }

  /**
   * Reloads Unified Shell when there is a new version of the active SPA available on load
   * @param config - Updated Unified Shell Config
   * @param spaAppId - New SPA app version
   * @returns HTML Status
   * @private
   */
  private async handleOutdatedResponse(config: UnifiedShellConfig, spaAppId = ''): Promise<HTMLStatus> {
    const windowConfig = getWindowConfig();

    // If solution that updated is current solution, refresh page.
    // Otherwise, update outdated solution versions.
    const updateData = spaAppId && this.hasAppVersionChanged(spaAppId, config, windowConfig);
    if (updateData) {
      await reload({
        data: updateData,
        initiator: 'Service Worker',
        reason: 'New App Version'
      });
      return HTMLStatus.OUTDATED;
    }
    this.metrics.event('ServiceWorker.appsVersionUpdated');
    // Copy solutions from new config.
    windowConfig.solutions = config.solutions;
    return HTMLStatus.OUTDATED;
  }

  /**
   * Reloads Unified Shell when a new version is available on load.
   * @param assetsCached - Total new version assets already cached by the worker
   * @param spaVersion - New Unified Shell version
   * @returns HTML Status
   * @private
   */
  private async handleNewVersion({assetsCached, spaVersion}: UpdateResponse): Promise<HTMLStatus> {
    const windowConfig = getWindowConfig();

    // If spaVersion outdated
    const oldVersion = windowConfig.spaVersion;

    if (oldVersion.startsWith('PR-')) {
      // This is a PR, not really outdated.
      return HTMLStatus.OK;
    }

    if (spaVersion !== oldVersion) {
      await reload({
        data: {
          assetsCached,
          newVersion: spaVersion,
          oldVersion
        },
        initiator: 'Service Worker',
        reason: 'New Unified Shell Version'
      });
      return HTMLStatus.OUTDATED;
    }

    // Shouldn't happen.
    this.metrics.event('ServiceWorker.invalidVersionUpdate', {assetsCached, oldVersion, spaVersion}, {level: Level.ERROR});
    return HTMLStatus.OK;
  }

  /**
   * Unified Shell loads the cached HTML and waits for a fresh HTML from the service worker.
   * This method returns the maximum amount of time it should wait for a fresh HTML to be ready.
   * We want to keep the timeout is short as possible to avoid delaying Unified Shell load.
   * When the loaded HTML is already fresh (< 5 minutes old), the maximum wait time is 0.
   * When the loaded HTML is under 24 hours old, the maximum wait time is 5 seconds.
   * When the loaded HTML is over 24 hours old, the maximum wait time is 20 seconds.
   * Older cached HTMLs (> 24 hours) are more likely to point at outdated Unified Shell/SPA versions
   * hence the maximum time allowed is longer - Unified Shell will auto-refresh when fresh HTML
   * includes a newer version than the cached one.
   */
  public getWaitTimeout(): number {
    const {lastModified, time} = window.config || {};
    const lastUpdate = time ?? lastModified;
    const timePassed = lastUpdate ? Date.now() - lastUpdate : 0;
    if (timePassed === 0 || timePassed > HTML_RECENT_THRESHOLD) {
      return HTML_LONG_TIMEOUT;
    }
    return timePassed < HTML_VERY_RECENT_THRESHOLD ? 0 : HTML_SHORT_TIMEOUT;
  }

  /**
   * Sends a fetch request to ping service worker to check the live index.html
   * version, compare it against the cached index.html version, and update
   * cached assets as needed. We will also need to reload the page to update
   * the HTML in some cases.
   * @param {Solution} solutionConfig Active solution config
   * @returns true if not reloading. false otherwise (But window.reload will cut execution)
   */
  public async checkHtmlForUpdates(solutionConfig?: Solution): Promise<HTMLStatus> {
    const timeout = this.getWaitTimeout();

    if (timeout === 0 || !isWorkerActivated()) {
      // Don't check for updates if the HTML is very recent or worker not activated.
      return HTMLStatus.OK;
    }

    try {
      const TIMEOUT_RESPONSE = new Response(JSON.stringify({status: 'timeout'}));
      const res = await runWithTimeout(getHtmlFromWorker(), timeout, TIMEOUT_RESPONSE);
      const {data: statusData = {}, status} = await res.json();
      const {config, url} = statusData;

      switch (status) {
        case 'outdated':
          return this.handleOutdatedResponse(config, solutionConfig?.spaAppId);
        case 'new version':
          return this.handleNewVersion(statusData);
        case 'timeout': {
          const ping = await checkCDN();
          this.metrics.event('ServiceWorker.timeout', {ping, timeout}, {level: Level.ERROR});
          return HTMLStatus.TIMEOUT;
        }
        case 'non standard url':
          this.metrics.event('ServiceWorker.nonStandardUrl', {url});
          return HTMLStatus.OK;
        case 'html structure changed':
          this.metrics.event('ServiceWorker.badHtml', statusData, {level: Level.ERROR});
          return HTMLStatus.CORRUPT;
        case 'failed to get html': {
          const ping = await checkCDN();
          this.metrics.event('ServiceWorker.unreachableHtml', {...statusData, online: navigator.onLine, ping}, {level: Level.ERROR});
          return HTMLStatus.UNREACHABLE;
        }
        case 'no change':
          this.metrics.event('ServiceWorker.noHtmlUpdates');
          break;
        default:
          this.metrics.event('ServiceWorker.noHtmlUpdates', {status});
      }
    } catch (err) {
      this.metrics.event('ServiceWorker.failedToCheckHTML', {error: getErrorMessage(err)}, {level: Level.ERROR});
    }
    return HTMLStatus.OK;
  }
}

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, PDH_GLOBAL, RUNTIME, SolutionRoute, THUNDERBIRD} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/spaTemplates.ts

const templateCommon = {
  autoInstantiateRuntime: true,
  analytics: {omegaGlobal: PDH_GLOBAL.BOTH},
  runtime: RUNTIME.CDN,
  sandbox: {
    history: {
      type: HISTORY.HASH,
      config: {addParamsToHash: true}
    },
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013',
      prod: '' // Templates should not run in Prod
    }
  },
  thunderbird: THUNDERBIRD.SERVICE_WORKER
};

export const spaTemplateParcel: SolutionRoute = {
  ...templateCommon,
  appId: 'spaTemplateParcel',
  path: '/spa-templates/parcel-template',
  spaAppId: 'dx-spa-templates-spa-template-parcel'
};

export const spaTemplateTypescriptWebpackRtl: SolutionRoute = {
  ...templateCommon,
  appId: 'spaTemplateTypescriptWebpackRtl',
  path: '/spa-templates/webpack-typescript-rtl-template',
  spaAppId: 'dx-spa-templates-spa-template-typescript-webpack-rtl'
};

export const spaTemplateAEP: SolutionRoute = {
  ...templateCommon,
  appId: 'spaTemplateAEPUI',
  path: '/spa-templates/aep-ui-template',
  spaAppId: 'dx-spa-templates-aep-ui-template',
  urlContext: {key: 'sandbox'}
};

export const ajoUiTemplate: SolutionRoute = {
  ...templateCommon,
  appId: 'ajoUiTemplate',
  path: '/spa-templates/ajo-ui-template',
  spaAppId: 'dx-spa-templates-ajo-ui-template',
  urlContext: {key: 'sandbox'}
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, RUNTIME, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/targetSandbox.ts

const targetSandbox: SolutionRoute = {
  appId: 'targetSandbox',
  path: '/target-sandbox',
  serviceCode: 'dma_target_sandbox',
  runtime: RUNTIME.CDN,
  sandbox: {
    hideInitialSpinner: true,
    history: HISTORY.HASH,
    sources: {
      dev: 'https://localhost.corp.adobe.com:4200',
      qa: 'https://experience-qa.adobe.com/solutions/tnt-bullet',
      stage: 'https://experience-stage.adobe.com/solutions/tnt-bullet',
      prod: ''
    }
  },
  access: {code: ['dma_target_sandbox']}
};

export default targetSandbox;

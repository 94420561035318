/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {Application} from '@adobe/exc-app/metrics/Application';
import {AvatarData, extractTokenMetadata, getErrorMessage, ImsOrganization} from '@exc/shared';
import {getValueFromPath} from '@exc/url';
import hashString from 'string-hash';
import {HISTORY, SolutionRoute} from '../models/Solution';
import type {IMS} from '@adobe/exc-app/user';
import type {ImsProfile} from '@adobe/exc-app/ims/ImsProfile';
import {Internal} from '@adobe/exc-app/internal';
import {Level, Metrics} from '@adobe/exc-app/metrics';

const w = window as any;

const INTERNAL_EMAIL_DOMAINS: string[] = [
  '([a-z]*.)?adobe.com',
  '([a-z]*.)?adobevlab.com',
  'adobe-aemmson.com',
  'adobecreate.com',
  'adobedemo.com',
  'adobedemo.de',
  'adobeeventlab.com',
  'adobetest.com',
  'omniture.com',
  'tryadobe.com'
];
const EMAIL_REGEX = new RegExp(`^(${INTERNAL_EMAIL_DOMAINS.join('|')})$`, 'i');

/**
 * Returns true if the user is an internal user.
 * @param email The user's email address.
 * @returns True if the user is an internal user.
 */
export function isInternal(email: string): boolean {
  return EMAIL_REGEX.test(email.split('@')[1]);
}

/**
 * Returns true if the navigation was an org change.
 * @param {string} oldPath window location pathname.
 * @param {string} newPath history location pathname.
 * @returns {boolean} Returns true if the navigation was an org change.
 */
export function isOrgOrSubOrgChange(oldPath: string, newPath: string): boolean {
  const oldPathArr = oldPath.split('/');
  const newPathArr = newPath.split('/');

  // Check if the tenant has changed
  if (oldPathArr[1] !== newPathArr[1]) {
    return true;
  }
  // Check if the suborg has changed
  if (getValueFromPath(oldPath, 'so') !== getValueFromPath(newPath, 'so')) {
    return true;
  }
  // If the length is not the same, more has changed than just the tenant meaning
  // it is not an org change.
  if (oldPathArr.length !== newPathArr.length) {
    return false;
  }
  // Because we are splitting by '/' arr[0] will be "" and arr[1] will be the tenant.
  for (let i = 2; i < oldPathArr.length - 1; i++) {
    if (oldPathArr[i] !== newPathArr[i]) {
      return false;
    }
  }
  return oldPathArr[1] !== newPathArr[1];
}

interface OrgMetadata {
  orgName?: string;
  orgRegion?: string;
}
/**
 * Get name and acp region of org.
 * @param {string} org String with org.
 * @param {ImsOrganization[]} map List of orgs.
 * @returns {orgMetadata} Org name and Org region.
 */
export function getOrgMetadata(org: string, map: ImsOrganization[]): OrgMetadata {
  const {orgName, aepRegion: orgRegion} = map.find(({imsOrgId}) => imsOrgId === org) || {};
  return {orgName, orgRegion};
}

/**
 * Debounce a function for the provided wait amount in ms.
 * @param {function} callback - The callback to call once the wait period is over.
 * @param {number} wait - The amount of time to wait in milliseconds.
 * @returns {function}
 */
export function debounce(callback: () => void, wait: number): () => void {
  let timeout: ReturnType<typeof setTimeout>;

  /**
   * Function that does the debouncing.
   */
  return function(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => callback(...args), wait);
  };
}

interface AvatarUrls {
  avatar?: string;
  avatarSrc?: string;
}
/**
 * Gets the preferred avatar URL from the PPS payload.
 * @param {AvatarData} avatar PPS avatar information.
 * @param {number[]} sizes Array of preferred sizes, ordered by preference.
 * @returns {AvatarUrls} Avatar URL and Data URL.
 */
export function getAvatarUrls(avatar: AvatarData, sizes: number[]): AvatarUrls {
  if (avatar && avatar.images) {
    const size: number|undefined = sizes.find(s => avatar.images[String(s)]);
    return {
      avatar: avatar.images[String(size)],
      avatarSrc: avatar.encodedImage ? `data:image/jpeg;base64, ${avatar.encodedImage}` : undefined
    };
  }
  return {};
}

export type GainsightCallback = (solution: string, omegaGlobal: boolean) => void;

interface PromiseContainer<T> {
  promise: Promise<T> | undefined;
}

const gainsightPromise: PromiseContainer<GainsightCallback> = {promise: undefined};
const gainsightInitPromise: PromiseContainer<void> = {promise: undefined};

const pollForCondition = <T>(check: () => T | undefined, interval: number, maxAttempts: number, promiseContainer: PromiseContainer<T>): Promise<T> => {
  if (promiseContainer?.promise) {
    return promiseContainer.promise;
  }
  const promise = new Promise<T>((resolve, reject) => {
    let cnt = 0;
    const poll = () => {
      cnt++;
      const result = check();
      if (result) {
        resolve(result);
      } else if (cnt >= maxAttempts) {
        // Reset the promise, so it can be retried later on.
        promiseContainer && (promiseContainer.promise = undefined);
        reject(new Error(`Condition not met after ${maxAttempts} attempts`));
      } else {
        setTimeout(poll, interval);
      }
    };
    poll();
  });
  promiseContainer && (promiseContainer.promise = promise);
  return promise;
};

/**
 * Promise for grabbing the gainsight callback off of the window. This will
 * be used to trigger when a new app is loaded. This method only runs the
 * promise on the first run and caches the result for future calls.
 * @returns The promise that will include the callback.
 */
export const waitForGainsightCallback = (): Promise<GainsightCallback> => pollForCondition(
  () => w.initGainsight, 500, 10, gainsightPromise
);

/**
 * Promise that waits for Gainsight to be initialized in the Unified Shell frame.
 */
export const waitForGainsightInit = (): Promise<void> => pollForCondition(
  () => w.aptrinsic?.init, 500, 20, gainsightInitPromise
);

/**
 * Enhances security and defense against HTML injections on post messages.
 * @returns {string} Valid source strings translated to a regex string.
 */
export function getValidSourcesRegexString(): RegExp {
  const frameSrc = w.config.csp?.frameSrc || [];

  if (!frameSrc.length) {
    return /.*/;
  }

  let regexSources = '';
  frameSrc.filter((str: string) => !str.includes('blob:')).map((str: string) => {
    str = str.replace(/\./g, '\\.').replace(/\*/g, '.*').replace(/\/\//g, '\\/\\/');
    regexSources += `${str}$|`;
  });

  return new RegExp(regexSources.slice(0, -2));
}

/**
 * Wait for idle time to execute.
 * (On browsers supporting the requestIdleCallback API)
 * @param {boolean} background - Wait for idle? (Defaults to true)
 */
export function runInBackground(background = true): Promise<void> {
  const requestCallback = w.requestIdleCallback ?
    w.requestIdleCallback :
    (callback: () => Promise<any>): Promise<any> => callback();
  if (background) {
    return new Promise(resolve => requestCallback(resolve));
  }
  return Promise.resolve();
}

/**
 * Executes a request using a stale-while-revalidate strategy.
 * If data already exists, return it right away and fetch in the background.
 * Otherwise, fetch.
 * @template T
 * @param {() => Promise<T>} getPromise A function to request data.
 * @param {T} cached cached data (Or undefined if no data cached)
 * @param {boolean} runImmediate Whether or not to wait until page is idle to execute promise.
 * @param {err => void} onBackgroundError (Optional) Error handler for background requests only.
 */
export function staleWhileRevalidate<T>(
  getPromise: () => Promise<T>,
  cached: T | undefined,
  runImmediate = false,
  onBackgroundError?: (err: Error) => void): Promise<T> {
  if (cached) {
    (runImmediate ? Promise.resolve() : runInBackground())
      .then(getPromise)
      .catch(err => onBackgroundError && onBackgroundError(err));
    return Promise.resolve(cached);
  }
  return getPromise();
}

export function orgHasServiceCode(imsOrg: string, imsProfile: ImsProfile, serviceCode: string|string[]): boolean {
  const serviceCodes = typeof serviceCode === 'string' ? [serviceCode] : serviceCode;
  if (imsOrg && imsProfile?.projectedProductContext) {
    for (const code of serviceCodes) {
      const pc = imsProfile.projectedProductContext.find(({prodCtx = {}}) =>
        prodCtx.owningEntity === imsOrg && prodCtx.serviceCode === code);
      if (!pc) {
        return false;
      }
    }
    return true;
  }
  return false;
}

export const deepEqual = (x: any, y: any): boolean => {
  const ok = Object.keys, tx = typeof x, ty = typeof y;
  return x && y && tx === 'object' && tx === ty ? (
    ok(x).length === ok(y).length &&
    ok(x).every(key => deepEqual(x[key], y[key]))
  ) : (x === y);
};

export const resetSolutionId = () => Internal.setApplication({solution: {id: 'n/a'}} as Application);

/**
 * Returns the application's history type (Hash, History or Server)
 * @param history - History configuration
 * @returns history type (HASH/HISTORY/SERVER)
 */
export const getHistoryType = ({sandbox: {history}}: SolutionRoute): HISTORY => (typeof history === 'object') ? history.type : history;

/**
 * Returns a hash value of the email address.
 * @param email - User's email address.
 * @returns hash value of the email address.
 */
export const generateUserHash = (email?: string): string | undefined => {
  if (email) {
    email = email.toLowerCase();
    return `${email.substring(0, 2)}${hashString(email)}`;
  }
  return undefined;
};

/**
 * Checks if all org names for profile are available.
 * @param {array} orgsMap - Array of org names
 * @param {IMSProfile} profile - IMS Profile
 *
 * @returns {boolean} True if all profile orgs are in map, false otherwise.
 */
export const allOrgsInProfile = (orgsMap: Record<string, any>[], profile: ImsProfile, metrics: Metrics) => {
  try {
    if (orgsMap && orgsMap.length && profile) {
      const pcEntries = profile.projectedProductContext.filter(pc => pc.prodCtx.serviceCode === 'dma_tartan').map(pc => pc.prodCtx.owningEntity);
      const orgs = orgsMap.map(org => org.imsOrgId);
      if (pcEntries.every(org => orgs.includes(org))) {
        return true;
      }
      metrics.event('Core.cachedOrgsStale');
      return false;
    }
    profile && metrics.event('Core.noCachedOrgs');
  } catch (err) {
    const errMsg = getErrorMessage(err);
    metrics.event('Core.cachedOrgsError', {err: errMsg}, {level: Level.ERROR});
  }
  return false;
};

/**
 * Determine if the customToken argument is valid for the customIms argument.
 * @param customIms IMS client object to validate against.
 * @param customToken Token to validate.
 * @returns True if the token is valid for the client, false otherwise.
 */
export const isCustomTokenValid = (customIms?: IMS, customToken?: string): boolean => {
  if (!customIms || !customToken) {
    return false;
  }
  const sortScopes = (scopes?: string) => scopes?.split(',').sort().join(',');
  const {client_id, scope} = extractTokenMetadata(customToken) || {};
  return client_id === customIms.client_id && sortScopes(scope) === sortScopes(customIms.scopes);
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {ErrorTypes, ErrorView} from '@exc/core';
import {getBestSupportedLocale, SUPPORTED_LOCALES} from '@exc/core/src/utils/locale';
import {IntlProvider} from 'react-intl';
import React from 'react';
import type {WindowStorage} from '@exc/storage/src/storage';

/**
 * Check if storage is available
 * See: {@link https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#feature-detecting_localstorage}
 * @param {string} type - Session type: localStorage or sessionStorage
 * @returns {boolean} true if storage is available
 */
export const storageAvailable = function(type: 'localStorage' | 'sessionStorage') {
  let storage: WindowStorage | undefined;
  try {
    storage = window[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return e instanceof DOMException && (
      e.code === 22 || e.code === 1014 || e.name === 'QuotaExceededError' ||
      e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      (storage?.length !== 0);
  }
};

export default function cookieError() {
  const locale = getBestSupportedLocale([navigator.language], SUPPORTED_LOCALES);
  return (
    <React.Suspense fallback={null}>
      <IntlProvider locale={locale}>
        <div>
          <ErrorView type={ErrorTypes.COOKIE_NEEDED} />
        </div>
      </IntlProvider>
    </React.Suspense>
  );
}

{
  "alpha": "アルファ版",
  "alphaFeedback": "アルファ版フィードバック",
  "beta": "ベータ版",
  "betaFeedback": "ベータ版フィードバック",
  "customReleaseTrial": "体験版",
  "releaseBadgeAlpha": "アルファ版",
  "releaseBadgeBeta": "ベータ版",
  "releaseBadgeDev": "開発",
  "releaseBadgePoc": "POC"
}

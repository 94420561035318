/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {CACHE_SCOPE, DiscoveryCacheConfig, DiscoverySource, PATH_CACHE_METHOD} from '@exc/core/src/models/Discovery';
import {HISTORY, SolutionRoute} from '@exc/core/src/models/Solution';
import type {ProductContext} from '@adobe/exc-app/ims/ImsProfile';

// This file was generated automatically from ../solutions/marketo.ts

const getSubOrgName = (prodCtx: ProductContext): string => {
  const {user_visible_name = null, fulfillable_entity_resource_name = null} = prodCtx;
  // Marketo suborgs should use the user friendly "user_visible_name" from the
  // product context if it exists. Otherwise, use "fulfillable_entity_resource_name".
  return user_visible_name || fulfillable_entity_resource_name;
};

const access = {
  code: ['dma_marketo']
};

const DISCOVERY_CACHE: DiscoveryCacheConfig = {
  pathCacheMethod: PATH_CACHE_METHOD.CACHE_PARAMS,
  scope: CACHE_SCOPE.LOCAL,
  ttl: 86400
};

const getDiscovery = (discovery: string, cache?: boolean): DiscoverySource => ({
  cache: cache ? DISCOVERY_CACHE : undefined,
  discovery,
  payload: ['activeProductContext', 'imsOrg', 'imsProfile', 'path'],
  reduceProfile: true,
  withCredentials: true
});

export const marketo: SolutionRoute = {
  appId: 'marketo',
  experienceLeague: {
    communities: 'marketo',
    filter: 'Marketo Engage'
  },
  helpCenter: {
    hideDefaultResources: true
  },
  path: '/marketo',
  permissionsPolicy: ['clipboard-write'],
  spaAppId: 'meue-exc-launch-page',
  sandbox: {
    history: HISTORY.HASH,
    sources: {
      dev: 'https://localhost.corp.adobe.com:12399'
    }
  },
  access
};

export const marketoEngage: SolutionRoute = {
  appId: 'marketoEngage',
  appRoot: 'marketo',
  experienceLeague: {
    communities: 'marketo',
    filter: 'Marketo Engage'
  },
  helpCenter: {
    hideDefaultResources: true
  },
  path: '/marketo-engage',
  permissionsPolicy: ['clipboard-write'],
  browserParamFilterList: [
    'uShell', 'idsSSOToken', 'symfony', 'xsrfId', 'munchkinId', 'appUrl', 'clusterId', 'dataCenterId', 'languages'
  ],
  sandbox: {
    history: HISTORY.HASH,
    sources: {
      dev: getDiscovery('https://meue-loader.devdocker.marketo.com/discovery'),
      dev2: getDiscovery('https://meueloader.mkto.dev/discovery'),
      int: getDiscovery('https://engage-int.marketo.com/discovery'),
      qa: getDiscovery('https://engage-qe.marketo.com/discovery'),
      stage: getDiscovery('https://engage-qe.marketo.com/discovery'),
      marketo_stage: getDiscovery('https://engage-st.marketo.com/discovery'),
      prod: getDiscovery('https://engage-ab.marketo.com/discovery')
    }
  },
  serviceCode: 'dma_marketo',
  urlContext: {
    key: 'subOrg',
    config: {
      customIdName: 'munchkinId',
      id: 'fulfillable_entity_resource_locator',
      name: getSubOrgName,
      serviceCode: 'dma_marketo',
      useRootPathOnChange: true
    }
  },
  access,
  subOrgAccessOnlyOnCurrentOrg: true
};

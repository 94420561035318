/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {DiscoveryPayloadKeys} from '@exc/core/src/models/Discovery';
import {FLOODGATE_FLAG_PREFIX, HISTORY, RUNTIME, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/firefly.ts

const common = {
  appRoot: 'firefly',
  serviceCode: 'dma_app_builder',
  permissionsPolicy: [
    'accelerometer',
    'camera',
    'clipboard-read',
    'clipboard-write',
    'display-capture',
    'geolocation',
    'gyroscope',
    'magnetometer',
    'microphone',
    'midi'
  ],
  refreshOnImsChange: true,
  runtime: RUNTIME.CDN_LEGACY,
  access: {
    code: ['dma_app_builder', 'dma_app_builder_trial'],
    flag: [`${FLOODGATE_FLAG_PREFIX}firefly-enabled-list`, 'firefly-enabled-list']
  }
};

const payload: DiscoveryPayloadKeys = ['imsOrg', 'fullPath'];

const commonSources = {
  qa: {
    discovery: 'https://jaeger-qa.adobe.io/resolver',
    payload,
    xApiKey: true
  },
  stage: {
    discovery: 'https://jaeger-stage.adobe.io/resolver',
    payload,
    xApiKey: true
  },
  prod: {
    discovery: 'https://jaeger.adobe.io/resolver',
    payload,
    xApiKey: true
  }
};

export const firefly: SolutionRoute = {
  ...common,
  appId: 'firefly',
  path: '/custom-apps',
  refreshOnImsChange: true,
  redirectFrom: [{path: '/myapps'}, {path: '/apps'}],
  sandbox: {
    hideInitialSpinner: true,
    history: HISTORY.SERVER,
    sources: commonSources
  }
};

export const firefly3rd: SolutionRoute = {
  ...common,
  appId: 'firefly-3rd',
  path: '/custom-apps/:id',
  redirectFrom: [{path: '/myapps/:id'}, {path: '/apps/:id'}],
  sandbox: {
    history: HISTORY.SERVER,
    sources: commonSources,
    thirdParty: true
  }
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, PDH_GLOBAL, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/uberReleaseRegistry.ts

const uberReleaseRegistry : SolutionRoute = {
  appId: 'uberReleaseRegistry',
  path: '/uber-release-registry',
  sandbox: {
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:3000/ui/',
      qa: 'https://releaseregistry-dev-va6.stage.cloud.adobe.io/ui/',
      stage: 'https://releaseregistry-dev-va6.stage.cloud.adobe.io/ui/',
      prod: 'https://release-registry.corp.adobe.com/ui/'
    }
  },
  analytics: {omegaGlobal: PDH_GLOBAL.AA}
};

export default uberReleaseRegistry;

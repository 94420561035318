/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, PDH_GLOBAL, RUNTIME, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/permissionsUI.ts

const permissionsUI: SolutionRoute = {
  appId: 'permissionsUI',
  path: '/admin/permissions',
  gainsight: {productKey: 'AP-GLVNMB5BLV8T-2'},
  spaAppId: 'experience-platform-permissions-ui',
  analytics: {omegaGlobal: PDH_GLOBAL.BOTH},
  runtime: RUNTIME.CDN,
  sandbox: {
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  },
  access: {
    code: ['acp'],
    fi: ['acp:acp_data_services_foundation'],
    flag: 'upf-enabled',
    logicOp: 'AND'
  }
};

export default permissionsUI;

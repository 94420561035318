/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

export const SEARCHBAR_WIDTH = 260;
export const SEARCH_ICON_WIDTH = 48;
export const HEADING_CONTAINER_RIGHT_MARGIN = 16;

export enum ELEMENTS {
  APP_SWITCHER = 'app-switcher-button',
  CUSTOM_SEARCH = 'custom-search',
  ENV_LABELS = 'env-labels',
  FEEDBACK = 'feedback-button',
  GLOBAL_SEARCH = 'global-search-placeholder',
  GLOBAL_SEARCH_ICON = 'search-menu',
  HELP = 'help-menu',
  HERO = 'hero-title',
  ORG_SWITCHER = 'org-button',
  OTHER_LABELS = 'other-labels',
  PULSE = 'pulse-button',
  SANDBOX = 'sandbox-button-text',
  SIDEBAR = 'sidebar-button'
}

export const resizingOrder = [
  [ELEMENTS.GLOBAL_SEARCH],
  [ELEMENTS.PULSE, ELEMENTS.HELP],
  [ELEMENTS.CUSTOM_SEARCH],
  [ELEMENTS.FEEDBACK],
  [ELEMENTS.SANDBOX],
  [ELEMENTS.APP_SWITCHER],
  [ELEMENTS.GLOBAL_SEARCH_ICON],
  [ELEMENTS.SIDEBAR],
  [ELEMENTS.HERO],
  [ELEMENTS.HERO],
  [ELEMENTS.ORG_SWITCHER],
  [ELEMENTS.ENV_LABELS],
  [ELEMENTS.OTHER_LABELS]
];

export const ELEMENT_INDICES = {
  [ELEMENTS.GLOBAL_SEARCH]: 0,
  [ELEMENTS.HELP]: 1,
  [ELEMENTS.PULSE]: 1,
  [ELEMENTS.CUSTOM_SEARCH]: 2,
  [ELEMENTS.FEEDBACK]: 3,
  [ELEMENTS.SANDBOX]: 4,
  [ELEMENTS.APP_SWITCHER]: 5,
  [ELEMENTS.GLOBAL_SEARCH_ICON]: 6,
  [ELEMENTS.SIDEBAR]: 7,
  [ELEMENTS.HERO]: 8,
  [ELEMENTS.ORG_SWITCHER]: 10,
  [ELEMENTS.ENV_LABELS]: 11,
  [ELEMENTS.OTHER_LABELS]: 12
};

export const SECOND_ACTION_BAR_ITEMS = [ELEMENTS.ENV_LABELS, ELEMENTS.OTHER_LABELS, ELEMENTS.FEEDBACK];

export enum PAGE_SIZES {
  LARGE = 'L',
  MEDIUM = 'M',
  SMALL = 'S',
  EXTRA_SMALL = 'XS'
}

/**
 * Turns a string representation of pixel style value into a number
 * @param {string} style The pixel value
 * @returns The numeric pixel value
 */
const parsePixels = (style: string) => parseInt(style.replace('px', ''));

/**
 * Returns the extra width added to the element by padding, border, and margin,
 * and, in the case of the sandbox button text and pulse menu, the extra width of the parent.
 * @param {Element} element The element to calculate the extra width of
 * @returns {number} The extra width not included in the clientWidth of the element
 */
const getExtraWidth = (element: Element) => {
  const style = getComputedStyle(element);
  let extraWidth = (
    parsePixels(style.marginLeft) +
    parsePixels(style.marginRight) +
    (parsePixels(style.borderWidth) * 2)
  );
  // For the sandbox text, removing the label also removes the margin around the entire element, as the badge has its own margin
  // For custom search, the margin is added to the div surrounding the button
  if ([ELEMENTS.CUSTOM_SEARCH, ELEMENTS.SANDBOX].includes(element.id as ELEMENTS) && element.parentElement) {
    extraWidth += getExtraWidth(element.parentElement);
  }
  // Pulse sometimes has a wrapper and sometimes doesn't, so we need to check
  if (element.id === ELEMENTS.PULSE && element.parentElement && !element.parentElement.className) {
    extraWidth += getExtraWidth(element.parentElement);
  }
  // There is a 10px gap between the labels and the release badge/workspaces
  if ([ELEMENTS.ENV_LABELS, ELEMENTS.OTHER_LABELS].includes(element.id as ELEMENTS)) {
    extraWidth += 10;
  }
  // The hero icon gains an extra 7px margin when the title is removed
  if (element.id === ELEMENTS.HERO) {
    extraWidth -= 7;
  }
  return extraWidth;
};

/**
 * Given an id, find the element and calculate its width, including extra width (margin, padding, border)
 * @param {string} id The id to use to query
 * @returns {number} The width of the element
 */
export const calculateElementWidth = (id: string) => {
  const element = document.querySelector(`#${id}`);
  if (element) {
    // Removing the searchbar adds the search icon to the header
    if (id === ELEMENTS.GLOBAL_SEARCH) {
      return element.clientWidth - SEARCH_ICON_WIDTH;
    }
    return element.clientWidth + getExtraWidth(element);
  }
  return 0;
};

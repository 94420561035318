/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import metrics from '@adobe/exc-app/metrics';

/**
 * Checks for the gzip signature to ensure a string is compressed.
 */
export const checkForGZip = (dataString: string) => dataString.startsWith('\x1F\x8B');

/**
 * Checks for the valid JSON to prevent errors during parsing.
 */
export const parseIfJson = (dataString: string) => {
  // Invalid JSON will throw an error
  try {
    return JSON.parse(dataString);
  } catch (error) {
    return dataString;
  }
};

/**
 * Checks a data string from a product context for encoding by trying to decode it.
 */
export const decodeBase64String = (dataString: string) => {
  // Check if the data string is Base64 encoded
  try {
    return window.atob(dataString);
  } catch (error) {
    // If it is not Base64 encoded, an error will throw but we do nothing
    return undefined;
  }
};

/**
 * Helper function to decompress a gzipped data string.
 * Used for content hub fulfillable_data in IMS product context.
 * DecompressionStream steps attributed to GitHub user asidko - Alexander Sidko
 * https://gist.github.com/asidko/9c7064027039411a11323eaf7d8ea2a4
 * @param encodedString - A binary string (not base 64, must be decoded first)
 */
export const decompressString = async (encodedString: string) => {
  const decompressMetrics = metrics.create('exc.core.utils.decompressString');
  // Check to confirm it's a gzip compression
  const isGZip = checkForGZip(encodedString);
  if (!isGZip) {
    decompressMetrics.error('Core util decompressString - Data is not compressed with gzip.');
    return;
  }
  const bytes = Uint8Array.from(encodedString, c => c.charCodeAt(0));
  // Try to use DecompressionStream or fallback to pako
  try {
    const cs = new (window as any).DecompressionStream('gzip');
    const writer = cs.writable.getWriter();
    writer.write(bytes);
    writer.close();
    const buffer = await new Response(cs.readable).arrayBuffer();
    return new TextDecoder().decode(buffer);
  } catch (e) {
    decompressMetrics.info('Core util decompressString - Error using DecompressionStream, falling back to pako.', {error: e});
  }
  // Not all browsers support DecompressionStream so we use pako as a fallback
  const pako = await import('pako');
  return pako.inflate(encodedString, {to: 'string'});
};

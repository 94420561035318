/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import React from 'react';
import ReactDOM from 'react-dom';
import UnifiedShell from './js/App';

const config = {
  cdn: '', env: '', region: '', scripts: {}, scriptsPath: '/assets', spaVersion: '', ...(window.config || {})
};

ReactDOM.render(
  <UnifiedShell
    cdn={config.cdn}
    env={config.env}
    region={config.region}
    scriptsPath={config.scriptsPath}
    spaVersion={config.spaVersion} />,
  document.getElementById('exc')
);

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, PDH_GLOBAL, RUNTIME, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/firstmile.ts

const firstmile: SolutionRoute = {
  appId: 'firstmile',
  path: '/firstmile',
  spaAppId: 'dms-mobile-chamaeleon',
  analytics: {omegaGlobal: PDH_GLOBAL.AA},
  runtime: RUNTIME.CDN,
  sandbox: {
    hideInitialSpinner: true,
    history: HISTORY.HISTORY,
    sources: {
      proxy: 'https://local.firstmile.adobe.com',
      dev: 'https://local.firstmile.adobe.com:8888'
    }
  },
  urlContext: {
    key: 'sandbox'
  }
};

export default firstmile;


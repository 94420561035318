/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {LoadState} from '../enums';
import {runInBackground} from '../utils';

export const TIMEOUT_MS = 2000;

interface OrchestraionPromise {
  promise: Promise<void>;
  resolve: () => void;
}

const getNewPromise = (): OrchestraionPromise => {
  let resolve!: () => void;
  const promise = new Promise<void>(resolveFn => resolve = resolveFn);
  return {
    promise,
    resolve
  };
};

class OrchestrationService {
  private state: LoadState = LoadState.INIT;
  private readonly readyForBackground = getNewPromise();
  private readonly loaded = getNewPromise();
  private timer = 0;

  constructor() {
    // Start a timeout for solution load.
    this.startTimeout();
  }

  /**
   * Clear any existing timeouts.
   * @private
   */
  private clearTimeout() {
    if (this.timer) {
      window.clearTimeout(this.timer);
      this.timer = 0;
    }
  }

  /**
   * Set state to timeout if state has not progress in a given time.
   * @private
   */
  private startTimeout() {
    const currentState = this.state;
    this.clearTimeout();
    this.timer = window.setTimeout(() => {
      if (this.state === currentState) {
        // Solution did not progress in a timely manner, timeout.
        this.state = LoadState.TIMEOUT;
        this.timer = 0;
        this.readyForBackground.resolve();
      }
    }, TIMEOUT_MS);
  }

  /**
   * Called when an application starts loading.
   */
  public onStartLoad() {
    if (this.state === LoadState.INIT) {
      this.state = LoadState.LOAD_START;
      this.startTimeout();
    }
  }

  /**
   * Called when an application is done loading.
   */
  public onLoaded() {
    if (this.state !== LoadState.LOADED) {
      if (this.state !== LoadState.TIMEOUT) {
        this.readyForBackground.resolve();
      }
      this.loaded.resolve();
      this.state = LoadState.LOADED;
    }
  }

  /**
   * Returns a promise that resolves when we are ready to run background tasks.
   */
  public waitForBackground(): Promise<void> {
    return this.readyForBackground.promise.then(() => runInBackground(true));
  }

  /**
   * Returns a promise that resolves when the first application has loaded.
   */
  public waitForFirstLoad(idle = true): Promise<void> {
    const {promise} = this.loaded;
    return idle ? promise.then(() => runInBackground(true)) : promise;
  }
}

const orchestrationService = new OrchestrationService();

export default orchestrationService;

/*************************************************************************
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: Adobe permits you to use, modify, and distribute this file in
 * accordance with the terms of the Adobe license agreement accompanying
 * it. If you have received this file from a source other than Adobe,
 * then your use, modification, or distribution of it requires the prior
 * written permission of Adobe.
 **************************************************************************/

/**
 * The Feature Flags API provides feature flag data from LaunchDarkly and Floodgate.
 *
 * To consume this API, add the following import to your code.
 *
 * ```typescript
 * import featureFlags from '@adobe/exc-app/featureflags';
 * ```
 *
 * The default export is an object of type [FeatureFlagsApi](../interfaces/_featureflags_.featureflags.md)
 *
 * API reference: [scroll down](#index)
 *
 * ### Sample code
 *
 * ```typescript
 * import featureFlags from '@adobe/exc-app/featureflags';
 *
 * const featureFlags = await featureFlags.get(['cjm', 'experience-platform']);
 * ```
 *
 * Feature flags can also be requested from Floodgate by:
 * - including the provider in the options
 * - including the clientIds in the options (instead of projectIds)
 * If no provider is specified, the default provider is LaunchDarkly.
 *
 * ```typescript
 * import featureFlags, {PROVIDERS} from '@adobe/exc-app/featureflags';
 *
 * const floodgateFlags = await featureFlags().get({clientIds: ['unified-shell'], provider: PROVIDERS.FLOODGATE});
 * ```
 *
 * All feature flags can be requested using the key '*'
 * ```typescript
 * import featureFlags, {PROVIDERS} from '@adobe/exc-app/featureflags';
 *
 * const featureFlags = await featureFlags.get(['*']);
 *
 * const featureFlags = await featureFlags.get({clientIds: ['*'], provider: PROVIDERS.FLOODGATE});
 *
 * ```
 * In some cases you may want to include context options that are not already
 * included in the current app's active context. For example, requesting feature
 * flags for a project that uses sandboxes from an app that does not.
 *
 * To include options in the request, pass an object with the projectIds or clientIds and the context options.
 *
 * ```typescript
 * import featureFlags from '@adobe/exc-app/featureflags';
 *
 * const sandbox = {name: 'name'};
 * const featureFlags = await featureFlags.get({projectsIds: ['cjm'], sandbox});
 * ```
 *
 * API for fetching app data.
 * @packageDocumentation
 * @module featureflags
 */

export enum PROVIDERS {
  FLOODGATE = 'Floodgate',
  LAUNCH_DARKLY = 'Launch Darkly'
}

export enum SANDBOX_TYPES {
  development = 'DEVELOPMENT',
  production = 'PRODUCTION'
}

import {getImpl} from './src/Global';

export interface FeatureFlagConfig {
  /**
   * If true, the feature flags will be fetched from the server and not from the cache.
   */
  force?: boolean;
}

/**
 * A feature flag object.
 */
export interface FlattenedFeatureFlag {
  [name: string]: string;
}

/**
 * The response from the featureflags service.
 */
export interface FeatureFlagsResponse {
  [projectId: string]: FlattenedFeatureFlag;
}

/**
 * The additional context options that can be optionally included.
 * For example, include a sandbox for an API call from an app that isn't
 * configured with a sandbox.
 * If you require additional support for context options not listed here,
 * please reach out to the Unified Shell team.
 */
export interface SandboxOptions {
  name: string;
  region?: string;
  type?: SANDBOX_TYPES;
}

export type GetFlagOptions<T> = T & {
  config?: FeatureFlagConfig;
  provider?: PROVIDERS;
  sandbox?: SandboxOptions;
};

export interface FloodgateOptions {
  clientIds: string[];
}

export interface LaunchDarklyOptions {
  projectIds: string[];
}

/**
 * The data object when the projects and options are sent as a message through the API.
 */
export type GetFlagsWithOptions = GetFlagOptions<FloodgateOptions> | GetFlagOptions<LaunchDarklyOptions>;

/**
 * The input parameters for the feature flags API.
 * This should be a list of LaunchDarkly project ids to get.
 * You'll find a list of allowed projectIds in the
 * documentation site:
 * https://git.corp.adobe.com/pages/exc/unified-shell-docs/docs/intro
 */

export type ProjectIds = string[] | GetFlagsWithOptions;

/**
 * APIs to get featureFlags.
 */
export interface FeatureFlagsApi {
  /**
   * Gets feature flags by array of projectIds or ['*']
   * You'll find a list of allowed projectIds in the
   * documentation site:
   * https://git.corp.adobe.com/pages/exc/unified-shell-docs/docs/intro
   * @param projectIds ProjectIds used to identify feature flags to retrieve.
   * @returns A promise for the feature flags.
   */
  get(projectIds: ProjectIds): Promise<FeatureFlagsResponse>;
}

const featureFlags: FeatureFlagsApi = {
  get: (params: ProjectIds) => {
    return getImpl('featureFlags')().get(params);
  }
};

export default featureFlags;

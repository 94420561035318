/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

interface ScriptList {
  [name: string]: {
    readonly cssHash?: string[];
    readonly hash: string[];
    readonly when: string;
  };
}

interface Config {
  cdn?: string;
  env?: string;
  region?: string;
  spaVersion?: string;
  scriptsPath?: string;
  scripts?: ScriptList;
  preloaded?: {
    [href: string]: boolean;
  };
}

interface Preloader {
  asset: (path: string, resourceType: string) => void;
  script: (name: string) => void;
  onNeed: (when: string) => void;
}

export const preload = (doc: Document, config: Config): Preloader => {
  const cdn: string = config.cdn || '';
  const scripts: ScriptList = config.scripts || {};
  const scriptsPath: string = config.scriptsPath || '/assets';

  function asset(href: string, resourceType: string): void {
    config.preloaded = config.preloaded || {};

    if (!config.preloaded[href]) {
      const preloadLink = doc.createElement('link');
      preloadLink.rel = 'preload';
      preloadLink.as = resourceType;
      preloadLink.href = href;
      preloadLink.crossOrigin = 'anonymous';
      doc.head.appendChild(preloadLink);
      config.preloaded[href] = true;
    }
  }

  function script(name: string): void {
    if (scripts[name]) {
      const scriptInfo = scripts[name];
      scriptInfo.hash && scriptInfo.hash.forEach(hash =>
        hash && asset(`${cdn}${scriptsPath}/${name}.${hash}.js`, 'script')
      );

      scriptInfo.cssHash && scriptInfo.cssHash.forEach(hash =>
        hash && asset(`${cdn}${scriptsPath}/${name}.${hash}.css`, 'style')
      );
    }
  }

  function onNeed(when: string): void {
    for (const name in scripts) {
      const scriptInfo = scripts[name];
      if (scriptInfo.when === when) {
        script(name);
      }
    }
  }

  return {asset, onNeed, script};
};


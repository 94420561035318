/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import AdobeExperienceCloud from 'url:@exc/brand-icons/hero/AdobeExperienceCloud.svg';
import AdobeExperiencePlatform from 'url:@exc/brand-icons/hero/AdobeExperiencePlatform.svg';
import AdobeExperienceSubCloud from 'url:@exc/brand-icons/hero/AdobeExperienceSubCloud.svg';
import {getBootstrap} from './services/BootstrapService';
import {getPathWithoutTenant, hashToPath} from '@exc/url';
import HeroIcons from 'url:@exc/brand-icons/HeroIcons.svg';
import {RELEASE_TYPE} from '@exc/core/src/models/Solution';
import WorkfrontHero from 'url:@exc/brand-icons/hero/WorkfrontHero.svg';

/**
 * This is something of a stop gap until work hub takes over more configuration work.
 * There exist apps that either have internal integrations, like Analytics,
 * or composed apps that require the same branding to be used for anything opened
 * inside of their context. To avoid needing to add explicit overrides for every
 * edge case or copying multiple things between solution configs and app assembly
 * we're just putting the branding for those cases here for now.
 */
export const COMPOSED_APP_CONFIGS = [
  // AJO is a composed application
  {
    heroConfig: {
      favicon: AdobeExperienceCloud,
      icon: `${HeroIcons}#AdobeExperienceCloud`,
      path: '/journey-optimizer/home',
      shortTitle: 'Journey Optimizer',
      title: 'Adobe Journey Optimizer'
    },
    id: 'journey-optimizer',
    pathMatchFragment: ['/cjm', '/journey-optimizer']
  },
  // Data Collection is a composed application
  {
    heroConfig: {
      favicon: AdobeExperienceCloud,
      icon: `${HeroIcons}#AdobeExperienceCloud`,
      path: '/data-collection',
      shortTitle: 'Data Collection',
      title: 'Adobe Experience Platform Data Collection'
    },
    id: 'data-collection',
    pathMatchFragment: '/data-collection'
  },
  // Primetime has several parts with different appids that need the same branding
  {
    heroConfig: {
      favicon: AdobeExperienceSubCloud,
      icon: `${HeroIcons}#AdobeExperienceSubCloud`,
      path: '/primetime/home',
      shortTitle: 'Primetime',
      title: 'Adobe Primetime'
    },
    id: 'primetime',
    pathMatchFragment: '/primetime/home'
  },
  {
    heroConfig: {
      favicon: AdobeExperienceSubCloud,
      icon: `${HeroIcons}#AdobeExperienceSubCloud`,
      path: '/primetime/aiq',
      shortTitle: 'Primetime Account IQ',
      title: 'Adobe Primetime Account IQ'
    },
    id: 'primetime',
    pathMatchFragment: '/primetime/aiq'
  },
  {
    heroConfig: {
      favicon: AdobeExperienceSubCloud,
      icon: `${HeroIcons}#AdobeExperienceSubCloud`,
      path: '/primetime/dai',
      shortTitle: 'Adobe Primetime Ad Insertion',
      title: 'Adobe Primetime Ad Insertion'
    },
    id: 'primetime',
    pathMatchFragment: '/primetime/dai'
  },
  {
    heroConfig: {
      favicon: AdobeExperienceSubCloud,
      icon: `${HeroIcons}#AdobeExperienceSubCloud`,
      path: '/pass/authentication',
      shortTitle: 'Pass Authentication',
      title: 'Adobe Pass Authentication'
    },
    id: 'adobepassunifiedshellconsoleclient',
    pathMatchFragment: '/pass/authentication'
  },
  // Analytics isn't technically a composed app, but does have some internal integrations
  // like data governance that need Analytics branding applied to them
  {
    heroConfig: {
      favicon: AdobeExperienceSubCloud,
      icon: `${HeroIcons}#AdobeExperienceSubCloud`,
      path: '/analytics/spa/index.html',
      shortTitle: 'Analytics',
      title: 'Adobe Analytics'
    },
    id: 'analytics',
    pathMatchFragment: '/analytics'
  },
  {
    heroConfig: {
      favicon: AdobeExperienceSubCloud,
      icon: `${HeroIcons}#AdobeExperienceSubCloud`,
      path: '/platform/analytics',
      shortTitle: 'Customer Journey Analytics',
      title: 'Customer Journey Analytics'
    },
    id: 'platformAnalytics',
    pathMatchFragment: '/platform/analytics'
  },
  {
    heroConfig: {
      favicon: AdobeExperiencePlatform,
      icon: `${HeroIcons}#AdobeExperiencePlatform`,
      path: '/platform',
      shortTitle: 'Experience Platform',
      title: 'Adobe Experience Platform'
    },
    id: 'experiencePlatformUI',
    pathMatchFragment: '/platform',
    pathMatchIgnore: ['/platform/analytics', '/journey-optimizer-b2b/platform']
  },
  {
    heroConfig: {
      favicon: AdobeExperienceCloud,
      icon: `${HeroIcons}#AdobeExperienceCloud`,
      path: '/rtcdp-collaboration',
      shortTitle: 'RTCDP Collaboration',
      title: 'Real-Time CDP Collaboration'
    },
    id: 'agoraUI',
    pathMatchFragment: '/rtcdp-collaboration'
  },
  {
    heroConfig: {
      favicon: AdobeExperienceSubCloud,
      icon: `${HeroIcons}#AdobeExperienceSubCloud`,
      path: '/acc',
      shortTitle: 'Campaign',
      title: 'Adobe Campaign'
    },
    id: 'accwebui',
    pathMatchFragment: '/acc'
  },
  // While the hero is being rewritten
  {
    heroConfig: {
      favicon: AdobeExperienceSubCloud,
      icon: `${HeroIcons}#AdobeExperienceSubCloud`,
      path: '/marketo',
      shortTitle: 'Marketo Engage',
      title: 'Adobe Marketo Engage'
    },
    id: 'marketo',
    pathMatchFragment: '/marketo'
  },
  {
    heroConfig: {
      favicon: AdobeExperienceSubCloud,
      icon: `${HeroIcons}#AdobeExperienceSubCloud`,
      path: '/mix-modeler',
      shortTitle: 'Mix Modeler',
      title: 'Adobe Mix Modeler'
    },
    id: 'adobemixmodeler',
    pathMatchFragment: '/mix-modeler'
  },
  {
    heroConfig: {
      favicon: AdobeExperienceSubCloud,
      icon: `${HeroIcons}#AdobeExperienceSubCloud`,
      path: '/journey-optimizer-b2b/home',
      shortTitle: 'Journey Optimizer B2B Edition',
      title: 'Adobe Journey Optimizer B2B Edition'
    },
    id: 'sapphireHome',
    pathMatchFragment: '/journey-optimizer-b2b'
  },
  {
    heroConfig: {
      favicon: AdobeExperienceCloud,
      icon: `${HeroIcons}#AdobeExperienceCloud`,
      path: '/advertising/ssc',
      shortTitle: 'Search, Social, & Commerce',
      title: 'Adobe Advertising Search, Social, & Commerce'
    },
    id: 'advertising-ssc',
    pathMatchFragment: '/advertising/ssc'
  },
  {
    heroConfig: {
      favicon: AdobeExperienceSubCloud,
      icon: `${HeroIcons}#AdobeExperienceSubCloud`,
      path: '/target',
      shortTitle: 'Target',
      title: 'Adobe Target'
    },
    id: 'target',
    pathMatchFragment: '/target'
  },
  // Orion is a composed application
  {
    heroConfig: {
      favicon: AdobeExperienceCloud,
      icon: `${HeroIcons}#AdobeExperienceCloud`,
      path: '/orion/home',
      releaseType: RELEASE_TYPE.ALPHA,
      shortTitle: 'Project Orion',
      title: 'Project Orion'
    },
    id: 'orion',
    pathMatchFragment: ['/orion']
  },
  // Gen Studio is a composed application
  {
    heroConfig: {
      favicon: AdobeExperienceSubCloud,
      icon: `${HeroIcons}#AdobeExperienceSubCloud`,
      path: '/assets/contenthub',
      shortTitle: 'Experience Manager Assets Content Hub',
      title: 'Adobe Experience Manager Assets Content Hub'
    },
    id: 'ContentHub',
    pathMatchFragment: ['/assets/contenthub']
  },
  {
    heroConfig: {
      favicon: AdobeExperienceSubCloud,
      icon: `${HeroIcons}#AdobeExperienceSubCloud`,
      path: '/genstudio',
      releaseType: RELEASE_TYPE.BETA,
      shortTitle: 'GenStudio',
      title: 'Adobe GenStudio'
    },
    id: 'genstudio',
    pathMatchFragment: ['/genstudio']
  }
];

/**
 * These serve as the default branding of apps based off of their app assembly type.
 * These assumptions do not hold true for composed apps or some one off integrations.
 */
export const HERO_CONFIGS = {
  application: {
    favicon: AdobeExperienceSubCloud,
    icon: `${HeroIcons}#AdobeExperienceSubCloud`,
    shortTitle: 'Adobe Experience Cloud'
  },
  cloud: {
    favicon: AdobeExperienceCloud,
    icon: `${HeroIcons}#AdobeExperienceCloud`,
    path: '/home',
    shortTitle: 'Adobe Experience Cloud'
  },
  fusion: {
    favicon: WorkfrontHero,
    icon: `${HeroIcons}#WorkfrontHero`,
    shortTitle: 'Workfront Fusion'
  },
  platform: {
    favicon: AdobeExperiencePlatform,
    icon: `${HeroIcons}#AdobeExperiencePlatform`,
    shortTitle: 'Adobe Experience Platform'
  },
  service: {
    favicon: AdobeExperienceCloud,
    icon: `${HeroIcons}#AdobeExperienceCloud`,
    shortTitle: 'Adobe Experience Cloud'
  },
  workfront: {
    favicon: WorkfrontHero,
    icon: `${HeroIcons}#WorkfrontHero`,
    shortTitle: 'Workfront'
  }
};

export const getHeroConfig = (shellResponse, solutionConfig, stateHero) => {
  const bootstrap = getBootstrap();
  // Grab the current solution or service in order to get the proper name string
  const allSolutionsAndServices = (shellResponse.solutions || []).concat(shellResponse.services || []);
  const hashPath = getPathWithoutTenant(hashToPath());

  const composedAppConfig = COMPOSED_APP_CONFIGS.find(({pathMatchFragment, pathMatchIgnore}) => {
    const pathstoMatch = [].concat(pathMatchFragment);
    const matched = pathstoMatch.find(path => new RegExp(`^${path}(/|$)`).test(hashPath));
    return matched && (!pathMatchIgnore || !pathMatchIgnore.some(path => hashPath.startsWith(path)));
  });

  const current = allSolutionsAndServices.find(solServ => {
    const apps = Array.isArray(solServ.appId) ? solServ.appId : [solServ.appId];
    const appId = hashPath.includes('/journey-optimizer/') ? 'cjm-home' : solutionConfig.appId;
    return apps.indexOf(appId) > -1;
  });

  let newHero = HERO_CONFIGS.cloud;
  const type = (current && current.adobeBrandType) || 'cloud';
  const {longName, name, releaseType} = current || {};

  if (type !== 'cloud' || longName === 'Admin Tool') {
    // State hero is used here to push runtime changes forward in case getHeroConfig
    // is called in such a way that App Assembly load or a Solution config change occurs
    // after a meaningful runtime change has been made.
    let runtimeOverrides = {};
    if (stateHero) {
      runtimeOverrides = {path: stateHero.path};
      if (solutionConfig?.sandbox?.thirdParty) {
        runtimeOverrides = stateHero;
      }
    }

    const baseHeroConfig = HERO_CONFIGS[type];
    // Precedence: Runtime set hero values, solution set hero values, default type values.
    newHero = {...baseHeroConfig, // shortTitle / name is the only required label for a solution, so all other labels fallback to it
      // or are generated from it.
      releaseType: bootstrap.isDemoMode() ? RELEASE_TYPE.GA : (releaseType || baseHeroConfig.releaseType),
      shortTitle: name || baseHeroConfig.shortTitle,
      title: longName || name || baseHeroConfig.title, ...runtimeOverrides};
  }

  // Composed apps should override everything else (currently) with shared branding / behavior
  if (composedAppConfig) {
    newHero = {composed: true, ...newHero, ...composedAppConfig.heroConfig};
  }

  // Find and set the favicon to the new hero icon
  document.querySelectorAll('#favicon').forEach(link => {
    link.href = newHero.favicon;
  });

  return newHero;
};

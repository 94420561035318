/*************************************************************************
 * Copyright 2020 Adobe
 * All Rights Reserved.
 *
 * NOTICE: Adobe permits you to use, modify, and distribute this file in
 * accordance with the terms of the Adobe license agreement accompanying
 * it. If you have received this file from a source other than Adobe,
 * then your use, modification, or distribution of it requires the prior
 * written permission of Adobe.
 **************************************************************************/

/**
 * @packageDocumentation
 * @module metrics
 */

/**
 * Named events in loading lifecycle
 */
/**
 * @ignore
 */
export enum Events {
  PAGE_LOAD_DONE= 'exc.metrics.pageState.load.done',
  PAGE_LOAD_START= 'exc.metrics.pageState.load.start',
  SPINNER_DONE = 'exc.metrics.pageState.spinner.done',
  SPINNER_START= 'exc.metrics.pageState.spinner.start'
}

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
/* eslint-disable no-useless-escape */
export function del(name: string, secure: boolean): void {
  document.cookie = `${name}=;domain=.adobe.com;expires=Thu, 01 Jan 1970 00:00:00 UTC;${secure ? 'secure' : ''}`;
}

export function get(name: string): string|undefined {
  const cookie = decodeURIComponent(document.cookie)
    .split(';')
    .find(c => (new RegExp(`\s*${name}=`)).test(c));
  return cookie && cookie.split('=')[1];
}

export function set(name: string, value: string, secure: boolean): void {
  document.cookie = `${name}=${value};domain=.adobe.com;${secure ? 'secure' : ''}`;
}

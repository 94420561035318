/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, PDH_GLOBAL, RUNTIME, SolutionRoute, THUNDERBIRD} from '@exc/core/src/models/Solution';
import type {ProductContext} from '@adobe/exc-app/ims/ImsProfile';
import {translationConfig} from './translationService';

// This file was generated automatically from ../solutions/hummingbird.ts

type HummingbirdFD = {
  code?: string;
  munchkinId?: string;
  custPrefix?: string;
  geo?: string;
};

export const getSubOrgName = (prodCtx: ProductContext): string => {
  // fallback to ferl if fullfillable_data.custPrefix doesn't exist
  let subOrg = prodCtx.fulfillable_entity_resource_locator;
  let fd: HummingbirdFD = {};
  try {
    fd = JSON.parse(prodCtx.fulfillable_data || '{}');
  } catch (_err) {
    fd = {};
  }
  if (fd.custPrefix) {
    subOrg = fd.custPrefix;
  }
  return subOrg;
};

const baseConfig: Partial<SolutionRoute> = {
  access: {code: ['dma_dynamic_chat']},
  urlContext: {
    key: 'subOrg',
    config: {
      dontWaitForLoad: true,
      id: 'fulfillable_entity_resource_locator',
      name: getSubOrgName,
      orgIdName: getSubOrgName,
      serviceCode: 'dma_dynamic_chat'
    }
  }
};

export const hummingbird: SolutionRoute = {
  ...baseConfig,
  appId: 'hummingbird',
  analytics: {omegaGlobal: PDH_GLOBAL.BOTH},
  gainsight: {productKey: 'AP-BMO3UTBNWBLE-2'},
  path: '/dynamic-chat',
  refreshOnImsChange: true,
  runtime: RUNTIME.CDN,
  sandbox: {
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:3000'
    }
  },
  spaAppId: 'Project-Hummingbird-designer-ui',
  serviceCode: 'dma_dynamic_chat',
  thunderbird: THUNDERBIRD.SERVICE_WORKER
};

export const hummingbirdTranslations: SolutionRoute = {
  ...baseConfig,
  ...translationConfig,
  appId: 'dynamic-chat-translationService',
  path: '/dynamic-chat/translations',
  metadata: {
    productId: 'DynamicChat',
    productName: 'Adobe Dynamic Chat'
  }
};

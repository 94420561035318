/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, PDH_GLOBAL, RUNTIME, SolutionRoute, THUNDERBIRD} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/journeysNextAdmin.ts

const journeysNextAdmin: SolutionRoute = {
  appId: 'journeysNextAdmin',
  analytics: {omegaGlobal: PDH_GLOBAL.WEBSDK},
  experienceLeague: {
    communities: 'journey-orchestration',
    filter: 'Journey Orchestration'
  },
  path: '/journeys',
  redirectFrom: [{path: '/journeys/v2'}],
  runtime: RUNTIME.CDN,
  spaAppId: 'Voyager-voyager-authoring-ui-react',
  sandbox: {
    history: {type: HISTORY.HASH, config: {addParamsToHash: true}},
    pathPrefix: {default: '/admin-app', preHash: true},
    sources: {
      dev: 'https://localhost.corp.adobe.com:8098'
    }
  },
  access: {code: ['bso']},
  thunderbird: THUNDERBIRD.SERVICE_WORKER
};

export default journeysNextAdmin;

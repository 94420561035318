/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

/**
 * Some apps generate URLs that aren't technically spec compliant and when copied into things like Teams or Slack
 * get mangled since Teams and Slack encode problematic characters to make the urls spec compliant. The problem with this is
 * that when we recieve weirdly encoded URLs unexpected things can happen and apps lose metadata they were expecting.
 * It's particularly problematic in cases where we call discovery or need to pass the url to another service that
 * might then also need to encode or decode it. This function aims to resolve the issue with the '%23' or # instance
 * of this issue, but it theoretically could crop up with other percentage encoding reserved characters
 * like those listed here: https://developer.mozilla.org/en-US/docs/Glossary/percent-encoding
 * @param {ReactRouterHistory} routerHistory
 */
const decodeUrl = routerHistory => {
  const originalHash = window.location?.hash;
  if (originalHash) {
    const unencodedUrl = originalHash.replace('%23', '#');
    (unencodedUrl !== originalHash) && routerHistory.replace(unencodedUrl.slice(1));
  }
};

export default decodeUrl;

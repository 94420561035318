/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {AppSelectorSandbox} from './modules/AppSelectorSandbox';
import {AuthInstance, reload} from '@exc/auth';
import {AuthState, getBootstrap} from './services/BootstrapService';
import {cleanTokenFromHash, getQueryParams, getQueryValue} from '@exc/url/query';
import Core from './Core';
import * as coreLocaleData from './__localization__/CoreStrings';
import {defaultTheme, Provider as SpectrumV3Provider} from '@adobe/react-spectrum';
import {
  encodeForRedirect,
  getCurrentTenantAndPath,
  getPathWithoutTenant,
  getTenantAndPath,
  hashToPath,
  removeQueryParamsFromPath
} from '@exc/url';
import {ErrorTypes} from './enums';
import {getBestSupportedLocale, SUPPORTED_LOCALES} from './utils/locale';
import {history} from '@exc/router';
import {Internal} from '@adobe/exc-app/internal';
import {IntlProvider} from '@quarry/intl';
import metrics, {Level, Events as MetricsEvents} from '@adobe/exc-app/metrics';
import React from 'react';
import Sandbox from './modules/Sandbox';
import ShellWait from '@exc/shell/src/ShellWait';
import {updatePath} from '@exc/url/pathUpdate';

const ACCESS_PREFIX = '#/access_token=';
const SSO_PREFIX = '#/sso:';
const SSO_REGEX = /#\/sso:(.*?)(\/.*|$)/;

const ErrorView = React.lazy(() => import('./modules/Error'));

const waitComponent = (
  <div className="full-screen">
    <ShellWait locale="en-US" />
  </div>
);

export {AppSelectorSandbox, ErrorTypes, ErrorView, getPathWithoutTenant, getQueryValue, Sandbox, waitComponent};

export default class Main extends React.Component {
  constructor(props) {
    super(props);

    this.metrics = metrics.create('exc.core.App');
    this.shellTimer = this.metrics.start('Shell');
    this.metrics.event(MetricsEvents.PAGE_LOAD_START, 'App constructed');
    const locale = getBestSupportedLocale([navigator.language], SUPPORTED_LOCALES);

    this.ssoDomain = null;
    this.debugHashes = []; // Triple of [initialHash, currentHash, newHash]
    let {hash: initialHash} = window.location;
    const firstHash = initialHash;
    if (initialHash.startsWith(SSO_PREFIX)) {
      const [, domain, hash = ''] = initialHash.match(SSO_REGEX) || [];
      if (domain && domain.startsWith('@') && domain.includes('.')) {
        this.ssoDomain = domain;
      }
      updatePath({path: hash}, true);
    }
    // If the hash is #/access_token, that will break the routing, so we need
    // to push that to #/home instead.
    if (initialHash.includes(ACCESS_PREFIX)) {
      updatePath({path: initialHash.substring(0, initialHash.indexOf(ACCESS_PREFIX)).replace(/^#/, '') || '/home'}, true);
    }

    // If the hash is not already encoded (e.g. user is logged out and clicks on a bookmarked link)
    // we should encode the hash to properly redirect once the user is logged in.
    this.redirectUri = window.location.href.includes('old_hash') ?
      window.location.href : encodeForRedirect();

    // This is the trigger that can be used to
    // replace the history with the hash of the login history. The history types
    // are different because login uses browser history while the app uses hash
    // history.
    // The old_hash property is added by us and passed through by IMS so we
    // know what page to load. There are scenarios where this value is
    // encoded and isn't handled properly. This will decode the URL hash to
    // get the correct hash to parse params.
    let hasOldHash = false;
    if (initialHash.includes('old_hash')) {
      this.debugHashes.push([firstHash, window.location.hash, '']);
      initialHash = this.decodeHash(initialHash, /old_hash=/);
      hasOldHash = true;
    }

    // Pull the tenant and path off of the hash so that tenant can be
    // added back later. This allows us to keep tenant in certain scenarios.
    const {path, tenant} = getTenantAndPath(hashToPath({hash: initialHash, removePrecedingSlash: /^#\/(?!@)/.test(initialHash)}));
    const params = getQueryParams(path.replace(/^\//, ''));
    this.redirectTenant = tenant;

    if ('old_hash' in params) {
      initialHash = decodeURIComponent(params.old_hash);
      // Remove any extra old_hash's leaving only the rightmost (oldest) value
      initialHash = initialHash.replace(/.*old_hash=#/, '');
      // If tenant was in the URL, make sure it stays unless hash already
      // has it since old_hash was from a previous session.
      if (tenant && !initialHash.includes('/@')) {
        initialHash = `#/@${tenant}${initialHash.substring(1)}`;
      }
      this.metrics.info('Login.old_hash', {hash: initialHash});
      hasOldHash = true;
    }

    if (hasOldHash) {
      this.debugHashes.push([firstHash, window.location.hash, initialHash]);
      history.replace(hashToPath({hash: initialHash}));
    }

    // Clean token from URL if it exists
    const cleanedHash = cleanTokenFromHash(window.location.hash);
    if (cleanedHash) {
      this.metrics.warn('Access token found in window hash');
      history.replace(hashToPath({hash: cleanedHash}));
    }
    const {search} = location;
    if (search.includes('redirectLocation=')) {
      const urlPath = removeQueryParamsFromPath(`${location.pathname}${search}${location.hash}`, ['redirectLocation']);
      // We need to use window.history and not history here because we are cleaning
      // a query parameter, not the hash.
      window.history.replaceState({}, undefined, urlPath);
    }

    // User is likely offline. With a service worker, Unified Shell can still
    // start loading even if there is no internet connection.
    navigator.onLine || this.verifyNetworkState();

    this.initAuth();

    this.state = {
      auth: null,
      defaultComponent: waitComponent,
      error: null,
      loading: true,
      locale,
      ...this.authState
    };
  }

  /**
   * Verify that the user is indeed offline and show a message in that case.
   */
  async verifyNetworkState() {
    const setOffline = () => this.setState({error: ErrorTypes.OFFLINE});
    try {
      this.metrics.warn('Browser online state is false, verifying');
      const {verifyBrowserIsOffline} = await import('./utils/offlineVerification');
      if (await verifyBrowserIsOffline()) {
        setOffline();
      }
    } catch (error) {
      this.metrics.error('Failed to verify network state', {error: error.toString()});
      // Failed to dynamically import - This can happen if the user is offline
      // and the offline verification script is not yet cached, assume offline.
      setOffline();
    }
    window.addEventListener('online', () => reload({
      initiator: 'Online Check', reason: 'Back Online'
    }));
  }

  async initAuth() {
    const listeners = {
      'auth.accesstokenexpired': async () => {
        this.metrics.event('Auth.userToken.fail', 'Access token expired, logged out', {level: Level.WARN});
        this.state.auth && await this.state.auth.signOut();
      },
      'auth.apiFailure': () => {
        this.metrics.event('Auth.apiFailure', 'API for getting user profile failed. Showing message to user.', {level: Level.WARN});
        this.setState({error: ErrorTypes.UNKNOWN});
      },
      'auth.initializeError': () => {
        // If there is an error during initialization, adobeIMS does an internal redirect
        // to the login page therefore we don't need to handle it ourselves.
        this.metrics.event('Auth.initializeError', 'IMS Token check call aborted. Showing login page to user.', {level: Level.ERROR});
      },
      'auth.rateLimited': () => {
        this.metrics.event('Auth.rateLimited', 'IMS is rate limited. Showing message to user.', {level: Level.WARN});
        this.setState({error: ErrorTypes.RATE_LIMIT});
      },
      'auth.validatetokenfailed': async event => {
        if (!event || event.isExcClient) {
          this.metrics.event('Auth.userToken.fail', 'User token validation failed, logged out', {level: Level.WARN});
          await Internal.flush();
          this.state.auth && await this.state.auth.signOut();
        }
      }
    };

    const bootstrap = getBootstrap();
    const {config} = bootstrap;

    this.metrics.event('Auth.start', getCurrentTenantAndPath());
    const {authState, fromIMS, ...imsObjects} = bootstrap.authInformation || await bootstrap.getAuthInformation();
    const auth = AuthInstance({
      accountClusterPromise: bootstrap.getAccountCluster(),
      env: config.environment,
      imsConfig: {...config.ims},
      imsObjects,
      listeners
    });

    this.shellTimer.time('imslib.loaded');

    if (this.debugHashes.length) {
      this.debugHashes.forEach(([initialHash, currentHash, newHash]) => {
        this.metrics.debug('Index.old_hash', {
          authState,
          fromIMS,
          hash: currentHash,
          href: window.location.href,
          initialHash,
          newHash,
          params: getQueryParams(hashToPath({hash: currentHash, removePrecedingSlash: true}))
        });
      });
      this.debugHashes = [];
    }

    if (authState === AuthState.NO_AUTH) {
      return auth.signIn(this.redirectUri, this.ssoDomain, {
        tenantId: this.redirectTenant
      });
    }

    // If auth is available upfront, this code will execute before the initial state is created
    // in the class constructor. In that case "setState" should not be used and this.authState
    // will be used instead by the constructor.
    this.authState = {auth, isLogin: authState === AuthState.LOGIN, loading: false};
    if (this.state) {
      this.setState(this.authState);
    }
  }

  decodeHash(hash, regex, attempts = 2) {
    if (regex.test(hash) || attempts === 0) {
      return hash;
    }
    return this.decodeHash(decodeURIComponent(hash), regex, attempts - 1);
  }

  /**
   * Move Shell back to a spinner ahead of a logout or reload.
   * Done in order to remove the active iframe and prevent navigation block.
   */
  showSpinner() {
    return new Promise(resolve => this.setState({reloading: true}, resolve));
  }

  onLocale(locale) {
    if (locale && locale !== this.state.locale) {
      this.setState({locale});
    }
  }

  render() {
    const {defaultComponent, loading, locale, reloading} = this.state;
    // If loading or logging off, show spinner.
    const renderSpinner = reloading || loading;
    // If already loaded, track page.

    return (
      <IntlProvider locale={locale} messages={coreLocaleData}>
        {renderSpinner ? <SpectrumV3Provider colorScheme="light" theme={defaultTheme}>{defaultComponent}</SpectrumV3Provider> : null}
        <Core
          auth={this.state.auth}
          defaultComponent={defaultComponent}
          error={this.state.error}
          isLogin={this.state.isLogin}
          listedApps={this.props.listedApps}
          onLocale={currentLocale => this.onLocale(currentLocale)}
          shellTimer={this.shellTimer}
          showSpinner={() => this.showSpinner()}>
          {this.props.children}
        </Core>
      </IntlProvider>
    );
  }
}

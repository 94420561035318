/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

// The ids and displayNames for each capability must match the ids and displayNames
// from Work Hub. If it is not in Work Hub yet, do not add here.
const capabilities = [
  {
    displayName: 'Profiles',
    icon: 'Enterprise',
    id: 'accountProfile'
  },
  {
    displayName: 'Configurations',
    icon: 'Gears',
    id: 'admin'
  },
  {
    displayName: 'Alerts',
    icon: 'Alert',
    id: 'alert'
  },
  {
    displayName: 'App Configurations',
    icon: 'DesktopAndMobile',
    id: 'appconfigurations'
  },
  {
    displayName: 'App Surfaces',
    icon: 'DesktopAndMobile',
    id: 'appsurfaces'
  },
  {
    displayName: 'Applications',
    icon: 'Service',
    id: 'applications'
  },
  {
    displayName: 'Assets',
    icon: 'Asset',
    id: 'assets'
  },
  {
    displayName: 'Assurance',
    icon: 'Assurance',
    id: 'assurance'
  },
  {
    displayName: 'Tags',
    icon: 'ClientLaunch',
    id: 'clientproperties'
  },
  {
    displayName: 'Campaigns',
    icon: 'Promote',
    id: 'campaigns',
    selectOn: [{path: '/journey-optimizer/reports/campaign'}, {path: '/journey-optimizer/campaign/report'}]
  },
  {
    displayName: 'Components',
    icon: 'Attributes',
    id: 'components',
    selectOn: [{path: '/platform/components'}]
  },
  {
    displayName: 'Dashboards',
    icon: 'Dashboard',
    id: 'dashboard',
    selectOn: [{path: '/platform/dashboard'}]
  },
  {
    displayName: 'Dataflows',
    icon: 'DataFlow',
    id: 'dataflow'
  },
  {
    displayName: 'Datasets',
    icon: 'Data',
    id: 'dataset'
  },
  {
    displayName: 'Datastreams',
    icon: 'DataStreams',
    id: 'datastreams'
  },
  {
    displayName: 'Datastreams (Beta)',
    icon: 'DataStreams',
    id: 'datastreamsbeta'
  },
  {
    displayName: 'Destinations',
    icon: 'Destination',
    id: 'destination'
  },
  {
    displayName: 'Home',
    icon: 'Home',
    id: 'home'
  },
  {
    displayName: 'Home',
    icon: 'Home',
    id: 'experiencePlatformUI-home'
  },
  {
    displayName: 'Identities',
    icon: 'IdentityService',
    id: 'identities'
  },
  {
    displayName: 'Identities',
    icon: 'IdentityService',
    id: 'identity'
  },
  {
    displayName: 'Journeys',
    icon: 'Journey',
    id: 'journeys',
    selectOn: [{path: '/journey-optimizer/journey/report'}]
  },
  {
    displayName: 'Journeys',
    icon: 'Journey',
    id: 'journeysNextAdmin'
  },
  {
    displayName: 'Journeys',
    icon: 'Journey',
    id: 'journeysNextAuthoring'
  },
  {
    displayName: 'Landing pages',
    icon: 'LandingPages',
    id: 'landingPages',
    selectOn: [{path: '/journey-optimizer/landing-page/report'}]
  },
  {
    displayName: 'Loyalty Challenge',
    icon: 'InvertAdj',
    id: 'ajoLoyaltyChallenge'
  },
  {
    displayName: 'License Usage',
    icon: 'LicenseUsage',
    id: 'licenseUsage',
    selectOn: [
      {path: '/platform/license-usage'},
      {path: '/journey-optimizer/platform/license-usage'}
    ]
  },
  {
    displayName: 'Messages',
    icon: 'Message',
    id: 'messages'
  },
  {
    displayName: 'Models',
    icon: 'Beaker',
    id: 'model'
  },
  {
    displayName: 'Monitoring',
    icon: 'Monitorings',
    id: 'monitoring'
  },
  {
    displayName: 'Monitoring',
    icon: 'Monitorings',
    id: 'datacollectionmonitoring'
  },
  {
    displayName: 'Notebooks',
    icon: 'Code',
    id: 'notebook'
  },
  {
    displayName: 'Offers',
    icon: 'Offer',
    id: 'offer',
    selectOn: [{path: '/platform/offers'}]
  },
  {
    displayName: 'Components',
    icon: 'OfferActivity',
    id: 'offeractivity'
  },
  {
    displayName: 'Policies',
    icon: 'Privacy',
    id: 'policy'
  },
  {
    displayName: 'Audits',
    icon: 'TaskList',
    id: 'privacyAudit'
  },
  {
    displayName: 'Profiles',
    icon: 'User',
    id: 'profile'
  },
  {
    displayName: 'Queries',
    icon: 'DataSearch',
    id: 'query'
  },
  {
    displayName: 'Requests',
    icon: 'TaskList',
    id: 'request'
  },
  {
    displayName: 'Sandboxes',
    icon: 'Actions',
    id: 'sandbox'
  },
  {
    displayName: 'Schemas',
    icon: 'Schema',
    id: 'schema'
  },
  {
    displayName: 'Segments',
    icon: 'Segmentation',
    id: 'segment'
  },
  {
    displayName: 'Audiences',
    icon: 'Segmentation',
    id: 'segmentAudience'
  },
  {
    displayName: 'Segments',
    icon: 'Segmentation',
    id: 'accountSegments'
  },
  {
    displayName: 'Subscription list',
    icon: 'Subscriptions',
    id: 'subscriptions',
    selectOn: [{path: '/journey-optimizer/subscription/report'}]
  },
  {
    displayName: 'Event Forwarding',
    icon: 'ServerLaunch',
    id: 'serverproperties'
  },
  {
    displayName: 'Services',
    icon: 'Service',
    id: 'service'
  },
  {
    displayName: 'Channels',
    icon: 'Settings',
    id: 'settings'
  },
  {
    displayName: 'Sources',
    icon: 'Source',
    id: 'source'
  },
  {
    displayName: 'Workflows',
    icon: 'Merge',
    id: 'workflow'
  },
  {
    displayName: 'Business rules',
    icon: 'BidRule',
    id: 'businessRules'
  },
  {
    displayName: 'Business rules',
    icon: 'BidRule',
    id: 'controlRules'
  },
  {
    displayName: 'Tags',
    icon: 'UnifiedTags',
    id: 'unifiedTags'
  },
  {
    displayName: 'Tags (Beta)',
    icon: 'UnifiedTags',
    id: 'unifiedTagsBeta'
  },
  {
    displayName: 'Data Hygiene',
    icon: 'DataCleansing',
    id: 'dataCleansing'
  },
  {
    displayName: 'Privacy Console',
    icon: 'PrivacyConsole',
    id: 'privacyConsole'
  },
  {
    displayName: 'Playbooks',
    icon: 'experience',
    id: 'playbooks'
  },
  {
    displayName: 'Initiatives',
    icon: 'project',
    id: 'initiatives'
  },
  {
    displayName: 'Places',
    icon: 'Places',
    id: 'places'
  },
  {
    displayName: 'Permissions',
    icon: 'Permissions',
    id: 'permissionsUI'
  },
  {
    displayName: 'Content Templates',
    icon: 'Experience',
    id: 'contentTemplates'
  },
  {
    displayName: 'Fragments',
    icon: 'FilingCabinet',
    id: 'fragments'
  },
  {
    displayName: 'Overview',
    icon: 'Home',
    id: 'ammhome'
  },
  {
    displayName: 'Marketing Campaigns',
    icon: 'Journey',
    id: 'marketingCampaign'
  },
  {
    displayName: 'Reports',
    icon: 'DataSearch',
    id: 'report',
    selectOn: [{path: '/journey-optimizer/channel/report'}]
  },
  {
    displayName: 'Model Management',
    icon: 'Service',
    id: 'modelmanagement'
  },
  {
    displayName: 'Scenarios',
    icon: 'Offer',
    id: 'scenario'
  },
  {
    displayName: 'Models',
    icon: 'FileData',
    id: 'ammmodels'
  },
  {
    displayName: 'Plans',
    icon: 'FileChart',
    id: 'ammplans'
  },
  {
    displayName: 'Harmonized datasets',
    icon: 'DataCheck',
    id: 'ammharmonization'
  },
  {
    displayName: 'Encryption',
    icon: 'EncryptionKey',
    id: 'platformUIEncryption'
  },
  {
    displayName: 'Profiles',
    icon: 'User',
    id: 'prospectProfile'
  },
  {
    displayName: 'Audiences',
    icon: 'Segmentation',
    id: 'prospectAudience'
  },
  {
    displayName: 'Reports',
    icon: 'FileTxt',
    id: 'channelReporting'
  },
  {
    displayName: 'Items',
    icon: 'DecisionItems',
    id: 'decisionItems'
  },
  {
    displayName: 'Configuration',
    icon: 'DecisionConfiguration',
    id: 'decisionConfiguration'
  },
  {
    displayName: 'Translation projects',
    icon: 'GlobeGrid',
    id: 'translationProjects'
  },
  {
    displayName: 'Translations',
    icon: 'GlobeGrid',
    id: 'translationServiceAdmin'
  },
  {
    displayName: 'Optimization metrics',
    icon: 'SuccessMetric',
    id: 'optimizationMetrics'
  },

  // Sapphire
  {
    displayName: 'Home',
    icon: 'Home',
    id: 'sapphireHome',
    selectOn: [{path: '/journey-optimizer-b2b/home'}]
  },
  {
    displayName: 'Account Journeys',
    icon: 'Journey',
    id: 'sapphireAccountJourney',
    selectOn: [{path: '/journey-optimizer-b2b/account-journeys'}]
  },
  {
    displayName: 'Account Audiences',
    icon: 'Segmentation',
    id: 'sapphireAccountAudience',
    selectOn: [{path: '/journey-optimizer-b2b/platform/account/audience'}]
  },
  {
    displayName: 'Dashboard',
    icon: 'Dashboard',
    id: 'sapphireInsightsDashboard',
    selectOn: [{path: '/journey-optimizer-b2b/insights-dashboard'}]
  },
  {
    displayName: 'Buying Group Templates',
    icon: 'Workflow',
    id: 'sapphireBuyingGroupTemplates',
    selectOn: [{path: '/journey-optimizer-b2b/buying-groups'}]
  },
  {
    displayName: 'Assets',
    icon: 'Asset',
    id: 'sapphireMarketoAssets',
    selectOn: [{path: '/journey-optimizer-b2b/marketo-assets'}, {path: '/journey-optimizer-b2b/assets-listing'}]
  },
  {
    displayName: 'Assets',
    icon: 'Asset',
    id: 'sapphireAssetListing',
    selectOn: [{path: '/journey-optimizer-b2b/assets-listing'}]
  },
  {
    displayName: 'Templates',
    icon: 'Experience',
    id: 'sapphireEmailTemplates',
    selectOn: [{path: '/journey-optimizer-b2b/email-templates'}]
  },
  {
    displayName: 'SMS API Credentials',
    icon: 'Settings',
    id: 'sapphireSMSConfig'
  },
  {
    displayName: 'Account journeys',
    icon: 'JourneyVoyager',
    id: 'sapphireAccountJourneyV2',
    selectOn: [{path: '/journey-optimizer-b2b/account-journeys'}]
  },
  {
    displayName: 'Profiles',
    icon: 'Building',
    id: 'sapphireAccountProfiles'
  },
  {
    displayName: 'Audiences',
    icon: 'Enterprise',
    id: 'sapphireAccountAudienceV2',
    selectOn: [{path: '/journey-optimizer-b2b/platform/account/audience'}]
  },
  {
    displayName: 'Buying groups',
    icon: 'UserGroup',
    id: 'sapphireBuyingGroupTemplatesV2',
    selectOn: [{path: '/journey-optimizer-b2b/buying-groups'}]
  },
  {
    displayName: 'Assets',
    icon: 'Asset',
    id: 'sapphireMarketoAssetsV2',
    selectOn: [{path: '/journey-optimizer-b2b/marketo-assets'}, {path: '/journey-optimizer-b2b/assets-listing'}]
  },
  {
    displayName: 'Fragments',
    icon: 'FilingCabinet',
    id: 'sapphireFragments',
    selectOn: [{path: '/journey-optimizer-b2b/fragments'}]
  },
  {
    displayName: 'Schema',
    icon: 'Schema',
    id: 'sapphireSchema'
  },
  {
    displayName: 'Datasets',
    icon: 'Data',
    id: 'sapphireDatasets'
  },
  {
    displayName: 'Queries',
    icon: 'SQLQuery',
    id: 'sapphireQueries'
  },
  {
    displayName: 'Monitoring',
    icon: 'Monitoring',
    id: 'sapphireMonitoring'
  },
  {
    displayName: 'Audit log',
    icon: 'TaskList',
    id: 'sapphireAuditLog'
  },
  {
    displayName: 'Configuration',
    icon: 'Gears',
    id: 'sapphireAdminConfig'
  },
  {
    displayName: 'Channels',
    icon: 'EmailGear',
    id: 'sapphireChannelConfig',
    selectOn: [{path: '/journey-optimizer-b2b/channels-config'}]
  },
  {
    displayName: 'Permissions',
    icon: 'UserLock',
    id: 'sapphirePermissions'
  },
  {
    displayName: 'Sources',
    icon: 'Source',
    id: 'sapphireSource'
  },
  {
    displayName: 'Destinations',
    icon: 'Destination',
    id: 'sapphireDestination'
  },
  {
    displayName: 'Requests',
    icon: 'TaskList',
    id: 'sapphireRequest'
  },

  // Orion
  {
    displayName: 'Home',
    icon: 'Home',
    id: 'orionHome'
  },
  {
    displayName: 'Campaigns',
    icon: 'Promote',
    id: 'orionCampaigns',
    selectOn: [{path: '/orion/reports/campaign'}]
  },
  {
    displayName: 'Journeys',
    icon: 'Journey',
    id: 'orionJourneys'
  },
  {
    displayName: 'Assets',
    icon: 'Asset',
    id: 'orionAssets'
  },
  {
    displayName: 'Audiences',
    icon: 'Segmentation',
    id: 'orionSegmentAudience'
  },
  {
    displayName: 'Projects',
    icon: 'DesktopAndMobile',
    id: 'orionProjects'
  },
  {
    displayName: 'Schemas',
    icon: 'Schema',
    id: 'orionSchemas'
  },

  // Gen Studio
  {
    displayName: 'Assets',
    icon: 'Asset',
    id: 'genStudioAssets'
  },

  // federated audience compositions
  {
    displayName: 'Models',
    icon: 'Models',
    id: 'federatedModel'
  },
  {
    displayName: 'Audit trail',
    icon: 'Gears',
    id: 'federatedAudit'
  },
  {
    displayName: 'Federated Databases',
    icon: 'FederatedDatabase',
    id: 'federatedDatabase'
  }
];

module.exports = capabilities;

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {PDH_GLOBAL, SolutionRoute} from '@exc/core/src/models/Solution';
import {puebloBaseConfig} from './pueblo';

// This file was generated automatically from ../solutions/landing.ts

const landing: SolutionRoute = {
  ...puebloBaseConfig,
  appId: 'landing',
  analytics: {
    omegaGlobal: PDH_GLOBAL.BOTH
  } as const,
  omegaSuiteId: 'landing',
  dataPrefetchContracts: ['recents-org', 'user-roles', 'dashboard-org'],
  path: '/home',
  permissionsPolicy: ['clipboard-write'],
  redirectFrom: [{path: '/login'}],
  gainsight: {productKey: 'AP-DFHK5XLSAWVI-2'},
  unifiedSearch: {
    access: {
      appIds: ['cjm-home', 'experiencePlatformUI']
    }
  }
};

export default landing;

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {IntlProvider} from '@quarry/intl';
import * as localizationStringsShell from './__localization__/ShellStrings';
import React from 'react';
import Shell from './Shell';

const I18nShell = props =>
  <IntlProvider messages={localizationStringsShell}>
    <Shell {...props} />
  </IntlProvider>;

export {I18nShell as Shell};

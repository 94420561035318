/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import CoreContext from '../context';
import CoreStrings from '../CoreStrings';
import React, {FC, useContext} from 'react';
import ShellWait from '@exc/shell/src/ShellWait';

const Heading = React.lazy<FC<any>>(() => import('@react/react-spectrum/Heading'));

interface SpinnerProps {
  ignoreNav: boolean;
  showWaitMessage: boolean;
}

/**
 * Stylized Wait Component
 */
const SandboxSpinner: FC<SpinnerProps> = ({ignoreNav, showWaitMessage}) => {
  const {intl, sideNavOpen, shellSideNavPresent, shellSideNavWidth} = useContext(CoreContext);
  const sideNavStyles: {style?: Record<string, number | string>} = {};
  const waitingStyles: {style?: Record<string, number | string>} = {};
  if (!ignoreNav && shellSideNavPresent && sideNavOpen) {
    waitingStyles.style = {left: `calc(50% + ${shellSideNavWidth / 2}px)`};
    sideNavStyles.style = {left: shellSideNavWidth};
  }

  return (
    <React.Fragment>
      <div className="spinner-overlay" {...sideNavStyles} />
      <div className="spinner-container spinner-light" {...sideNavStyles}>
        <ShellWait centered locale={intl.locale} size="L" />
      </div>
      {showWaitMessage && (
        <React.Suspense fallback={null}>
          <Heading className="still-waiting" variant="subtitle1" {...waitingStyles}>
            {intl.formatMessage(CoreStrings.iframeWaitingMessage)}
          </Heading>
        </React.Suspense>
      )}
    </React.Fragment>
  );
};

export default SandboxSpinner;

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, RUNTIME, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/sitesMicroFrontends.ts

const getSitesMFEStorybook = (name: string, spaAppId: string): SolutionRoute => {
  const repoName = /^(^cq-)?(.+)/i.exec(spaAppId)?.[2];
  return {
    appId: `${repoName}-storybook`,
    spaAppId,
    path: `/sites-micro-frontends/${repoName}`,
    sandbox: {
      pathPrefix: {default: '/static-assets/storybook/index.html'},
      history: HISTORY.HISTORY,
      sources: {
        dev: 'https://localhost.corp.adobe.com:8443/',
        prod: ''
      }
    },
    runtime: RUNTIME.CDN,
    urlContext: {
      key: 'sandbox',
      optional: true
    }
  };
};

export const sitesMicroFrontendsStorybook: SolutionRoute = getSitesMFEStorybook(
  'Sites Micro Frontends Content Fragment Selector storybook',
  'CQ-sites-content-fragment-selector'
);

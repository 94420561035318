/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {Events} from '@exc/shared';
import {getAppController} from '../utils/appSandboxUtils';
import {getValidSourcesRegexString} from '../utils';
import metrics, {Level, Metrics} from '@adobe/exc-app/metrics';
import React from 'react';
import {Timer} from '@adobe/exc-app/metrics/Metrics';

export interface IframeProps {
  frameKey: string;
  name: string;
  onFrameSrcViolation: (blockedURI: string) => void;
  onInvalidSource: (source: string) => void;
  onMessage: (event: MessageEvent<any>) => void;
  onMount: (iframe: HTMLIFrameElement) => void;
  permissionsPolicy: string;
  sandboxId: string;
  src: string;
}

const LISTENER_TIMEOUT = 30000;

class Iframe extends React.Component<IframeProps> {
  private box?: HTMLIFrameElement;
  private readonly id = `exc-app-sandbox-${Math.random()}`;
  private readonly metrics: Metrics;
  private readonly iframeTimer: Timer;
  private readonly validSourcesRegexString = getValidSourcesRegexString();
  private cspTimeout = 0;

  constructor(props: IframeProps) {
    super(props);
    this.metrics = metrics.create('exc.core.modules.Iframe', props);
    this.metrics.event('Iframe.constructed');
    this.iframeTimer = this.metrics.start('Sandbox.iframe');
  }

  componentDidMount() {
    window.addEventListener('message', this.onPostMessage, false);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onPostMessage, false);
    this.removeCSPViolationListener();
  }

  shouldComponentUpdate(nextProps: IframeProps) {
    return !!nextProps.src && (
      this.props.src !== nextProps.src ||
      this.props.frameKey !== nextProps.frameKey ||
      this.props.name !== nextProps.name);
  }

  private onLoad = () => this.iframeTimer.time('onLoad');

  onPostMessage = (event: MessageEvent<any>) => {
    const {sandboxId} = this.props;
    // This event came from a different window than is assigned to this iframe
    // instance. This can happen because this listens to events on the window.
    // Don't process this, as it will cause badness.
    if (event.source !== this.box?.contentWindow &&
      (event.source !== window || !getAppController().verifyMessage(event.data, sandboxId))) {
      if (event.data?.unifiedShell === 1 && event.data.type === Events.PING) {
        let source = 'unknown';
        try {
          source = (event.source && 'location' in event.source && event.source.location?.href) || 'no-location';
        } catch {
          // Can not access source.
        }
        this.props.onInvalidSource(source);
      }
      return;
    }
    if (event.origin) {
      // Enhances security and defense against HTML injections on post messages.
      // Returns early if the origin is not one of the csp allowed origins.
      if (!this.validSourcesRegexString.test(event.origin)) {
        this.metrics.event('Iframe.postOriginInvalid', 'Invalid origin', {level: Level.WARN}, {origin: event.origin});
        return;
      }
      this.props.onMessage(event);
    }
  };

  public onRef(ref: HTMLIFrameElement | null) {
    if (ref && this.box !== ref) {
      this.box = ref;
      this.props.onMount(this.box);
      this.iframeTimer.time('init');
    }
  }

  onCSPViolation = (event: SecurityPolicyViolationEvent) => {
    const {blockedURI, violatedDirective} = event;
    // Frame source violation during iframe load. This likely will lead to a timeout.
    violatedDirective === 'frame-src' && this.props.onFrameSrcViolation(blockedURI);
  };

  removeCSPViolationListener = () => {
    if (this.cspTimeout) {
      clearTimeout(this.cspTimeout);
      this.cspTimeout = 0;
      window.removeEventListener('securitypolicyviolation', this.onCSPViolation, false);
    }
  };

  private detectCSPViolations() {
    this.removeCSPViolationListener();
    window.addEventListener('securitypolicyviolation', this.onCSPViolation, false);
    this.cspTimeout = window.setTimeout(this.removeCSPViolationListener, LISTENER_TIMEOUT);
  }

  render() {
    const {frameKey, name, permissionsPolicy, src} = this.props;
    src && this.detectCSPViolations();
    return (
      <iframe
        allow={permissionsPolicy}
        id={this.id}
        key={frameKey}
        name={name}
        onLoad={this.onLoad}
        ref={iframeRef => this.onRef(iframeRef)}
        src={src}
        title={name}
      />
    );
  }
}

export default Iframe;

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

export interface IMSFilterOptions {
  imsOrgId?: string;
  tenantId?: string;
}

const STANDARD_METADATA = '"findFirst":true, "fallbackToAA":true, "preferForwardProfile":true';
/**
 * Returns a T2E Profile filter for the given IMS org information.
 * For more information about filter syntax see:
 * {@link https://wiki.corp.adobe.com/display/ims/Profile+Filter+syntax}
 * @param imsOrgId - IMS Org ID (Optional)
 * @param tenantId - Tenant ID (Optional)
 * @returns Profile filter string
 */
export const getImsFilter = ({imsOrgId, tenantId}: IMSFilterOptions = {}): string => {
  let filter = 'hasPC("dma_tartan")';
  // Unified Shell supports both tenant IDs and IMS orgs in the URL.
  // If the tenant ends with "@AdobeOrg", treat it as an IMS org.
  if (imsOrgId || tenantId?.endsWith('@AdobeOrg')) {
    filter = `isOwnedByOrg('${imsOrgId || tenantId}')`;
  } else if (tenantId) {
    filter = `hasPcParam('dma_tartan','tenant_id','${tenantId}')`;
  }
  return `{${STANDARD_METADATA}, "searchEntireCluster":${filter.startsWith('isOwnedByOrg')}}; ${filter}`;
};

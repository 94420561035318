/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {HISTORY, PDH_GLOBAL, SolutionRoute} from '@exc/core/src/models/Solution';

// This file was generated automatically from ../solutions/formsSpa.ts

const formsSpa: SolutionRoute = {
  analytics: {omegaGlobal: PDH_GLOBAL.BOTH} as const,
  appId: 'formsSpa',
  gainsight: {productKey: 'AP-AULLRFDZLJ9K-2'},
  metricsAppId: 'forms-af-wizard',
  omegaSuiteId: 'aem',
  path: '/aem/forms/af/create',
  spaAppId: 'livecycle-forms-spa',
  sandbox: {
    history: HISTORY.HISTORY,
    sources: {
      dev: 'https://localhost.corp.adobe.com:8013'
    }
  }
};

export default formsSpa;

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import * as routes from './routes';
import type {SolutionRoute} from '@exc/core/src/models/Solution';

/**
 * Returns all Unified Shell routes. This method is used:
 * 1. Enable Unit Tests (Can be mocked)
 * 2. Type the imported routes.
 */
export const getRoutes = (): Record<string, SolutionRoute> => routes;
